import React from 'react';
import { styled } from '@compiled/react';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import InlineEdit, { type InlineEditProps } from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { DecoratedDateTime } from '../../decoration/datetime/index.tsx';

type DateTimeFieldProps = {
	isEditable: boolean;
	value: string | undefined;
	decoration: ValueDecoration | undefined;
	skipWrap?: boolean | undefined;
	placeholder?: string | undefined;
	onUpdate: ((inputValue: string | undefined) => void) | undefined;
};

export const DateTimeField = ({
	value,
	decoration,
	onUpdate,
	isEditable,
	skipWrap,
	placeholder,
}: DateTimeFieldProps) => {
	const initialValue = value !== undefined ? value : new Date().toISOString();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleConfirm = (newValue: any) => {
		const valueForUpdate = newValue === '' ? undefined : newValue;
		onUpdate && onUpdate(valueForUpdate);
	};

	const readView = () => (
		<ReadViewContainer>
			{value !== undefined || placeholder !== undefined ? (
				<DecoratedDateTime
					datetime={value}
					decoration={decoration}
					skipWrap={skipWrap}
					placeholder={placeholder}
				/>
			) : null}
		</ReadViewContainer>
	);

	const editView: InlineEditProps<string>['editView'] = (fieldProps) => (
		<DateTimePicker {...fieldProps} autoFocus spacing="compact" />
	);

	return isEditable ? (
		<Box xcss={containerStyles}>
			<InlineEdit
				defaultValue={initialValue}
				readViewFitContainerWidth
				onConfirm={handleConfirm}
				readView={readView}
				editView={editView}
				hideActionButtons
			/>
		</Box>
	) : (
		// Inline Edit component wraps a border around the read view component, we do the same here
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={{ border: '2px solid transparent' }}>{readView()}</div>
	);
};

const containerStyles = xcss({
	marginTop: 'space.negative.100',
	paddingLeft: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	minHeight: '16px',
	overflow: 'hidden',
	wordBreak: 'break-word',
	textOverflow: 'ellipsis',
});
