import React, { useState, useCallback, type SyntheticEvent, useEffect } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import LinkIcon from '@atlaskit/icon/glyph/link';
import { Stack, Inline, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { AccessLevel } from '@atlassian/jira-polaris-common/src/common/types/access/index.tsx';
import { useFullscreenQueryParameter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useCanExportViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { AccessBanner } from './access-banner/index.tsx';
import messages from './messages.tsx';

type ShareDialogContentProps = {
	accessLevel: AccessLevel | undefined;
};

export const ShareDialogContent = ({ accessLevel }: ShareDialogContentProps) => {
	const { error, success } = useNotifications();
	const fullscreen = useFullscreenQueryParameter();
	const { formatMessage } = useIntl();
	const [isFullscreen, setIsFullscreen] = useState(fullscreen);
	const canExportViews = useCanExportViews();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		sendPendoTrackEvent({
			actionSubjectAndAction: 'shareModal opened',
		});

		return () => {
			sendPendoTrackEvent({
				actionSubjectAndAction: 'shareModal closed',
			});
			fireUIAnalytics(createAnalyticsEvent({}), 'modal closed', 'share');
		};
	}, [createAnalyticsEvent]);

	const onCopy = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'copyLink', { isFullscreen });
			sendPendoTrackEvent({
				source: 'shareModal',
				actionSubjectAndAction: 'button clicked',
				actionSubjectId: 'copyLink',
				attributes: {
					isFullscreen,
				},
			});

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const shareUrl = new URL(window.location.href);
			const searchParams = new URLSearchParams(shareUrl.search);
			if (isFullscreen) {
				searchParams.set('fullscreen', 'true');
			}

			shareUrl.search = searchParams.toString();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			(fg('jfp-magma-undefined-navigator') ? globalThis.navigator.clipboard : navigator.clipboard)
				.writeText(shareUrl.toString())
				.then(() =>
					success({
						title: formatMessage(messages.copiedTitle),
						description: formatMessage(messages.copiedMessage),
					}),
				)
				.catch(() =>
					error({
						title: formatMessage(messages.copyFailTitle),
						description: formatMessage(messages.copyFailMessage),
					}),
				);
		},
		[error, formatMessage, success, isFullscreen],
	);

	const onToggleFullscreen = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'fullscreen', { isEnabled: !isFullscreen });
			sendPendoTrackEvent({
				source: 'shareModal',
				actionSubjectAndAction: 'toggle change',
				actionSubjectId: 'fullscreen',
				attributes: {
					isEnabled: !isFullscreen,
				},
			});
			setIsFullscreen((value) => !value);
		},
		[setIsFullscreen, isFullscreen],
	);

	return (
		<Stack space="space.300" xcss={containerStyles}>
			<FireScreenAnalytics attributes={{ isFullscreen }} />
			<Heading size="medium" as="h4">
				{formatMessage(messages.shareTitle)}
			</Heading>
			<AccessBanner accessLevel={accessLevel} />
			<Inline alignBlock="center">
				<Toggle
					isDisabled={!canExportViews}
					id="toggle-share-full-screen"
					onChange={onToggleFullscreen}
					isChecked={isFullscreen}
					size="large"
				/>
				<FullScreenTitle>{formatMessage(messages.shareFullScreenTitle)}</FullScreenTitle>
			</Inline>
			<CopyAction>
				<Button
					id="polaris-common.ui.share.share-content.button"
					onClick={onCopy}
					appearance="primary"
					iconBefore={<LinkIcon label={formatMessage(messages.copyLink)} />}
				>
					{formatMessage(messages.copyLink)}
				</Button>
			</CopyAction>
		</Stack>
	);
};

const containerStyles = xcss({
	width: '500px',
	padding: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CopyAction = styled.div({
	paddingTop: token('space.300', '24px'),
	position: 'relative',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		top: 0,
		left: 0,
		width: '100%',
		position: 'absolute',
		content: '',
		height: token('space.025', '2px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.accent.gray.subtlest', colors.N20),
		borderRadius: token('space.025', '2px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullScreenTitle = styled.p({
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	marginTop: 0,
	marginRight: token('space.100', '8px'),
	marginBottom: 0,
	marginLeft: token('space.100', '8px'),
});
