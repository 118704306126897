import React from 'react';
import { IconButton } from '@atlaskit/button/new';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';

import { messages } from './messages.tsx';

type Props = {
	isDisabled: boolean;
	onClick: () => void;
};

export const TrashButton = ({ isDisabled, onClick }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip content={isDisabled ? formatMessage(messages.tooltipContent) : null}>
			<Box xcss={wrapperStyles} data-component-selector="trash-button-tb1">
				<IconButton
					isDisabled={isDisabled}
					icon={TrashIcon}
					onClick={onClick}
					label={formatMessage(messages.deleteIssueTypeLabel)}
				/>
			</Box>
		</Tooltip>
	);
};

const wrapperStyles = xcss({
	visibility: 'hidden',
});
