import React, { useMemo, useState } from 'react';
import difference from 'lodash/difference';
import { Checkbox } from '@atlaskit/checkbox';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { ProjectTag } from '@atlassian/jira-polaris-lib-project-tag/src/ui/index.tsx';
import { SearchInput } from '@atlassian/jira-polaris-lib-search-input/src/ui/index.tsx';
import { EmptyScreen } from './empty-screen/index.tsx';
import messages from './messages.tsx';
import { ProjectsSkeleton } from './skeleton/index.tsx';
import { useAllProjects } from './utils.tsx';

type Props = {
	ignoredProjectsKeys: string[];
	selectedProjects: ProjectMetadata[];
	onSelectProjects: (selectedProjects: ProjectMetadata[]) => void;
};

export const AddProjectsSidebarBody = ({
	ignoredProjectsKeys,
	selectedProjects,
	onSelectProjects,
}: Props) => {
	const { formatMessage } = useIntl();
	const [searchQuery, setSearchQuery] = useState<string>('');
	const { isLoading, projects: availableProjects } = useAllProjects(ignoredProjectsKeys);

	const filteredProjects = useMemo(
		() =>
			availableProjects.filter((project) =>
				[project.name.toLocaleLowerCase(), project.key.toLocaleLowerCase()].some((value) =>
					value.includes(searchQuery.toLocaleLowerCase()),
				),
			),
		[availableProjects, searchQuery],
	);

	const handleToggleProject = (project: ProjectMetadata) => {
		onSelectProjects(
			selectedProjects.find(({ id }) => project.id === id)
				? selectedProjects.filter(({ id }) => id !== project.id)
				: [...selectedProjects, project],
		);
	};

	const handleToggleAllProjects = () => {
		onSelectProjects(selectedProjects.length > 0 ? [] : filteredProjects);
	};

	const isSomeSelected = selectedProjects.length > 0 && filteredProjects.length > 0;
	const isIndeterminateSelected = useMemo(
		() =>
			isSomeSelected &&
			difference(
				filteredProjects.map((project) => project.id),
				selectedProjects.map((project) => project.id),
			).length > 0,
		[filteredProjects, isSomeSelected, selectedProjects],
	);

	if (availableProjects.length === 0 && !isLoading) {
		return <EmptyScreen />;
	}

	return (
		<>
			<Box paddingBlock="space.150">
				<SearchInput
					testId="polaris-component-collection-configure-sidebar.ui.add-project-content.body.search-input"
					value={searchQuery}
					placeholder={formatMessage(messages.searchPlaceholder)}
					onChange={setSearchQuery}
					isDisabled={isLoading}
				/>
				{filteredProjects.length === 0 && !isLoading && (
					<Box paddingBlockStart="space.100">
						<Stack space="space.150">
							<Text size="small" color="color.text.subtlest">
								{formatMessage(messages.noSearchMatchMessage)}
							</Text>
							<Text weight="semibold" color="color.text.subtlest">
								{formatMessage(messages.noProjectsFoundMessage)}
							</Text>
						</Stack>
					</Box>
				)}
			</Box>
			{isLoading ? (
				<ProjectsSkeleton />
			) : (
				<>
					{filteredProjects.length > 0 && (
						<Box
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							xcss={xcss({
								paddingInlineStart: 'space.150',
								paddingBlock: 'space.100',
								borderBottom: `1px solid ${token('color.border')}`,
							})}
						>
							<Checkbox
								testId="polaris-component-collection-configure-sidebar.ui.add-project-content.body.all-projects-checkbox"
								isChecked={isSomeSelected}
								isIndeterminate={isIndeterminateSelected}
								onChange={handleToggleAllProjects}
								label={
									<Inline>
										<Text weight="semibold" size="small" color="color.text.subtle">
											{formatMessage(messages.selectAllProjectsCheckboxLabel, {
												projectsCount: filteredProjects.length,
											})}
										</Text>
									</Inline>
								}
								width="100%"
							/>
						</Box>
					)}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<Box xcss={xcss({ overflowY: 'auto' })}>
						{filteredProjects.map((project) => (
							<Box
								xcss={
									selectedProjects.some(({ id }) => id === project.id)
										? selectedProjectCheckboxWrapperStyles
										: projectCheckboxWrapperStyles
								}
								key={project.id}
							>
								<Checkbox
									isChecked={selectedProjects.some(({ id }) => id === project.id)}
									onChange={() => handleToggleProject(project)}
									label={
										<ProjectTag
											projectName={project.name}
											projectKey={project.key}
											projectAvatarUrl={project.avatarUrls['16x16']}
											maxWidth={280}
										/>
									}
									width="100%"
								/>
							</Box>
						))}
					</Box>
				</>
			)}
		</>
	);
};

const projectCheckboxWrapperStyles = xcss({
	paddingInlineStart: 'space.150',
	paddingBlock: 'space.100',
});

const selectedProjectCheckboxWrapperStyles = xcss({
	backgroundColor: 'color.background.selected',
	borderLeft: '4px solid',
	borderLeftColor: 'color.border.selected',
	borderRadius: 'border.radius.100',
	paddingInlineStart: 'space.100',
	paddingBlock: 'space.100',
});
