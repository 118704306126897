import React, { useMemo } from 'react';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';

export const PageUfoData = () => {
	const data = useMemo(
		() => ({
			currentUrl: `${globalThis.location.pathname}${globalThis.location.search}`,
		}),
		[],
	);

	return (
		<>
			<UFOCustomData data={data} />
		</>
	);
};
