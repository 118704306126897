import React from 'react';
import { styled } from '@compiled/react';
import type { DocNode } from '@atlaskit/adf-schema';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import type { QuotesContent } from '@atlassian/jira-polaris-domain-insight/src/snippet/quotes/types.tsx';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '../../../common/constants.tsx';
import { RemoteAttachment, Image } from '../../../common/ui/attachment/index.tsx';
import { Attachment } from '../../common/attachments/index.tsx';

type RenderProps = {
	contentItem: QuotesContent[0];
	prevItem: QuotesContent[0] | null;
	mediaClientConfig: MediaClientConfig;
	dataProviders: ProviderFactory;
};

const Render = ({ contentItem, prevItem, dataProviders, mediaClientConfig }: RenderProps) => {
	if (contentItem.type === 'quotesItem') {
		if (typeof contentItem.quote === 'string') {
			return (
				<>
					<Quote>{contentItem.quote}</Quote>
				</>
			);
		}

		return (
			<AkRenderer
				dataProviders={dataProviders}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				document={contentItem.quote as DocNode}
				analyticsEventSeverityTracking={{
					enabled: true,
					severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
					severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
				}}
				unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
				useSpecBasedValidator
				disableHeadingIDs
				eventHandlers={{
					smartCard: { onClick: onLinkClick },
					link: { onClick: onLinkClick },
				}}
				featureFlags={{
					codeBidiWarnings: true,
					'code-bidi-warnings': true,
					'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
				}}
				smartLinks={smartLinksDefault}
			/>
		);
	}
	if (contentItem.type === 'attachment') {
		return <Attachment id={contentItem.attachmentId} mediaClientConfig={mediaClientConfig} />;
	}
	if (contentItem.type === 'image') {
		return <Image src={contentItem.src} alt="" />;
	}
	if (contentItem.type === 'remoteAttachment') {
		return (
			<AttachmentWrapper>
				{prevItem !== null && prevItem.type === 'quotesItem' ? (
					<Spacer size={2} key="separator" />
				) : null}
				<RemoteAttachment icon={contentItem.icon} title={contentItem.title} url={contentItem.url} />
			</AttachmentWrapper>
		);
	}
	return null;
};

type QuotesProps = {
	content: QuotesContent;
	mediaClientConfig: MediaClientConfig;
	dataProviders: ProviderFactory;
};

export const Quotes = ({ content, mediaClientConfig, dataProviders }: QuotesProps) => (
	<>
		{content.map((contentItem, i) => (
			<Render
				key={i}
				contentItem={contentItem}
				prevItem={i > 0 ? content[i - 1] : null}
				mediaClientConfig={mediaClientConfig}
				dataProviders={dataProviders}
			/>
		))}
	</>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Quote = styled.div({
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	wordBreak: 'break-word',
	marginTop: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Spacer = styled.div<{ size?: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	paddingTop: ({ size }) => size !== undefined && `${size}px`,
	paddingRight: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	paddingBottom: ({ size }) => size !== undefined && `${size}px`,
	paddingLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AttachmentWrapper = styled.div({
	padding: '0px 11px',
});
