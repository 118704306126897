import { useCallback } from 'react';
import {
	useDroppableEventsCollectionUpdate,
	type DroppableCollectionProps,
} from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import type { ExtendedOption } from '../../../../../common/utils/board.tsx';

export type DroppableHandlerProps = {
	children: React.ReactNode;
	options: ExtendedOption<unknown>[];
	onOptionsUpdate: (nextOptions: ExtendedOption<unknown>[]) => void;
	onDragStart?: () => void;
	onDragEnd?: () => void;
};

export const DroppableHandler = ({
	children,
	options,
	onOptionsUpdate,
	onDragEnd,
	onDragStart,
}: DroppableHandlerProps) => {
	const onSort: DroppableCollectionProps<ExtendedOption<unknown>, number>['onSort'] = useCallback(
		({ updatedCollection }) => {
			onOptionsUpdate(updatedCollection);
		},
		[onOptionsUpdate],
	);

	useDroppableEventsCollectionUpdate({
		onDragEnd,
		onDragStart,
		onSort,
		collection: options,
		isSrcIdRepresentingIndex: true,
		originLabel: 'view-group-by-configuration',
	});

	return children;
};
