import React, { useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { useCallbacks } from '../../../../controllers/selectors/callback-hooks.tsx';
import { useIsPreview, useOuterSpacing } from '../../../../controllers/selectors/config-hooks.tsx';
import { useFieldValueDecorations } from '../../../../controllers/selectors/field-hooks.tsx';
import { DecoratorNumberItem } from './item/index.tsx';
import messages from './messages.tsx';

type DecoratorItemsProps = {
	decorationConfigHeader?: React.JSX.Element;
	readonly?: boolean;
};

export const createRules = (key: string) => {
	switch (key) {
		case 'GT':
			return [{ value: '0', operator: ValueRuleOperator.GT }];

		case 'LT':
			return [{ value: '0', operator: ValueRuleOperator.LT }];

		case 'BT':
			return [
				{ value: '0', operator: ValueRuleOperator.GTE },
				{ value: '0', operator: ValueRuleOperator.LTE },
			];
		default:
			return [];
	}
};

export const DecoratorNumberItems = ({
	decorationConfigHeader,
	readonly = false,
}: DecoratorItemsProps) => {
	const { formatMessage } = useIntl();
	const [openItem, setOpenItem] = useState<string | undefined>(undefined);
	const { onValueDecorationCreated } = useCallbacks();
	const outerSpacing = useOuterSpacing();
	const isPreview = useIsPreview();

	const decorations = useFieldValueDecorations();
	const decorationsToDisplay = isPreview ? decorations.slice(0, 10) : decorations;
	const numberOfHiddenDecorations = decorations.length - decorationsToDisplay.length;

	const options = [
		{
			label: formatMessage(messages.lessThan),
			value: 'LT',
		},
		{
			label: formatMessage(messages.between),
			value: 'BT',
		},
		{
			label: formatMessage(messages.moreThan),
			value: 'GT',
		},
	];

	const onAddDecoration = async (value: string) => {
		if (onValueDecorationCreated !== undefined) {
			const createProps = {
				backgroundColor: undefined,
				emoji: undefined,
				highlightContainer: false,
				rules: createRules(value),
			};
			const localDecorationId = await onValueDecorationCreated(createProps);
			setOpenItem(localDecorationId);
		}
	};

	const displayHiddenDecorationCount = isPreview && numberOfHiddenDecorations > 0;

	const isReadonlyWithoutFormatting = readonly && !decorationsToDisplay.length;

	return (
		<>
			{decorationConfigHeader}
			{isReadonlyWithoutFormatting && (
				<Text
					testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.no-formatting"
					size="small"
				>
					{formatMessage(messages.noFormatting)}
				</Text>
			)}
			<ItemsContainer>
				<DecoratorNumberWrapper outerSpacing={outerSpacing}>
					{decorationsToDisplay?.map((decoration: ValueDecoration) => (
						<DecoratorNumberItem
							key={decoration.localDecorationId}
							decoration={decoration}
							isOpen={openItem === decoration.localDecorationId}
							onOpenChanged={(isOpen) =>
								isOpen ? setOpenItem(decoration.localDecorationId) : setOpenItem(undefined)
							}
							readonly={readonly}
						/>
					))}
				</DecoratorNumberWrapper>
				{displayHiddenDecorationCount && (
					<Text size="small">
						{formatMessage(messages.moreDecorationsInPreview, {
							number: numberOfHiddenDecorations,
						})}
					</Text>
				)}
				{!readonly && (
					<AddRuleContainer>
						<DropdownMenu
							trigger={({ triggerRef, ...triggerProps }) => (
								<AddButton
									{...triggerProps}
									ref={triggerRef}
									id="polaris.common.src.ui.config.fields.common.rollup-formula.item.add-input-button"
									testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.add-rule-button"
									iconBefore={
										<EditorAddIcon
											label={formatMessage(messages.addDecorationRule)}
											size="medium"
										/>
									}
								>
									{formatMessage(messages.addDecorationRule)}
								</AddButton>
							)}
						>
							<DropdownItemGroup>
								<DropdownItemGroup>
									{options.map(({ value, label }, index) => (
										<DropdownItem
											testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.dropdown-item"
											key={index}
											onClick={() => onAddDecoration(value)}
										>
											{label}
										</DropdownItem>
									))}
								</DropdownItemGroup>
							</DropdownItemGroup>
						</DropdownMenu>
					</AddRuleContainer>
				)}
			</ItemsContainer>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemsContainer = styled.div({
	overflow: 'visible',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddRuleContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DecoratorNumberWrapper = styled.div<{ outerSpacing: string }>({
	paddingBottom: token('space.100', '0.5rem'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (props) => `0 -${props.outerSpacing}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddButton = styled(Button)({
	marginBottom: '0.75rem',
});
