/** @jsx jsx */
import React, { type PropsWithChildren } from 'react';
import { jsx, css } from '@compiled/react';
import { xcss, Anchor } from '@atlaskit/primitives';
import { useIssueLink } from '../../../common/utils/issue-link/index.tsx';
import type { IssueViewSection } from '../../../controllers/issue/types.tsx';
import { CONNECTION_ITEM_STYLES } from './constants.tsx';

export type ConnectionItemProps = PropsWithChildren<{
	issueKey: string;
	isLastChild?: boolean;
	issueViewSection?: IssueViewSection;
	childrenRef?: (node: HTMLDivElement | null) => void;
}>;

export const ConnectionItem = ({
	isLastChild,
	issueKey,
	issueViewSection,
	children,
	childrenRef,
}: ConnectionItemProps) => {
	const { link, target, onClick } = useIssueLink({
		issueKey,
		shouldOpenInSidebar: true,
		issueViewSection,
	});

	return (
		<Anchor
			onClick={onClick}
			xcss={[linkStyles, isLastChild ? lastElementStyles : beforeElementStyles]}
			href={link}
			target={target}
		>
			<div ref={childrenRef} css={childrenStyles}>
				{children}
			</div>
		</Anchor>
	);
};

// childrenStyles should be in sync with CONNECTION_ITEM_STYLES for card height calculations
/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values */
const childrenStyles = css({
	lineHeight: CONNECTION_ITEM_STYLES.lineHeight,
	fontSize: CONNECTION_ITEM_STYLES.fontsize,
	display: CONNECTION_ITEM_STYLES.display,
	overflow: CONNECTION_ITEM_STYLES.overflow,
	paddingLeft: CONNECTION_ITEM_STYLES.paddingLeft,
	WebkitLineClamp: CONNECTION_ITEM_STYLES.webkitLineClamp,
	WebkitBoxOrient: CONNECTION_ITEM_STYLES.webkitBoxOrient,
});
/* eslint-enable @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values */

const beforeElementStyles = xcss({
	'::before': {
		content: '""',
		borderTop: '2px solid',
		borderColor: 'color.border',
		transform: 'translateY(9px) translateX(3px)',
		width: '8px',
		flexShrink: 0,
	},
});

const linkStyles = xcss({
	display: 'flex',
	color: 'color.text',
	textDecoration: 'none',
	width: '100%',
	':hover': {
		color: 'color.text',
	},
	':focus': {
		color: 'color.text',
		textDecoration: 'none',
	},
});

const lastElementStyles = xcss({
	paddingLeft: 'space.100',
});
