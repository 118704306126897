import React from 'react';
import { IconButton } from '@atlaskit/button/new';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { useIntl } from '@atlassian/jira-intl';
import { IssueTypeAvatar } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-avatar.tsx';
import { messages } from './messages.tsx';
import { useAvatars } from './avatars-provider.tsx';

type Props = {
	onSelect: (avatarId: string) => void;
};

export const AvatarPicker = ({ onSelect }: Props) => {
	const avatars = useAvatars();

	const { formatMessage } = useIntl();
	return (
		<Box xcss={pickerBoxStyles}>
			<Tabs id="default">
				<TabList>
					<Tab>{formatMessage(messages.icons)}</Tab>
				</TabList>
				<TabPanel>
					<Box xcss={panelStyles}>
						<Inline space="space.100" shouldWrap>
							{avatars.map((avatar) => {
								return (
									<IconButton
										key={avatar.id}
										label=""
										icon={() => <IssueTypeAvatar avatarId={avatar.id} size="xsmall" />}
										onClick={() => onSelect(avatar.id)}
									/>
								);
							})}
						</Inline>
					</Box>
				</TabPanel>
			</Tabs>
		</Box>
	);
};

const panelStyles = xcss({
	paddingBlock: 'space.150',
});

const pickerBoxStyles = xcss({
	paddingInline: 'space.150',
	width: '216px',
});
