import React from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

type Props = {
	availableConnectionsCount: number;
	onConfigure: VoidFunction;
};

export const ConnectionsConfigEmpty = ({ availableConnectionsCount, onConfigure }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<SectionMessage
			title={formatMessage(messages.emptyScreenTitle)}
			actions={
				<SectionMessageAction
					onClick={onConfigure}
					key={messages.emptyScreenConfigureButtonLabel.id}
				>
					{formatMessage(messages.emptyScreenConfigureButtonLabel)}
				</SectionMessageAction>
			}
		>
			{formatMessage(messages.emptyScreenMessage, { count: availableConnectionsCount })}
		</SectionMessage>
	);
};
