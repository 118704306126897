import React from 'react';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useCurrentViewConfigured,
	useCurrentViewKind,
	useCurrentViewTitle,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useStatusCategories } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/status-categories-hook.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { BoardViewExportDialog } from './board-view-export-dialog/index.tsx';
import { useContainerName } from './hooks.tsx';
import { ListViewExportDialog } from './list-view-export-dialog/index.tsx';
import { MatrixViewExportDialog } from './matrix-view-export-dialog/index.tsx';
import { TimelineViewExportDialog } from './timeline-view-export-dialog/index.tsx';

export const ExportDialog = () => {
	const viewTitle = useCurrentViewTitle();
	const viewKind = useCurrentViewKind();
	const isViewConfigured = useCurrentViewConfigured();
	const statusCategories = useStatusCategories();
	const containerName = useContainerName();
	const { exportStringValues } = useIssueActions();

	const commonProps = {
		viewTitle,
		viewKind,
		isViewConfigured,
		statusCategories,
		containerName,
		onExportStringValues: exportStringValues,
	};

	switch (viewKind) {
		case VIEW_KIND_MATRIX:
			return <MatrixViewExportDialog {...commonProps} />;
		case VIEW_KIND_TIMELINE:
			return <TimelineViewExportDialog {...commonProps} />;
		case VIEW_KIND_BOARD:
			return <BoardViewExportDialog {...commonProps} />;
		case VIEW_KIND_TABLE:
		default:
			return <ListViewExportDialog {...commonProps} />;
	}
};
