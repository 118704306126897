import React from 'react';
import { styled } from '@compiled/react';
import Icon, { type IconProps } from '@atlaskit/icon';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	isReadOnly: boolean;
	hasUnread: boolean;
	highlight?: boolean;
	placeholderAppearance?: 'normal' | 'verbose';
	appearance?: 'normal' | 'compact';
	placeholder?: string;
	addGlyph: IconProps['glyph'];
	glyph: IconProps['glyph'];
	itemCount: number;
	label: string;
	onClick: () => void;
	testId?: string;
	isExporting?: boolean;
	canAdd?: boolean;
};

export const ValueButtonField = ({
	testId,
	onClick,
	appearance,
	placeholderAppearance,
	placeholder,
	glyph,
	addGlyph,
	hasUnread,
	itemCount,
	highlight,
	isReadOnly,
	label,
	isExporting,
	canAdd,
}: Props) => {
	const emptyAndEditable = itemCount === 0 && !isReadOnly && !highlight && canAdd;
	const showValueContainer =
		itemCount > 0 ||
		placeholderAppearance === 'verbose' ||
		appearance !== 'compact' ||
		(highlight === true && itemCount === 0);

	if (isExporting && itemCount === 0) {
		return null;
	}

	return (
		<Button
			data-testid={testId}
			data-component-selector="button-gJ88"
			onClick={onClick}
			title={`${itemCount} items`}
			emptyAndEditable={emptyAndEditable}
			highlight={highlight}
			appearance={appearance}
			disabled={isReadOnly}
		>
			<IconContainer appearance={appearance}>
				{emptyAndEditable ? (
					<Icon glyph={addGlyph} size="small" label="add" />
				) : (
					<Icon glyph={glyph} size="small" label={label} />
				)}
			</IconContainer>
			{showValueContainer && (
				<ValueContainer
					appearance={appearance}
					placeholderAppearance={placeholderAppearance}
					hasUnread={hasUnread}
					emptyAndEditable={emptyAndEditable}
				>
					{emptyAndEditable ? placeholder : itemCount}
				</ValueContainer>
			)}
		</Button>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.span<{ appearance: any }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ appearance }) =>
		appearance === 'normal' ? `0 ${token('space.050', '4px')}` : `0 ${token('space.025', '2px')}`,
	display: 'flex',
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Button = styled.button<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	highlight: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	appearance: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	emptyAndEditable?: any;
}>({
	display: 'flex',
	alignItems: 'center',
	borderWidth: 0,
	borderRadius: '3px',
	transition: 'background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	whiteSpace: 'nowrap',
	maxWidth: '100%',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	font: ({ appearance }) =>
		appearance === 'normal' ? token('font.body') : token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ appearance }) => `${appearance === 'normal' ? 32 : 24}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	lineHeight: ({ appearance }) => `${appearance === 'normal' ? 32 : 24}px`,
	fontWeight: token('font.weight.medium'),
	paddingTop: '0px',
	paddingRight: token('space.050', '4px'),
	paddingBottom: '0px',
	paddingLeft: token('space.050', '4px'),
	justifyContent: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover:not([disabled])': {
		opacity: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N30A),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ highlight }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			highlight ? token('color.text.brand', colors.B400) : token('color.text', colors.N700),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active:not([disabled])': {
		opacity: 1,
		background: 'rgba(179, 212, 255, 0.6)',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		color: colors.B400,
		transitionDuration: '0s',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus': {
		outline: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ highlight, emptyAndEditable }) => {
		if (highlight) {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			return token('color.text.brand', colors.B400);
		}

		return emptyAndEditable
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.text.disabled', colors.N80)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.text', colors.N700);
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ highlight }) =>
		highlight && token('color.background.accent.blue.subtlest', 'rgba(179,212,255,0.6)'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueContainer = styled.div<{
	appearance?: 'normal' | 'compact';
	placeholderAppearance?: 'normal' | 'verbose';
	hasUnread: boolean;
	emptyAndEditable?: boolean;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	font: ({ appearance }) =>
		appearance === 'normal' ? token('font.body') : token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ appearance }) =>
		appearance === 'normal'
			? `0px 0px 0px ${token('space.050', '4px')}`
			: `0px 0px 0px ${token('space.025', '2px')}`,
	flex: '1 1 auto',
	textAlign: 'left',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	transition: 'background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: ({ hasUnread }) => (hasUnread ? colors.B300 : 'currentColor'),
	/* eslint-disable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontWeight: ({ hasUnread, appearance }) =>
		hasUnread
			? appearance === 'normal'
				? token('font.weight.bold')
				: token('font.weight.semibold')
			: 'inherit',
	/* eslint-enable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ emptyAndEditable, placeholderAppearance }) =>
		!emptyAndEditable || placeholderAppearance === 'verbose' ? 1 : 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="button-gJ88"]:hover &': {
		opacity: 1,
	},
});
