import React, { useCallback } from 'react';
import Badge from '@atlaskit/badge';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	useFieldLabel,
	useIsGlobalCustomField,
	useIsGlobalNonSystemField,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueTypesConnectionsActions } from '@atlassian/jira-polaris-component-issue-types-connections-configuration/src/controllers/index.tsx';
import { ConnectionsConfigMenu } from '@atlassian/jira-polaris-component-issue-types-connections-configuration/src/ui/connections-config-menu.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsProjectAdmin } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { messages } from './messages.tsx';

type Props = {
	fieldKey: string;
	issuesCount: number;
	issueTypeId: IssueTypeId;
	onConfigure: VoidFunction;
};

export const Header = ({ fieldKey, issuesCount, issueTypeId, onConfigure }: Props) => {
	const label = useFieldLabel(fieldKey);
	const isGlobal = useIsGlobalCustomField(fieldKey);
	const isGlobalNonSystemField = useIsGlobalNonSystemField(fieldKey);
	const { formatMessage } = useIntl();
	const [isProjectAdmin] = useIsProjectAdmin();
	const isIssueArchived = useIsSelectedIssueArchived();
	const { hideConnection } = useIssueTypesConnectionsActions();
	const { error: showErrorFlag } = useNotifications();

	const handleHide = useCallback(async () => {
		try {
			await hideConnection(issueTypeId, fieldKey);
		} catch (_) {
			showErrorFlag({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
			});
		}
	}, [formatMessage, issueTypeId, hideConnection, showErrorFlag, fieldKey]);

	return (
		<Inline space="space.150" alignBlock="center" xcss={containerStyles}>
			<Text maxLines={1}>{label}</Text>
			{fg('polaris_display-gsf-as-a-regular-project-field') ? (
				<>{isGlobalNonSystemField && <GlobalFieldMarker />}</>
			) : (
				<>{isGlobal && <GlobalFieldMarker />}</>
			)}
			{issuesCount > 0 && <Badge>{issuesCount.toString()}</Badge>}
			{isProjectAdmin && !isIssueArchived && (
				<Box xcss={menuStyles}>
					<ConnectionsConfigMenu onConfigure={onConfigure} onHide={handleHide} />
				</Box>
			)}
		</Inline>
	);
};

const containerStyles = xcss({
	width: '100%',
});

const menuStyles = xcss({
	marginLeft: 'auto',
});
