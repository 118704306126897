import { useEffect, useState, useRef, useCallback, type MutableRefObject } from 'react';
import debounce from 'lodash/debounce';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import { BoardVirtualizer } from './board-virtualizer/index.tsx';
import type { ColumnChangeHandler, Group } from './types.tsx';

type UseBoardVirtualizationProps = {
	groups: Group[];
	containerRef: MutableRefObject<HTMLDivElement | null>;
	isReadOnly: boolean;
	isFooterVisible: boolean;
	hasColumnWithHeaders?: boolean;
};

export const useBoardVirtualization = (props: UseBoardVirtualizationProps) => {
	const { groups, containerRef, isReadOnly, isFooterVisible, hasColumnWithHeaders = false } = props;
	const boardVirtualizerRef = useRef<BoardVirtualizer>();
	const [groupColumnContentHeights, setGroupColumnContentHeights] = useState<Map<string, number>>(
		new Map(),
	);
	const [columnListeners] = useState<Map<string, ColumnChangeHandler[]>>(new Map());
	const isExportingViewImage = useIsExportingViewImage();

	const handleScroll = useCallback(() => {
		if (!boardVirtualizerRef.current) {
			return;
		}

		// in Safari the scroll value can be negative (-1) but we want to have
		// the same behavior as when it is 0
		const offsetX = Math.max(containerRef?.current?.scrollLeft || 0, 0);
		const offsetY = Math.max(containerRef?.current?.scrollTop || 0, 0);

		const { changedColumnUids, columnCardsMap } = boardVirtualizerRef.current.handleScroll(
			offsetX,
			offsetY,
			isExportingViewImage,
		);

		for (const columnUid of changedColumnUids) {
			const listeners = columnListeners.get(columnUid) || [];

			listeners.forEach((listener) => listener(columnCardsMap.get(columnUid) || []));
		}
	}, [columnListeners, containerRef, isExportingViewImage]);

	const handleResize = useCallback(
		(entries: ResizeObserverEntry[]) => {
			const container = entries[0];

			if (!boardVirtualizerRef.current) {
				return;
			}

			boardVirtualizerRef.current.updateContainerDimensions(
				Math.round(container.contentRect.width),
				Math.round(container.contentRect.height),
			);

			handleScroll();
		},
		[handleScroll],
	);

	useEffect(() => {
		const container = containerRef.current;

		if (!container) {
			return undefined;
		}

		const debouncedHandleResize = debounce(handleResize, 100);

		container.addEventListener('scroll', handleScroll, {
			passive: true,
		});

		const resizeObserver = new ResizeObserver(debouncedHandleResize);
		resizeObserver.observe(container);

		boardVirtualizerRef.current = new BoardVirtualizer(
			groups,
			container.offsetWidth,
			container.offsetHeight,
			isReadOnly || !isFooterVisible,
			hasColumnWithHeaders,
		);
		setGroupColumnContentHeights(boardVirtualizerRef.current.getGroupColumnContentHeights());
		handleScroll();

		return () => {
			container.removeEventListener('scroll', handleScroll);
			resizeObserver.disconnect();
		};
	}, [
		containerRef,
		groups,
		isReadOnly,
		hasColumnWithHeaders,
		handleScroll,
		handleResize,
		isFooterVisible,
	]);

	const subscribeToColumnChanges = useCallback(
		(columnUid: string, callback: ColumnChangeHandler) => {
			let listeners = columnListeners.get(columnUid);

			if (!listeners || !Array.isArray(listeners)) {
				listeners = [];
				columnListeners.set(columnUid, listeners);
			}

			listeners.push(callback);
		},
		[columnListeners],
	);

	const unsubscribeToColumnChanges = useCallback(
		(columnUid: string, callback: ColumnChangeHandler) => {
			const listeners = columnListeners.get(columnUid);

			if (!listeners || !Array.isArray(listeners)) {
				return;
			}

			const listenerIndex = listeners.indexOf(callback);

			if (listenerIndex !== -1) {
				listeners.splice(listenerIndex, 1);
			}
		},
		[columnListeners],
	);

	return {
		groupColumnContentHeights,
		subscribeToColumnChanges,
		unsubscribeToColumnChanges,
	};
};
