/** @jsx jsx */
import React, {
	memo,
	useState,
	useEffect,
	useMemo,
	type ReactNode,
	type ReactElement,
	type ChangeEvent,
	useRef,
	type PropsWithChildren,
} from 'react';
import { styled, jsx, css } from '@compiled/react';
import groupBy from 'lodash/groupBy';
import toLower from 'lodash/toLower';
import without from 'lodash/without';
import { useVirtualizer } from '@tanstack/react-virtual';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import EditorSearchIcon from '@atlaskit/icon/core/migration/search';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { xcss, Flex } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import messages from './messages.tsx';

export type SelectOption = {
	label: string;
	/**
	 * can be used to override label when search and preview display should be
	 * different (e.g. search for both key and summary, only display summary in preview)
	 */
	previewLabel?: string;
	id: string | undefined;
	OptionRenderComponent: () => ReactElement;
};

type Props = {
	selected: (string | undefined)[];
	options: SelectOption[];
	onChange: (value: (string | undefined)[]) => void;
};

type OptionProps = {
	selected: boolean;
	component: ReactNode;
	onChange: () => void;
};

const OPTION_HEIGHT = 32;

const Option = memo(({ selected, onChange, component }: OptionProps) => (
	<OptionWrapper onClick={onChange}>
		<CheckboxWrapper>
			<Checkbox isChecked={selected} />
		</CheckboxWrapper>
		<OptionContent>{component}</OptionContent>
	</OptionWrapper>
));

const ClearButton = ({ onClick }: { onClick: () => void }) => {
	const { formatMessage } = useIntl();

	return (
		<Button
			appearance="link"
			onClick={onClick}
			css={[fg('polaris_better_date_filters') && clearSelectedItemsButtonStyles]}
		>
			{formatMessage(messages.clearSelectedItems)}
		</Button>
	);
};

const VirtualizedItem = ({
	children,
	height,
	start,
}: PropsWithChildren<{ height: number; start: number }>) => (
	// eslint-disable-next-line jira/react/no-style-attribute
	<div css={virtualizedItemStyles} style={{ height, transform: `translateY(${start}px)` }}>
		{children}
	</div>
);

const SelectFilterContentComponentOld = ({ options, selected, onChange }: Props) => {
	const { formatMessage } = useIntl();

	const [search, setSearch] = useState('');
	const [initialSelected, setInitialSelected] = useState(selected);

	useEffect(() => {
		if (initialSelected !== selected) {
			setSearch('');
			setInitialSelected(selected);
		}
	}, [initialSelected, selected]);

	const onOptionSelectionChanged = (optionId: string | undefined, selection: boolean) => {
		const newSelection = selection ? [...selected, optionId] : without(selected, optionId);
		onChange(newSelection);
	};

	const filteredOptions = useMemo(
		() => options.filter((option) => toLower(option.label).includes(toLower(search))),
		[options, search],
	);

	const visibleOptionsByInitialSelection = useMemo(
		() => groupBy(filteredOptions, ({ id }) => initialSelected.includes(id)),
		[filteredOptions, initialSelected],
	);

	const clearItems = () => {
		setInitialSelected([]);
		onChange([]);
	};

	return (
		<ContentContainer>
			<SearchFieldContainer>
				<Textfield
					autoFocus
					appearance="subtle"
					value={search}
					placeholder={formatMessage(messages.searchHint)}
					onChange={(event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
					elemAfterInput={
						<EditorSearchIcon
							color={token('color.icon.accent.gray')}
							label={formatMessage(messages.searchIconMessage)}
							LEGACY_primaryColor={token('color.border.input')}
							spacing="spacious"
						/>
					}
				/>
			</SearchFieldContainer>
			{filteredOptions.length === 0 && (
				<EmptyIndicator>{formatMessage(messages.noMatchesIndicator)}</EmptyIndicator>
			)}
			<OptionsContainer>
				{visibleOptionsByInitialSelection.true && (
					<>
						{visibleOptionsByInitialSelection.true.map(({ id, OptionRenderComponent }) => {
							const isSelected = selected.includes(id);
							return (
								<Option
									key={`selected-${id}`}
									selected={isSelected}
									onChange={() => onOptionSelectionChanged(id, !isSelected)}
									component={<OptionRenderComponent />}
								/>
							);
						})}
						<ClearButton onClick={clearItems} />
					</>
				)}
				{visibleOptionsByInitialSelection.false &&
					visibleOptionsByInitialSelection.false.map(({ id, OptionRenderComponent }) => {
						const isSelected = selected.includes(id);
						return (
							<Option
								key={`unselected-${id}`}
								selected={isSelected}
								onChange={() => onOptionSelectionChanged(id, !isSelected)}
								component={<OptionRenderComponent />}
							/>
						);
					})}
			</OptionsContainer>
		</ContentContainer>
	);
};

const SelectFilterContentComponentNext = ({ options, selected, onChange }: Props) => {
	const { formatMessage } = useIntl();
	const containerRef = useRef<HTMLDivElement>(null);

	const [search, setSearch] = useState('');
	const [initialSelected, setInitialSelected] = useState(selected);

	useEffect(() => {
		if (initialSelected !== selected) {
			setSearch('');
			setInitialSelected(selected);
		}
	}, [initialSelected, selected]);

	const onOptionSelectionChanged = (optionId: string | undefined, selection: boolean) => {
		const newSelection = selection ? [...selected, optionId] : without(selected, optionId);
		onChange(newSelection);
	};

	const filteredOptions = useMemo(
		() => options.filter((option) => toLower(option.label).includes(toLower(search))),
		[options, search],
	);

	const visibleOptionsByInitialSelection = useMemo(
		() => groupBy(filteredOptions, ({ id }) => initialSelected.includes(id)),
		[filteredOptions, initialSelected],
	);

	const clearItems = () => {
		setInitialSelected([]);
		onChange([]);
	};

	const items = useMemo(
		() => [
			...(visibleOptionsByInitialSelection.true ?? []),
			...(visibleOptionsByInitialSelection.true ? [{ isClearButton: true }] : []),
			...(visibleOptionsByInitialSelection.false ?? []),
		],
		[visibleOptionsByInitialSelection],
	);

	const virtualizer = useVirtualizer({
		count: items.length,
		estimateSize: () => OPTION_HEIGHT,
		getScrollElement: () => containerRef.current,
		overscan: 20,
	});

	return (
		<Flex direction="column" xcss={contentContainerStyles}>
			<SearchFieldContainer>
				<Textfield
					autoFocus
					appearance="subtle"
					value={search}
					placeholder={formatMessage(messages.searchHint)}
					onChange={(event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
					elemAfterInput={
						<EditorSearchIcon
							color={token('color.icon.accent.gray')}
							label={formatMessage(messages.searchIconMessage)}
							LEGACY_primaryColor={token('color.border.input')}
							spacing="spacious"
						/>
					}
				/>
			</SearchFieldContainer>
			{filteredOptions.length === 0 && (
				<EmptyIndicator>{formatMessage(messages.noMatchesIndicator)}</EmptyIndicator>
			)}
			<OptionsContainer ref={containerRef}>
				{/* eslint-disable-next-line jira/react/no-style-attribute */}
				<div style={{ minHeight: virtualizer.getTotalSize() }}>
					{virtualizer.getVirtualItems().map(({ index, size, start }) => {
						const item = items[index];

						if ('isClearButton' in item) {
							return (
								<VirtualizedItem key="clear-button" height={size} start={start}>
									<ClearButton onClick={clearItems} />
								</VirtualizedItem>
							);
						}

						const { id, OptionRenderComponent } = item;
						const isSelected = selected.includes(id);

						return (
							<VirtualizedItem key={id} height={size} start={start}>
								<Option
									selected={isSelected}
									onChange={() => onOptionSelectionChanged(id, !isSelected)}
									component={<OptionRenderComponent />}
								/>
							</VirtualizedItem>
						);
					})}
				</div>
			</OptionsContainer>
		</Flex>
	);
};

export const SelectFilterContentComponent = componentWithFG(
	'jpd_virtualize_options_in_group_and_filter',
	SelectFilterContentComponentNext,
	SelectFilterContentComponentOld,
);

const clearSelectedItemsButtonStyles = css({
	alignSelf: 'flex-start',
});

const virtualizedItemStyles = css({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
});

const contentContainerStyles = xcss({
	width: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	minWidth: '220px',
	maxWidth: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchFieldContainer = styled.div({
	borderBottom: `2px solid ${token('color.border')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginTop: token('space.075'),
	marginBottom: token('space.075'),
	maxHeight: '300px',
	overflowY: 'auto',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div({
	minHeight: '32px',
	display: 'flex',
	alignItems: 'center',
	paddingLeft: token('space.200'),
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyIndicator = styled.div({
	marginLeft: token('space.200'),
	color: token('color.text'),
	marginTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxWrapper = styled.div({
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContent = styled.div({
	flex: '1 1 auto',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'*': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		cursor: 'pointer !important',
	},
});
