import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import Button from '@atlaskit/button/new';
import DropdownMenu from '@atlaskit/dropdown-menu';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { Box, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	NEW_DEFAULT_TEMPLATES,
	OLD_DEFAULT_TEMPLATES,
} from '@atlassian/jira-polaris-common/src/common/types/idea-templates/default/index.tsx';
import type { IdeaTemplate } from '@atlassian/jira-polaris-common/src/common/types/idea-templates/types.tsx';
import {
	useProjectIdUnsafe,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async.tsx';
import { AdfSkeleton } from '@atlassian/jira-polaris-lib-editor/src/common/ui/adf-skeleton/index.tsx';
import { prepareForRender } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import {
	WaitForAdfConsumerProps,
	IdeaCreateAdfController,
} from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import type { EditorActions } from '@atlassian/jira-polaris-lib-editor/src/ui/editor/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { EditorAPI } from '@atlassian/jira-loom-utils/src/controllers/use-loom-video-editor-inserter/index.tsx';
import { getIssueModalCreateConfig } from '../../../../../services/gira/issue-modal-create-config/index.tsx';
import { TemplatesPopup } from '../templates-popup/index.tsx';
import messages from './messages.tsx';

type AdfComponentProps = {
	isDisabled: boolean;
	isLoadingTemplate: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onUpdate: (value: any) => void;
	description: ADF | undefined;
	isPreviewInProjectSettings: boolean | undefined;
};

const IDEA_CREATION_MODAL =
	'[data-testid="polaris-common.ui.create-idea-dialog.form-modal-dialog.issue-creation-form-modal"]';

const jsonTransformer: JSONTransformer = new JSONTransformer();

export const AdfComponent = (props: AdfComponentProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const { formatMessage } = useIntl();
	const isEditorAiEnabled = useEditorAiEnabled();
	const [editorActions, setEditorActions] = useState<EditorActions | undefined>();
	const editorApiRef = useRef<EditorAPI | null>(null);

	const [isOpenPopup, setOpenPopup] = useState(false);
	const { isDisabled, onUpdate, description, isLoadingTemplate, isPreviewInProjectSettings } =
		props;
	const safeDescription = useMemo(() => prepareForRender(description), [description]);
	const isHoveringRef = useRef(false);
	const triggerButtonRef = useRef<HTMLButtonElement | null>(null);

	const [isPreview, setPreview] = useState(false);
	const [previewTemplate, setPreviewTemplate] = useState<string | null>(null);
	const [modalElement, setModalElement] = useState<HTMLElement | null>(null);

	const DEFAULT_TEMPLATES = expVal(
		'jpd_create_idea_template_experiment',
		'ideaCreateFormExperimentEnabled',
		false,
	)
		? NEW_DEFAULT_TEMPLATES
		: OLD_DEFAULT_TEMPLATES;

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const foundElement = document.querySelector<HTMLElement>(IDEA_CREATION_MODAL);
		if (foundElement) {
			setModalElement(foundElement);
		}
	}, []);

	const isEditorEmpty = useCallback(async () => {
		if (!editorActions) return false;
		const currentContent = await editorActions.getValue();
		return !currentContent.content.content.length || !currentContent.content.size;
	}, [editorActions]);

	const getMediaContext = useCallback(
		async ({ projectId: targetProjectId }: { projectId: ProjectId }) =>
			getIssueModalCreateConfig(targetProjectId).then((config) => {
				const uploadToken = config?.data?.jiraIssueCreateFields?.fields?.edges?.find(
					(f: { node: { fieldId: string } }) => f.node.fieldId === 'description',
				)?.node?.mediaContext?.uploadToken;

				const mediaUploadContext = {
					clientId: uploadToken.clientId,
					serviceHost: uploadToken.endpointUrl,
					collection: uploadToken.targetCollection,
					token: uploadToken.token,
					tokenLifespanInMs: uploadToken.tokenDurationInMin * 60 * 1000,
					tokenIssueTimestamp: Date.now() - 10000, // added 10s buffer to cover network calls
				};

				return {
					userAuth: null,
					viewContext: {
						...mediaUploadContext,
						areTokenPermissionsUpToDate: true,
					},
					uploadContext: mediaUploadContext,
				};
			}),
		[],
	);

	const onChange = useCallback(
		(value?: ADF) => {
			if (value === undefined) return;
			onUpdate(value);
		},
		[onUpdate],
	);

	const onPreviewTemplate = useCallback((templateData: IdeaTemplate) => {
		if (editorApiRef.current && templateData.template) {
			editorApiRef.current.core.actions.replaceDocument(templateData.template); // Replace with template content
			setPreview(true);
			setPreviewTemplate(templateData.id);
		}
	}, []);

	const onEditorReady = useCallback(
		(actions: EditorActions) => {
			setEditorActions(actions);
			actions.getValue().then((value) => {
				if (!description) return;
				onUpdate(value);
			});
		},
		[description, onUpdate],
	);

	useEffect(() => {
		if (
			triggerButtonRef.current &&
			!isPreviewInProjectSettings &&
			expVal('jpd_create_idea_template_experiment', 'ideaCreateFormExperimentEnabled', false)
		) {
			isEditorEmpty().then((isEmpty) => {
				if (isEmpty) {
					setTimeout(() => {
						setOpenPopup(true);
						onPreviewTemplate(DEFAULT_TEMPLATES[0]);
					}, 500);
				}
			});
		}
	}, [isPreviewInProjectSettings, onPreviewTemplate, DEFAULT_TEMPLATES, isEditorEmpty]);

	const onApplyTemplate = (templateContent: ADF | undefined) => {
		editorActions?.getValue().then((value) => {
			const adfDescription = jsonTransformer.encode(value);
			const descriptionWithTemplate = {
				...adfDescription,
				content: [
					...(adfDescription ? adfDescription.content : []),
					...(templateContent ? templateContent.content : []),
				],
			};
			const finalContent = isPreview ? templateContent : descriptionWithTemplate;
			if (editorApiRef.current && finalContent) {
				editorApiRef.current.core.actions.replaceDocument(finalContent);
			}

			setPreview(false);
			setOpenPopup(false);
			setPreviewTemplate(null);
		});
	};

	const onResetPreview = useCallback(() => {
		if (isPreview) {
			isHoveringRef.current = false;
			editorActions?.clear(); // reset editor
			setPreview(false);
			setPreviewTemplate(null);
		}
	}, [editorActions, isPreview]);

	const handleHoverStart = async (template: IdeaTemplate) => {
		const isEmpty = await isEditorEmpty();
		if (!isEmpty && !isPreview) return;
		isHoveringRef.current = true;
		onPreviewTemplate(template);
	};

	const handleHoverEnd = () => {
		isHoveringRef.current = false;
	};

	const onOpenChange = () => {
		if (isOpenPopup) {
			onResetPreview();
		}
		setOpenPopup(!isOpenPopup);
	};

	return (
		<IdeaCreateAdfController
			projectId={projectId}
			projectKey={projectKey}
			getMediaContext={getMediaContext}
		>
			<EditorWrapper
				isPreview={isPreview}
				isPreviewInProjectSettings={isPreviewInProjectSettings}
				ref={containerRef}
			>
				<WaitForAdfConsumerProps forceLoadingView={isLoadingTemplate}>
					{({ akEditorProps }) => (
						<EditorBoundary fallback={<AdfSkeleton />}>
							<Editor
								{...akEditorProps}
								editorApiRef={editorApiRef}
								disabled={isDisabled}
								withActionButtons={false}
								useStickyToolbar
								defaultValue={safeDescription}
								placeholder={formatMessage(messages.descriptionPlaceholder)}
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								popupsMountPoint={modalElement || document.body || undefined}
								allowFullEditorCapabilities
								onChange={onChange}
								onEditorReady={onEditorReady}
								popupsBoundariesElement={containerRef.current || undefined}
								isAiEnabled={isEditorAiEnabled}
								extraButtons={
									isPreviewInProjectSettings ||
									!expVal(
										'jpd_create_idea_template_experiment',
										'ideaCreateFormExperimentEnabled',
										false,
									) ? undefined : (
										<DropdownMenu
											placement="right-end"
											isOpen={isOpenPopup}
											onOpenChange={onOpenChange}
											shouldRenderToParent
											returnFocusRef={containerRef}
											trigger={({ triggerRef, isSelected, ...providedProps }) => (
												<Box ref={triggerButtonRef} xcss={templatesBtnStyles}>
													<Button
														{...providedProps}
														ref={triggerRef}
														isSelected={isOpenPopup}
														onClick={() => setOpenPopup(!isOpenPopup)}
													>
														{formatMessage(messages.templatesButton)}
													</Button>
												</Box>
											)}
										>
											<TemplatesPopup
												previewTemplateId={previewTemplate}
												onApplyTemplate={onApplyTemplate}
												onPreviewTemplate={handleHoverStart}
												onResetPreviewTemplate={handleHoverEnd}
											/>
										</DropdownMenu>
									)
								}
							/>
						</EditorBoundary>
					)}
				</WaitForAdfConsumerProps>
			</EditorWrapper>
		</IdeaCreateAdfController>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditorWrapper = styled.div<{ isPreview?: boolean; isPreviewInProjectSettings?: boolean }>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.akEditor': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ isPreviewInProjectSettings }) => (isPreviewInProjectSettings ? 'auto' : '360px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-testid='click-wrapper']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ isPreview }) => (isPreview ? '0.5' : '1'),
		overflow: 'auto',
	},
});

const templatesBtnStyles = xcss({
	alignSelf: 'end',
	marginLeft: 'auto',
});
