import React from 'react';
import { Pressable, xcss } from '@atlaskit/primitives';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	FieldDecorationPopup,
	type FieldDecorationPopupProps,
} from '@atlassian/jira-polaris-lib-field-option-configuration/src/ui/field-decoration-popup/index.tsx';

type OptionalFieldDecorationPopupPropsKeys = 'isNameEditable' | 'value';
type OmittedFieldDecorationPopupPropsKeys = 'onClose' | 'trigger';

type SharedFieldDecorationPopupProps = Omit<
	FieldDecorationPopupProps,
	OptionalFieldDecorationPopupPropsKeys | OmittedFieldDecorationPopupPropsKeys
> &
	Partial<Pick<FieldDecorationPopupProps, OptionalFieldDecorationPopupPropsKeys>> & {
		decorationNode: React.ReactNode;
		onOpen: (open: boolean) => void;
		readonly: boolean;
	};

export const SharedFieldDecorationPopup = ({
	decorationNode,
	isNameEditable,
	onOpen,
	readonly,
	value,
	...other
}: SharedFieldDecorationPopupProps) => {
	return (
		<>
			<FireUiAnalytics
				actionSubject="fieldDecorationMenu"
				action="viewed"
				actionSubjectId="viewControls"
			/>
			<FieldDecorationPopup
				{...other}
				isNameEditable={isNameEditable || false}
				onClose={() => {
					onOpen(false);
				}}
				trigger={(triggerProps) => (
					<Pressable
						{...triggerProps}
						isDisabled={readonly}
						onClick={() => {
							onOpen(!other.isOpen);
						}}
						testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.box"
						xcss={decorationNodeWrapperStyles}
					>
						{decorationNode}
					</Pressable>
				)}
				value={value || ''}
			/>
		</>
	);
};

const decorationNodeWrapperStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	display: 'block',
	minHeight: 'size.200',
	paddingLeft: 'space.200',
});
