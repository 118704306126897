import React, { useState, useEffect } from 'react';
import Button from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueTypeIdsForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { ProjectIdProvider } from './project-id-provider.tsx';
import { AvatarsProvider } from './avatars-provider.tsx';
import { TypeCard } from './type-card.tsx';
import { TypeCardCreate } from './type-card-create.tsx';
import { messages } from './messages.tsx';

type Props = {
	projectId: ProjectId;
};

export const IssueTypesRenderer = ({ projectId }: Props) => {
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });
	const { formatMessage } = useIntl();
	const [isCreatingNewType, setIsCreatingNewType] = useState(false);

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Esc' || event.key === 'Escape') {
				setIsCreatingNewType(false);
			}
		};
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('keydown', onKeyDown);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	return (
		<>
			<ProjectIdProvider projectId={projectId}>
				<AvatarsProvider projectId={projectId}>
					{issueTypeIds.map((issueTypeId) => {
						return <TypeCard key={issueTypeId} issueTypeId={issueTypeId} />;
					})}

					{isCreatingNewType && <TypeCardCreate onCreate={() => setIsCreatingNewType(false)} />}

					{!isCreatingNewType && (
						<Box xcss={issueTypeCreateSectionStyles}>
							<Button iconBefore={AddIcon} onClick={() => setIsCreatingNewType(true)}>
								{formatMessage(messages.addType)}
							</Button>
						</Box>
					)}
				</AvatarsProvider>
			</ProjectIdProvider>
		</>
	);
};

const issueTypeCreateSectionStyles = xcss({
	marginTop: 'space.150',
});
