import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type ChangeEvent,
	type KeyboardEvent,
} from 'react';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { IssueTypeSelect } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-select/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { ValidationIcon } from './validation-icon.tsx';
import { messages } from './messages.tsx';

const MAX_SUMMARY_LENGTH = 255 as const;

type Props = {
	defaultValue?: string;
	isCompact?: boolean;
	placeholder: string;
	onCancel: () => void;
	onCreate: (issueTypeId: IssueTypeId, summary: string, createdByBlur: boolean) => void;
	testId?: string;
	preventAutoFocusScroll?: boolean;
	isCreationOnBlurPrevented?: (element: Element) => boolean;
	defaultIssueTypeId: IssueTypeId;
	availableIssueTypeIds?: IssueTypeId[];
	shouldShowIssueTypeSelect?: boolean;
	paddingLeft?: string;
};

export const IssueCreateInput = ({
	defaultValue = '',
	isCompact = false,
	placeholder,
	onCancel,
	onCreate,
	preventAutoFocusScroll = false,
	isCreationOnBlurPrevented = () => false,
	testId = 'polaris-lib-create-issue-input.ui.text-field',
	defaultIssueTypeId,
	availableIssueTypeIds,
	paddingLeft = token('space.100'),
	shouldShowIssueTypeSelect = true,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const textFieldRef = useRef<HTMLInputElement>(null);
	const [issueTypeId, setIssueTypeId] = useState<IssueTypeId>(defaultIssueTypeId);
	const [inputValue, setInputValue] = useState(defaultValue);
	const trimmedValue = inputValue.trim();

	const errorMessage = useMemo(() => {
		if (trimmedValue.length > MAX_SUMMARY_LENGTH) {
			return formatMessage(messages.maxLengthError);
		}

		return undefined;
	}, [formatMessage, trimmedValue]);

	useEffect(() => {
		if (preventAutoFocusScroll) {
			textFieldRef.current?.focus({ preventScroll: true });
		}
	}, [preventAutoFocusScroll]);

	const handleSubmit = useCallback(
		(createdByBlur: boolean) => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'submitted', actionSubject: 'form' }),
				'createIdea',
			);
			sendPendoTrackEvent({
				actionSubjectAndAction: 'form submitted',
				actionSubjectId: 'createIdea',
			});

			onCreate(issueTypeId, trimmedValue, createdByBlur);
		},
		[onCreate, createAnalyticsEvent, issueTypeId, trimmedValue],
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Esc' || event.key === 'Escape') {
				onCancel();
				return;
			}

			if (event.key === 'Enter' && trimmedValue.length > 0 && !errorMessage) {
				handleSubmit(false);
			}
		},
		[onCancel, handleSubmit, trimmedValue.length, errorMessage],
	);

	const handleClickOutside = useCallback(
		(event: Event) => {
			if (event?.target instanceof Element && isCreationOnBlurPrevented(event.target)) {
				return;
			}

			if (trimmedValue.length > 0 && !errorMessage) {
				handleSubmit(true);
			} else {
				onCancel();
			}
		},
		[isCreationOnBlurPrevented, onCancel, handleSubmit, errorMessage, trimmedValue.length],
	);

	return (
		<OutsideClickAlerter onClickOutside={handleClickOutside}>
			{(outsideClickAlerterProps) => (
				<div {...outsideClickAlerterProps}>
					<TextField
						value={inputValue}
						onChange={(event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value)}
						onKeyDown={handleKeyDown}
						ref={textFieldRef}
						autoFocus={!preventAutoFocusScroll}
						autoComplete="off"
						isCompact={isCompact}
						testId={testId}
						placeholder={placeholder}
						elemAfterInput={errorMessage && <ValidationIcon errorMessage={errorMessage} />}
						elemBeforeInput={
							shouldShowIssueTypeSelect && (
								<Box
									xcss={issueTypesSelectStyles}
									// eslint-disable-next-line jira/react/no-style-attribute
									style={{ paddingLeft }}
								>
									<IssueTypeSelect
										issueTypeId={issueTypeId}
										onChange={({ id }) => {
											setIssueTypeId(id);
										}}
										returnFocusRef={textFieldRef}
										triggerType="icon-and-chevron"
										availableIssueTypeIds={availableIssueTypeIds}
									/>
								</Box>
							)
						}
					/>
				</div>
			)}
		</OutsideClickAlerter>
	);
};

const issueTypesSelectStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});
