import { useState, useEffect, useRef } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useActions } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { messages } from '../messages.tsx';
import { useFirstAvailableAvatar } from '../avatars-provider.tsx';

type Props = {
	defaultAvatarId?: string;
	defaultName?: string;
	issueTypeId?: IssueTypeId; // issueTypeId is not existing for the issueType that is being created
	projectId: string;
};

export type OnUpdateIssueType = {
	newName?: string;
	newAvatarId?: string;
};

type OnUpdate = {
	newName: string;
	newAvatarId: string;
};

const EMPTY_NAME = '';
const EMPTY_AVATAR_ID = '';

export const useFormState = ({ defaultAvatarId, defaultName, issueTypeId, projectId }: Props) => {
	const [avatarId, setAvatarId] = useState(defaultAvatarId || EMPTY_AVATAR_ID);
	const [name, setName] = useState(defaultName || EMPTY_NAME);
	const { updateIssueType } = useActions();
	const { avatarId: firstAvailableAvatar } = useFirstAvailableAvatar();
	const { formatMessage } = useIntl();
	const { error } = useNotifications();
	const [isNameInvalid, setIsNameInvalid] = useState(false);
	const stateSubmitted = useRef({ name, avatarId });

	const onUpdateIssueType = async ({ newAvatarId, newName }: OnUpdate) => {
		if (isNameInvalid) {
			return;
		}

		// Don't perform an update if the name or avatarId of the issue type is the same as previously submitted
		if (
			newName === stateSubmitted.current.name &&
			newAvatarId === stateSubmitted.current.avatarId
		) {
			return;
		}

		if (issueTypeId) {
			try {
				await updateIssueType(issueTypeId, projectId, {
					avatarId: newAvatarId,
					name: newName?.trim(),
					description: '',
				});
				stateSubmitted.current = {
					name: newName,
					avatarId: newAvatarId,
				};
			} catch (err) {
				error({
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.errorDescription),
				});
			}
		}
	};

	const onChangeName = (value: string) => {
		if (value === '') {
			setIsNameInvalid(true);
		} else {
			setIsNameInvalid(false);
		}
		setName(value);
	};

	const onChangeAvatarId = (value: string) => {
		setAvatarId(value);
	};

	const onChangeAndSubmitAvatarId = async (value: string) => {
		onChangeAvatarId(value);
		onUpdateIssueType({ newAvatarId: value, newName: name });
	};

	const onSubmitAvatarIdAndName = async () => {
		return onUpdateIssueType({ newAvatarId: avatarId, newName: name });
	};

	// If no avatar was provided, use default one from the system.
	useEffect(() => {
		if (!avatarId) {
			onChangeAvatarId(firstAvailableAvatar);
		}
	}, [avatarId, firstAvailableAvatar]);

	return {
		avatarId,
		name,
		onChangeName,
		onChangeAndSubmitAvatarId,
		onSubmitAvatarIdAndName,
		isNameInvalid,
	};
};
