import { useCallback, useEffect, useMemo } from 'react';
import uniq from 'lodash/uniq';
import { useFieldKeys } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useIsInitialized as useIsIssuesInitialized } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { useIssueViewLayout } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useFieldKeyToAriMapAsync } from '@atlassian/jira-polaris-component-field-ari/src/index.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	useUserPropertiesActions,
	useUserProperty,
} from '@atlassian/jira-polaris-common/src/controllers/user-properties/index.tsx';

export const useLoadIssuesAndInitialize = () => {
	const { loadIssues, initialize } = useIssueActions();
	const [fieldKeys] = useFieldKeys();
	const fieldKeyToAriMap = useFieldKeyToAriMapAsync({ fieldKeys });
	const isIssuesInitialized = useIsIssuesInitialized();
	const issueViewLayout = useIssueViewLayout();
	const shouldInitialize = !isIssuesInitialized && issueViewLayout === undefined;

	useEffect(() => {
		if (shouldInitialize) {
			loadIssues().then(() => initialize(fieldKeyToAriMap));
		}
	}, [fieldKeyToAriMap, initialize, shouldInitialize, loadIssues]);

	return isIssuesInitialized;
};

export const useCollapsedSections = (issueTypeId: IssueTypeId) => {
	const container = useEnvironmentContainer();
	const userPropertyKey =
		`jpd.connections.${container?.id ?? 'unknown-container-id'}.${issueTypeId}`.toLowerCase();

	const [, { loadProperty, saveProperty }] = useUserPropertiesActions();
	const [{ value, isLoading }] = useUserProperty(userPropertyKey);

	const collapsedSections = useMemo(() => (Array.isArray(value) ? value : []), [value]);

	useEffect(() => {
		loadProperty(userPropertyKey);
	}, [loadProperty, userPropertyKey]);

	const toggleSection = useCallback(
		(fieldKey: FieldKey) => {
			const isCollapsed = collapsedSections.includes(fieldKey);
			const newCollapsedSections = isCollapsed
				? collapsedSections.filter((key) => key !== fieldKey)
				: uniq([...collapsedSections, fieldKey]);
			saveProperty(userPropertyKey, newCollapsedSections, false);
		},
		[collapsedSections, saveProperty, userPropertyKey],
	);

	return [isLoading, collapsedSections, toggleSection] as const;
};
