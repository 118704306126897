import React, { useMemo } from 'react';
import { useFieldValueChangeReporter } from '@atlassian/jira-polaris-use-field-value-change-reporter/src/index.tsx';
import {
	usePlatformGoalsValue,
	useSafeJiraIssueId,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { GoalsField } from './main.tsx';
import type { Props } from './types.tsx';

export const PlatformGoalsField = (props: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const { localIssueId, fieldKey, onValueChange = () => {} } = props;
	const value = usePlatformGoalsValue(fieldKey, localIssueId);
	const issueId = useSafeJiraIssueId(localIssueId);
	const goalsAris = useMemo(() => {
		if (value === undefined) {
			return [];
		}
		if (Array.isArray(value)) {
			return value;
		}
		return [value];
	}, [value]);

	useFieldValueChangeReporter(value, onValueChange);

	return <GoalsField issueId={issueId} value={goalsAris} {...props} />;
};
