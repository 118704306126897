import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.title',
		defaultMessage: 'Add a project',
		description: 'Title for the roadmap configuration sidebar on step of adding a project',
	},
	backButtonLabel: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.back-button-label',
		defaultMessage: 'Back to managing projects of a roadmap',
		description:
			'Back button label for the roadmap configuration sidebar on step of adding a project',
	},
	description: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.description',
		defaultMessage: 'Display more ideas in the roadmap by adding more projects.',
		description: 'Description for the roadmap configuration sidebar on step of adding a project',
	},
	cancelButtonLabel: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.cancel-button-label',
		defaultMessage: 'Cancel',
		description:
			'Label for the button that cancels adding a project in the roadmap configuration sidebar',
	},
	addButtonLabel: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.add-button-label',
		defaultMessage: 'Add',
		description: 'Label for the button that adds a project in the roadmap configuration sidebar',
	},
	errorTitle: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.error-title',
		defaultMessage: 'We couldn’t add the project',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.error-description',
		defaultMessage: 'Please try again or refresh the page.',
		description: 'Flag description for an error occurring while adding a project to a roadmap.',
	},
});
