import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	newLozenge: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.cell.name.new-lozenge',
		defaultMessage: 'New',
		description: 'Label showing that the field is a recent addition.',
	},
	hiddenLozenge: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.cell.name.hidden-lozenge',
		defaultMessage: 'Hidden',
		description: 'Label showing that the field has been hidden.',
	},
	hiddenDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.cell.name.hidden-description',
		defaultMessage: 'This field is hidden from the project',
		description: 'Tooltip text for when hovering a hidden field label',
	},
});
