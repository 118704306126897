import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import AttachmentIcon from '@atlaskit/icon/glyph/attachment';
import type { MediaClientConfig } from '@atlaskit/media-core';
import {
	Browser,
	type UploadEndEventPayload,
	type UploadErrorEventPayload,
	type UploadsStartEventPayload,
} from '@atlaskit/media-picker';
import { useIntl } from '@atlassian/jira-intl';
import type { MediaUploadPermissionResponse } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-upload-permissions/index.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useSelectedIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { getMediaUploadConfig } from '@atlassian/jira-polaris-common/src/controllers/media/utils.tsx';
import { useCanAddAttachments } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/index.tsx';
import messages from './messages.tsx';

const defaultBrowserConfig = {
	multiple: false,
	uploadParams: {},
} as const;

type Props = {
	showLabel?: boolean;
};

export const AttachButton = ({ showLabel = false }: Props) => {
	const [isBrowserOpen, setIsBrowserOpen] = useState(false);
	const [uploadContext, setUploadContext] = useState<MediaUploadPermissionResponse | undefined>(
		undefined,
	);
	const [autoHealAttempts, setAutoHealAttempts] = useState(0);
	const { error, errorWithRefresh } = useNotifications();
	const tokenRefreshTimer = useRef<ReturnType<typeof setTimeout> | undefined>();
	const mountSafeguard = useRef(true);
	const [browserConfig, setBrowserConfig] = useState(defaultBrowserConfig);
	const { addAttachment, addAttachmentInUpload, abortAttachmentInUpload } = useIdeaActions();
	const issueKey = useSelectedIssueKey();
	const [canAddAttachments] = useCanAddAttachments();
	const accountId = useAccountId();

	const { addMediaToIssueAttachments } = useIssueRemote();

	const { formatMessage } = useIntl();

	const refreshUploadContext = useCallback(() => {
		setUploadContext(undefined);
		if (issueKey !== undefined) {
			getMediaUploadConfig(issueKey).then((response) => {
				setUploadContext(response);
				setBrowserConfig({
					...defaultBrowserConfig,
					uploadParams: {
						...defaultBrowserConfig.uploadParams,
						collection: response.targetCollection,
					},
				});
				// added 10s buffer to cover network calls
				const tokenExpiration = response.tokenDurationInMins * 60000 - 10000;
				tokenRefreshTimer.current = setTimeout(refreshUploadContext, tokenExpiration);
			});
		}
	}, [issueKey]);

	useEffect(() => {
		refreshUploadContext();

		return () => {
			mountSafeguard.current = false;
			if (tokenRefreshTimer.current !== undefined) {
				clearTimeout(tokenRefreshTimer.current);
			}
		};
	}, [issueKey, refreshUploadContext]);

	const mediaClientConfig = useMemo<MediaClientConfig>(
		() => ({
			authProvider: () =>
				new Promise((resolve, reject) => {
					if (!uploadContext) {
						reject();
						return;
					}
					const { endpointUrl, clientId, token } = uploadContext;
					resolve({
						clientId,
						token,
						baseUrl: endpointUrl,
					});
				}),
		}),
		[uploadContext],
	);

	const openBrowser = useCallback(() => setIsBrowserOpen(true), []);
	const closeBrowser = useCallback(() => setIsBrowserOpen(false), []);

	const onUploadStart = useCallback(
		({ files }: UploadsStartEventPayload) => {
			experience.ideaView.fileAttach.start();
			files.forEach(({ id }) => {
				addAttachmentInUpload(id);
			});
		},
		[addAttachmentInUpload],
	);

	const onUploadEnd = useCallback(
		({ file }: UploadEndEventPayload) => {
			if (issueKey !== undefined) {
				addMediaToIssueAttachments(issueKey, file.id)
					.then((attachmentId) => {
						addAttachment(attachmentId, file.id, mediaClientConfig, accountId);
					})
					.then(() => experience.ideaView.fileAttach.success());
			}
		},
		[addAttachment, issueKey, mediaClientConfig, accountId, addMediaToIssueAttachments],
	);

	const onError = useCallback(
		(err: UploadErrorEventPayload) => {
			if (isClientFetchError(err.error.rawError)) {
				experience.ideaView.fileAttach.abort(err.error.rawError);
			} else {
				experience.ideaView.fileAttach.failure(err.error.rawError);
			}
			abortAttachmentInUpload(err.fileId);
			// @ts-expect-error - Property 'reason' does not exist on type 'Error'.
			if (autoHealAttempts === 0 && err.error?.rawError?.reason === 'serverForbidden') {
				// token was not accepted, attempt to refresh it
				error({
					title: formatMessage(messages.failedToUploadCaption),
					description: formatMessage(messages.firstFailedToUploadMessage),
				});
				setAutoHealAttempts(autoHealAttempts + 1);
				refreshUploadContext();
			} else {
				errorWithRefresh({
					title: formatMessage(messages.failedToUploadCaption),
					description: formatMessage(messages.finalFailedToUploadMessage),
				});
			}
		},
		[
			autoHealAttempts,
			setAutoHealAttempts,
			refreshUploadContext,
			abortAttachmentInUpload,
			error,
			errorWithRefresh,
			formatMessage,
		],
	);

	if (!canAddAttachments) {
		return null;
	}

	return (
		<>
			<ButtonWithTooltip
				iconBefore={<AttachmentIcon label={formatMessage(messages.attachButtonCaption)} />}
				onClick={openBrowser}
				isDisabled={uploadContext === undefined}
				tooltipProps={{
					content: showLabel ? undefined : formatMessage(messages.attachButtonCaption),
				}}
			>
				{showLabel && formatMessage(messages.attachButtonCaption)}
			</ButtonWithTooltip>
			{uploadContext !== undefined && (
				<Browser
					isOpen={isBrowserOpen}
					config={browserConfig}
					mediaClientConfig={mediaClientConfig}
					onEnd={onUploadEnd}
					onUploadsStart={onUploadStart}
					onClose={closeBrowser}
					onError={onError}
				/>
			)}
		</>
	);
};
