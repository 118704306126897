import React, {
	useState,
	useCallback,
	type SyntheticEvent,
	useEffect,
	useRef,
	useMemo,
} from 'react';
import { styled } from '@compiled/react';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Stack, xcss } from '@atlaskit/primitives';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	OutsideClickAlerter,
	isFocusOutsideJFE,
} from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import {
	type UIAnalyticsEvent,
	ContextualAnalyticsData,
	fireUIAnalytics,
	MODAL,
	useAnalyticsEvents,
	fireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { AsyncSharingForm } from './async.tsx';
import { DisabledScreen } from './disabled-screen/index.tsx';
import { ErrorScreen } from './error-screen/index.tsx';
import { LoadingScreen } from './loading-screen/index.tsx';
import { PublishButton } from './publish-button/index.tsx';
import type { Props } from './types.tsx';

export const PublishDialogContent = ({
	isDisabled,
	hasError,
	hasSettingsError,
	...props
}: Props) => {
	const { isSiteAdmin } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isPublished = props.settings?.sharingLinkEnabled === true;
	const isPublic = props.settings?.publicSharingEnabled === true;
	const unsupportedFieldsCount = props.unsupportedFields?.length || 0;
	const isMountedRef = useRef(false);
	const stakeholdersCount = props.stakeholders.users.length;
	const groupsCount = props.stakeholders.groups.length;
	const unsupportedFieldsTypes = useMemo(
		() => props.unsupportedFields?.map(({ type }) => type) || [],
		[props.unsupportedFields],
	);

	useEffect(() => {
		if (isMountedRef.current) {
			return;
		}
		isMountedRef.current = true;

		const analyticsAttributes = {
			stakeholdersCount,
			groupsCount,
			unsupportedFieldsCount,
			unsupportedFieldsTypes,
			isPublished,
			isPublic,
			hasError: hasError || false,
			isDisabledBySiteAdmin: isDisabled || false,
			isSiteAdmin,
		};
		fireScreenAnalytics(createAnalyticsEvent({}), analyticsAttributes);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'publishModal opened',
			attributes: {
				...analyticsAttributes,
				unsupportedFieldsTypes: unsupportedFieldsTypes.join(', '),
			},
		});
	}, [
		createAnalyticsEvent,
		isPublished,
		isPublic,
		stakeholdersCount,
		groupsCount,
		unsupportedFieldsCount,
		unsupportedFieldsTypes,
		hasError,
		isDisabled,
		isSiteAdmin,
	]);

	useEffect(
		() => () => {
			sendPendoTrackEvent({
				actionSubjectAndAction: 'publishModal closed',
			});
			fireUIAnalytics(createAnalyticsEvent({}), 'modal closed', 'publish');
		},
		[createAnalyticsEvent],
	);

	if (hasError) {
		return <ErrorScreen />;
	}

	if (isDisabled) {
		return <DisabledScreen />;
	}

	return <AsyncSharingForm {...props} />;
};

export const PublishDialog = (props: Props) => {
	const [dialogOpen, setDialogOpen] = useState(props.isInitiallyOpen ?? false);
	const { viewUUID, onOpen, misconfiguredViewUnsupportedFields, settings } = props;
	const showLoadingScreen = props.isLoading || !viewUUID;

	const handleTogglePopup = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		if (!dialogOpen) {
			experience.sharing.dialogLoad.start();
			fireUIAnalytics(analyticsEvent, 'button clicked', 'publishModal');
		}
		setDialogOpen(!dialogOpen);
	};

	const handleClickOutside = useCallback(
		(event: Event) => {
			if (isFocusOutsideJFE(event?.target)) {
				return;
			}
			setDialogOpen(false);
		},
		[setDialogOpen],
	);

	useEffect(() => {
		if (dialogOpen && viewUUID) {
			onOpen(viewUUID);
		}
	}, [dialogOpen, viewUUID, onOpen]);

	return (
		<>
			<OutsideClickAlerter onClickOutside={handleClickOutside}>
				{(outsideClickAlerterProps) => (
					<OutsideClickAlerterWrapper {...outsideClickAlerterProps}>
						<Popup
							isOpen={dialogOpen}
							placement="bottom-start"
							content={() => (
								<UFOSegment name="jpd.control-sharing.publish">
									<ContextualAnalyticsData sourceName="publish" sourceType={MODAL}>
										<Stack xcss={contentStyles}>
											{showLoadingScreen ? <LoadingScreen /> : <PublishDialogContent {...props} />}
										</Stack>
									</ContextualAnalyticsData>
								</UFOSegment>
							)}
							trigger={(triggerProps) => (
								<PublishButton
									isSelected={dialogOpen}
									onClick={handleTogglePopup}
									triggerProps={triggerProps}
									isPublished={
										props.settings?.sharingLinkEnabled === true && !props.hasSettingsError
									}
									isPublic={
										settings?.publicSharingEnabled === true &&
										!(
											props.publicSharingControls?.status === 'disabled' &&
											props.publicSharingControls?.reason === 'global-configuration' &&
											!showLoadingScreen
										)
									}
									isMisconfigured={
										misconfiguredViewUnsupportedFields &&
										misconfiguredViewUnsupportedFields.length > 0
									}
								/>
							)}
						/>
					</OutsideClickAlerterWrapper>
				)}
			</OutsideClickAlerter>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OutsideClickAlerterWrapper = styled.div({
	flex: '0 0 auto',
});

const contentStyles = xcss({
	width: '500px',
	maxHeight: 'calc(100vh - 165px)',
	overflowY: 'auto',
	marginTop: 'space.250',
	marginBottom: 'space.250',
	paddingLeft: 'space.250',
	paddingRight: 'space.250',
});
