import React, { useCallback, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import AvatarGroup from '@atlaskit/avatar-group';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useField } from '../../../controllers/issue/selectors/fields-hooks.tsx';
import type { UsersFieldProps } from './types.tsx';
import { UserTags } from './user-tags/index.tsx';
import { UsersFieldEditable } from './users-field-editable/index.tsx';

const MAX_AVATAR_COUNT = 7;

export const UsersField = (props: UsersFieldProps) => {
	const { isEditable, value, interactive, placeholder, verticalLayout } = props;
	const [isEditing, setIsEditing] = useState(false);
	const field = useField(props.fieldKey);
	const showFullName = field?.configuration?.displayMode === 'fullNameWithAvatar';

	const onEditingCancel = useCallback(() => setIsEditing(false), []);
	const onEditingStart = useCallback(() => setIsEditing(true), []);

	const hiddenCountRef = useRef<number>(-1);
	const setHiddenCountRefValue = useCallback((count: number) => {
		hiddenCountRef.current = count;
	}, []);

	const readView = useCallback(
		() =>
			!value?.length ? (
				<Placeholder>{placeholder}</Placeholder>
			) : (
				<AvatarGroupContainer isEditable={isEditable}>
					{showFullName ? (
						<UserTags
							users={value}
							verticalLayout={verticalLayout}
							hiddenCountRef={hiddenCountRef}
							setHiddenCountRefValue={setHiddenCountRefValue}
						/>
					) : (
						<AvatarGroup appearance="stack" data={value} maxCount={MAX_AVATAR_COUNT} size="small" />
					)}
				</AvatarGroupContainer>
			),
		[value, placeholder, isEditable, showFullName, verticalLayout, setHiddenCountRefValue],
	);

	return isEditable && (interactive || isEditing) ? (
		<UsersFieldEditable
			{...props}
			users={value}
			readView={readView}
			onEdit={onEditingStart}
			onCancel={onEditingCancel}
		/>
	) : (
		<Box xcss={readViewContainerStyles}>{readView()}</Box>
	);
};

const readViewContainerStyles = xcss({
	border: '2px solid transparent', // Inline Edit component wraps a border around the read view component, we do the same here
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div({
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarGroupContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		cursor: ({ isEditable }) => (isEditable ? 'pointer' : 'default'),
	},
	paddingTop: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingRight: '3px',
	paddingBottom: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'*': {
		zIndex: 0,
	},
});
