import React, { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import noop from 'lodash/noop';
import type { ViewToDelete } from '@atlassian/jira-polaris-component-collection-delete-modal/src/common/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

type CollectionConfigure = {
	collectionUUID: string | undefined;
	views: ViewToDelete[];
	currentUser: AccountId | undefined;
	onClose: VoidFunction;
	onBeforeCollectionDelete?: () => Promise<void>;
	onAfterCollectionDelete?: VoidFunction;
	onAfterSelfAccessPermissionDelete?: VoidFunction;
};
const CollectionConfigureContext = createContext<CollectionConfigure>({
	collectionUUID: undefined,
	currentUser: undefined,
	views: [],
	onClose: noop,
});

export const CollectionConfigureContextProvider = ({
	children,
	collectionUUID,
	currentUser,
	views,
	onClose,
	onBeforeCollectionDelete,
	onAfterCollectionDelete,
	onAfterSelfAccessPermissionDelete,
}: PropsWithChildren<CollectionConfigure>) => {
	const value = useMemo(
		() => ({
			currentUser,
			collectionUUID,
			views,
			onClose,
			onAfterCollectionDelete,
			onBeforeCollectionDelete,
			onAfterSelfAccessPermissionDelete,
		}),
		[
			currentUser,
			collectionUUID,
			views,
			onClose,
			onAfterCollectionDelete,
			onBeforeCollectionDelete,
			onAfterSelfAccessPermissionDelete,
		],
	);

	return (
		<CollectionConfigureContext.Provider value={value}>
			{children}
		</CollectionConfigureContext.Provider>
	);
};

export const useCollectionUUID = () => {
	const { collectionUUID } = useContext(CollectionConfigureContext);

	return collectionUUID;
};

export const useViews = () => {
	const { views } = useContext(CollectionConfigureContext);

	return views;
};

export const useActions = () => {
	const {
		onClose,
		onAfterCollectionDelete,
		onBeforeCollectionDelete,
		onAfterSelfAccessPermissionDelete,
	} = useContext(CollectionConfigureContext);

	return {
		onClose,
		onAfterCollectionDelete,
		onBeforeCollectionDelete,
		onAfterSelfAccessPermissionDelete,
	};
};

export const useCurrentUser = () => {
	const { currentUser } = useContext(CollectionConfigureContext);

	return currentUser;
};
