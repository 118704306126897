/** @jsx jsx */
import { useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button';
import OverviewIcon from '@atlaskit/icon/core/migration/align-left--overview';
import { Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useMarkViewAsSeenLocally } from '@atlassian/jira-polaris-component-view-visitors/src/controllers/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

type Props = {
	viewUUID?: string;
	autoOpenSidebarCondition?: boolean;
	onOpenRightSidebarOnViewInfo: () => void;
};

export const ShowDescriptionButton = ({
	viewUUID,
	onOpenRightSidebarOnViewInfo,
	autoOpenSidebarCondition,
}: Props) => {
	const { formatMessage } = useIntl();
	const markViewAsSeenLocally = useMarkViewAsSeenLocally();

	useEffect(() => {
		if (autoOpenSidebarCondition) {
			onOpenRightSidebarOnViewInfo();
			if (viewUUID !== undefined) {
				markViewAsSeenLocally(viewUUID);
			}
		}
	}, [onOpenRightSidebarOnViewInfo, autoOpenSidebarCondition, markViewAsSeenLocally, viewUUID]);

	return isVisualRefreshEnabled() ? (
		<Pressable
			onClick={onOpenRightSidebarOnViewInfo}
			backgroundColor="color.background.neutral.subtle"
			padding="space.0"
			xcss={pressableStyles}
		>
			<OverviewIcon
				label={formatMessage(messages.descriptionButton)}
				LEGACY_size="small"
				color={token('color.icon.subtlest')}
			/>
			{formatMessage(messages.descriptionButton)}
		</Pressable>
	) : (
		<div css={customButtonStyles}>
			<Button
				onClick={onOpenRightSidebarOnViewInfo}
				iconBefore={
					<OverviewIcon
						label={formatMessage(messages.descriptionButton)}
						LEGACY_size="small"
						color={token('color.icon.subtlest')}
					/>
				}
			>
				{formatMessage(messages.descriptionButton)}
			</Button>
		</div>
	);
};

const pressableStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.UNSAFE_small',
	display: 'flex',
	gap: 'space.050',

	':hover': {
		textDecoration: 'underline',
	},
	':active': {
		color: 'color.text',
	},
});

const customButtonStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	button: {
		display: 'flex',
		alignItems: 'center',
		gap: token('space.050', '4px'),
		padding: 0,
		font: token('font.body.small'),
		height: 'auto',
		verticalAlign: 'baseline',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		color: `${token('color.text.subtlest')} !important`,
		background: 'transparent',
		'&:hover': {
			background: 'transparent',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
			color: `${token('color.text.subtlest')} !important`,
			textDecoration: 'underline',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > *': {
			margin: 0,
		},
	},
});
