import LinkButton, { type Appearance } from '@atlaskit/button/new';
import { Pressable, type XCSS } from '@atlaskit/primitives';
import { type Options, useCrossFlow } from '@atlassiansox/cross-flow-support';
import { useMessageInstanceEvent } from '@post-office/message-instance-event-frontend-client';
import { useMessageEvent } from '@post-office/message-lifecycle';
import { type ReactNode, useCallback } from 'react';

export type BaseButtonProps = {
	crossflowOptions: Options;
	ctaText: ReactNode;
	alt?: string;
};

export type LinkButtonProps = BaseButtonProps & {
	appearance: Appearance;
};

export type PressableProps = BaseButtonProps & {
	pressableStyle: XCSS;
};

export const CrossflowCtaPrimaryLinkButton = ({
	crossflowOptions,
	ctaText,
	appearance,
	alt,
}: LinkButtonProps) => {
	const crossflow = useCrossFlow();
	const { messageClicked } = useMessageEvent();
	const { messageInstanceClicked } = useMessageInstanceEvent();

	const handleClick = useCallback(() => {
		messageClicked().catch(() => {});
		messageInstanceClicked({
			messageEventType: 'clicked',
			clickedElement: {
				clickIdentifier: 'cta',
				clickedElement: 'button',
				buttonType: 'primary',
			},
			action: {
				actionType: 'acknowledge',
			},
			additionalMetadataFromFrontend: {},
		}).catch(() => {});
		if (crossflow.isEnabled) {
			crossflow.api.open(crossflowOptions).catch(() => {});
		}
	}, [crossflow, crossflowOptions, messageClicked, messageInstanceClicked]);

	return (
		<LinkButton onClick={handleClick} appearance={appearance} aria-label={alt}>
			{ctaText}
		</LinkButton>
	);
};

export const CrossflowCtaPrimaryPressable = ({
	crossflowOptions,
	ctaText,
	pressableStyle,
	alt,
}: PressableProps) => {
	const crossflow = useCrossFlow();
	const { messageClicked } = useMessageEvent();
	const { messageInstanceClicked } = useMessageInstanceEvent();
	const handleClick = useCallback(() => {
		messageClicked().catch(() => {});
		messageInstanceClicked({
			messageEventType: 'clicked',
			clickedElement: {
				clickIdentifier: 'cta',
				clickedElement: 'button',
				buttonType: 'primary',
			},
			action: {
				actionType: 'acknowledge',
			},
			additionalMetadataFromFrontend: {},
		}).catch(() => {});
		if (crossflow.isEnabled) {
			crossflow.api.open(crossflowOptions).catch(() => {});
		}
	}, [crossflow, crossflowOptions, messageClicked, messageInstanceClicked]);

	return (
		<Pressable onClick={handleClick} xcss={pressableStyle} aria-label={alt}>
			{ctaText}
		</Pressable>
	);
};

export const CrossflowCtaSecondaryPressable = ({
	crossflowOptions,
	ctaText,
	pressableStyle,
	alt,
}: PressableProps) => {
	const crossflow = useCrossFlow();
	const { messageClickedSecondary } = useMessageEvent();
	const { messageInstanceClicked } = useMessageInstanceEvent();
	const handleClick = useCallback(() => {
		messageClickedSecondary().catch(() => {});
		messageInstanceClicked({
			messageEventType: 'clicked',
			clickedElement: {
				clickIdentifier: 'cta',
				clickedElement: 'button',
				buttonType: 'secondary',
			},
			action: {
				actionType: 'acknowledge',
			},
			additionalMetadataFromFrontend: {},
		}).catch(() => {});
		if (crossflow.isEnabled) {
			crossflow.api.open(crossflowOptions).catch(() => {});
		}
	}, [crossflow, crossflowOptions, messageClickedSecondary, messageInstanceClicked]);

	return (
		<Pressable onClick={handleClick} xcss={pressableStyle} aria-label={alt}>
			{ctaText}
		</Pressable>
	);
};
