import React, {
	type PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { get as getAvatars } from '@atlassian/jira-common-rest/src/api/2/universal-avatar/type/issuetype/owner/id/rest.tsx';
import type { RawAvatar } from '@atlassian/jira-common-rest/src/api/2/universal-avatar/type/issuetype/owner/id/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

const Context = createContext<null | RawAvatar[]>(null);

type Props = PropsWithChildren<{ projectId: ProjectId }>;

export const AvatarsProvider = ({ projectId, children }: Props) => {
	const [avatars, setAvatars] = useState<RawAvatar[]>([]);

	useEffect(() => {
		getAvatars(Number(projectId)).then(({ system }) => setAvatars(system));
	}, [projectId]);

	return <Context.Provider value={avatars}>{children}</Context.Provider>;
};

export const useAvatars = () => {
	const ctx = useContext(Context);

	if (ctx === null) {
		throw new Error('Missing AvatarsProvider');
	}

	return ctx;
};

export const useFirstAvailableAvatar = () => {
	const avatars = useAvatars();
	const avatarId = avatars[0]?.id ?? '';
	return { avatarId };
};
