/** @jsx jsx */
import { css, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { ProjectTag } from '@atlassian/jira-polaris-lib-project-tag/src/ui/index.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useFieldOptions } from '../../../../controllers/selectors/field-hooks.tsx';

const ProjectItem = ({ projectId }: { projectId: ProjectId }) => {
	const project = useProjectMetadata({ projectId });

	if (project === undefined) {
		return null;
	}

	return (
		<ProjectTag
			isLink
			projectName={project.name}
			projectAvatarUrl={project.avatarUrls['16x16']}
			projectKey={project.key}
		/>
	);
};

export const ProjectDecorationItem = () => {
	const fieldOptions = useFieldOptions();

	return (
		<Box xcss={projectsContainerCss}>
			{fieldOptions.map(({ id }) => (
				<span key={id} css={projectItemContainerCss}>
					<span css={projectItemWrapperCss}>
						<ProjectItem projectId={id} />
					</span>
					<span css={placeholderCss} />
				</span>
			))}
		</Box>
	);
};

const projectsContainerCss = xcss({
	display: 'flex',
	flexDirection: 'column',
});

const projectItemContainerCss = css({
	display: 'flex',
});

const projectItemWrapperCss = css({
	flexGrow: 0,
	paddingTop: token('space.075', '4px'),
	paddingRight: token('space.075', '4px'),
	paddingBottom: token('space.075', '4px'),
	paddingLeft: token('space.075', '4px'),
});

const placeholderCss = css({
	flexGrow: 1,
});
