import React from 'react';
import { styled } from '@compiled/react';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { getCollectionViewPageUrl } from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import TitleIconResolver from '@atlassian/jira-polaris-component-view-icon/src/ui/index.tsx';
import { ExpandableSection } from '@atlassian/jira-polaris-lib-expandable-section/src/ui/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import type { ViewToDelete } from '../../common/types.tsx';
import messages from './messages.tsx';

const ViewItem = ({
	title,
	emoji,
	kind,
	uuid,
	collectionUUID,
}: ViewToDelete & { collectionUUID: string }) => (
	<LinkItem target="_blank" href={getCollectionViewPageUrl(collectionUUID, uuid)}>
		<Flex alignItems="center" justifyContent="center" xcss={iconStyles}>
			<TitleIconResolver emojiId={emoji} viewKind={kind} isReadOnly />
		</Flex>
		<Box as="p" xcss={viewTitleStyles}>
			{title}
		</Box>
	</LinkItem>
);

type Props = {
	collectionUUID: string;
	views: ViewToDelete[];
};

export const ViewsList = ({ collectionUUID, views }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ExpandableSection title={formatMessage(messages.expandableSectionTitle)}>
			<Stack space="space.100" as="ul" xcss={viewsListStyles}>
				{views.map((view) => (
					<ViewItem key={view.uuid} collectionUUID={collectionUUID} {...view} />
				))}
			</Stack>
		</ExpandableSection>
	);
};

const viewsListStyles = xcss({
	maxHeight: '300px',
	overflowY: 'auto',
	padding: 'space.0',
	margin: 'space.0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const LinkItem = styled(Link)({
	display: 'flex',
	alignItems: 'center',
	width: 'fit-content',
});

const iconStyles = xcss({
	height: 'space.300',
	width: 'space.300',
	marginRight: 'space.075',
});

const viewTitleStyles = xcss({
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	maxWidth: '500px',
	margin: 'space.0',
});
