import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	ConnectedIssueCard,
	type IssueCardProps,
} from '@atlassian/jira-polaris-common/src/ui/issue-card/index.tsx';
import { NoValueContainer } from '../../../../common/board-header-column/styled.tsx';
import type {
	ColumnHeaderComponentProps,
	ColumnHeaderConfiguration,
	EmptyColumnHeaderComponentProps,
} from '../types.tsx';
import messages from './messages.tsx';

const ANCHOR_PROPS = {
	draggable: false,
} satisfies IssueCardProps['anchorProps'];

const Component = ({ groupIdentity }: ColumnHeaderComponentProps) => (
	<Box padding="space.025">
		<ConnectedIssueCard id={groupIdentity} type="link" anchorProps={ANCHOR_PROPS} />
	</Box>
);

const EmptyComponent = ({ isRow }: EmptyColumnHeaderComponentProps) => {
	const { formatMessage } = useIntl();

	return (
		<NoValueContainer isRow={isRow}>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>
	);
};

export const connectionColumnHeaderConfig: ColumnHeaderConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
	EmptyComponent,
};
