import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import noResults from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const EmptyScreen = () => {
	const { formatMessage } = useIntl();

	return (
		<EmptyState
			testId="polaris-component-collection-configure-sidebar.ui.add-project-content.body.empty-screen.empty-state"
			header={formatMessage(messages.emptyScreenHeader)}
			renderImage={getAkEmptyStateRenderImageFn(noResults)}
			maxImageWidth={120}
			description={formatMessage(messages.emptyScreenDescription)}
		/>
	);
};
