import React, { useCallback, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { useIntl } from '@atlassian/jira-intl';
import { CollapsibleGroup } from '@atlassian/jira-issue-view-layout-group/src/ui/index.tsx';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIsInitialized as useIsFieldsInitialized } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks.tsx';
import { useIsLoading } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIsInitialized as useIsIssuesInitialized } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { useIssueViewLayout } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useHasCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useCanPinIssueViewFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ExperienceSuccess } from '@atlassian/ufo';
import { FieldEntry } from '../entry/index.tsx';
import { FieldsSkeleton } from '../skeleton/index.tsx';
import { useFieldSections } from '../utils.tsx';
import { FieldsThatRequireAllIdeasWarning } from './fields-that-require-all-ideas-warning/index.tsx';
import messages from './messages.tsx';
import type { FieldsListProps } from './types.tsx';

const FieldsListInternal = ({
	onManageField,
	onManagePinned,
	portalElement,
	onPinField,
}: FieldsListProps) => {
	const { formatMessage } = useIntl();
	const {
		pinnedFields,
		nonPinnedCurrentViewFields,
		otherFields,
		hasFieldsRequiringAllIdeas,
		hasFormulaFields,
		hasLabelFields,
	} = useFieldSections();
	const canPinIssueViewFields = useCanPinIssueViewFields();
	const isFieldsInitialized = useIsFieldsInitialized();
	const isIssuesInitialized = useIsIssuesInitialized();
	const isLoadingIssue = useIsLoading();
	const isViewsLoading = !useHasCurrentView();
	const projectKey = useProjectKeyUnsafe();
	const issueViewLayout = useIssueViewLayout();
	const ideaIsOpenInFullscreen = issueViewLayout === undefined;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isCollectionView = useIsCollectionView();
	const isReady =
		!isViewsLoading && (isIssuesInitialized || (isFieldsInitialized && !isLoadingIssue));

	const handlePinClick = useCallback(
		(fieldKeyToPin: FieldKey) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'field pinned', { fieldKey: fieldKeyToPin });
			onPinField([SUMMARY_FIELDKEY, ...pinnedFields.allFields, fieldKeyToPin]);
		},
		[onPinField, pinnedFields, createAnalyticsEvent],
	);

	const handleUnpinClick = useCallback(
		(fieldKeyToUnpin: FieldKey) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'field unpinned', {
				fieldKey: fieldKeyToUnpin,
			});
			const pinnedFieldsKeys = pinnedFields.allFields.filter(
				(fieldKey) => fieldKey !== fieldKeyToUnpin,
			);
			onPinField([SUMMARY_FIELDKEY, ...pinnedFieldsKeys]);
		},
		[onPinField, pinnedFields, createAnalyticsEvent],
	);

	if (isReady) {
		return (
			<FieldsListWrapper data-testid="polaris-ideas.ui.idea-view.fields.field.list.fields-grid">
				<ExperienceSuccess experience={experience.fieldsSidebar.pageSegmentLoad.ufoExperience} />
				{hasFieldsRequiringAllIdeas && !isIssuesInitialized && ideaIsOpenInFullscreen && (
					<FieldsThatRequireAllIdeasWarning
						hasFormulaFields={hasFormulaFields}
						hasLabelFields={hasLabelFields}
					/>
				)}
				{pinnedFields.fields.length > 0 && (
					<CollapsibleGroup
						id="DEFAULT"
						projectKey={projectKey}
						title={formatMessage(messages.sectionHeaderPinned)}
						initialOpened
						onConfigButtonClick={canPinIssueViewFields ? onManagePinned : undefined}
					>
						<FieldsGrid data-testid="polaris-ideas.ui.idea-view.fields.field.list.pinned-fields">
							{pinnedFields.fields.map((fieldKey) => (
								<FieldEntry
									key={fieldKey}
									fieldKey={fieldKey}
									onManageField={onManageField}
									portalElement={portalElement}
									isPinned
									onPinClick={canPinIssueViewFields ? handleUnpinClick : undefined}
								/>
							))}
						</FieldsGrid>
					</CollapsibleGroup>
				)}
				{nonPinnedCurrentViewFields.fields.length > 0 && (
					<CollapsibleGroup
						id="VIEW"
						projectKey={projectKey}
						title={formatMessage(messages.sectionHeaderCurrent)}
						initialOpened
					>
						<FieldsGrid data-testid="polaris-ideas.ui.idea-view.fields.field.list.non-pinned-fields">
							{nonPinnedCurrentViewFields.fields.map((fieldKey) => (
								<FieldEntry
									key={fieldKey}
									fieldKey={fieldKey}
									onManageField={onManageField}
									portalElement={portalElement}
									onPinClick={canPinIssueViewFields ? handlePinClick : undefined}
								/>
							))}
						</FieldsGrid>
					</CollapsibleGroup>
				)}
				{otherFields.fields.length > 0 && (
					<CollapsibleGroup
						id="OTHER"
						projectKey={projectKey}
						title={formatMessage(
							isCollectionView ? messages.sectionHeaderOtherInRoadmap : messages.sectionHeaderOther,
						)}
					>
						<FieldsGrid data-testid="polaris-ideas.ui.idea-view.fields.field.list.other-fields">
							{otherFields.fields.map((fieldKey) => (
								<FieldEntry
									key={fieldKey}
									fieldKey={fieldKey}
									onManageField={onManageField}
									portalElement={portalElement}
									onPinClick={canPinIssueViewFields ? handlePinClick : undefined}
								/>
							))}
						</FieldsGrid>
					</CollapsibleGroup>
				)}
			</FieldsListWrapper>
		);
	}

	return (
		<UFOLoadHold name="idea-fields">
			<FieldsSkeleton />
		</UFOLoadHold>
	);
};

export const FieldsList = (props: FieldsListProps) => {
	const isMounted = useRef(false);

	if (!isMounted.current) {
		experience.fieldsSidebar.pageSegmentLoad.start();
	}

	useEffect(() => {
		isMounted.current = true;
	}, []);

	return (
		<ExperienceFailErrorBoundary experience={[experience.fieldsSidebar.pageSegmentLoad]}>
			<FieldsListInternal {...props} />
		</ExperienceFailErrorBoundary>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsGrid = styled.div({
	display: 'grid',
	gridTemplateColumns: '[title] minmax(100px, 0.6fr) [content] 1fr',
	gridAutoRows: `minmax(${token('space.500', '40px')}, auto)`,
	alignItems: 'center',
	gap: `0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsListWrapper = styled.div({
	paddingTop: 0,
	paddingRight: token('space.100', '8px'),
	paddingBottom: 0,
	paddingLeft: token('space.100', '8px'),
	marginBottom: token('space.400', '32px'),
	position: 'relative', // POL-10311 - Fix for overscroll issue
});
