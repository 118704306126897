import React from 'react';
import noop from 'lodash/noop';
import { Flex, Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { GoalsField } from '@atlassian/jira-polaris-common/src/ui/fields/goals/main.tsx';
import messages from './messages.tsx';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const GoalsFieldValue = ({ fieldKey, groupIdentity }: FieldValueComponentProps) => (
	<Flex>
		<GoalsField isEditable={false} fieldKey={fieldKey} value={[groupIdentity]} onUpdate={noop} />
	</Flex>
);

const EmptyGoalsFieldValue = () => {
	const { formatMessage } = useIntl();

	return <Box paddingInlineStart="space.050">{formatMessage(messages.emptyFieldValue)}</Box>;
};

export const goalsFieldValueConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: GoalsFieldValue,
	EmptyComponent: EmptyGoalsFieldValue,
} as const;
