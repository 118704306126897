import React from 'react';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EyeOpenStrikethroughIcon from '@atlaskit/icon/core/eye-open-strikethrough';
import SettingsIcon from '@atlaskit/icon/core/settings';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

type Props = {
	onHide: VoidFunction;
	onConfigure: VoidFunction;
};

export const ConnectionsConfigMenu = ({ onHide, onConfigure }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<DropdownMenu<HTMLButtonElement>
			trigger={({ triggerRef, ...props }) => (
				<IconButton
					{...props}
					icon={ShowMoreHorizontalIcon}
					label={formatMessage(messages.moreButtonLabel)}
					spacing="compact"
					appearance="subtle"
					ref={triggerRef}
					onClick={(e) => {
						e.stopPropagation();
						props.onClick?.(e);
					}}
				/>
			)}
			spacing="compact"
			shouldRenderToParent
		>
			<DropdownItemGroup>
				<DropdownItem
					elemBefore={<EyeOpenStrikethroughIcon label={formatMessage(messages.hideButtonLabel)} />}
					onClick={(e) => {
						e.stopPropagation();
						onHide();
					}}
				>
					{formatMessage(messages.hideButtonLabel)}
				</DropdownItem>
			</DropdownItemGroup>
			<DropdownItemGroup hasSeparator>
				<DropdownItem
					elemBefore={<SettingsIcon label={formatMessage(messages.configureButtonLabel)} />}
					onClick={(e) => {
						e.stopPropagation();
						onConfigure();
					}}
				>
					{formatMessage(messages.configureButtonLabel)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};
