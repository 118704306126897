import {
	createProjectHook as createProjectHookMain,
	createHigherLevelProjectHook as createHigherLevelProjectHookMain,
} from '../main.tsx';
import type { Props, State } from '../types.tsx';

export const createProjectHook = createProjectHookMain;

export const createHigherLevelProjectHook = <TArg1, TReturnValue>(
	selectorCreator: (arg1: TArg1) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1) => TReturnValue) =>
	createHigherLevelProjectHookMain<TReturnValue, [TArg1]>(selectorCreator);

export const createHigherLevelProjectHook2 = <TArg1, TArg2, TReturnValue>(
	selectorCreator: (
		arg1: TArg1,
		arg2: TArg2,
	) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1, arg2: TArg2) => TReturnValue) =>
	createHigherLevelProjectHookMain<TReturnValue, [TArg1, TArg2]>(selectorCreator);
