import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { styled } from '@compiled/react';
import { type UIAnalyticsEvent, AnalyticsListener } from '@atlaskit/analytics-next';
import { type State, ConnectedReactionsView } from '@atlaskit/reactions';
import { token } from '@atlaskit/tokens';
import { getEmojiProviderWithCustomEmojiUploadDisabled } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import { useFieldAri } from '@atlassian/jira-polaris-component-field-ari/src/controllers/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { createIssueAri } from '../../../common/utils/ari/index.tsx';
import { useIssueActions } from '../../../controllers/issue/main.tsx';
import { useField } from '../../../controllers/issue/selectors/fields-hooks.tsx';
import { useJiraIssueIdByLocalIssueId } from '../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import { useIsIssueArchived } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { reactionStore } from '../../../controllers/reactions/index.tsx';
import { useCurrentUserAccountId } from '../../../controllers/user/index.tsx';
import { MoreTag } from './more-tag/index.tsx';

type ReactionsFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	onReactionPickerOpen?: () => void;
	onReactionPickerClose?: () => void;
};

export const ReactionsField = ({
	localIssueId,
	fieldKey,
	onReactionPickerClose,
	onReactionPickerOpen,
}: ReactionsFieldProps) => {
	const field = useField(fieldKey);
	const currentUser = useCurrentUserAccountId();
	const cloudId = useCloudId();
	const issueId = useJiraIssueIdByLocalIssueId(localIssueId);
	const issueAri = createIssueAri(cloudId, issueId);
	const isIdeaArchived = useIsIssueArchived(localIssueId);
	const { toggleReaction } = useIssueActions();
	const fieldAri = useFieldAri(fieldKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onChangeCallback = useCallback(
		(state: State) => {
			const reactionsState = state && state.reactions[`${fieldAri}|${issueAri}`];
			if (reactionsState?.status === 'READY') {
				toggleReaction(fieldKey, localIssueId, reactionsState.reactions);
			}
		},
		[fieldAri, fieldKey, issueAri, localIssueId, toggleReaction],
	);

	useEffect(() => {
		reactionStore.onChange(onChangeCallback);
		return () => {
			reactionStore.removeOnChangeListener(onChangeCallback);
		};
	}, [fieldAri, fieldKey, issueAri, localIssueId, onChangeCallback, toggleReaction]);

	const reactionsRef = useRef<HTMLDivElement | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);

	const [isMoreButtonVisible, setIsMoreBtnVisible] = useState(false);
	const [_, setReactionPickerOpen] = useState(false);

	const resizeCallback = useCallback((entries: ResizeObserverEntry[]) => {
		const container = containerRef?.current;
		if (container) {
			const [entry] = entries;
			const isMoreThatContainerWidth = entry.contentRect.width > container.clientWidth;
			setIsMoreBtnVisible(isMoreThatContainerWidth);
		}
	}, []);

	useEffect(() => {
		const reactionsEl = reactionsRef?.current;
		const container = containerRef?.current;

		if (!reactionsEl || !container) {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		const resizeObserver = new ResizeObserver(resizeCallback);

		// requestAnimationFrame is necessary to prevent "ResizeObserver - loop limit exceeded" errors
		// to be triggered, which breaks the drag and drop of cards with many reactions
		requestAnimationFrame(() => {
			resizeObserver.observe(reactionsEl);
		});

		return () => {
			resizeObserver.disconnect();
		};
	});

	const reactionsEmojiProvider = useMemo(
		() => getEmojiProviderWithCustomEmojiUploadDisabled(cloudId, currentUser),
		[cloudId, currentUser],
	);

	const handleReactionPickerClose = useCallback(() => {
		setReactionPickerOpen(false);
		onReactionPickerClose?.();
	}, [onReactionPickerClose]);

	const handleReactionPickerToggle = useCallback(() => {
		setReactionPickerOpen((value) => {
			value ? onReactionPickerClose?.() : onReactionPickerOpen?.();
			return !value;
		});
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
			'issueField',
		);
	}, [createAnalyticsEvent, onReactionPickerClose, onReactionPickerOpen]);

	const handleReactionClick = useCallback(
		(event: UIAnalyticsEvent) => {
			const { action, actionSubject, actionSubjectId } = event.payload || {};

			if (action === 'clicked' && actionSubject === 'reactionPickerButton') {
				handleReactionPickerToggle();
			}

			if (
				action === 'clicked' &&
				actionSubject === 'reactionPicker' &&
				actionSubjectId === 'emoji'
			) {
				handleReactionPickerClose();
				fireCompoundAnalyticsEvent.ReactionAddedReactionsField();
			}

			if (action === 'clicked' && actionSubject === 'existingReaction') {
				event.payload.attributes.added
					? fireCompoundAnalyticsEvent.ReactionAddedReactionsField()
					: fireCompoundAnalyticsEvent.ReactionRemovedReactionsField();
			}

			if (action === 'cancelled' || action === 'closed') {
				handleReactionPickerClose();
			}
		},
		[handleReactionPickerClose, handleReactionPickerToggle],
	);

	const renderReactions = useCallback(
		() => (
			<div data-component-selector="reactions-button-wrapper">
				<AnalyticsListener channel="fabric-elements" onEvent={handleReactionClick}>
					<ConnectedReactionsView
						ari={issueAri}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						containerAri={fieldAri!}
						store={reactionStore}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						emojiProvider={reactionsEmojiProvider!}
						allowAllEmojis
					/>
				</AnalyticsListener>
			</div>
		),
		[fieldAri, handleReactionClick, issueAri, reactionsEmojiProvider],
	);

	if (!field || fieldAri === undefined || !reactionsEmojiProvider || !issueAri) {
		return null;
	}

	return (
		<ReactionsFieldContainer ref={containerRef} disabled={isIdeaArchived}>
			<div ref={reactionsRef}>{renderReactions()}</div>
			{isMoreButtonVisible && (
				<MoreTag onDialogOpen={onReactionPickerOpen} onDialogClose={onReactionPickerClose}>
					<ReactionsDialogWrapper data-component-selector="all-emojis-dialog">
						{renderReactions()}
					</ReactionsDialogWrapper>
				</MoreTag>
			)}
		</ReactionsFieldContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReactionsFieldContainer = styled.div<{ disabled?: boolean }>({
	display: 'flex',
	width: 'inherit',
	position: 'relative',
	alignItems: 'center',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ disabled }) => !disabled && 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& div[data-component-selector='reactions-button-wrapper']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		cursor: ({ disabled }) => disabled && 'no-drop',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& div[data-testid='render-reactions']": {
		flexWrap: 'nowrap',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		pointerEvents: ({ disabled }) => disabled && 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReactionsDialogWrapper = styled.div({
	paddingTop: token('space.200', '16px'),
	paddingRight: token('space.200', '16px'),
	paddingBottom: token('space.200', '16px'),
	paddingLeft: token('space.200', '16px'),
	maxWidth: '244px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& div[data-testid='render-reactions']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		flexWrap: 'wrap !important',
	},
});
