import React, { useCallback, useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import type { TriggerProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { FieldDecorationPopup } from '@atlassian/jira-polaris-lib-field-option-configuration/src/ui/field-decoration-popup/index.tsx';
import { validateFieldOptionName } from '@atlassian/jira-polaris-lib-field-option-configuration/src/utils/validate-field-option-name/index.tsx';
import { DeleteOptionHandler } from '@atlassian/jira-polaris-lib-option-utils/src/ui/delete-option/index.tsx';
import {
	fireTrackAnalytics,
	FireUiAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useCallbacks } from '../../../../../../../controllers/selectors/callback-hooks.tsx';
import { useDecorationForValue } from '../../../../../../../controllers/selectors/decoration-hooks.tsx';
import {
	useFieldKey,
	useFieldOptions,
	useFieldType,
} from '../../../../../../../controllers/selectors/field-hooks.tsx';
import messages from './messages.tsx';

type ControlledFieldDecoratorProps = {
	id: string;
	autoFocusName?: boolean;
	isDeleteDisabled?: boolean;
	isOpen: boolean;
	onClose: () => void;
	onCloseRequested: () => void;
	onOptionDeletion: () => void;
	children: (triggerProps: TriggerProps) => React.ReactNode;
};

const FIELD_TYPES_WITH_EDITABLE_OPTIONS: FieldType[] = [
	FIELD_TYPES.SINGLE_SELECT,
	FIELD_TYPES.MULTI_SELECT,
	FIELD_TYPES.JSW_MULTI_SELECT,
];

export const canModifyOptionName = (fieldType?: FieldType) =>
	!!fieldType && FIELD_TYPES_WITH_EDITABLE_OPTIONS.includes(fieldType);

const useFieldTypeDependentProps = (id?: string) => {
	const { formatMessage } = useIntl();
	const { onOptionRenamed } = useCallbacks();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fieldType = useFieldType();
	const isEditable = useMemo(() => canModifyOptionName(fieldType), [fieldType]);

	const options = useFieldOptions();
	const otherOptions = useMemo(
		() => options.filter(({ id: optionId }) => optionId !== id),
		[options, id],
	);

	const option = useMemo(() => {
		const optionsByKey = keyBy(options, ({ id: optionId }) => optionId);
		return id ? optionsByKey[id] : undefined;
	}, [id, options]);

	const optionNames = useMemo(() => (otherOptions || []).map(({ value }) => value), [otherOptions]);
	const onNameValidate = useCallback(
		(newValue: string) =>
			validateFieldOptionName({
				existingOptionNames: optionNames,
				newValue,
				formatMessage,
			}),
		[formatMessage, optionNames],
	);

	const onNameChange = useCallback(
		(newValue: string) => {
			if (id !== undefined) {
				fireUIAnalytics(createAnalyticsEvent({}), 'name changed', 'config-fields', {});
				onOptionRenamed && onOptionRenamed(id, newValue);
			}
		},
		[createAnalyticsEvent, id, onOptionRenamed],
	);

	return useMemo(() => {
		let retVal: {
			value: string;
			onNameValidate?: (arg1: string) => { isValid: boolean; error?: string | undefined };
			onNameChange?: (arg1: string) => void;
		} = {
			value: option?.value || '',
		};

		if (isEditable) {
			retVal = {
				...retVal,
				onNameValidate,
				onNameChange,
			};
		}

		return retVal;
	}, [isEditable, onNameChange, onNameValidate, option]);
};

export const ControlledFieldDecorator = ({
	id,
	autoFocusName,
	isOpen,
	isDeleteDisabled = false,
	onClose,
	onCloseRequested,
	onOptionDeletion,
	children,
}: ControlledFieldDecoratorProps) => {
	const { formatMessage } = useIntl();
	const decoration = useDecorationForValue(id);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fieldType = useFieldType();
	const fieldKey = useFieldKey();
	const { onValueDecorationCreated, onValueDecorationUpdated, onOptionDeleted } = useCallbacks();
	const additionalProps = useFieldTypeDependentProps(id);

	const onValueDecorationChanged = useCallback(
		({
			backgroundColor,
			emoji,
			highlightContainer,
		}: {
			backgroundColor: string | undefined;
			emoji: string | undefined;
			highlightContainer: boolean;
		}) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'fieldDecoration changed', 'config-fields', {
				action: decoration !== undefined ? 'update' : 'create',
				backgroundColor,
				emoji,
				highlightContainer,
			});

			sendPendoTrackEvent({
				actionSubjectAndAction: 'fieldDecoration changed',
				actionSubjectId: 'optionDecorationChanged',
				source: 'fieldOptionList',
				attributes: {
					action: decoration !== undefined ? 'update' : 'create',
					backgroundColor: backgroundColor ?? '',
					emoji: emoji ?? '',
					highlightContainer,
				},
			});

			if (decoration !== undefined) {
				onValueDecorationUpdated?.({
					localDecorationId: decoration.localDecorationId,
					backgroundColor,
					emoji,
					highlightContainer,
					rules: [{ value: id, operator: ValueRuleOperator.EQ }],
				});
			} else {
				onValueDecorationCreated?.({
					backgroundColor,
					emoji,
					highlightContainer,
					rules: [{ value: id, operator: ValueRuleOperator.EQ }],
				});
			}
		},
		[createAnalyticsEvent, decoration, id, onValueDecorationCreated, onValueDecorationUpdated],
	);

	const onColorChanged = useCallback(
		(backgroundColor: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'color clicked', 'config-fields', {
				backgroundColor,
			});

			sendPendoTrackEvent({
				actionSubjectAndAction: 'color clicked',
				actionSubjectId: 'optionColorChanged',
				source: 'fieldOptionList',
				attributes: {
					value: backgroundColor ?? '',
				},
			});

			onValueDecorationChanged({
				backgroundColor,
				emoji: decoration?.emoji,
				highlightContainer: decoration?.highlightContainer || false,
			});
		},
		[createAnalyticsEvent, decoration, onValueDecorationChanged],
	);

	const onEmojiChanged = useCallback(
		(emoji: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'emoji clicked', 'config-fields', {
				emoji,
			});

			sendPendoTrackEvent({
				actionSubjectAndAction: 'emoji clicked',
				actionSubjectId: 'optionEmojiChanged',
				source: 'fieldOptionList',
				attributes: {
					value: emoji ?? '',
				},
			});

			onValueDecorationChanged({
				backgroundColor: decoration?.backgroundColor,
				emoji,
				highlightContainer: decoration?.highlightContainer || false,
			});
		},
		[createAnalyticsEvent, decoration, onValueDecorationChanged],
	);

	const onHighlightContainerChanged = useCallback(
		(highlightContainer: boolean) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'highlightContainer clicked', 'config-fields', {
				highlightContainer,
			});

			sendPendoTrackEvent({
				actionSubjectAndAction: 'highlightContainer clicked',
				actionSubjectId: 'optionHighlightContainerChanged',
				source: 'fieldOptionList',
				attributes: {
					value: highlightContainer,
				},
			});

			onValueDecorationChanged({
				backgroundColor: decoration?.backgroundColor,
				emoji: decoration?.emoji,
				highlightContainer,
			});
		},
		[createAnalyticsEvent, decoration, onValueDecorationChanged],
	);

	const deleteOptionTooltipMessage = useMemo(() => {
		if (!isDeleteDisabled) {
			return undefined;
		}

		if (fieldType === FIELD_TYPES.LABELS || fieldType === FIELD_TYPES.CUSTOM_LABELS) {
			return formatMessage(messages.deleteLabelOptionDisabled);
		}

		return formatMessage(messages.deleteSelectOptionDisabled);
	}, [fieldType, formatMessage, isDeleteDisabled]);

	return (
		<>
			<FireUiAnalytics
				actionSubject="fieldDecorationMenu"
				action="viewed"
				actionSubjectId="viewControls"
			/>
			<DeleteOptionHandler
				fieldKey={fieldKey}
				onDeleteConfirmed={(groupIdentity) => {
					onOptionDeletion();
					if (onOptionDeleted) {
						return onOptionDeleted(groupIdentity);
					}
					return Promise.resolve();
				}}
			>
				{({ onOptionDeleteRequested }) => (
					<FieldDecorationPopup
						isOpen={isOpen}
						onClose={onClose}
						trigger={children ?? (() => <></>)}
						isDeleteDisabled={isDeleteDisabled}
						onDelete={() => {
							sendPendoTrackEvent({
								actionSubjectAndAction: 'option deleted',
								actionSubjectId: 'deleteOption',
								source: 'fieldOptionList',
							});

							onOptionDeleteRequested({
								groupIdentity: id,
								optionLabel: additionalProps.value,
							});
						}}
						isHighlighted={decoration?.highlightContainer === true}
						color={decoration?.backgroundColor}
						emojiId={decoration?.emoji}
						isNameEditable={canModifyOptionName(fieldType)}
						onColorChanged={(hexCode) => {
							onColorChanged(hexCode);
						}}
						onHighlightingChanged={(highlighted) => {
							onHighlightContainerChanged(highlighted);
						}}
						onEmojiSelected={(selectedEmoji) => {
							onEmojiChanged(selectedEmoji);
						}}
						value={additionalProps.value}
						onNameValidate={additionalProps.onNameValidate}
						onNameChange={additionalProps.onNameChange}
						autoFocusName={autoFocusName === true}
						onCloseRequested={onCloseRequested}
						deleteTooltip={deleteOptionTooltipMessage}
					/>
				)}
			</DeleteOptionHandler>
		</>
	);
};
