// User property names
export const GO_TO_PROJECT_SPOTLIGHT_DISMISSED =
	'polaris.global-fields.go-to-project-spotlight-dismissed';
export const GLOBAL_FIELDS_TOUR_DISMISSED = 'polaris.global-fields.tour-dismissed';
export const EXPLORE_SECTION_MESSAGE_DISMISSED =
	'polaris.project-fields.explore-section-message-dismissed';
export const SEEN_PULSATING_FIELDS_BUTTON = 'polaris.project-fields.seen-pulsating-fields-button';
export const PROJECT_FIELDS_TOUR_DISMISSED = 'polaris.project-fields.tour-dismissed';
export const PROJECT_FIELDS_PAGE_VIEWED = 'polaris.project-fields.page-viewed';
export const TIMELINE_DATES_CONFIG_SECTION_MESSAGE_DISMISSED =
	'polaris.timeline.dates-config-section-message-dismissed';

export const COLLECTIONS_TOUR_DISMISSED = 'polaris.collections.tour-dismissed';
export const CROSS_PROJECT_VIEW_TOUR_DISMISSED = 'polaris.cross-project-view.tour-dismissed';
export const VIEW_PERMISSIONS_LOCK_SPOTLIGHT_DISMISSED =
	'polaris.view-permissions-lock.spotlight-dismissed';
const CROSS_PROJECT_VIEW_LIST_FIELDS_DISMISSED = 'polaris.cross-project-view.list-fields-dismissed';
const CROSS_PROJECT_VIEW_TIMELINE_FIELDS_DISMISSED =
	'polaris.cross-project-view.timeline-fields-dismissed';
const CROSS_PROJECT_VIEW_BOARD_FIELDS_DISMISSED =
	'polaris.cross-project-view.board-fields-dismissed';
const CROSS_PROJECT_VIEW_MATRIX_FIELDS_DISMISSED =
	'polaris.cross-project-view.matrix-fields-dismissed';
export const CROSS_PROJECT_LIST_VIEW_FIELDS_DISMISSED_KEY = 'listViewfieldsDismissed';
export const CROSS_PROJECT_TIMELINE_VIEW_FIELDS_DISMISSED_KEY = 'timelineViewfieldsDismissed';
export const CROSS_PROJECT_BOARD_VIEW_FIELDS_DISMISSED_KEY = 'boardViewfieldsDismissed';
export const CROSS_PROJECT_MATRIX_VIEW_FIELDS_DISMISSED_KEY = 'matrixViewfieldsDismissed';

export const USER_PROPERTY_BY_STATE_KEY = {
	[CROSS_PROJECT_LIST_VIEW_FIELDS_DISMISSED_KEY]: CROSS_PROJECT_VIEW_LIST_FIELDS_DISMISSED,
	[CROSS_PROJECT_TIMELINE_VIEW_FIELDS_DISMISSED_KEY]: CROSS_PROJECT_VIEW_TIMELINE_FIELDS_DISMISSED,
	[CROSS_PROJECT_BOARD_VIEW_FIELDS_DISMISSED_KEY]: CROSS_PROJECT_VIEW_BOARD_FIELDS_DISMISSED,
	[CROSS_PROJECT_MATRIX_VIEW_FIELDS_DISMISSED_KEY]: CROSS_PROJECT_VIEW_MATRIX_FIELDS_DISMISSED,
};

// Spotlights
export const GLOBAL_FIELDS_TOUR_SPOTLIGHTS_STEP_1 = 'global-fields.tour.spotlights.step-1';
export const GLOBAL_FIELDS_TOUR_SPOTLIGHTS_STEP_2 = 'global-fields.tour.spotlights.step-2';
export const GLOBAL_FIELDS_TOUR_SPOTLIGHTS_STEP_3 = 'global-fields.tour.spotlights.step-3';
export const GLOBAL_FIELDS_TOUR_SPOTLIGHTS_STEP_4 = 'global-fields.tour.spotlights.step-4';
export const GLOBAL_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST = 'global-fields.tour.spotlights.step-last';
export const GLOBAL_FIELDS_GO_TO_PROJECT_SPOTLIGHT = 'global-fields.go-to-project.spotlight';
export const PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1 = 'project-fields.tour.spotlights.step-1';
export const PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_2 = 'project-fields.tour.spotlights.step-2';
export const PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3 = 'project-fields.tour.spotlights.step-3';
export const PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_4 = 'project-fields.tour.spotlights.step-4';
export const PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_5 = 'project-fields.tour.spotlights.step-5';
export const PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST = 'project-fields.tour.spotlights.step-last';

export const COLLECTIONS_CREATE_ROADMAP_SPOTLIGHT = 'collections.create-roadmap.spotlight';
export const COLLECTIONS_CREATE_ROADMAP_SPOTLIGHT_DISMISSED =
	'collections.create-roadmap.spotlight-dismissed';
export const CROSS_PROJECT_VIEW_TOUR_SPOTLIGHTS_STEP_1 =
	'cross-project-view.tour.spotlights.step-1';
export const CROSS_PROJECT_VIEW_TOUR_SPOTLIGHTS_STEP_LAST =
	'cross-project-view.tour.spotlights.step-last';
