import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useIntl } from '@atlassian/jira-intl';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type {
	ValueDecoration,
	ValueOperator,
	ValueRule,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	convertLogicToRules,
	isDecorationWithLogic,
} from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useCallbacks } from '../../../../../controllers/selectors/callback-hooks.tsx';
import { SharedFieldDecorationPopup } from '../../shared-field-decoration-popup.tsx';
import { DateDecorationLabel } from './label/index.tsx';
import { messages } from './messages.tsx';

const { LT, LTE, GT, GTE } = ValueRuleOperator;

type BaseDecoratorItemProps = {
	decoration: ValueDecoration;
	readonly?: boolean;
};

type DecoratorItemProps = {
	isOpen: boolean;
} & BaseDecoratorItemProps & {
		onOpenChanged: (open: boolean) => void;
	};

const generatePreviewValueForRules = (rules: ValueRule[]): number => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const ltValue = rules.find(({ operator }) => ([LT, LTE] as ValueOperator[]).includes(operator));
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const gtValue = rules.find(({ operator }) => ([GT, GTE] as ValueOperator[]).includes(operator));

	if (gtValue === undefined && ltValue !== undefined) {
		return -1;
	}
	return Number.parseInt(ltValue?.value || '0', 10);
};

const formatRules = (oldDecoration: ValueDecoration, newRules: ValueRule[]) => {
	const ltValue = newRules.find(
		(rule) => rule.operator === ValueRuleOperator.LT || rule.operator === ValueRuleOperator.LTE,
	)?.value;
	const gteValue = newRules.find(
		(rule) => rule.operator === ValueRuleOperator.GTE || rule.operator === ValueRuleOperator.GT,
	)?.value;

	const currentGteRule = isDecorationWithLogic(oldDecoration)
		? undefined // TODO: Handle Decoration Logic
		: oldDecoration.rules.find(
				(rule) => rule.operator === ValueRuleOperator.GTE || rule.operator === ValueRuleOperator.GT,
			);
	const currentLtRule = isDecorationWithLogic(oldDecoration)
		? undefined // TODO: Handle Decoration Logic
		: oldDecoration.rules.find(
				(rule) => rule.operator === ValueRuleOperator.LT || rule.operator === ValueRuleOperator.LTE,
			);

	const rules: Array<ValueRule> = [];

	if (currentGteRule !== undefined) {
		if (gteValue !== undefined) {
			rules.push({ ...currentGteRule, value: gteValue });
		} else {
			rules.push(currentGteRule);
		}
	}

	if (currentLtRule !== undefined) {
		if (ltValue !== undefined) {
			rules.push({ ...currentLtRule, value: ltValue });
		} else {
			rules.push(currentLtRule);
		}
	}

	return rules;
};

export const DecoratorDateTimeItem = (props: DecoratorItemProps) => {
	const { onOpenChanged, isOpen, decoration, readonly = false } = props;
	const rules = useMemo(
		() =>
			isDecorationWithLogic(decoration) ? convertLogicToRules(decoration.logic) : decoration.rules,
		[decoration],
	);

	const { formatMessage } = useIntl();
	const { onValueDecorationUpdated, onValueDecorationDeleted } = useCallbacks();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [currentColor, setCurrentColor] = useState(decoration.backgroundColor);
	const [currentHighlighted, setCurrentHighlighted] = useState(!!decoration.highlightContainer);
	const [currentEmojiId, setCurrentEmojiId] = useState(decoration.emoji);
	const [currentRules, setCurrentRules] = useState(rules);

	const onValueDecorationChanged = useCallback(
		({
			backgroundColor,
			emoji,
			highlightContainer,
		}: {
			backgroundColor: string | undefined;
			emoji: string | undefined;
			highlightContainer: boolean;
		}) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'fieldDecoration changed', 'config-fields', {
				action: 'update',
				backgroundColor,
				emoji,
				highlightContainer,
			});

			onValueDecorationUpdated?.({
				localDecorationId: decoration.localDecorationId,
				backgroundColor,
				emoji,
				highlightContainer,
				rules,
			});
		},
		[createAnalyticsEvent, decoration.localDecorationId, rules, onValueDecorationUpdated],
	);

	const onColorChanged = useCallback(
		(backgroundColor: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'color clicked', 'config-fields', {
				backgroundColor,
			});

			onValueDecorationChanged({
				backgroundColor,
				emoji: currentEmojiId,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentEmojiId, currentHighlighted, onValueDecorationChanged],
	);

	const onEmojiChanged = useCallback(
		(emoji: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'emoji clicked', 'config-fields', {
				emoji,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentColor, currentHighlighted, onValueDecorationChanged],
	);

	const onHighlightContainerChanged = useCallback(
		(highlightContainer: boolean) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'highlightContainer clicked', 'config-fields', {
				highlightContainer,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji: currentEmojiId,
				highlightContainer,
			});
		},
		[createAnalyticsEvent, currentColor, currentEmojiId, onValueDecorationChanged],
	);

	const onValueChange = useMemo(
		() =>
			debounce((value) => {
				const gtValue = rules.find(({ operator }) => operator === GTE);

				if (gtValue) {
					const newRules = formatRules(decoration, [
						{ value: value.toString(), operator: LT },
						{ value: gtValue.value, operator: GTE },
					]);

					setCurrentRules(newRules);

					onValueDecorationUpdated?.({
						localDecorationId: decoration.localDecorationId,
						backgroundColor: decoration.backgroundColor,
						emoji: decoration.emoji,
						highlightContainer: Boolean(decoration.highlightContainer),
						rules: newRules,
					});
				}
			}, 350),
		[rules, decoration, onValueDecorationUpdated],
	);

	const handleValidateValue = (
		newValue: string,
	): {
		isValid: boolean;
		error?: string;
	} => {
		if (newValue === '') {
			return {
				isValid: false,
			};
		}

		const newNumberValue = Number(newValue);

		if (!Number.isInteger(newNumberValue)) {
			return {
				isValid: false,
				error: formatMessage(messages.notIntegerError),
			};
		}

		if (newNumberValue < 0) {
			return {
				isValid: false,
				error: formatMessage(messages.negativeNumbersError),
			};
		}

		return {
			isValid: true,
		};
	};

	const daysValue = generatePreviewValueForRules(currentRules);
	const isOverdue = daysValue < 0;

	return (
		<SharedFieldDecorationPopup
			isOpen={isOpen}
			onOpen={onOpenChanged}
			readonly={readonly}
			decorationNode={
				<DateDecorationLabel
					rules={rules}
					color={currentColor}
					emoji={currentEmojiId}
					value={daysValue}
				/>
			}
			onDelete={
				onValueDecorationDeleted !== undefined
					? async () =>
							onValueDecorationDeleted({ localDecorationId: decoration.localDecorationId })
					: undefined
			}
			isNameEditable={!isOverdue}
			isHighlighted={currentHighlighted}
			color={currentColor}
			emojiId={currentEmojiId}
			input={{
				width: 60,
				label: formatMessage(messages[isOverdue ? 'dateIsOverdue' : 'dateIsWithin']),
				type: 'number',
				min: 0,
				labelPosition: 'start',
				useSeparateInputForEmoji: true,
			}}
			value={String(daysValue)}
			onNameChange={onValueChange}
			onColorChanged={(hexCode) => {
				setCurrentColor(hexCode);
				onColorChanged(hexCode);
			}}
			onHighlightingChanged={(highlighted) => {
				setCurrentHighlighted(highlighted);
				onHighlightContainerChanged(highlighted);
			}}
			onEmojiSelected={(selectedEmoji) => {
				setCurrentEmojiId(selectedEmoji);
				onEmojiChanged(selectedEmoji);
			}}
			onNameValidate={handleValidateValue}
		/>
	);
};
