import React, { type MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import CheckIcon from '@atlaskit/icon/glyph/check';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useHasViewUnsavedChanges,
	useCurrentViewId,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

export const SaveViewButton = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const { saveUnsavedChanges, saveCurrentUnsavedViewAsNew } = useViewActions();
	const hasViewUnsavedChanges = useHasViewUnsavedChanges();
	const currentViewId = useCurrentViewId();
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

	const handleMoreClick = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();
			setIsOpen(!isOpen);
		},
		[isOpen],
	);

	const handleSave = useCallback(() => {
		saveUnsavedChanges();
		setIsSaved(true);
		timeoutRef.current = setTimeout(() => {
			setIsSaved(false);
		}, 3000);
		fireCompoundAnalyticsEvent.View.unsavedChangesSave(createAnalyticsEvent({}));
	}, [saveUnsavedChanges, createAnalyticsEvent]);

	const handleSaveAsNew = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();
			saveCurrentUnsavedViewAsNew();
			setIsOpen(false);
			fireCompoundAnalyticsEvent.View.unsavedChangesSaveAsNew(createAnalyticsEvent({}));
		},
		[saveCurrentUnsavedViewAsNew, createAnalyticsEvent],
	);

	useEffect(() => {
		if (hasViewUnsavedChanges) {
			clearTimeout(timeoutRef.current);
			setIsSaved(false);
		}
	}, [hasViewUnsavedChanges]);

	useEffect(() => {
		clearTimeout(timeoutRef.current);
		setIsSaved(false);
	}, [currentViewId]);

	useEffect(() => () => clearTimeout(timeoutRef.current), []);

	if (!hasViewUnsavedChanges && !isSaved) {
		return null;
	}

	if (isSaved) {
		return (
			<SuccessMessage>
				<CheckIcon label="" />
				{formatMessage(messages.configSaved)}
			</SuccessMessage>
		);
	}

	return (
		<SaveViewButtonStyled
			id="polaris-ideas.ui.view-controls.unsaved-changes.save-for-everyone"
			onClick={handleSave}
			iconAfter={
				<Popup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					placement="bottom-start"
					content={() => (
						<Box xcss={dropdownContentStyles}>
							<Button
								id="polaris-ideas.ui.view-controls.unsaved-changes.save-as-new-view"
								onClick={handleSaveAsNew}
							>
								{formatMessage(messages.saveAsNew)}
							</Button>
						</Box>
					)}
					trigger={(triggerProps) => (
						<div {...triggerProps}>
							<MoreButton
								isSelected={isOpen}
								onClick={handleMoreClick}
								iconAfter={<HipchatChevronDownIcon size="small" label="" />}
							/>
						</div>
					)}
				/>
			}
		>
			{formatMessage(messages.save)}
		</SaveViewButtonStyled>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SaveViewButtonStyled = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		paddingRight: token('space.050', '4px'),
		fontWeight: token('font.weight.regular'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:not(:hover)': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('color.background.warning', colors.Y50),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreButton = styled(Button)({
	marginLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		height: '24px',
	},
});

const dropdownContentStyles = xcss({
	padding: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SuccessMessage = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.success', colors.G500),
	font: token('font.body'),
	display: 'flex',
	alignItems: 'center',
});
