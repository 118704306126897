/** @jsx jsx */
import React, { useCallback, useState } from 'react';
import { css, styled, jsx } from '@compiled/react';
import ButtonOld from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
	useProjectId,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	useHasProjectCustomIssueTypes,
	useIssueTypeIdForProject,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import type { CreatePayload } from '@atlassian/jira-polaris-lib-create-issue-input/src/common/types.tsx';
import { CreateIssueInput } from '@atlassian/jira-polaris-lib-create-issue-input/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { IssueCreateInput } from '@atlassian/jira-polaris-component-issue-create-input/src/ui/issue-create-input.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useFieldFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { useFilteredIssueTypeValues } from '@atlassian/jira-polaris-component-issue-types/src/utils.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { useOnCreatedOrMovedIssueFiltered } from '../../../view-content/common/on-created-filtered-issue/index.tsx';
import { messages } from './messages.tsx';

type FooterProps = {
	onIdeaCreated: (id: LocalIssueId) => void;
};

const FooterOld = ({ onIdeaCreated }: FooterProps) => {
	const { formatMessage } = useIntl();

	const projectId = useProjectId();
	const issueTypeId = useIssueTypeIdForProject({ projectId });

	const [isInputVisible, setIsInputVisible] = useState(false);
	const { createAndUpdateLegacy } = useIssueActions();
	const onCreatedIssueFiltered = useOnCreatedOrMovedIssueFiltered();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleCreateIdea = useCallback(
		({ summary }: CreatePayload) => {
			if (issueTypeId === undefined) return;

			createAndUpdateLegacy(
				issueTypeId,
				summary,
				{ summary },
				onIdeaCreated,
				(id: LocalIssueId) => onCreatedIssueFiltered(id),
				'issuesBucketDrawer',
			);

			setIsInputVisible(false);
		},
		[issueTypeId, createAndUpdateLegacy, onIdeaCreated, onCreatedIssueFiltered],
	);

	const handleCreateIdeaStart = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'createIdea',
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'button clicked',
			actionSubjectId: 'createIdea',
		});
		setIsInputVisible(true);
	}, [createAnalyticsEvent]);

	const handleCreateIdeaCancel = useCallback(() => setIsInputVisible(false), []);

	return (
		<FooterContainer>
			{isInputVisible ? (
				<CreateIssueInput
					placeholder={formatMessage(messages.placeholderLegacy)}
					onCreate={handleCreateIdea}
					onCancel={handleCreateIdeaCancel}
				/>
			) : (
				<FooterButton
					id="pendo.ideas-bucket.create-idea-button"
					appearance="primary"
					shouldFitContainer
					onClick={handleCreateIdeaStart}
				>
					{formatMessage(messages.buttonLabelLegacy)}
				</FooterButton>
			)}
		</FooterContainer>
	);
};

const FooterNew = ({ onIdeaCreated }: FooterProps) => {
	const { formatMessage } = useIntl();
	const [isInputVisible, setIsInputVisible] = useState(false);
	const { createAndUpdate } = useIssueActions();
	const onCreatedIssueFiltered = useOnCreatedOrMovedIssueFiltered();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;
	const hasProjectCustomIssueTypes = useHasProjectCustomIssueTypes({ projectId });
	const currentViewIssueTypeFilter = useFieldFilter(ISSUETYPE_FIELDKEY);
	const currentViewFilterIssueTypeValues = useFilteredIssueTypeValues({
		filter: currentViewIssueTypeFilter,
	});
	const currentViewFilterIssueTypeIds = currentViewFilterIssueTypeValues.map(({ id }) => id);
	const { setLastSelectedIssueTypeId } = useViewActions();

	const handleCreateIdea = useCallback(
		(issueTypeId: IssueTypeId, summary: string) => {
			setLastSelectedIssueTypeId(issueTypeId);

			const issueType = currentViewFilterIssueTypeValues.find((type) => type.id === issueTypeId);

			createAndUpdate({
				issueType,
				summary,
				onOptimisticCreationFinished: onIdeaCreated,
				updateOperation: (id, newState, issueResponse, isFiltered) => {
					if (isFiltered) {
						onCreatedIssueFiltered(id);
					}
				},
				analyticsSource: 'issuesBucketDrawer',
			});

			setIsInputVisible(false);
		},
		[
			createAndUpdate,
			currentViewFilterIssueTypeValues,
			onCreatedIssueFiltered,
			onIdeaCreated,
			setLastSelectedIssueTypeId,
		],
	);

	const handleCreateIdeaStart = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'createIdea',
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'button clicked',
			actionSubjectId: 'createIdea',
		});
		setIsInputVisible(true);
	}, [createAnalyticsEvent]);

	const handleCreateIdeaCancel = useCallback(() => setIsInputVisible(false), []);

	return (
		<div css={footerContainerStyles}>
			{isInputVisible ? (
				<IssueCreateInput
					placeholder={formatMessage(messages.placeholder)}
					onCancel={handleCreateIdeaCancel}
					onCreate={handleCreateIdea}
					defaultIssueTypeId={currentViewFilterIssueTypeIds[0]}
					availableIssueTypeIds={currentViewFilterIssueTypeIds}
					shouldShowIssueTypeSelect={hasProjectCustomIssueTypes}
				/>
			) : (
				<Button
					id="pendo.ideas-bucket.create-idea-button"
					appearance="primary"
					shouldFitContainer
					onClick={handleCreateIdeaStart}
					data-component-selector="create-issue-button-213n"
				>
					{formatMessage(messages.buttonLabel)}
				</Button>
			)}
		</div>
	);
};

export const Footer = componentWithFG('jpd_issues_relationships', FooterNew, FooterOld);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.footer({
	paddingTop: token('space.300', '24px'),
	paddingRight: token('space.200', '16px'),
	paddingBottom: token('space.300', '24px'),
	paddingLeft: token('space.200', '16px'),
	borderTop: `1px solid ${token('color.border', N40)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterButton = styled(ButtonOld)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		height: '40px',
		lineHeight: '40px',
	},
});

const footerContainerStyles = css({
	paddingTop: token('space.300'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.300'),
	paddingLeft: token('space.200'),
	borderTop: `1px solid ${token('color.border')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="create-issue-button-213n"]': {
		height: '40px',
		alignItems: 'center',
	},
});
