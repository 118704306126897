import React, { useCallback, useState } from 'react';
import Button from '@atlaskit/button/new';
import { Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import { useIssueTypeName } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	useDroppableEventsCollectionUpdate,
	type DroppableCollectionProps,
} from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { List } from './connections-config-list/connections-list.tsx';
import { messages } from './messages.tsx';

type Props = {
	allConnections: Field[];
	selectedConnectionsKeys: FieldKey[];
	issueTypeId: IssueTypeId;
	onSave: (configuration: FieldKey[]) => Promise<void>;
	onCancel: () => void;
};

export const ConnectionsConfig = ({
	allConnections,
	issueTypeId,
	selectedConnectionsKeys: initialSelectedConnectionsKeys,
	onSave,
	onCancel,
}: Props) => {
	const { formatMessage } = useIntl();
	const issueTypeName = useIssueTypeName({ issueTypeId });
	const [isSaving, setIsSaving] = useState(false);

	const [selectedConnectionsKeys, setSelectedConnectionsKeys] = useState<FieldKey[]>(
		initialSelectedConnectionsKeys,
	);
	const selectedConnections = selectedConnectionsKeys
		.map((key) => allConnections.find((c) => c.key === key))
		.filter(Boolean);
	const availableConnections = allConnections.filter(
		({ key }) => !selectedConnectionsKeys.includes(key),
	);

	const handleToggle = useCallback((fieldKey: FieldKey, isChecked: boolean) => {
		if (isChecked) {
			setSelectedConnectionsKeys((prevSelectedConnections) => [
				...new Set([...prevSelectedConnections, fieldKey]),
			]);
		} else {
			setSelectedConnectionsKeys((prevSelectedConnections) =>
				prevSelectedConnections.filter((key) => key !== fieldKey),
			);
		}
	}, []);

	const handleSort: DroppableCollectionProps<FieldKey, FieldKey>['onSort'] = useCallback(
		({ updatedCollection }) => {
			setSelectedConnectionsKeys(updatedCollection);
		},
		[],
	);

	const handleSave = async () => {
		setIsSaving(true);
		await onSave(selectedConnectionsKeys);
		setIsSaving(false);
	};

	useDroppableEventsCollectionUpdate({
		onSort: handleSort,
		collection: selectedConnectionsKeys,
		originLabel: 'issue-types-connections-configuration',
	});

	return (
		<Stack space="space.250">
			{selectedConnections.length > 0 && (
				<Stack space="space.100">
					<Text size="medium" weight="semibold" maxLines={1}>
						<Inline alignBlock="center" space="space.100">
							{formatMessage(messages.enabledConnections, {
								issueType: (
									<Inline
										xcss={issueTypeBadgeStyles}
										alignBlock="center"
										alignInline="center"
										space="space.075"
									>
										<IssueTypeIcon issueTypeId={issueTypeId} />
										<Text weight="regular">{issueTypeName}</Text>
									</Inline>
								),
							})}
						</Inline>
					</Text>
					<List options={selectedConnections} onToggle={handleToggle} isSortable />
				</Stack>
			)}
			{availableConnections.length > 0 && (
				<Stack space="space.150">
					<Text size="medium" weight="semibold" maxLines={1}>
						{formatMessage(messages.availableConnections)}
					</Text>
					<List options={availableConnections} onToggle={handleToggle} />
				</Stack>
			)}
			<Inline space="space.100">
				<Button
					testId="polaris-component-issue-types-connections-configuration.ui.save-button"
					appearance="primary"
					onClick={handleSave}
					isLoading={isSaving}
				>
					{formatMessage(messages.saveButtonLabel)}
				</Button>
				<Button onClick={onCancel}>{formatMessage(messages.cancelButtonLabel)}</Button>
			</Inline>
		</Stack>
	);
};

const issueTypeBadgeStyles = xcss({
	flexShrink: 0,
	backgroundColor: 'color.background.accent.gray.subtlest',
	paddingInlineStart: 'space.050',
	paddingInlineEnd: 'space.100',
	borderRadius: '4px',
	height: '24px',
});
