import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { PlatformGoalsField } from '@atlassian/jira-polaris-common/src/ui/fields/goals/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import messages from './messages.tsx';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
};

export const Goals = ({ fieldKey, localIssueId }: Props) => {
	const { formatMessage } = useIntl();
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	const onUpdate = useCallback(
		(newValue: string | string[] | undefined) => {
			updateFieldValue({
				fieldKey,
				localIssueIds: [localIssueId],
				newValue,
			});
		},
		[fieldKey, localIssueId, updateFieldValue],
	);

	if (field === undefined) {
		return null;
	}

	return (
		<PlatformGoalsField
			isActive
			localIssueId={localIssueId}
			fieldKey={fieldKey}
			isEditable={canEditIssues && field.editable}
			onUpdate={onUpdate}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};
