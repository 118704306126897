import React from 'react';
import { styled } from '@compiled/react';
import { N200, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type NoContributionssComponentProps = {
	hasCurrentUserContribution: boolean;
};

export const NoContributionssComponent = ({
	hasCurrentUserContribution,
}: NoContributionssComponentProps) => {
	const { formatMessage } = useIntl();

	return (
		<Wrapper>
			<Header>
				{hasCurrentUserContribution
					? formatMessage(messages.noOtherContributionsHeader)
					: formatMessage(messages.noContributionsHeader)}
			</Header>
			<Detail data-testid="polaris-common.ui.plays.no-contributions.no-contributions">
				{hasCurrentUserContribution
					? formatMessage(messages.noOtherContributionsText)
					: formatMessage(messages.noContributionsText)}
			</Detail>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	paddingTop: token('space.500', '40px'),
	paddingRight: token('space.500', '40px'),
	paddingBottom: token('space.500', '40px'),
	paddingLeft: token('space.500', '40px'),
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.span({
	color: token('color.text.subtle', N800),

	font: token('font.heading.medium'),
	marginBottom: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Detail = styled.span({
	color: token('color.text.subtlest', N200),
	margin: 0,
});
