/** @jsx jsx */
import { useRef } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

type Props = {
	isDisabled: boolean;
	shouldAutofocus?: boolean;
	onChange: (value: string) => void;
	value: string;
	onSubmit?: () => void;
	submitOnBlur?: boolean;
	isInvalid: boolean;
};

export const NameInput = ({
	onChange,
	value,
	shouldAutofocus = false,
	isDisabled,
	onSubmit,
	isInvalid,
	submitOnBlur,
}: Props) => {
	const { formatMessage } = useIntl();
	const ref = useRef<HTMLInputElement | null>(null);

	return (
		<input
			ref={ref}
			css={[inputStyles, isInvalid && inputStylesInvalid]}
			placeholder={formatMessage(messages.enterTypeName)}
			type="text"
			value={value}
			onChange={(event) => {
				onChange(event.target.value);
			}}
			disabled={isDisabled}
			onBlur={() => {
				if (value !== '') {
					onSubmit && submitOnBlur && onSubmit();
				}
			}}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					if (submitOnBlur) {
						event.currentTarget.blur();
					} else {
						onSubmit && onSubmit();
					}
				}
			}}
			// eslint-disable-next-line jsx-a11y/no-autofocus
			autoFocus={shouldAutofocus}
		/>
	);
};

const inputStyles = css({
	font: token('font.body'),
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	color: token('color.text'),
	overflow: 'hidden',
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),

	display: 'flex',
	borderWidth: '1px',
	borderColor: 'transparent',
	borderRadius: '4px',
	borderStyle: 'solid',
	width: '100%',
	background: 'transparent',

	'&:focus': {
		outline: 'none',
		boxShadow: 'none',
		borderColor: token('color.border.input'),
		backgroundColor: token('elevation.surface'),
	},
});

const inputStylesInvalid = css({
	borderColor: token('color.border.danger'),

	'&:focus': {
		borderColor: token('color.border.danger'),
	},
});
