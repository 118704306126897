import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	clearFilters: {
		id: 'polaris-common.controllers.issue.error.clear-filters',
		defaultMessage: 'Clear filters',
		description: 'Button caption to clear all filters.',
	},
	createSuccess: {
		id: 'polaris-common.controllers.issue.error.create-success',
		defaultMessage: 'Idea successfully created',
		description:
			'Notification header that an idea has been successfully created, but might not be visible. See notification description message for more detail.',
	},
	createSuccessInvisibleDescription: {
		id: 'polaris-common.controllers.issue.error.create-success-invisible-description',
		defaultMessage: 'Your idea was created, but filters prevent it from showing in this view',
		description:
			'Notification description for polaris-common.controllers.issue.error.create-success',
	},
	errorTitle: {
		id: 'polaris-common.controllers.issue.error.error-title',
		defaultMessage: 'Something went wrong.',
		description: 'Flag title to communicate an error.',
	},
	ideaCreationFailedErrorTitle: {
		id: 'polaris-common.controllers.issue.error.idea-creation-failed-error-title',
		defaultMessage: 'Could not create an idea',
		description: 'Flag title for an error occurring while creating an idea.',
	},
	issueLoadingFailedDescription: {
		id: 'polaris-common.controllers.issue.error.issue-loading-failed-description',
		defaultMessage:
			'We had some difficulties to load some issue data from the server. Please refresh the page.',
		description: 'Flag description for an error occurring while loading an issue.',
	},
	issueLoadingFailedDescriptionIssueTermRefresh: {
		id: 'polaris-common.controllers.issue.error.issue-loading-failed-description-issue-term-refresh',
		defaultMessage:
			'We had some difficulties loading some work item data from the server. Please refresh the page.',
		description: 'Flag description for an error occurring while loading an issue.',
	},
	issueCreationFailedDescription: {
		id: 'polaris-common.controllers.issue.error.issue-creation-failed-description',
		defaultMessage:
			'We had some difficulties to create an issue on the server. Please try again or refresh the page.',
		description: 'Flag description for an error occurring while creating an issue.',
	},
	issueCreationFailedDescriptionIssueTermRefresh: {
		id: 'polaris-common.controllers.issue.error.issue-creation-failed-description-issue-term-refresh',
		defaultMessage:
			'We had some difficulties creating a work item on the server. Please try again or refresh the page.',
		description: 'Flag description for an error occurring while creating an issue.',
	},
	issueUpdateFailedDescription: {
		id: 'polaris-common.controllers.issue.error.issue-update-failed-description',
		defaultMessage:
			'We had some difficulties to update an issue on the server. Please try again or refresh the page.',
		description: 'Flag description for an error occurring while updating an issue.',
	},
	issueUpdateFailedDescriptionIssueTermRefresh: {
		id: 'polaris-common.controllers.issue.error.issue-update-failed-description-issue-term-refresh',
		defaultMessage:
			'We had some difficulties updating a work item on the server. Please try again or refresh the page.',
		description: 'Flag description for an error occurring while updating an issue.',
	},
	transitionFailed: {
		id: 'polaris-common.controllers.issue.error.transition-failed',
		defaultMessage: 'Transition failed',
		description: 'Flag title for an error occurring while transitioning an issue.',
	},
});
