import React, { memo } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useColumnDraggingInProgress } from '../../controllers/selectors/ui-hooks.tsx';
import { ColumnDndOverlay } from './column-dnd/index.tsx';
import { MovedRowOverlay } from './moved-row/index.tsx';

type OverlayRendererProps = {
	isBulkEditDialogShown: boolean;
};

const OverlayRendererLegacy = ({ isBulkEditDialogShown }: OverlayRendererProps) => (
	<>
		<ColumnDndOverlay />
		<MovedRowOverlay isBulkEditDialogShown={isBulkEditDialogShown} />
	</>
);

const OverlayRendererNext = memo(({ isBulkEditDialogShown }: OverlayRendererProps) => {
	const columnDraggingInProgress = useColumnDraggingInProgress();

	return (
		<>
			{columnDraggingInProgress && <ColumnDndOverlay />}
			<MovedRowOverlay isBulkEditDialogShown={isBulkEditDialogShown} />
		</>
	);
});

export const OverlayRenderer = componentWithFG(
	'polaris_idea_list_performance_improvements',
	OverlayRendererNext,
	OverlayRendererLegacy,
);
