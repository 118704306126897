/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { Emoji } from '@atlaskit/emoji';
import { Box, xcss } from '@atlaskit/primitives';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import {
	useCollectionEmoji,
	useCollectionName,
} from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import {
	getCollectionViewPageUrl,
	getCollectionsPageUrl,
} from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import { ViewItemIcon } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

type RoadmapBreadcrumbsProps = {
	currentViewTitle?: string;
	viewUUID?: string;
	viewEmojiId?: string;
	viewKind?: ViewKind;
};

export type ViewBreadcrumbsProps = {
	isCollectionView: boolean;
	currentViewTitle?: string;
	emojiId?: string;
	currentViewUrl?: string;
	viewUUID?: string;
	projectsPath: string;
	viewKind?: ViewKind;
};

const RoadmapBreadcrumbs = memo(
	({ currentViewTitle, viewUUID, viewEmojiId, viewKind }: RoadmapBreadcrumbsProps) => {
		const { formatMessage } = useIntl();
		const environmentContainer = useEnvironmentContainer();
		const collectionUUID = environmentContainer?.id;
		const collectionName = useCollectionName({ collectionUUID });
		const collectionEmojiId = useCollectionEmoji({ collectionUUID });
		const collectionEmoji = useEmoji(collectionEmojiId);
		const viewEmoji = useEmoji(viewEmojiId);
		const { push } = useRouterActions();

		const collectionViewPageUrl =
			collectionUUID && viewUUID ? getCollectionViewPageUrl(collectionUUID, viewUUID) : '';

		const routeToRoadmaps = (e: React.MouseEvent) => {
			e.preventDefault();
			push(getCollectionsPageUrl());
		};

		return (
			<Breadcrumbs>
				<BreadcrumbsItem
					href={getCollectionsPageUrl()}
					text={formatMessage(messages.roadmaps)}
					onClick={routeToRoadmaps}
				/>
				{collectionUUID && collectionName && (
					<>
						<BreadcrumbsItem
							href={`${getCollectionsPageUrl()}/${collectionUUID}`}
							text={collectionName}
							iconBefore={
								collectionEmoji && (
									<Box xcss={emojiWrapperStyles}>
										<Emoji emoji={collectionEmoji} fitToHeight={16} />
									</Box>
								)
							}
						/>
						{currentViewTitle && (
							<BreadcrumbsItem
								href={collectionViewPageUrl}
								text={currentViewTitle}
								iconBefore={
									viewEmoji ? (
										<Box xcss={emojiWrapperStyles}>
											<Emoji emoji={viewEmoji} fitToHeight={16} />
										</Box>
									) : (
										<div css={viewIconsWrapperStyles}>
											<ViewItemIcon viewKind={viewKind} />
										</div>
									)
								}
							/>
						)}
					</>
				)}
			</Breadcrumbs>
		);
	},
);

const StandardBreadcrumbs = memo(
	({
		currentViewTitle,
		emojiId,
		currentViewUrl,
		projectsPath,
		viewKind,
	}: Omit<ViewBreadcrumbsProps, 'isCollectionView'>) => {
		const { formatMessage } = useIntl();
		const emoji = useEmoji(emojiId);
		const { push } = useRouterActions();

		const routeToProjects = (e: React.MouseEvent) => {
			e.preventDefault();
			push(projectsPath);
		};

		return (
			<Breadcrumbs>
				<BreadcrumbsItem
					href={projectsPath}
					text={formatMessage(messages.projects)}
					onClick={routeToProjects}
				/>
				{currentViewTitle && currentViewUrl && (
					<BreadcrumbsItem
						href={currentViewUrl}
						text={currentViewTitle}
						iconBefore={
							emoji ? (
								<Box xcss={emojiWrapperStyles}>
									<Emoji emoji={emoji} fitToHeight={16} />
								</Box>
							) : (
								<div css={viewIconsWrapperStyles}>
									<ViewItemIcon viewKind={viewKind} />
								</div>
							)
						}
					/>
				)}
			</Breadcrumbs>
		);
	},
);

export const ViewBreadcrumbs = memo(
	({
		currentViewTitle,
		emojiId,
		currentViewUrl,
		isCollectionView,
		viewUUID,
		projectsPath,
		viewKind,
	}: ViewBreadcrumbsProps) => {
		if (isCollectionView) {
			return (
				<RoadmapBreadcrumbs
					currentViewTitle={currentViewTitle}
					viewUUID={viewUUID}
					viewEmojiId={emojiId}
					viewKind={viewKind}
				/>
			);
		}

		return (
			<StandardBreadcrumbs
				currentViewTitle={currentViewTitle}
				emojiId={emojiId}
				currentViewUrl={currentViewUrl}
				projectsPath={projectsPath}
				viewKind={viewKind}
			/>
		);
	},
);

const emojiWrapperStyles = xcss({
	marginRight: 'space.050',
});

const viewIconsWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	svg: {
		transform: 'scale(0.66)',
	},
	display: 'flex',
	alignItems: 'center',
});
