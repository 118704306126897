import React from 'react';
import Button from '@atlaskit/button/new';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

type Props = {
	onDelete: () => void;
	onClose: () => void;
	isDeleting: boolean;
};

export const DeleteModal = ({ onDelete, onClose, isDeleting }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ModalTransition>
			<ShortcutScope>
				<ModalDialog onClose={onClose}>
					<ModalHeader>
						<ModalTitle appearance="danger">{formatMessage(messages.deleteTitle)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Text>{formatMessage(messages.deleteInfoMessage)}</Text>
					</ModalBody>
					<ModalFooter>
						<Button appearance="subtle" onClick={onClose}>
							{formatMessage(messages.cancelButton)}
						</Button>
						<Button appearance="danger" onClick={onDelete} isLoading={isDeleting}>
							{formatMessage(messages.deleteButton)}
						</Button>
					</ModalFooter>
				</ModalDialog>
			</ShortcutScope>
		</ModalTransition>
	);
};
