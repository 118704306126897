/** @jsx jsx */
import React, { memo } from 'react';

import { css, jsx } from '@compiled/react';
import JiraSideNavPlacement from '@post-office-placement/jira-side-nav/placement';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { PostOfficeProvider } from '@atlassian/jira-post-office-provider/src/index.tsx';
import { PlacementFetchProvider } from '@atlassian/jira-post-office-provider/src/placement-fetch-provider.tsx';
import killswitch from '@atlassian/jira-killswitch/src/index.tsx';
import { postOfficeJiraSideNavResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';

import { Placement } from '@atlassian/post-office-placement';

type Props = {
	onHasMessageChanged?: (hasMessage: boolean) => void;
};

const PLACEMENT_ID = 'jira-side-nav';

const SideNavPlacement = ({ onHasMessageChanged }: Props) => (
	<Placement
		placementId={PLACEMENT_ID}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore - Type 'ComponentType<Partial<ErrorBoundaryPropsWithRender>> is not assignable to type 'PlacementComponent<LocalPlacementProps>'
		PlacementComponent={JiraSideNavPlacement}
		placementProps={{ onHasMessageChanged }}
	/>
);

const SideNavRecommendation = ({ onHasMessageChanged }: Props) => {
	return (
		<JSErrorBoundary
			id="sideNavRecommendation"
			packageName="side-nav-recommendation"
			teamName="post-office"
			fallback={() => <></>}
		>
			<PostOfficeProvider>
				<div css={sideNavPlacementWrapper} data-testid="side-nav-recommendation.jira-side-nav">
					{fg('post_office_ssr_jira_side_nav_enabled') ? (
						<PlacementFetchProvider resource={postOfficeJiraSideNavResource}>
							<SideNavPlacement onHasMessageChanged={onHasMessageChanged} />
						</PlacementFetchProvider>
					) : (
						<SideNavPlacement onHasMessageChanged={onHasMessageChanged} />
					)}
				</div>
			</PostOfficeProvider>
		</JSErrorBoundary>
	);
};

export const JswSideNavRecommendation = memo(({ onHasMessageChanged }: Props) => {
	if (
		// eslint-disable-next-line jira/ff/no-preconditioning
		!killswitch('killswitch_post_office_in_jira') &&
		fg('post-office_enable_jira_sidenav_rec_experiment') &&
		expVal('post_office_jira_side_nav_experiment', 'isEnabled', false)
	) {
		return <SideNavRecommendation onHasMessageChanged={onHasMessageChanged} />;
	}

	return null;
});

export const JpdSideNavRecommendation = memo(({ onHasMessageChanged }: Props) => {
	if (
		// eslint-disable-next-line jira/ff/no-preconditioning
		!killswitch('killswitch_post_office_in_jira') &&
		fg('post-office_enable_jpd_side_nav_experiment') &&
		expVal('post_office_jpd_side_nav_experiment', 'isEnabled', false)
	) {
		return <SideNavRecommendation onHasMessageChanged={onHasMessageChanged} />;
	}

	return null;
});

const sideNavPlacementWrapper = css({ marginBottom: token('space.100') });
