import { useEffect, useMemo } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { getAllJpdProjects } from '../../../services/index.tsx';
import messages from './messages.tsx';

export const useAllProjects = (ignoredProjectsKeys: string[] = []) => {
	const { errorWithRefresh } = useNotifications();
	const { formatMessage } = useIntl();

	const {
		loading,
		error,
		data = [],
		fetch,
	} = useService<ProjectMetadata[]>(getAllJpdProjects, {
		loading: true,
	});

	useEffect(() => {
		fetch();
	}, [fetch]);

	useEffect(() => {
		if (error instanceof Error) {
			fireErrorAnalytics(
				createErrorAnalytics('polaris.error.availableProjectsLoadingFailed', error),
			);
			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
			});
		}
	}, [error, errorWithRefresh, formatMessage]);

	const projects = useMemo(() => {
		const ignoredProjectsKeysSet = new Set(ignoredProjectsKeys);
		return data.filter(({ key }) => !ignoredProjectsKeysSet.has(key));
	}, [data, ignoredProjectsKeys]);

	return {
		isLoading: loading,
		projects,
	};
};
