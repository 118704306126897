import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { ConnectedIssueCard } from '../../../../../issue-card/index.tsx';
import messages from './messages.tsx';

export const connectionNoValueOptionMessage = messages.noValueOption;

const NoValueRenderer = () => {
	const { formatMessage } = useIntl();
	return <Box paddingInlineStart="space.100">{formatMessage(connectionNoValueOptionMessage)}</Box>;
};

type ConnectionOptionRendererProps = {
	groupIdentity: string | undefined;
};

export const ConnectionOptionRenderer = ({ groupIdentity }: ConnectionOptionRendererProps) => {
	if (groupIdentity === undefined) {
		return <NoValueRenderer />;
	}

	return (
		<Box padding="space.025" xcss={cardStyles}>
			<ConnectedIssueCard shouldCloseSidebar type="link-with-icon" id={groupIdentity} />
		</Box>
	);
};

const cardStyles = xcss({
	width: 'fit-content',
});
