import React from 'react';
import { Inline, Box, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

type AutosaveViewConfigProps = {
	isEnabled: boolean;
	onChange: (isEnabled: boolean) => void;
};

export const AutosaveViewConfig = ({ isEnabled, onChange }: AutosaveViewConfigProps) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Inline alignBlock="center" xcss={toggleContainerStyles}>
				<Box xcss={toggleStyles}>
					<Toggle
						id="polaris-ideas.ui.view-controls.autosave-toggle"
						onChange={() => onChange(!isEnabled)}
						isChecked={isEnabled}
						label={formatMessage(messages.title)}
					/>
				</Box>
				<Box as="label" htmlFor="polaris-ideas.ui.view-controls.autosave-toggle" xcss={labelStyles}>
					{formatMessage(messages.title)}
				</Box>
			</Inline>
			<Box xcss={descriptionStyles}>{formatMessage(messages.description)}</Box>
		</>
	);
};

const toggleContainerStyles = xcss({
	marginLeft: 'space.negative.075',
});

const toggleStyles = xcss({
	flexShrink: 0,
});

const labelStyles = xcss({
	font: token('font.heading.xsmall'),
	fontWeight: token('font.weight.medium', '500'),
	color: 'color.text.subtle',
	marginLeft: 'space.075',
});

const descriptionStyles = xcss({
	font: token('font.body.small'),
	color: 'color.text.subtle',
	marginTop: 'space.100',
});
