import React from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import type { CustomTriggerProps } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import { useIssueTypeName } from '../../controllers/index.tsx';
import { messages } from './messages.tsx';

export type TriggerType = 'icon-only' | 'icon-and-name' | 'icon-and-chevron';

type Props = CustomTriggerProps & {
	issueTypeId: IssueTypeId;
	type: TriggerType;
};

export const IssueTypeSelectTrigger = ({
	issueTypeId,
	type,
	triggerRef,
	...triggerProps
}: Props) => {
	const { formatMessage } = useIntl();
	const issueTypeName = useIssueTypeName({ issueTypeId });

	switch (type) {
		case 'icon-and-name':
			return (
				<Button
					{...triggerProps}
					ref={triggerRef}
					appearance="subtle"
					iconBefore={() => <IssueTypeIcon issueTypeId={issueTypeId} />}
				>
					{issueTypeName}
				</Button>
			);
		case 'icon-and-chevron':
			return (
				<Tooltip content={issueTypeName} hideTooltipOnClick>
					{(tooltipProps) => (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Box {...tooltipProps} xcss={tooltipWrapperStyles}>
							<Button
								{...triggerProps}
								ref={triggerRef}
								appearance="subtle"
								spacing="compact"
								iconBefore={() => <IssueTypeIcon issueTypeId={issueTypeId} />}
							>
								<ChevronDownIcon label={formatMessage(messages.changeIssueTypeLabel)} />
							</Button>
						</Box>
					)}
				</Tooltip>
			);
		default:
			return (
				<IconButton
					{...triggerProps}
					ref={triggerRef}
					label={issueTypeName}
					appearance="subtle"
					spacing="compact"
					icon={() => <IssueTypeIcon issueTypeId={issueTypeId} />}
					isTooltipDisabled={false}
				/>
			);
	}
};

const tooltipWrapperStyles = xcss({
	display: 'inline-flex',
});
