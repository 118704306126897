import React, { type SyntheticEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import HintMessage from '@atlassian/jira-polaris-lib-hint-message/src/async.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useChromePreset } from './apps/chrome/index.tsx';
import { useSlackPreset } from './apps/slack/index.tsx';
import { useTeamsPreset } from './apps/teams/index.tsx';
import { messages } from './messages.tsx';

export const CREATE_INSIGHT_HINT_CLOSED_KEY = 'polaris.create-insight-hint-closed';
export const CREATE_INSIGHT_HELP_ARTICLE_ID = '2iEf81yt5skdET9ZLhxwzb';
export const CREATE_INSIGHT_HELP_FALLBACK_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/what-are-insights/';

export const CreateInsightTextHint = () => {
	const chrome = useChromePreset();
	const slack = useSlackPreset();
	const teams = useTeamsPreset();

	return (
		<CreateInsightTextHintContainer>
			<FormattedMessage
				{...messages.addInsightHint}
				values={{
					chrome: chrome.Action ? <chrome.Action /> : null,
					slack: slack.Action ? <slack.Action /> : null,
					teams: teams.Action ? <teams.Action /> : null,
				}}
			/>
		</CreateInsightTextHintContainer>
	);
};

type CreateInsightHintProps = {
	onClose: () => void;
	onCreate: () => void;
};

export const CreateInsightHint = ({ onClose, onCreate }: CreateInsightHintProps) => {
	const { formatMessage } = useIntl();
	const chrome = useChromePreset();
	const slack = useSlackPreset();
	const teams = useTeamsPreset();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleButtonClick = useCallback(() => {
		fireCompoundAnalyticsEvent.IdeaView.InsightsTab.createInsightButtonClicked(
			createAnalyticsEvent({}),
			true,
		);
		onClose();
		onCreate();
	}, [onClose, onCreate, createAnalyticsEvent]);

	const handleClose = useCallback(() => {
		fireCompoundAnalyticsEvent.IdeaView.InsightsTab.createInsightHintClosed(
			createAnalyticsEvent({}),
		);
		onClose();
	}, [onClose, createAnalyticsEvent]);

	return (
		<HintMessage
			title={formatMessage(messages.title)}
			description={formatMessage(messages.description)}
			onClose={handleClose}
			learnMore={{
				label: formatMessage(messages.learnMoreLink),
				articleId: CREATE_INSIGHT_HELP_ARTICLE_ID,
				fallbackHref: CREATE_INSIGHT_HELP_FALLBACK_URL,
				onClick: (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
					fireCompoundAnalyticsEvent.IdeaView.InsightsTab.createInsightLearnMoreButtonClicked(
						analyticsEvent,
						true,
					);
				},
			}}
			primaryButton={{
				label: formatMessage(messages.addInsightButton),
				onClick: handleButtonClick,
			}}
		>
			<AppsListContainer>
				{[chrome, slack, teams].map(({ Action, icon, key }) =>
					Action !== undefined ? (
						<AppContainer key={key}>
							<AppIcon src={icon} />
							<Action />
						</AppContainer>
					) : null,
				)}
			</AppsListContainer>
		</HintMessage>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateInsightTextHintContainer = styled.div({
	font: token('font.body.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppsListContainer = styled.div({
	marginTop: token('space.200', '16px'),
	display: 'flex',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppIcon = styled.img({
	display: 'flex',
	marginRight: token('space.100', '8px'),
	width: '24px',
	height: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppContainer = styled.div({
	display: 'flex',
	marginTop: token('space.050', '4px'),
	marginRight: token('space.300', '24px'),
	marginBottom: token('space.050', '4px'),
	marginLeft: 0,
	alignItems: 'center',
});
