import React, {
	type SyntheticEvent,
	memo,
	useCallback,
	useState,
	useEffect,
	useRef,
	type PropsWithChildren,
} from 'react';
import { styled } from '@compiled/react';
import throttle from 'lodash/throttle';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/core/close';
import ExpandIcon from '@atlaskit/icon/core/grow-diagonal';
import CollapseIcon from '@atlaskit/icon/core/shrink-diagonal';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import MediaServicesActualSizeIcon from '@atlaskit/icon/glyph/media-services/actual-size';
import MediaServicesFitToPageIcon from '@atlaskit/icon/glyph/media-services/fit-to-page';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { Inline } from '@atlaskit/primitives';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { GenericError } from '@atlassian/jira-polaris-common/src/common/utils/errors/main.tsx';
import {
	IdeaViewEndMark,
	IdeaViewStartMark,
	MARKS,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/idea-view.tsx';
import {
	jpdProjectPageLoadPermissionsStart,
	jpdProjectPageLoadPermissionsEnd,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project.tsx';
import {
	useIsSharedView,
	useIsCollectionView,
	useIsEmbedded,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useIdeaNotFoundError,
	useIsLoading,
	useCriticalDataLoadingError,
	useFieldsSidebarConfig,
} from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useLocalIssueIdForJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useIsIssuesLoading } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import {
	useIssueAnalitycsAttributes,
	useSelectedIssue,
	useSelectedIssueId,
	useSelectedIssueIssueType,
	useSelectedIssueKey,
	useIsSelectedIssueArchived,
	useSelectedIssueProject,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useProjectForIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import {
	usePolarisRouter,
	useIssueViewLayout,
	useIssueViewSection,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { ScrollableContainerContextProvider } from '@atlassian/jira-polaris-common/src/ui/common/scrollable-container/main.tsx';
import {
	useProjectKeyUnsafe,
	useProjectIdUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { PermissionsContainer } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import { getProjectContainerScope } from '@atlassian/jira-polaris-component-permissions-store/src/utils.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { AdfController } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { ignoreEscapePress } from '@atlassian/jira-polaris-lib-escape-keypress-utils/src/index.tsx';
import { useMediaRemote } from '@atlassian/jira-polaris-remote-media/src/controllers/index.tsx';
import {
	useAnalyticsEvents,
	ContextualAnalyticsData,
	SCREEN,
	DRAWER,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { toIssueId, toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { InsightsDataContainer } from '../../insights/insights/index.tsx';
import { ArchivedNotification } from '../archived/index.tsx';
import { Attachments } from '../attachments/index.tsx';
import { IssueBreadcrumbs } from '../breadcrumbs/index.tsx';
import { CollaborationControls } from '../collaboration-controls/index.tsx';
import { COMPACT_LAYOUT_MAX_WIDTH } from '../constants.tsx';
import { Controls } from '../controls/index.tsx';
import { Description } from '../description/index.tsx';
import { Fields } from '../fields/index.tsx';
import { IssueLinks } from '../issue-links/index.tsx';
import { IdeaViewKeyboardShortcuts } from '../keyboard-shortcuts/index.tsx';
import { IdeaNotFoundView } from '../not-found-view/index.tsx';
import { Sections } from '../sections/main.tsx';
import { Summary } from '../summary/index.tsx';
import { IdeaViewUfoData } from '../ufo-data/index.tsx';
import TabView from '../tab-view/index.tsx';
import { WatchersDropdown } from '../watchers/index.tsx';
import messages from './messages.tsx';

export const HeaderControlsContainerNew = ({ children }: PropsWithChildren) => {
	return <Inline space="space.150">{children}</Inline>;
};

type IdeaViewComponentProps = {
	isCompact: boolean;
};

const IdeaViewComponent = memo<IdeaViewComponentProps>(({ isCompact }: IdeaViewComponentProps) => {
	const { formatMessage } = useIntl();
	const isSharedView = useIsSharedView();
	const issueKey = useSelectedIssueKey();
	const id = useSelectedIssueId();
	const mainProjectId = useProjectIdUnsafe();
	const { closeIssueView } = usePolarisRouter();
	const { openIssueView } = usePolarisRouter();
	const { mode } = useFieldsSidebarConfig();

	const scrollableContainerRef = useRef<HTMLDivElement>(null);

	const [isCreatingIssueLink, setIsCreatingIssueLink] = useState(false);

	const issueId = id !== undefined ? toIssueId(id.toString()) : undefined;
	const isArchived = useIsSelectedIssueArchived();

	const localIssueId = useLocalIssueIdForJiraIssueId(issueId ?? '') || '';
	const issueProject = useProjectForIssue(localIssueId);

	const issueViewLayout = useIssueViewLayout();
	const issueViewSection = useIssueViewSection();
	const isIssueViewLayoutSidebar = issueViewLayout === 'sidebar';

	const projectId = issueProject?.id ?? mainProjectId;

	useCloseOnEscapePress({
		onClose: (event) => {
			// Two cases we're handling here:
			// - Field settings/list being opened on top of the idea view sidebar
			// - Idea view being opened in fullscreen, where we don't want ESC to close it
			if (
				ignoreEscapePress(event) ||
				['FIELD', 'LIST'].includes(mode) ||
				!isIssueViewLayoutSidebar
			) {
				return;
			}
			closeIssueView();
		},
	});

	const onClose = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'close');
			closeIssueView();
		},
		[closeIssueView],
	);

	const onLinkIssue = useCallback(() => {
		setIsCreatingIssueLink(true);
	}, []);

	const onIssueLinkCancelledOrCreated = useCallback(() => {
		setIsCreatingIssueLink(false);
	}, []);

	const onOpenIdeaView = useCallback(
		(issueKeyToOpen: string) => {
			openIssueView(issueKeyToOpen, { layout: issueViewLayout });
		},
		[issueViewLayout, openIssueView],
	);

	const onToggleFullscreen = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (!issueKey) return;
			fireUIAnalytics(analyticsEvent, 'fullscreen', {
				fullscreenEnabled: isIssueViewLayoutSidebar,
			});
			if (isIssueViewLayoutSidebar) {
				openIssueView(issueKey, {
					section: issueViewSection === 'overview' ? 'comments' : issueViewSection,
				});
			} else {
				openIssueView(issueKey, {
					layout: 'sidebar',
					section: 'overview',
				});
			}
		},
		[issueKey, isIssueViewLayoutSidebar, openIssueView, issueViewSection],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (issueKey) {
			fireCompoundAnalyticsEvent.IdeaView.viewed(createAnalyticsEvent({}));
		}
	}, [createAnalyticsEvent, issueKey]);

	if (!issueKey || !issueId) {
		return fg('jpd-rename-ufo-project-transition') ? (
			<UFOLoadHold name="idea-view-component-no-issue-id" />
		) : null;
	}

	const HeaderControlsContainer = isVisualRefreshEnabled()
		? HeaderControlsContainerNew
		: HeaderControlsContainerOld;

	return (
		<IdeaViewComponentContainer>
			{!isSharedView && fg('jpd-trace-ufo-transition') && <IdeaViewUfoData />}
			<IdeaViewEndMark mark={MARKS.MAIN_COMPONENT_LOADING} />
			<UFOSegment name="idea-view.header">
				<IdeaViewHeader isCompact={isCompact}>
					<KeyContainer isCompact={isCompact}>
						<IssueBreadcrumbs isInSidebar={isIssueViewLayoutSidebar} />
					</KeyContainer>
					<HeaderControlsContainer>
						{/* TODO Should be re-enabled when POL-6107 will be done */}
						{!isSharedView && (
							<>
								<WatchersDropdown />
								<CollaborationControls isCompact={isCompact} />
							</>
						)}
						{isVisualRefreshEnabled() ? (
							<>
								{isIssueViewLayoutSidebar ? (
									<IconButton
										label={formatMessage(messages.expandButtonLabel)}
										id="polaris.ideas.ui.right-sidebar.idea.header.expand"
										appearance="default"
										onClick={onToggleFullscreen}
										icon={ExpandIcon}
									/>
								) : (
									<IconButton
										label={formatMessage(messages.collapseButtonLabel)}
										id="polaris.ideas.ui.right-sidebar.idea.header.collapse"
										appearance="default"
										onClick={onToggleFullscreen}
										icon={CollapseIcon}
									/>
								)}
								<IconButton
									label={formatMessage(messages.closeButtonLabel)}
									appearance="default"
									icon={CloseIcon}
									onClick={onClose}
								/>
							</>
						) : (
							<>
								<FullscreenButton
									id={`polaris.ideas.ui.right-sidebar.idea.header.${
										isIssueViewLayoutSidebar ? 'expand' : 'collapse'
									}`}
									appearance="subtle-link"
									onClick={onToggleFullscreen}
									iconBefore={
										isIssueViewLayoutSidebar ? (
											<MediaServicesActualSizeIcon label="expand" />
										) : (
											<MediaServicesFitToPageIcon label="collapse" />
										)
									}
								/>
								<Button
									appearance="subtle-link"
									iconBefore={<CrossIcon label="close" size="medium" />}
									onClick={onClose}
								/>
							</>
						)}
					</HeaderControlsContainer>
				</IdeaViewHeader>
			</UFOSegment>
			<UFOSegment name="idea-view.title">
				<TitleContainer>
					<Summary />
					<ArchivedNotification isCompact={isCompact} />
				</TitleContainer>
			</UFOSegment>
			<InsightsDataContainer
				isVisible={issueViewSection === 'capture'}
				localIssueId={localIssueId}
				issueKey={toIssueKey(issueKey)}
				issueId={issueId}
				onOpenIdeaView={onOpenIdeaView}
				projectId={projectId}
			>
				{isIssueViewLayoutSidebar ? (
					<UFOSegment name="idea-view.tabs">
						<TabView isSharedView={isSharedView} issueId={issueId} issueKey={issueKey} />
					</UFOSegment>
				) : (
					<UFOSegment name="idea-view.body">
						<IdeaViewBody isCompact={isCompact}>
							<IdeaViewContent isCompact={isCompact} ref={scrollableContainerRef}>
								<ScrollableContainerContextProvider scrollableContainerRef={scrollableContainerRef}>
									{!isSharedView && !isArchived && (
										<UFOSegment name="idea-view.controls">
											<ControlsContainer>
												<Controls onLinkIssue={onLinkIssue} />
											</ControlsContainer>
										</UFOSegment>
									)}
									<UFOSegment name="idea-view.description">
										<Description />
									</UFOSegment>
									<UFOSegment name="idea-view.attachments">
										<Attachments />
									</UFOSegment>
									<UFOSegment name="idea-view.issue-links">
										<IssueLinks
											isCreatingIssueLink={isCreatingIssueLink}
											onLinkIssue={onLinkIssue}
											onCancelOrCreated={onIssueLinkCancelledOrCreated}
										/>
									</UFOSegment>
									{isCompact && (
										<UFOSegment name="idea-view.fields">
											<Fields isCompact />
										</UFOSegment>
									)}
									<UFOSegment name="idea-view.sections">
										<Sections
											issueId={issueId}
											localIssueId={localIssueId}
											isSharedView={isSharedView}
											scrollableContainerRef={scrollableContainerRef}
										/>
									</UFOSegment>
								</ScrollableContainerContextProvider>
							</IdeaViewContent>
							{!isCompact && (
								<UFOSegment name="idea-view.sidebar.fields">
									<IdeaViewSidebar>
										<Fields />
									</IdeaViewSidebar>
								</UFOSegment>
							)}
						</IdeaViewBody>
					</UFOSegment>
				)}
			</InsightsDataContainer>
		</IdeaViewComponentContainer>
	);
});
export type IdeaViewProps = {
	issueKey: IssueKey | undefined;
	isSidebarPreview?: boolean;
	testId?: string;
};

const IdeaViewWrapped = memo<IdeaViewProps>(
	({ issueKey, isSidebarPreview = false, testId }: IdeaViewProps) => {
		const ideaNotFoundError = useIdeaNotFoundError();
		const localIssueId = useSelectedIssue();
		const isLoading = useIsLoading();
		const isSharedView = useIsSharedView();
		const isIssuesLoading = useIsIssuesLoading();
		const analyticsData = useIssueAnalitycsAttributes(localIssueId);
		const issueId = useSelectedIssueId();
		const issueType = useSelectedIssueIssueType();
		const projectId = useProjectIdUnsafe();
		const projectKey = useProjectKeyUnsafe();
		const { fetchMediaContext } = useMediaRemote();

		const [isCompact, setIsCompact] = useState(isSidebarPreview);
		const throttledSetIsCompact = throttle(setIsCompact, 50);

		const criticalDataLoadingError = useCriticalDataLoadingError();

		if (criticalDataLoadingError) {
			experience.ideaView.pageSegmentLoad.failure(criticalDataLoadingError);
			experience.ideaView.directPageSegmentLoad.failure(criticalDataLoadingError);
			return <GenericError error={criticalDataLoadingError} />;
		}

		if (ideaNotFoundError || (isSharedView && !isLoading && localIssueId === undefined)) {
			return <IdeaNotFoundView />;
		}

		// if all project ideas have been successfully loaded, and we cannot resolve the issue-key, we bail early
		if (
			issueKey === undefined ||
			(!isIssuesLoading && localIssueId === undefined) ||
			issueId === undefined
		) {
			return fg('jpd-rename-ufo-project-transition') ? (
				<UFOLoadHold name="idea-view-wrapper-no-issue-id" />
			) : null;
		}

		return (
			<ContextualAnalyticsData
				sourceName="viewIssue"
				sourceType={isSidebarPreview ? DRAWER : SCREEN}
				objectId={analyticsData?.issueId}
				objectType="issue"
				attributes={analyticsData}
				containers={{
					issue: {
						id: issueId.toString(),
						type: issueType?.name.toLowerCase(),
					},
				}}
			>
				{!isSidebarPreview && (
					<RelativeWrapper>
						<WidthObserver
							setWidth={(newWidth: number) => {
								throttledSetIsCompact(newWidth <= COMPACT_LAYOUT_MAX_WIDTH);
							}}
						/>
					</RelativeWrapper>
				)}
				<IdeaViewContainer isSidebarPreview={isSidebarPreview} data-testid={testId}>
					<IdeaViewKeyboardShortcuts />
					<IdeaViewStartMark mark={MARKS.SMART_CARD_PROVIDER} />
					<IdeaViewEndMark mark={MARKS.SMART_CARD_PROVIDER} />
					<IdeaViewStartMark mark={MARKS.ADF_CONTROLLER} />
					<AdfController
						issueKey={issueKey}
						projectId={projectId}
						projectKey={projectKey}
						getMediaContext={fetchMediaContext}
						isSharedView={isSharedView}
					>
						<IdeaViewEndMark mark={MARKS.ADF_CONTROLLER} />
						<IdeaViewComponent isCompact={isCompact} />
					</AdfController>
				</IdeaViewContainer>
			</ContextualAnalyticsData>
		);
	},
);

export const IdeaView = (props: IdeaViewProps) => {
	const isCollectionView = useIsCollectionView();
	const isEmbeddedView = useIsEmbedded();
	const isSharedView = useIsSharedView();

	const project = useSelectedIssueProject();

	return (
		<UFOSegment name="jpd.idea-view">
			<ExperienceFailErrorBoundary
				experience={[
					experience.ideaView.pageSegmentLoad,
					experience.ideaView.directPageSegmentLoad,
				]}
			>
				{isCollectionView ? (
					<PermissionsContainer
						containerAri=""
						projectId={Number(project?.id)}
						isCollectionView={isCollectionView}
						isEmbeddedView={isEmbeddedView}
						isSharedView={isSharedView}
						scope={
							getWillShowNav4() && project?.id
								? getProjectContainerScope(project.id)
								: 'permissions-singleton'
						}
						onLoadStart={jpdProjectPageLoadPermissionsStart}
						onLoadEnd={jpdProjectPageLoadPermissionsEnd}
					>
						<IdeaViewWrapped {...props} />
					</PermissionsContainer>
				) : (
					<IdeaViewWrapped {...props} />
				)}
			</ExperienceFailErrorBoundary>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IdeaViewContainer = styled.div<{ isSidebarPreview?: boolean }>({
	boxSizing: 'border-box',
	width: '100%',
	height: '100%',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isSidebarPreview }) => (isSidebarPreview ? 'none' : 'transparent'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isSidebarPreview }) =>
		isSidebarPreview
			? `${token('space.300', '24px')} ${token('space.100', '8px')} 0`
			: `${token('space.300', '24px')} ${token('space.200', '16px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		height: '100%',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IdeaViewComponentContainer = styled.div({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IdeaViewHeader = styled.div<{ isCompact: boolean }>({
	display: 'flex',
	justifyContent: 'space-between',
	boxSizing: 'border-box',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isCompact }) =>
		isCompact ? `${token('space.0', '0')} ${token('space.100', '8px')}` : token('space.0', '0'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IdeaViewBody = styled.div<{ isCompact: boolean }>({
	height: '100%',
	overflow: 'hidden',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isCompact }) => (isCompact ? 'inline' : 'flex'),
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IdeaViewContent = styled.div<{ isCompact: boolean }>({
	overflow: 'auto',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flex: ({ isCompact }) => (isCompact ? 'none' : '1 1 auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ isCompact }) => (isCompact ? token('space.0', '0') : token('space.150', '12px')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IdeaViewSidebar = styled.div({
	minWidth: '368px',
	maxWidth: '706px',
	flex: '0 0 33%',
	boxSizing: 'border-box',
	overflow: 'auto',
	paddingLeft: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ControlsContainer = styled.div({
	marginBottom: token('space.200', '16px'),
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KeyContainer = styled.div<{ isCompact: boolean }>({
	marginTop: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ isCompact }) => (isCompact ? token('space.0', '0') : token('space.075', '6px')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderControlsContainerOld = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TitleContainer = styled.div({
	flex: '0 0 auto',
	paddingTop: token('space.100', '8px'),
	paddingRight: 0,
	paddingBottom: token('space.100', '8px'),
	paddingLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeWrapper = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullscreenButton = styled(Button)({
	marginRight: token('space.150', '12px'),
});
