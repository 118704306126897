import { useEffect, useCallback, useMemo } from 'react';
import without from 'lodash/without';
import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	useIssueTypeAvatarId,
	useIssueTypeDescription,
	useIssueTypeName,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';

import {
	useAllIssueTypes,
	useFetchIssueTypesForProject,
	useIssueTypeIdsForProject,
} from './controllers/index.tsx';
import type { IssueType } from './controllers/types.tsx';

type ProjectIssueTypesFetcherProps = {
	projectId: ProjectId;
};

export const ProjectIssueTypesFetcher = ({ projectId }: ProjectIssueTypesFetcherProps) => {
	const issueTypes = useIssueTypeIdsForProject({ projectId });
	const fetch = useFetchIssueTypesForProject();

	useEffect(() => {
		if (issueTypes.length === 0) {
			fetch(projectId);
		}
	}, [projectId, fetch, issueTypes]);

	return null;
};

type IssueTypeChange = {
	current: IssueType;
	previous: IssueType;
};

type IssueTypeChangeProviderProps = {
	projectId: ProjectId;
	onChanged: (changed: IssueTypeChange[], deleted: IssueTypeId[]) => void;
};

export const IssueTypeChangeProvider = ({ projectId, onChanged }: IssueTypeChangeProviderProps) => {
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });
	const issueTypes = useAllIssueTypes();

	const prevIssueTypeIds = usePrevious(issueTypeIds);
	const prevIssueTypes = usePrevious(issueTypes);

	useEffect(() => {
		if (issueTypes !== prevIssueTypes) {
			const removedIds = without(prevIssueTypeIds || [], ...issueTypeIds);

			const changes: IssueTypeChange[] = [];

			issueTypeIds.forEach((id) => {
				const current = issueTypes[id];
				const previous = prevIssueTypes?.[id];
				if (current !== undefined && previous !== undefined && current !== previous) {
					changes.push({ current, previous });
				}
			});

			onChanged(changes, removedIds);
		}
	}, [issueTypeIds, prevIssueTypeIds, issueTypes, prevIssueTypes, onChanged]);

	return null;
};

export const useIssueTypeNameFilter = () => {
	const issueTypes = useAllIssueTypes();

	return useCallback(
		(names: string[]) => {
			return Object.values(issueTypes)
				.filter(
					(issueType): issueType is IssueType =>
						issueType !== undefined && names.includes(issueType.name),
				)
				.map((issueType) => issueType.id);
		},
		[issueTypes],
	);
};

export const useIssueType = (issueTypeId: IssueTypeId) => {
	const avatarId = useIssueTypeAvatarId({ issueTypeId });
	const name = useIssueTypeName({ issueTypeId });
	const description = useIssueTypeDescription({ issueTypeId });

	return useMemo(() => {
		if (avatarId === undefined || name === undefined || description === undefined) {
			return null;
		}
		return { avatarId, name, description };
	}, [avatarId, name, description]);
};
