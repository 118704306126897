import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Skeleton, Spacer } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

export const CommentsStreamSkeleton = () => (
	<CommentsStreamSkeletonContainer>
		<CommentsSectionSkeletonContainer>
			<AvatarSkeleton />
			<InputSkeleton />
		</CommentsSectionSkeletonContainer>
		<Spacer height="20px" />
		<CommentsSectionSkeletonContainer>
			<AvatarSkeleton />
			<CommentSkeletonContainer>
				<Skeleton height="16px" width="350px" />
				<Spacer height="8px" />
				<Skeleton height="16px" width="400px" />
				<Spacer height="8px" />
				<Skeleton height="16px" width="140px" />
			</CommentSkeletonContainer>
		</CommentsSectionSkeletonContainer>
	</CommentsStreamSkeletonContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentsStreamSkeletonContainer = styled.div({
	paddingTop: token('space.300', '24px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: 0,
	paddingLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentsSectionSkeletonContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarSkeleton = styled(Skeleton)({
	width: '36px',
	height: '36px',
	borderRadius: '50%',
	marginRight: token('space.075', '6px'),
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InputSkeleton = styled(Skeleton)({
	height: '36px',
	flex: '1 1 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentSkeletonContainer = styled.div({
	flex: '1 1 auto',
	overflow: 'hidden',
});
