import mapValues from 'lodash/mapValues';
import { JIRA_API_FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { StatusCategoryKey } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	JiraSearchApiIssue,
	JpdJiraSearchApiIssue,
	JpdJiraIssueApiPropertiesDataResponse,
	IssueLink,
	JpdJiraSearchApiPublicIssue,
	Comment,
	PublicIssueLink,
	PublicComment,
} from '../types.tsx';

export const extractStatusCategoryKey = (
	jiraSearchIssue: JiraSearchApiIssue,
): StatusCategoryKey | undefined => jiraSearchIssue.fields?.status?.statusCategory?.key;

export const extractStatusName = (jiraSearchIssue: JiraSearchApiIssue): string | undefined =>
	jiraSearchIssue.fields?.status?.name;

export const createStableParentJql = (
	issueKeys: IssueKey[],
	fieldKeys: FieldKey[],
): string | undefined => {
	if (!fieldKeys.length) {
		return undefined;
	}
	return `(${fieldKeys
		.map((fieldKey) => `(cf[${fieldKey.replace('customfield_', '')}] IN (${issueKeys.join(', ')}))`)
		.join(' OR ')}) OR parent IN (${issueKeys.join(', ')})`;
};

export const extractParentKey = (
	jiraSearchIssue: JiraSearchApiIssue,
	parentLinkFields?: FieldKey[] | undefined,
): IssueKey | undefined => {
	if (jiraSearchIssue.fields?.parent?.key) {
		return jiraSearchIssue.fields?.parent?.key;
	}
	if (!parentLinkFields) {
		return undefined;
	}
	for (let i = 0; i < parentLinkFields.length; i += 1) {
		const fieldKey = parentLinkFields[i];
		if (jiraSearchIssue.fields[fieldKey]?.data) {
			return jiraSearchIssue.fields[fieldKey].data.key;
		}
		if (typeof jiraSearchIssue.fields[fieldKey] === 'string') {
			return jiraSearchIssue.fields[fieldKey];
		}
	}
	// can happen for sub-tasks
	return undefined;
};

const translateComment = (
	comment: Comment,
	response: JpdJiraIssueApiPropertiesDataResponse,
): PublicComment => ({
	...comment,
	id: String(comment.id),
	body: JSON.parse(comment.body),
	author: response.users[comment.author],
	updateAuthor: response.users[comment.updatedAuthor],
});

const translateLink = (
	link: IssueLink,
	response: JpdJiraIssueApiPropertiesDataResponse,
	issue: JpdJiraSearchApiIssue,
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const updatedLink: any = {
		...link,
		id: String(link.id),
		typeId: String(link.typeId),
	};

	updatedLink.inwardIssue.fields = {};
	updatedLink.inwardIssue.fields.status = response.statuses[link.inwardIssue.statusId];
	updatedLink.inwardIssue.fields.priority = link.inwardIssue.priority;
	updatedLink.inwardIssue.fields.issuetype = response.issueTypes[link.inwardIssue.issueTypeId];
	updatedLink.inwardIssue.fields.summary = link.inwardIssue.summary;

	updatedLink.outwardIssue.fields = {};
	updatedLink.outwardIssue.fields.status = response.statuses[link.outwardIssue.statusId];
	updatedLink.outwardIssue.fields.priority = link.outwardIssue.priority;
	updatedLink.outwardIssue.fields.issuetype = response.issueTypes[link.outwardIssue.issueTypeId];
	updatedLink.outwardIssue.fields.summary = link.outwardIssue.summary;

	// The new endpoint always returns both inward and outward issues,
	// where the former one didn't. In order to preserve the same behavior as
	// before and be backward compatible, we remove the unnecessary issue
	if (updatedLink.inwardIssue.key === issue.key) {
		delete updatedLink.inwardIssue;
	} else {
		delete updatedLink.outwardIssue;
	}

	updatedLink.type = {
		id: String(link.typeId),
		name: link.typeName,
		inward: link.typeInward,
		outward: link.typeOutward,
	};

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return updatedLink as PublicIssueLink;
};

const translateFieldValue = (
	fieldType: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any,
	response: JpdJiraIssueApiPropertiesDataResponse, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
	switch (fieldType) {
		case JIRA_API_FIELD_TYPES.PROJECT:
			return value && response.projects
				? {
						...response.projects[String(value)],
						id: String(response.projects[String(value)]?.id),
					}
				: null;
		case JIRA_API_FIELD_TYPES.DESCRIPTION:
			return value ? JSON.parse(value) : null;
		case JIRA_API_FIELD_TYPES.CREATED:
		case JIRA_API_FIELD_TYPES.UPDATED:
			return value ? new Date(value).toJSON() : null;
		case JIRA_API_FIELD_TYPES.ASSIGNEE:
		case JIRA_API_FIELD_TYPES.CREATOR:
		case JIRA_API_FIELD_TYPES.REPORTER:
			return response.users[value] || null;
		case JIRA_API_FIELD_TYPES.PEOPLE:
		case JIRA_API_FIELD_TYPES.JSW_PEOPLE:
			return value
				.map((accountId: string) => response.users[accountId])
				.filter((item: unknown) => item !== undefined);
		case JIRA_API_FIELD_TYPES.SINGLE_SELECT:
			return value ? { id: String(value) } : null;
		case JIRA_API_FIELD_TYPES.MULTI_SELECT:
		case JIRA_API_FIELD_TYPES.JSW_MULTI_SELECT:
			return value ? value.map((id: number) => ({ id: String(id) })) : [];
		case JIRA_API_FIELD_TYPES.STATUS:
			return value ? response.statuses[String(value)] : null;
		case JIRA_API_FIELD_TYPES.ISSUE_TYPE:
			return response.issueTypes[value];
		case JIRA_API_FIELD_TYPES.TEAM:
			if (!fg('polaris_team_field_integration')) {
				return null;
			}
			return value ? response.teams?.[value] : null;
		default:
			return value;
	}
};

export const translateToPublicIssue = (
	response: JpdJiraIssueApiPropertiesDataResponse,
	issue: JpdJiraSearchApiIssue,
): JpdJiraSearchApiPublicIssue => {
	const { fieldTypesByKey } = response;

	const fields = mapValues(issue.fields, (value, fieldKey) =>
		translateFieldValue(fieldTypesByKey[fieldKey], value, response),
	);

	fields.issuelinks = (issue.meta.issueLinks.links || []).map((link) =>
		translateLink(link, response, issue),
	);

	fields.comment = {
		comments: (issue.meta.comments.comments || []).map((comment) =>
			translateComment(comment, response),
		),
	};

	return {
		id: String(issue.id),
		key: issue.key,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		fields: fields as JpdJiraSearchApiPublicIssue['fields'],
		meta: {
			...issue.meta,
		},
	};
};
