import React from 'react';
import { Code } from '@atlaskit/code';
import { Text } from '@atlaskit/primitives';
import { useCollectionFiltersJql } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	collectionUUID: string | undefined;
};

export const Filters = ({ collectionUUID }: Props) => {
	const filtersJql = useCollectionFiltersJql({ collectionUUID });
	const { formatMessage } = useIntl();

	if (!filtersJql)
		return <Text color="color.text.subtlest">{formatMessage(messages.noFiltersMessage)}</Text>;

	return <Code>{filtersJql}</Code>;
};
