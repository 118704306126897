import React from 'react';
import { jsx } from '@compiled/react';
import { Inline, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	useProjectMetadataByKey,
	useProjectsKeysForContainer,
} from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { ProjectTag } from '@atlassian/jira-polaris-lib-project-tag/src/ui/index.tsx';
import { useCollectionUUID } from '../../../controllers/index.tsx';
import messages from './messages.tsx';

const ProjectLinkTag = ({ projectKey }: { projectKey: string }) => {
	const project = useProjectMetadataByKey({ projectKey });

	if (!project) {
		return null;
	}

	return (
		<ProjectTag
			projectKey={project.key}
			projectName={project.name}
			projectAvatarUrl={project.avatarUrls['16x16']}
			isLink
			maxWidth={160}
		/>
	);
};

export const ProjectsList = () => {
	const { formatMessage } = useIntl();
	const collectionUUID = useCollectionUUID();
	const projectKeys = useProjectsKeysForContainer({ containerId: collectionUUID });

	if (projectKeys.length === 0) {
		return (
			<Text
				color="color.text.subtlest"
				testId="polaris-component-collection-configure-sidebar.ui.default-content.projects-list.text-no-projects"
			>
				{formatMessage(messages.noProjectsMessage)}
			</Text>
		);
	}

	return (
		<Inline
			testId="polaris-component-collection-configure-sidebar.ui.default-content.projects-list"
			alignBlock="center"
			shouldWrap
			space="space.100"
			xcss={projectsListStyles}
		>
			{projectKeys.map((projectKey) => (
				<ProjectLinkTag projectKey={projectKey} key={projectKey} />
			))}
		</Inline>
	);
};

const projectsListStyles = xcss({ overflowY: 'auto', minHeight: '24px' });
