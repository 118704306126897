import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	appearance?: 'inprogress' | 'success';
	children: ReactNode;
};

export const MinimalLozenge = ({ appearance, children }: Props) => (
	// @ts-expect-error - TS2322: Type 'undefined' is not assignable to type 'inprogress | success | default'.
	<LozengeContainer appearance={appearance}>
		<LozengeTextContainer>{children}</LozengeTextContainer>
	</LozengeContainer>
);

const COLORS = {
	inprogress: token('color.text.information', colors.B500),
	success: token('color.text.success', colors.G500),
	default: token('color.text', colors.N500),
} as const;

const BACKGROUNDS = {
	inprogress: token('color.background.information', colors.B50),
	success: token('color.background.success', colors.G50),
	default: token('color.background.neutral', colors.N40),
} as const;

type LozengeContainerType = {
	appearance: keyof typeof COLORS;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeContainer = styled.div<LozengeContainerType>({
	paddingTop: token('space.025', '2px'),
	paddingRight: 0,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBottom: '3px',
	paddingLeft: 0,
	borderRadius: '3px',

	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	lineHeight: 1,
	textTransform: 'uppercase',
	verticalAlign: 'baseline',
	display: 'inline-block',
	boxSizing: 'border-box',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ appearance }) => (COLORS[appearance] ? COLORS[appearance] : COLORS.default),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ appearance }) =>
		BACKGROUNDS[appearance] ? BACKGROUNDS[appearance] : BACKGROUNDS.default,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeTextContainer = styled.span({
	maxWidth: '200px',
	display: 'inline-block',
	boxSizing: 'border-box',
	width: '100%',
	paddingTop: 0,
	paddingRight: token('space.050', '4px'),
	paddingBottom: 0,
	paddingLeft: token('space.050', '4px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	verticalAlign: 'top',
	whiteSpace: 'nowrap',
});
