import { LoomIcon } from '@atlaskit/logo';
import { JiraSideNavFirstPartyRecommendation } from '@post-office/components--jira-side-nav-first-party-recommendation';
// @ts-expect-error: 'React' is declared but its value is never read
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { createRender } from './types';
import { CCP_PRODUCT_ID, HAMS_PRODUCT_ID, PRODUCT_NAME } from '../../../common/constants';

const whyAmISeeingThisModalContent = {
	message: (
		<p>
			<FormattedMessage
				id="growth.recommendation-loom.reasons-modal.message"
				defaultMessage="We think {product} will improve your team's productivity. The recommendation is based on:"
				description="Explanation of why we think the Loom recommendation will be relevant to the customer."
				values={{ product: <strong>{PRODUCT_NAME}</strong> }}
			/>
		</p>
	),
};
export type Props = {
	onDismissed: (messageInstanceId: string) => void;
};
export const render = createRender(({ onDismissed }: Props) => (
	<JiraSideNavFirstPartyRecommendation
		app={{
			hamsIdentifier: HAMS_PRODUCT_ID,
			ccpProductId: CCP_PRODUCT_ID,
			logo: <LoomIcon appearance="brand" />,
			header: (
				<FormattedMessage
					id="growth.recommendation-loom.product-name.header"
					description="Header for Loom recommendation message in Jira Side Nav"
					defaultMessage="Record and share videos easily"
				/>
			),
			ctaMessage: (
				<FormattedMessage
					id="growth.recommendation-loom.cta.label"
					description="CTA text for Loom recommendation message in Jira Side Nav"
					defaultMessage="Try Loom"
				/>
			),
		}}
		onDismissed={onDismissed}
		whyAmISeeingThisModalContent={whyAmISeeingThisModalContent}
	/>
));
export default render;
