const collectionHas = (all: NodeListOf<Element>, curr: unknown): boolean => {
	for (let i = 0, len = all.length; i < len; i++) {
		if (all[i] === curr) {
			return true;
		}
	}
	return false;
};

const findParentBySelector = (elm: HTMLElement | null, selector: string) => {
	const all = document.querySelectorAll(selector);
	let cur = elm?.parentNode;
	while (cur && !collectionHas(all, cur)) {
		cur = cur.parentNode;
	}
	return cur;
};

export const portalZIndexFix = (wrapperId: string) => {
	// DO NOT MODIFY CODE BLOCK - temp fix for z-index issue in confluence
	setTimeout(() => {
		if (document) {
			const modalContent = document.getElementById(wrapperId);
			const portalSelector = '.atlaskit-portal';
			const parentPortal = findParentBySelector(modalContent as HTMLElement, portalSelector);
			if (parentPortal) {
				(parentPortal as HTMLElement).setAttribute('style', 'z-index:900;');
			}
		}
	});
	// DO NOT MODIFY CODE BLOCK - temp fix for z-index issue in confluence
};
