import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import { Box, xcss } from '@atlaskit/primitives';
import { EmojiWrapper } from '@atlassian/jira-polaris-lib-emoji-wrapper/src/ui/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';

export const ReactionsFirstFilterLabel = ({ labelId }: { labelId: string | undefined }) => {
	const emoji = useEmoji(labelId);
	if (emoji === undefined) {
		return null;
	}
	return (
		<Box xcss={filterLabelFirstItemStyles}>
			<EmojiWrapper>
				<Emoji emoji={emoji} fitToHeight={16} showTooltip />
			</EmojiWrapper>
		</Box>
	);
};

const filterLabelFirstItemStyles = xcss({
	marginInlineStart: 'space.050',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '260px',
});
