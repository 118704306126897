import React, { useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { IssueTypeFormSelect } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-form-select/index.tsx';
import { useFieldType } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useSubmitIdeaFormMandatoryFields } from '../../../controllers/project-properties/index.tsx';
import { DateField } from '../fields/date/index.tsx';
import { Description } from '../fields/document/index.tsx';
import { IntervalDateField } from '../fields/interval-date/index.tsx';
import { LabelsFieldComponent } from '../fields/labels/index.tsx';
import { NumberFieldComponent } from '../fields/number/index.tsx';
import { UsersFieldComponent } from '../fields/people/index.tsx';
import { SelectFieldComponent } from '../fields/select/index.tsx';
import { TextField } from '../fields/string/index.tsx';
import { TeamField } from '../fields/team/index.tsx';
import type { FieldComponentProps } from '../fields/types.tsx';
import { UserFieldComponent } from '../fields/user/index.tsx';
import { messages } from './messages.tsx';
import { MAX_TEXT_FIELD_LENGTH, useValidateFieldValue } from './utils.tsx';

export const useGetFieldConfig = (fieldKey: FieldKey) => {
	const { formatMessage } = useIntl();
	const areFieldsMandatory = useSubmitIdeaFormMandatoryFields();
	const validateFieldValue = useValidateFieldValue(fieldKey);
	const fieldType = useFieldType(fieldKey);
	return useMemo(() => {
		const isRequired = !!areFieldsMandatory.value && !areFieldsMandatory.isLoading;

		switch (fieldType) {
			case FIELD_TYPES.ASSIGNEE:
			case FIELD_TYPES.CREATOR:
				// there is no permission for changing assignee/creator field for contributors
				return null;
			case FIELD_TYPES.SUMMARY:
				return {
					Component: (props: FieldComponentProps) => <TextField fieldKey={fieldKey} {...props} />,
					isRequired: true,
					validateFieldValue: (value: string | undefined) => {
						if (value === undefined || value.trim().length === 0) {
							return formatMessage(messages.summaryRequiredError);
						}
						if (value.length > MAX_TEXT_FIELD_LENGTH) {
							return formatMessage(messages.summaryMaxLengthError);
						}
						return undefined;
					},
				};
			case FIELD_TYPES.ISSUE_TYPE:
				if (fg('jpd_issues_relationships')) {
					return {
						Component: ({ isEditable, onUpdate, predefinedValue }: FieldComponentProps) => (
							<IssueTypeFormSelect
								readonly={!isEditable}
								onChange={onUpdate}
								predefinedValue={predefinedValue}
							/>
						),
						isRequired: true,
					};
				}
				return null;
			case FIELD_TYPES.HYPERLINK:
			case FIELD_TYPES.SHORT_TEXT:
				return {
					Component: (props: FieldComponentProps) => <TextField fieldKey={fieldKey} {...props} />,
					isRequired,
					validateFieldValue: (value: string | undefined) => {
						if (value && value.length > MAX_TEXT_FIELD_LENGTH) {
							return formatMessage(messages.textFieldLimitError);
						}
						return validateFieldValue(value);
					},
				};
			case FIELD_TYPES.DESCRIPTION:
				return {
					Component: (props: FieldComponentProps) => <Description {...props} />,
					isRequired,
					validateFieldValue,
				};
			case FIELD_TYPES.SINGLE_SELECT:
				return {
					Component: ({ predefinedValue, ...props }: FieldComponentProps) => (
						<SelectFieldComponent
							fieldKey={fieldKey}
							predefinedValue={predefinedValue && [predefinedValue]}
							{...props}
						/>
					),
					isRequired,
					validateFieldValue,
				};
			case FIELD_TYPES.MULTI_SELECT:
			case FIELD_TYPES.JSW_MULTI_SELECT:
				return {
					Component: (props: FieldComponentProps) => (
						<SelectFieldComponent fieldKey={fieldKey} isMulti {...props} />
					),
					isRequired,
					validateFieldValue,
				};
			case FIELD_TYPES.NUMBER:
			case FIELD_TYPES.RATING:
			case FIELD_TYPES.SLIDER:
			case FIELD_TYPES.CHECKBOX:
				return {
					Component: (props: FieldComponentProps) => (
						<NumberFieldComponent fieldKey={fieldKey} {...props} />
					),
					isRequired,
					validateFieldValue,
				};
			case FIELD_TYPES.REPORTER:
				return {
					Component: (props: FieldComponentProps) => (
						<UserFieldComponent fieldKey={fieldKey} {...props} />
					),
					isRequired: true,
					validateFieldValue,
				};
			case FIELD_TYPES.PEOPLE:
			case FIELD_TYPES.JSW_PEOPLE:
				return {
					Component: (props: FieldComponentProps) => (
						<UsersFieldComponent fieldKey={fieldKey} {...props} />
					),
					isRequired,
				};
			case FIELD_TYPES.DATE:
				return {
					Component: (props: FieldComponentProps) => <DateField {...props} />,
					isRequired,
					validateFieldValue,
				};
			case FIELD_TYPES.INTERVAL:
				return {
					Component: (props: FieldComponentProps) => <IntervalDateField {...props} />,
					isRequired,
					validateFieldValue,
				};
			case FIELD_TYPES.LABELS:
			case FIELD_TYPES.CUSTOM_LABELS:
				return {
					Component: ({ predefinedValue, ...props }: FieldComponentProps) => {
						const predefinedLabels =
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							predefinedValue && predefinedValue.map((value: any) => ({ id: value }));
						return (
							<LabelsFieldComponent
								fieldKey={fieldKey}
								predefinedValue={predefinedLabels}
								{...props}
							/>
						);
					},
					isRequired,
					validateFieldValue,
				};
			case FIELD_TYPES.TEAM:
				if (fg('polaris_team_field_integration')) {
					return {
						Component: (props) => (
							<TeamField
								fieldKey={fieldKey}
								onChange={props.onUpdate}
								isDisabled={!props.isEditable}
							/>
						),
						isRequired,
						validateFieldValue,
					};
				}
				return null;
			default:
				return null;
		}
	}, [
		areFieldsMandatory.isLoading,
		areFieldsMandatory.value,
		fieldKey,
		fieldType,
		formatMessage,
		validateFieldValue,
	]);
};
