import React from 'react';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { IssueTypeSelect as IssueTypeSelectComponent } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-select/index.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { useIssueType } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';

export type FieldProps = {
	localIssueId: LocalIssueId;
};

export const IssueTypeSelect = ({ localIssueId }: FieldProps) => {
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();
	const issueType = useIssueType(localIssueId);

	return (
		<IssueTypeSelectComponent
			readonly={!canEditIssues}
			issueTypeId={issueType?.id ?? ''}
			onChange={(newValue) =>
				updateFieldValue({
					fieldKey: ISSUETYPE_FIELDKEY,
					localIssueIds: [localIssueId],
					newValue,
				})
			}
			triggerType="icon-and-name"
		/>
	);
};
