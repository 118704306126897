import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { AdControls } from '@post-office/components--ad-controls';
import { type ComponentProps, type FunctionComponent } from 'react';

type PropsWithOnDismissed = {
	onDismissed: () => void;
	whyAmISeeingThisModalContent?: ComponentProps<typeof AdControls>['whyAmISeeingThisModalContent'];
};

const actionsStyles = css({
	position: 'absolute',
	display: 'flex',
	top: '0',
	right: '0',
	padding: token('space.100', '8px'),
});

export const Actions: FunctionComponent<PropsWithOnDismissed> = ({
	onDismissed,
	whyAmISeeingThisModalContent,
}) => {
	return (
		<div css={actionsStyles}>
			<AdControls
				iconSize="small"
				onDismissed={onDismissed}
				whyAmISeeingThisModalContent={whyAmISeeingThisModalContent}
			/>
		</div>
	);
};
