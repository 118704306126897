import React, { useEffect, useCallback, memo } from 'react';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import FuzzyDatePickerContent from './fuzzy-date-picker-content/index.tsx';
import { FuzzyDate } from './fuzzy-date/index.tsx';
import type { FuzzyDatePickerProps } from './fuzzy-date/types.tsx';

export const FuzzyDatePicker = memo(
	({ value, onChange, placeholder, onClose }: FuzzyDatePickerProps) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		useEffect(() => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'toggled',
					actionSubject: 'datepicker',
				}),
				'issueField',
			);
		}, [createAnalyticsEvent]);

		const onClearDate = useCallback(() => {
			onChange(undefined);
		}, [onChange]);

		return (
			<Popup
				zIndex={610}
				placement="bottom-start"
				content={() => <FuzzyDatePickerContent onChange={onChange} value={value} />}
				isOpen
				onClose={onClose}
				shouldUseCaptureOnOutsideClick
				trigger={(triggerProps) => (
					<FuzzyDate
						value={value}
						placeholder={placeholder}
						triggerProps={triggerProps}
						onClick={onClose}
						onClearDate={onClearDate}
					/>
				)}
			/>
		);
	},
);
