import { useMemo } from 'react';
import sumBy from 'lodash/sumBy';
import type { GroupBase, OptionsOrGroups } from '@atlaskit/react-select';
import { createFilter } from '@atlaskit/select';
import { getSearchParams } from '@atlassian/jira-polaris-common/src/ui/common/issue-select/utils.tsx';
import { useAllIssueTypeValues } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useCanCreateAndEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useConnectionFieldIssueTypeIds } from '../../../controllers/field/selectors/field-hooks.tsx';
import {
	useAllIssueIdsMatchingConnectionFieldFilters,
	useConnectionFieldIssueIds,
} from '../../../controllers/issue/selectors/connection-hooks.tsx';
import {
	useLocalIssueIdToJiraIssueId,
	useLocalIssueIdsByJiraIssueId,
} from '../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useIssueTypes,
	useKeys,
	useSummaries,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { useExternalIssueDataByJiraId } from '../../../controllers/issue/selectors/properties/linked-issues/hooks.tsx';
import {
	isCreatableOption,
	type ConnectionFieldOption,
	type CreatableConnectionFieldOption,
	type SelectableConnectionFieldOption,
} from './types.tsx';

export const useConnectionFieldOptions = (localIssueId: string, fieldKey: string) => {
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const localIssueIdByJiraIssueId = useLocalIssueIdsByJiraIssueId();
	const externalIssueData = useExternalIssueDataByJiraId();
	const linkedIssueIds = useConnectionFieldIssueIds(localIssueId, fieldKey);
	const allIssues = useAllIssueIdsMatchingConnectionFieldFilters(localIssueId, fieldKey);
	const issueTypes = useIssueTypes();
	const summaries = useSummaries();
	const keys = useKeys();

	const allOptions: SelectableConnectionFieldOption[] = useMemo(
		() =>
			allIssues.map((id) => ({
				value: localIssueIdToJiraId[id],
				label: summaries[id],
				issueKey: keys[id],
				issueType: issueTypes[id],
				type: 'selectable',
			})),
		[allIssues, issueTypes, keys, localIssueIdToJiraId, summaries],
	);

	const selectedOptions: SelectableConnectionFieldOption[] = linkedIssueIds.map(({ id }) => {
		const jiraId = parseInt(id, 10);
		const localId = localIssueIdByJiraIssueId[jiraId];
		return {
			value: id,
			issueKey: externalIssueData[jiraId]?.issueKey,
			label: summaries[localId] ?? externalIssueData[jiraId]?.summary,
			issueType: issueTypes[localId] ?? externalIssueData[jiraId]?.issueType,
			type: 'selectable',
		};
	});

	return [allOptions, selectedOptions] as const;
};

export const isGroup = (
	optionOrGroup: ConnectionFieldOption | GroupBase<ConnectionFieldOption>,
): optionOrGroup is GroupBase<ConnectionFieldOption> => 'options' in optionOrGroup;

export const filterOption = (option: ConnectionFieldOption, inputValue: string) => {
	const { searchString } = getSearchParams(inputValue);

	if (isCreatableOption(option)) {
		const filter = createFilter<CreatableConnectionFieldOption>({
			stringify: () => inputValue,
		});
		return filter({ value: inputValue, label: inputValue, data: option }, searchString);
	}

	const filter = createFilter<SelectableConnectionFieldOption>({
		stringify: ({ data }) => `${data.label} ${data.issueKey}`,
	});
	return filter({ value: option.value, label: option.label, data: option }, searchString);
};

export const getTotalOptions = (
	options: OptionsOrGroups<ConnectionFieldOption, GroupBase<ConnectionFieldOption>>,
) => sumBy(options, (option) => (isGroup(option) ? option.options.length : 1));

export const useCreatableOptions = (fieldKey: string): CreatableConnectionFieldOption[] => {
	const connectionFieldIssueTypeIds = useConnectionFieldIssueTypeIds(fieldKey);
	const allIssueTypesValues = useAllIssueTypeValues();
	const canCreateAndEditIssues = useCanCreateAndEditIssues();

	if (!canCreateAndEditIssues) {
		return [];
	}

	return connectionFieldIssueTypeIds
		.map((id): CreatableConnectionFieldOption | null => {
			const issueType = allIssueTypesValues.get(id);

			if (!issueType) {
				return null;
			}

			return {
				issueType,
				type: 'creatable',
			};
		})
		.filter(Boolean);
};
