// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { DocNode as ADF } from '@atlaskit/adf-schema';

const template: ADF = {
	type: 'doc',
	content: [
		{
			type: 'heading',
			attrs: {
				level: 1,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						id: '1f9d0',
						text: '🧐',
						shortName: ':face_with_monocle:',
					},
				},
				{
					text: ' Idea Evaluation',
					type: 'text',
				},
			],
		},
		{
			type: 'panel',
			attrs: {
				// @ts-expect-error -  Type '"info"' is not assignable to type 'PanelType'.
				panelType: 'info',
			},
			content: [
				{
					type: 'paragraph',
					content: [
						{
							text: 'Use this to evaluate ideas, assess MVP features, and ensure the product meets customer needs',
							type: 'text',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Problem',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'What is the problem? Why is this a problem worth solving?',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Solution',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Summarize the solution in one or two sentences',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Desirability',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'How much do users want this? What unique value proposition does it provide?',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Viability',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Does this idea benefit the business? Is this in line with the broader company strategy?',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					text: 'Feasibility',
					type: 'text',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'The degree to which it can technically be built. Does the skillset and expertise exist to create this solution?',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
						{
							type: 'em',
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					text: 'Resources',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
				{
					text: ' ',
					type: 'text',
				},
				{
					text: '(add your own)',
					type: 'text',
					// @ts-expect-error - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'strong',
						},
					],
				},
			],
		},
		{
			type: 'bulletList',
			content: [
				{
					type: 'listItem',
					content: [
						{
							type: 'paragraph',
							content: [
								{
									type: 'emoji',
									attrs: {
										id: '1f4dd',
										text: '📝',
										shortName: ':pencil:',
									},
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'PRD/spec',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						{
							type: 'paragraph',
							content: [
								{
									type: 'emoji',
									attrs: {
										id: '1f4f9',
										text: '📹',
										shortName: ':video_camera:',
									},
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'Loom',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'Video',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						{
							type: 'paragraph',
							content: [
								{
									type: 'emoji',
									attrs: {
										id: '1f469-200d-1f3a8',
										text: '👩‍🎨',
										shortName: ':woman_artist:',
									},
								},
								{
									text: ' ',
									type: 'text',
								},
								{
									text: 'Design file',
									type: 'text',
									marks: [
										{
											type: 'strong',
										},
									],
								},
							],
						},
					],
				},
			],
		},
	],
	version: 1,
};

export default template;
