import React, { useCallback, useMemo, useState, type SyntheticEvent } from 'react';
import Button from '@atlaskit/button/new';
import PremiumIcon from '@atlaskit/icon/core/migration/premium';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, Text, xcss } from '@atlaskit/primitives';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	ContextualAnalyticsData,
	SCREEN,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useShouldShowPublishButton } from '../services/use-should-show-publish-button.tsx';
import messages from './messages.tsx';
import { panelEntryPoint } from './panel/entrypoint.tsx';

const PublishFeaturePopup = () => {
	const cloudId = useCloudId();
	const [dialogOpen, setDialogOpen] = useState(false);
	const { formatMessage } = useIntl();

	const shouldShow = useShouldShowPublishButton();

	const handleTogglePopup = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (!dialogOpen) {
				fireUIAnalytics(analyticsEvent, 'button clicked', 'publishFeatureDiscovery');
			}
			setDialogOpen(!dialogOpen);
		},
		[dialogOpen],
	);

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			productKey: 'jira-product-discovery' as const,
			offeringKey: '35b9268e-66e2-45fb-81d3-dacd1e31831a',
		}),
		[cloudId],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		panelEntryPoint,
		entryPointParams,
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions, true);

	const handleOnClose = () => {
		setDialogOpen(false);
		entryPointActions.unload();
	};

	if (!shouldShow) {
		return null;
	}

	return (
		<ContextualAnalyticsData sourceName="publishFeatureDiscovery" sourceType={SCREEN}>
			<Popup
				isOpen={dialogOpen}
				onClose={handleOnClose}
				placement="bottom-end"
				content={() => (
					<Box xcss={contentStyles}>
						<JiraEntryPointContainer
							entryPointReferenceSubject={entryPointReferenceSubject}
							id="jpdPublishFeatureGate"
							packageName="jiraJpdPublishFeatureDiscovery"
							teamName="transmuters-growth"
							runtimeProps={{ onClose: handleOnClose }}
						/>
					</Box>
				)}
				trigger={(triggerProps) => (
					<Button
						{...triggerProps}
						onClick={handleTogglePopup}
						ref={mergeRefs(triggerProps.ref, triggerRef)}
						isSelected={dialogOpen}
						iconBefore={() => (
							<PremiumIcon LEGACY_size="small" color="var(--ds-icon-discovery)" label="" />
						)}
						appearance="default"
					>
						<Text color="color.text.brand">{formatMessage(messages.publishButton)}</Text>
					</Button>
				)}
			/>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

const contentStyles = xcss({
	width: '500px',
	maxHeight: 'calc(100vh - 165px)',
	overflowY: 'auto',
});

export default PublishFeaturePopup;
