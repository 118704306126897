import React, { type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import { Flex, xcss, Box, Inline, Text } from '@atlaskit/primitives';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useIsInitialized,
	useIsIssuesLoading,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ShowDescriptionButton } from './show-description-button/index.tsx';
import { ViewIdeaCount } from './view-idea-count/index.tsx';
import { ViewProjectsCount } from './view-projects-count/index.tsx';

type Props = {
	showViewDescriptionButton: boolean;
};

const Separator = () => {
	return <Box xcss={separatorStyles} />;
};

const TextSegment = ({ children }: PropsWithChildren) => {
	return (
		<Text color="color.text.subtlest" size="UNSAFE_small">
			{children}
		</Text>
	);
};

export const Subtitle = ({ showViewDescriptionButton }: Props) => {
	const isIssuesLoading = useIsIssuesLoading();
	const isInitialized = useIsInitialized();
	const isCollectionView = useIsCollectionView();

	if (isIssuesLoading || !isInitialized) {
		return <Box xcss={skeletonStyles} />;
	}

	return isVisualRefreshEnabled() ? (
		<Inline space="space.050" alignBlock="center">
			{showViewDescriptionButton && (
				<>
					<ShowDescriptionButton />
					<Separator />
				</>
			)}
			<TextSegment>
				<ViewIdeaCount />
			</TextSegment>
			{isCollectionView && (
				<>
					<Separator />
					<TextSegment>
						<ViewProjectsCount />
					</TextSegment>
				</>
			)}
		</Inline>
	) : (
		<Flex gap="space.050" alignItems="center" xcss={subtitleStyles}>
			{showViewDescriptionButton && (
				<>
					<ShowDescriptionButton />
					{' • '}
				</>
			)}
			<IdeaCountContainer>
				<ViewIdeaCount />
			</IdeaCountContainer>
			{isCollectionView && (
				<>
					{' • '}
					<ViewProjectsCount />
				</>
			)}
		</Flex>
	);
};

const separatorStyles = xcss({
	font: 'font.body.UNSAFE_small',
	color: 'color.text.subtlest',
	display: 'inline',
	'::after': {
		content: "'•'",
		display: 'inline',
		marginInline: 'space.050',
	},
});

const subtitleStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.small',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeaCountContainer = styled.span({
	flexShrink: 0,
});

// TODO: use an actual skeleton component from the design system
const skeletonStyles = xcss({
	height: 'space.200',
});
