import React, { useMemo } from 'react';
import { useSearchableLocalIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import { useLocalIssueIdToJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useSummaries,
	useKeys,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useFieldFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { useFieldType } from '@atlassian/jira-polaris-component-field-configuration/src/controllers/selectors/formula-hooks.tsx';
import { useFilteredIssueTypeValues } from '@atlassian/jira-polaris-component-issue-types/src/utils.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { useHasProjectCustomIssueTypes } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { CreateOption } from './create-option/index.tsx';
import { Selectable } from './selectable/index.tsx';
import type { AddCardProps, CreatableOption, SelectableOption, Option } from './types.tsx';

export const useOptions = ({
	inputValue,
	fieldKey,
	groupIdentity,
	verticalFieldKey,
	verticalGroupIdentity,
}: { inputValue: string } & Pick<
	AddCardProps,
	'fieldKey' | 'groupIdentity' | 'verticalGroupIdentity' | 'verticalFieldKey'
>) => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;
	const issueTypeFilter = useFieldFilter(ISSUETYPE_FIELDKEY);
	const issueTypeValues = useFilteredIssueTypeValues({ filter: issueTypeFilter });
	const hasProjectCustomIssueTypes = useHasProjectCustomIssueTypes({ projectId });

	const summaries = useSummaries();
	const keys = useKeys();
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const issueIds = useSearchableLocalIssueIds(
		fieldKey,
		groupIdentity,
		verticalFieldKey,
		verticalGroupIdentity,
	);
	const groupIdentityFieldType = useFieldType(fieldKey);

	const creatableOptions: CreatableOption[] = issueTypeValues.map((issueType) => ({
		label: (
			<CreateOption
				showIcon={hasProjectCustomIssueTypes}
				issueTypeId={issueType.id}
				inputValue={inputValue}
			/>
		),
		value: inputValue,
		issueType,
		type: 'creatable',
	}));

	const selectableOptions = useMemo((): Option[] => {
		const transformed: SelectableOption[] = issueIds.map((issueId) => ({
			label: <Selectable showIcon={hasProjectCustomIssueTypes} issueId={issueId} />,
			value: keys[issueId] + summaries[issueId],
			issueId,
			type: 'selectable',
		}));

		if (groupIdentityFieldType === FIELD_TYPES.CONNECTION) {
			return transformed.filter(
				({ issueId }) => !issueId || localIssueIdToJiraId[issueId] !== groupIdentity,
			);
		}

		return transformed;
	}, [
		issueIds,
		groupIdentityFieldType,
		hasProjectCustomIssueTypes,
		keys,
		summaries,
		localIssueIdToJiraId,
		groupIdentity,
	]);

	return { creatableOptions, selectableOptions };
};
