import React from 'react';

import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { VIEW_KIND_BOARD } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { useConnectionFieldIssueIds } from '../../../../controllers/issue/selectors/connection-hooks.tsx';
import { ConnectionsList } from '../../../connection/connection-list/index.tsx';

import { useCurrentViewKind } from '../../../../controllers/views/selectors/view-hooks.tsx';
import { InlineConnectionsList } from '../../../connection/connection-list/inline/index.tsx';
import { FieldLabel } from '../field/styled.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { DetailedShort } from './detailed-short/index.tsx';
import { LabelItem } from './label-item/index.tsx';

type ConnectionFieldProps = {
	variant?: ViewLayoutType;
	isSummary?: boolean;
	issueId: string;
	fieldKey: string;
	hideEmpty?: boolean;
};

export const ConnectionField = (props: ConnectionFieldProps) => {
	const { fieldKey, issueId, variant, isSummary, hideEmpty = false } = props;
	const linkedIssueIds = useConnectionFieldIssueIds(issueId, fieldKey);
	const viewKind = useCurrentViewKind();
	const label = useFieldLabel(fieldKey);

	if (hideEmpty && (linkedIssueIds === undefined || linkedIssueIds.length === 0)) {
		return null;
	}

	if (isSummary || viewKind === VIEW_KIND_BOARD) {
		return (
			<ConnectionsList
				localIssueId={issueId}
				connectionItems={linkedIssueIds}
				fieldKey={fieldKey}
			/>
		);
	}

	switch (variant) {
		case ViewLayoutType.DETAILED:
			return (
				<>
					<FieldLabel>{label}</FieldLabel>
					<InlineConnectionsList localIssueId={issueId} fieldKey={fieldKey} />
				</>
			);
		case ViewLayoutType.COMPACT:
			return (
				<DetailedShort
					localIssueId={issueId}
					fieldKey={fieldKey}
					connectionItems={linkedIssueIds}
				/>
			);
		default:
			return <LabelItem amount={linkedIssueIds.length} fieldKey={fieldKey} />;
	}
};
