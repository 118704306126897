import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { isDecorationWithLogic } from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import { Rating } from '@atlassian/jira-polaris-lib-rating/src/ui/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useCallbacks } from '../../../../../controllers/selectors/callback-hooks.tsx';
import { SharedFieldDecorationPopup } from '../../shared-field-decoration-popup.tsx';

type BaseDecoratorItemProps = {
	decoration: ValueDecoration;
	readonly?: boolean;
};

type DecoratorItemProps = {
	isOpen: boolean;
} & BaseDecoratorItemProps & {
		onOpenChanged: (open: boolean) => void;
	};

export const DecoratorRatingItem = ({
	onOpenChanged,
	isOpen,
	decoration,
	readonly = false,
}: DecoratorItemProps) => {
	const { onValueDecorationUpdated } = useCallbacks();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const rules = useMemo(
		() =>
			isDecorationWithLogic(decoration)
				? [] // TODO: Handle Decoration Logic
				: decoration.rules,
		[decoration],
	);

	const [currentColor, setCurrentColor] = useState(decoration.backgroundColor);
	const [currentHighlighted, setCurrentHighlighted] = useState(!!decoration.highlightContainer);
	const [currentEmojiId, setCurrentEmojiId] = useState(decoration.emoji);
	const [currentRules, setCurrentRules] = useState(rules);
	const currentRatingValue = Number.parseInt(rules[0].value, 10);

	useEffect(() => {
		setCurrentRules(rules);
	}, [rules]);

	const onValueDecorationChanged = useCallback(
		({
			backgroundColor,
			emoji,
			highlightContainer,
		}: {
			backgroundColor: string | undefined;
			emoji: string | undefined;
			highlightContainer: boolean;
		}) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'fieldDecoration changed', 'config-fields', {
				action: 'update',
				backgroundColor,
				emoji,
				highlightContainer,
			});

			onValueDecorationUpdated &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onValueDecorationUpdated({
					localDecorationId: decoration.localDecorationId,
					backgroundColor,
					emoji,
					highlightContainer,
					rules,
				} as ValueDecoration);
		},
		[createAnalyticsEvent, onValueDecorationUpdated, decoration.localDecorationId, rules],
	);

	const onColorChanged = useCallback(
		(backgroundColor: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'color clicked', 'config-fields', {
				backgroundColor,
			});

			onValueDecorationChanged({
				backgroundColor,
				emoji: currentEmojiId,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentEmojiId, currentHighlighted, onValueDecorationChanged],
	);

	const onEmojiChanged = useCallback(
		(emoji: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'emoji clicked', 'config-fields', {
				emoji,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentColor, currentHighlighted, onValueDecorationChanged],
	);

	const onHighlightContainerChanged = useCallback(
		(highlightContainer: boolean) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'highlightContainer clicked', 'config-fields', {
				highlightContainer,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji: currentEmojiId,
				highlightContainer,
			});
		},
		[createAnalyticsEvent, currentColor, currentEmojiId, onValueDecorationChanged],
	);

	const onUpdateRules = useCallback(() => {
		onValueDecorationUpdated &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onValueDecorationUpdated({
				backgroundColor: decoration.backgroundColor,
				highlightContainer: !!decoration.highlightContainer,
				emoji: decoration.emoji,
				localDecorationId: decoration.localDecorationId,
				rules: currentRules,
			} as ValueDecoration);
	}, [
		currentRules,
		decoration.backgroundColor,
		decoration.emoji,
		decoration.highlightContainer,
		decoration.localDecorationId,
		onValueDecorationUpdated,
	]);

	const onToggleEdit = useCallback(
		(isEditActive: boolean) => {
			onOpenChanged(isEditActive);
			if (isEditActive === false) {
				onUpdateRules();
			}
		},
		[onOpenChanged, onUpdateRules],
	);

	return (
		<SharedFieldDecorationPopup
			isOpen={isOpen}
			onOpen={onToggleEdit}
			readonly={readonly}
			decorationNode={
				<Box xcss={ratingContainerStyles}>
					<Rating mainColor={currentColor} value={currentRatingValue} />
				</Box>
			}
			isHighlightingEditable={false}
			isEmojiEditable={false}
			isHighlighted={currentHighlighted}
			color={currentColor}
			emojiId={currentEmojiId}
			onColorChanged={(hexCode) => {
				setCurrentColor(hexCode);
				onColorChanged(hexCode);
			}}
			onHighlightingChanged={(highlighted) => {
				setCurrentHighlighted(highlighted);
				onHighlightContainerChanged(highlighted);
			}}
			onEmojiSelected={(selectedEmoji) => {
				setCurrentEmojiId(selectedEmoji);
				onEmojiChanged(selectedEmoji);
			}}
		/>
	);
};

const ratingContainerStyles = xcss({
	backgroundColor: 'elevation.surface',
});
