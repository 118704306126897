import React, { Component, type ReactNode, createRef, type PropsWithChildren, useRef } from 'react';
import { styled } from '@compiled/react';
import {
	ProfileCardTrigger,
	type ProfileClient,
	type ProfileCardAction,
	type ProfilecardTriggerPosition,
	type TriggerType,
	type PrepopulatedData,
} from '@atlaskit/profilecard';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { type FlagId, AkFlag, toFlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { FlagCustomRenderProps } from '@atlassian/jira-flags/src/services/types'; // ignore-for-ENGHEALTH-17759
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProfileClient } from '../../controllers/profileclient/index.tsx';
import { analyticsViewComponentName } from './constants.tsx';

export type PropsOld = {
	accountId: string;
	children: ReactNode;
	cloudId: string;
	profileClient: ProfileClient | null;
	actions?: ProfileCardAction[];
	position?: ProfilecardTriggerPosition;
	trigger?: TriggerType;
	onProfileClick?: (arg1: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	addFlag: (flag: any) => void;
	dismissFlag: (flagId: FlagId) => void;
	testId?: string;
	prepopulatedData?: PrepopulatedData;
	viewingUserId?: string;
	ariaHideProfileTrigger?: boolean;
};

const PEOPLE_PROFILE_PATH = '/people/';

const ProfileCardTriggerWithAnalytics = AnalyticsEventToProps(analyticsViewComponentName, {
	analytics: '',
})(ProfileCardTrigger);

/**
 * @deprecated Cleanup with empanada_nin_concurrent_mode_fixes
 */
// eslint-disable-next-line jira/react/no-class-components
export class ProfileCardViewOld extends Component<PropsOld> {
	static defaultProps = {
		position: 'top-start',
		trigger: 'hover',
	};

	profileCardWrapperRef = createRef<HTMLDivElement>();

	render() {
		const {
			children,
			position,
			trigger,
			accountId,
			cloudId,
			profileClient,
			actions,
			onProfileClick,
			addFlag,
			dismissFlag,
			testId,
			prepopulatedData,
			viewingUserId,
			ariaHideProfileTrigger,
		} = this.props;

		const ProfileCardComponent = ProfileCardTriggerWithAnalytics;

		return (
			// Stop the propagation of the click event when clicking on the profile card pop up,
			// to avoid closing the popup and opening the people picker when clicking the info inside.
			// Don't stop propagation when clicking the people picker directly.
			<Wrapper
				ref={this.profileCardWrapperRef}
				onClick={(event) => {
					if (
						this.profileCardWrapperRef?.current !== null &&
						// @ts-expect-error - TS2571 - Object is of type 'unknown'.
						!this.profileCardWrapperRef?.current?.contains(event.target)
					) {
						event.stopPropagation();
					}
				}}
				data-vc={`profilecard-wrapper${__SERVER__ ? '-ssr' : ''}`}
				isVisualRefresh={isVisualRefreshEnabled() && fg('visual-refresh_drop_2')}
			>
				<ProfileCardComponent
					cloudId={cloudId}
					userId={accountId}
					resourceClient={profileClient}
					position={position}
					trigger={trigger}
					testId={testId}
					actions={actions}
					reportingLinesProfileUrl={PEOPLE_PROFILE_PATH}
					prepopulatedData={prepopulatedData}
					onReportingLinesClick={
						// @ts-expect-error - TS7006 - Parameter 'user' implicitly has an 'any' type.
						onProfileClick ? (user) => onProfileClick(user.accountIdentifier) : null
					}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					addFlag={(flag: any) => {
						const flagId = toFlagId(flag.id);
						addFlag({
							id: flagId,
							render: (props: FlagCustomRenderProps) => (
								<AkFlag
									{...flag}
									onDismissed={() => {
										dismissFlag(flagId);
									}}
									{...props}
								/>
							),
						});
					}}
					viewingUserId={viewingUserId}
					ariaHideProfileTrigger={ariaHideProfileTrigger}
				>
					<div data-testid="profilecard-next.ui.profilecard.profilecard-trigger">
						<>{children}</>
					</div>
				</ProfileCardComponent>
			</Wrapper>
		);
	}
}

export type Props = {
	accountId: string;
	actions?: ProfileCardAction[];
	position?: ProfilecardTriggerPosition;
	trigger?: TriggerType;
	onProfileClick?: (arg1: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/react/handler-naming
	addFlag: (flag: any) => void;
	// eslint-disable-next-line jira/react/handler-naming
	dismissFlag: (flagId: FlagId) => void;
	testId?: string;
	prepopulatedData?: PrepopulatedData;
	ariaHideProfileTrigger?: boolean;
};

export const ProfileCardViewNew = ({
	children,
	position = 'top-start',
	trigger = 'hover',
	accountId,
	actions,
	onProfileClick,
	addFlag,
	dismissFlag,
	testId,
	prepopulatedData,
	ariaHideProfileTrigger,
}: PropsWithChildren<Props>) => {
	const profileCardWrapperRef = useRef<HTMLDivElement>(null);
	const cloudId = useCloudId();
	const viewingUserId = useAccountId();
	const { profileClient } = useProfileClient();

	return (
		// Stop the propagation of the click event when clicking on the profile card pop up,
		// to avoid closing the popup and opening the people picker when clicking the info inside.
		// Don't stop propagation when clicking the people picker directly.
		<Wrapper
			ref={profileCardWrapperRef}
			onClick={(event) => {
				if (
					profileCardWrapperRef?.current !== null &&
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					!profileCardWrapperRef?.current?.contains(event.target)
				) {
					event.stopPropagation();
				}
			}}
			data-vc={`profilecard-wrapper${__SERVER__ ? '-ssr' : ''}`}
			isVisualRefresh={isVisualRefreshEnabled() && fg('visual-refresh_drop_2')}
		>
			<ProfileCardTriggerWithAnalytics
				cloudId={cloudId}
				userId={accountId}
				resourceClient={profileClient}
				position={position}
				trigger={trigger}
				testId={testId}
				actions={actions}
				reportingLinesProfileUrl={PEOPLE_PROFILE_PATH}
				prepopulatedData={prepopulatedData}
				onReportingLinesClick={
					// @ts-expect-error - TS7006 - Parameter 'user' implicitly has an 'any' type.
					onProfileClick ? (user) => onProfileClick(user.accountIdentifier) : null
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				addFlag={(flag: any) => {
					const flagId = toFlagId(flag.id);
					addFlag({
						id: flagId,
						render: (props: FlagCustomRenderProps) => (
							<AkFlag
								{...flag}
								onDismissed={() => {
									dismissFlag(flagId);
								}}
								{...props}
							/>
						),
					});
				}}
				viewingUserId={viewingUserId}
				ariaHideProfileTrigger={ariaHideProfileTrigger}
			>
				<div data-testid="profilecard-next.ui.profilecard.profilecard-trigger">
					<>{children}</>
				</div>
			</ProfileCardTriggerWithAnalytics>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isVisualRefresh: boolean }>({
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	color: ({ isVisualRefresh }) => (isVisualRefresh ? token('color.text.subtle') : undefined),
});

Wrapper.displayName = 'Wrapper';
