import React from 'react';
import { Inline, Box, xcss } from '@atlaskit/primitives';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueTypeName } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import messages from './messages.tsx';

type Props = {
	issueTypeId: IssueTypeId;
	inputValue: string;
	showIcon?: boolean;
};

export const CreateOption = ({ issueTypeId, inputValue, showIcon = true }: Props) => {
	const { formatMessage } = useIntl();

	const issueTypeName = useIssueTypeName({ issueTypeId });

	return (
		<Inline space="space.050" alignBlock="center">
			{showIcon && <IssueTypeIcon issueTypeId={issueTypeId} />}
			<Box xcss={inputValueStyles}>
				{formatMessage(messages.createOptionLabel, {
					inputValue,
					issueTypeName: issueTypeName?.toLocaleLowerCase() ?? '',
				})}
			</Box>
		</Inline>
	);
};

const inputValueStyles = xcss({
	flex: '1 1 auto',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
