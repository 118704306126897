import {
	useEnvironmentContainer,
	PolarisEnvironmentContainerTypes,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { FieldValueFilter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { useIssueTypeValuesForProject } from './controllers/index.tsx';

export const useFilteredIssueTypeValues = ({ filter }: { filter: FieldValueFilter }) => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;
	const issueTypesValues = useIssueTypeValuesForProject({ projectId });

	if (!filter.values.length) {
		return issueTypesValues;
	}

	return issueTypesValues.filter(({ id }) =>
		filter.values.some(({ stringValue }) => stringValue === id),
	);
};
