import type { State } from '../types.tsx';

export const getCanEditField = (state: State): boolean => state.canEditField;

export const getNotEditableReason = (state: State) => state.notEditableReason;

export const getOuterSpacing = (state: State) => state.outerSpacing;

export const getProjectId = (state: State) => state.projectId;

export const getProjectKey = (state: State) => state.projectKey;

export const getReadonly = (state: State) => state.readonly;

export const getIsPreview = (state: State) => state.isPreview;

export const getIsDescriptionEditorAiEnabled = (state: State) => state.isDescriptionEditorAiEnabled;

export const getIsCollectionView = (state: State) => state.isCollectionView;
