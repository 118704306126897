// If you're looking for typed Object.keys please use
// import { getTypedKeys } from '@atlassian/jira-shared-types/src/narrowing.tsx';
// We dont' re-export things as it's not allowed anymore

export function ensureNonNullable<T>(value: T, label?: string): NonNullable<T> {
	if (value === undefined || value === null) {
		throw new Error(label || 'Expected value to be NonNullable');
	}

	return value;
}

export function assertUnreachable(value: never, label?: string): never {
	throw new Error(
		label || `assertUnreachable should never be called, but it was called with value: ${value}`,
	);
}
