import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { useIntl } from '@atlassian/jira-intl';
import GenericError from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/generic-error/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { messages } from './messages.tsx';

export const ConnectionsConfigError = () => {
	const { formatMessage } = useIntl();

	return (
		<EmptyState
			renderImage={getAkEmptyStateRenderImageFn(GenericError)}
			maxImageWidth={120}
			header={formatMessage(messages.errorHeader)}
			description={formatMessage(messages.errorBody)}
		/>
	);
};
