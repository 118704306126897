import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useHasReachedViewsLimit } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view/view-sets/hooks.tsx';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { VIEW_CREATE_POPUP_TYPES } from '@atlassian/jira-polaris-lib-create-view-popup/src/types.tsx';
import { ViewCreatePopup } from '@atlassian/jira-polaris-lib-create-view-popup/src/ui/index.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import { NoViewsEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/no-views-empty-state/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export const NoViewsScreen = () => {
	const { createNewView } = useViewActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [limitType, limitValue] = useHasReachedViewsLimit(false);
	const getEntityLimitMessage = useEntityLimitMessage();
	const canManageViews = useCanManageViews();

	const onCreateViewItem = useCallback(
		(kind: ViewKind) => {
			experience.noViewsScreen.createView.start();

			createNewView(
				kind,
				undefined,
				() => {
					experience.noViewsScreen.createView.success({
						metadata: {
							kind,
						},
					});
				},
				(error?: Error) => {
					fireCompoundAnalyticsEvent.View.createdFailure(createAnalyticsEvent({}), kind);
					experience.noViewsScreen.createView.failure({
						metadata: {
							error: error?.message,
							kind,
						},
					});
				},
			);
		},
		[createAnalyticsEvent, createNewView],
	);

	return (
		<Container>
			<NoViewsEmptyState
				primaryAction={
					canManageViews ? (
						<ViewCreatePopup
							type={VIEW_CREATE_POPUP_TYPES.BUTTON}
							onCreateView={onCreateViewItem}
							viewEntityLimitMessage={limitType && getEntityLimitMessage(limitType, limitValue)}
						/>
					) : null
				}
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	position: 'relative',
	marginTop: token('space.400', '32px'),
	marginRight: token('space.300', '24px'),
	marginBottom: token('space.400', '32px'),
	marginLeft: token('space.300', '24px'),
	height: '100%',
	minHeight: '450px',
	maxHeight: '630px',
});
