import { useMessageInstanceEvent } from '@post-office/message-instance-event-frontend-client';
import { useMessageEvent } from '@post-office/message-lifecycle';
import {
	type FunctionComponent,
	type PropsWithChildren,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useInViewport } from 'react-in-viewport';

import { useMessageAnalyticsContext } from './message-analytics-context';
import { TrackMount } from './track-mount';

// Use this component to set the fireMessageViewedInMessageComponent to true.
// This will override the default behavior of firing the message viewed event in the message renderer.
// You will need to fire the message viewed event manually in the message component.
export const ManualMessageViewedEvent: FunctionComponent<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const { fireMessageViewedInMessageComponent, setFireMessageViewedInMessageComponent } =
		useMessageAnalyticsContext();
	if (fireMessageViewedInMessageComponent) {
		setFireMessageViewedInMessageComponent(true);
	}
	return <>{children}</>;
};

/**
 * This component is used as a child of a message component to fire the message viewed event when mounted.
 * It will only fire the message viewed event if the message viewed event is not fired in the message component.
 * @returns a component that fires the message viewed event when mounted.
 */
export const MessageViewed: FunctionComponent<unknown> = () => {
	const { fireMessageViewedInMessageComponent } = useMessageAnalyticsContext();
	const { messageViewed } = useMessageEvent();
	const { messageInstanceViewed, messageInstanceDeliveredSuccessfully } = useMessageInstanceEvent();
	const [hasDeliveredSuccessfullyBeenFired, setHasDeliveredSuccessfullyBeenFired] = useState(false);

	// Only fire the delivery successful event once.
	useEffect(() => {
		if (!hasDeliveredSuccessfullyBeenFired) {
			void messageInstanceDeliveredSuccessfully();
			setHasDeliveredSuccessfullyBeenFired(true);
		}
	}, [hasDeliveredSuccessfullyBeenFired, messageInstanceDeliveredSuccessfully]);

	// messageViewed returns a promise of void, which is incompatible with the TrackMount onMount prop
	const messageViewedVoidFunction = useCallback(() => {
		// Only fire the message viewed event if the message viewed event is not fired in the message component.
		if (fireMessageViewedInMessageComponent && !fireMessageViewedInMessageComponent.current) {
			void messageViewed();
			void messageInstanceViewed();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <TrackMount onMount={() => messageViewedVoidFunction()} />;
};

/**
 * NOT USED YET due to implementation affecting rendering.
 * This component wraps a message component and fires the message "viewed" event when the message enters the viewport.
 * It will also fire a "delivery successful" event when the message is rendered, regardless of whether it is in the viewport.
 * @returns a component that fires the message viewed event when the message is in the viewport.
 */
export const MessageViewedTrackerWithViewportTracking: FunctionComponent<
	PropsWithChildren<unknown>
> = ({ children }) => {
	const messageRef = useRef<HTMLElement>(null);

	const { messageInstanceDeliveredSuccessfully } = useMessageInstanceEvent();
	const { inViewport, enterCount } = useInViewport(messageRef, {});

	const [hasDeliveredSuccessfullyBeenFired, setHasDeliveredSuccessfullyBeenFired] = useState(false);

	// Only fire the delivery successful event once.
	useEffect(() => {
		if (!hasDeliveredSuccessfullyBeenFired) {
			void messageInstanceDeliveredSuccessfully();
			setHasDeliveredSuccessfullyBeenFired(true);
		}
	}, [hasDeliveredSuccessfullyBeenFired, messageInstanceDeliveredSuccessfully]);

	return (
		<section ref={messageRef}>
			{inViewport && enterCount >= 1 ? <MessageViewed /> : null}
			{children}
		</section>
	);
};
