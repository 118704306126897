import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const viewLinkTypesSettingPageUrl = '/secure/admin/ViewLinkTypes!default.jspa';

export const IssueLinkingDisabledNotification = ({ boldTitle }: { boldTitle?: boolean }) => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={notificationMessageStyles}>
			<NotificationTitle bold={boldTitle}>
				{formatMessage(
					fg('polaris-issue-terminology-refresh') ? messages.titleIssueTermRefresh : messages.title,
				)}
			</NotificationTitle>{' '}
			<FormattedI18nMessage
				message={formatMessage(messages.description, {
					linkStart: '{linkStart}',
					linkEnd: '{linkEnd}',
				})}
				componentsMapping={{
					link: (props) => (
						<a target="_blank" href={viewLinkTypesSettingPageUrl}>
							{props.children}
						</a>
					),
				}}
			/>
		</Box>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NotificationTitle = styled.span<{ bold?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontWeight: (props) => (props.bold ? token('font.weight.semibold') : 'inherit'),
});

const notificationMessageStyles = xcss({
	marginLeft: 'space.050',
});
