import {
	type JiraSideNav as MessagePipe,
	createClientPipeline,
	createServerPipeline,
} from '@post-office/placement-common';

import { type MessageTemplateContext } from '../../..';

export type HydrationResult = Record<string, never>;

type Pipe = MessagePipe<{
	messageContext: MessageTemplateContext;
	hydrationResult: HydrationResult;
}>;

export const { createRender, createValidate } = createClientPipeline<Pipe>();
export const { createHydrate, createHydrateExample, createTransformMetadata } =
	createServerPipeline<Pipe>();
