import React, { type PropsWithChildren } from 'react';
import { Flex, xcss, Box, Inline, Text } from '@atlaskit/primitives';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { ShowDescriptionButton } from './show-description-button/index.tsx';
import { ViewProjectsCount } from './view-projects-count/index.tsx';

export type SubtitleProps = {
	showViewDescriptionButton: boolean;
	viewKind?: ViewKind;
	isInitialized: boolean;
	isIssuesLoading: boolean;
	isCollectionView: boolean;
	viewUUID?: string;
	autoOpenSidebarCondition?: boolean;
	onOpenRightSidebarOnViewInfo: () => void;
};

const Separator = () => {
	return <Box xcss={separatorStyles} />;
};

const TextSegment = ({ children }: PropsWithChildren) => {
	return (
		<Text color="color.text.subtlest" size="UNSAFE_small">
			{children}
		</Text>
	);
};

export const Subtitle = ({
	showViewDescriptionButton,
	isIssuesLoading,
	isInitialized,
	isCollectionView,
	onOpenRightSidebarOnViewInfo,
	viewUUID,
	autoOpenSidebarCondition,
}: SubtitleProps) => {
	if (isIssuesLoading || !isInitialized) {
		return <Box xcss={skeletonStyles} />;
	}

	return isVisualRefreshEnabled() ? (
		<Inline space="space.050" alignBlock="center">
			{showViewDescriptionButton && (
				<ShowDescriptionButton
					onOpenRightSidebarOnViewInfo={onOpenRightSidebarOnViewInfo}
					viewUUID={viewUUID}
					autoOpenSidebarCondition={autoOpenSidebarCondition}
				/>
			)}
			{isCollectionView && (
				<>
					<Separator />
					<TextSegment>
						<ViewProjectsCount />
					</TextSegment>
				</>
			)}
		</Inline>
	) : (
		<Flex gap="space.050" alignItems="center" xcss={subtitleStyles}>
			{showViewDescriptionButton && (
				<ShowDescriptionButton
					onOpenRightSidebarOnViewInfo={onOpenRightSidebarOnViewInfo}
					viewUUID={viewUUID}
					autoOpenSidebarCondition={autoOpenSidebarCondition}
				/>
			)}
			{isCollectionView && (
				<>
					{' • '}
					<ViewProjectsCount />
				</>
			)}
		</Flex>
	);
};

const separatorStyles = xcss({
	font: 'font.body.UNSAFE_small',
	color: 'color.text.subtlest',
	display: 'inline',
	'::after': {
		content: "'•'",
		display: 'inline',
		marginInline: 'space.050',
	},
});

const subtitleStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.small',
	lineHeight: '14px',
});

const skeletonStyles = xcss({
	height: 'space.200',
});
