import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyScreenHeader: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.body.empty-screen.empty-screen-header',
		defaultMessage: 'No available projects',
		description: 'Header of the empty projects screen',
	},
	emptyScreenDescription: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.body.empty-screen.empty-screen-description',
		defaultMessage:
			'You can’t add any project because you lack permission to view them or there are no discovery projects yet.',
		description: 'Description of the empty projects screen',
	},
});
