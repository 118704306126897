import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { B400, N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import {
	useGetIdeaPlayTotalBudget,
	useGetIdeaPlayTotalContributors,
	useGetIdeaPlayUserContribution,
} from '../../../../controllers/issue/selectors/properties/plays/hooks.tsx';
import { useOpenRightSidebarOnPlay } from '../../../../controllers/right-sidebar/actions/hooks.tsx';
import { useCurrentUserAccountId } from '../../../../controllers/user/index.tsx';
import messages from './messages.tsx';

type PlayFieldPlaceholderProps = {
	appearance: 'list' | 'board';
	showPlaceholderLabel?: 'always' | 'hovered';
	onClick: (arg1: LocalIssueId, arg2: Ari, arg3: FieldKey) => void;
	isDisabled?: boolean;
};

type InternalPlayFieldProps = {
	isEditable: boolean;
	isCurrentUserContributed: boolean;
	value: number | null;
	totalContributors: number;
	localIssueId: LocalIssueId;
	playId: Ari;
	fieldKey: FieldKey;
} & PlayFieldPlaceholderProps;

const getSpacing = (appearance: 'list' | 'board') =>
	appearance === 'board' ? 'compact' : 'default';

const Placeholder = ({
	onClick,
	appearance,
	showPlaceholderLabel,
	isDisabled,
}: PlayFieldPlaceholderProps) => {
	const { formatMessage } = useIntl();
	const spacing = getSpacing(appearance);
	return (
		<>
			<HideOnHoverTableCellWrapper data-component-selector="table-cell-wrapper-hide-95Gj">
				<StyledButton
					spacing={spacing}
					// @ts-expect-error - TS2322 - Type '(arg1: string, arg2: string, arg3: string) => void' is not assignable to type 'MouseEventHandler<SVGSymbolElement> | MouseEventHandler<HTMLObjectElement> | MouseEventHandler<HTMLSourceElement> | ... 115 more ... | undefined'.
					onClick={onClick}
					appearance="subtle"
					iconBefore={<LikeIcon size="small" label="vote" />}
					isDisabled={isDisabled}
				>
					<NonHoveredPlaceholderLabel isVisible={showPlaceholderLabel === 'always'}>
						<PlaceholderLabel>{formatMessage(messages.placeholder)}</PlaceholderLabel>
					</NonHoveredPlaceholderLabel>
				</StyledButton>
			</HideOnHoverTableCellWrapper>
			<ShowOnHoverTableCellWrapper data-component-selector="table-cell-wrapper-show-G7d8">
				<StyledButton
					spacing={spacing}
					// @ts-expect-error - TS2322 - Type '(arg1: string, arg2: string, arg3: string) => void' is not assignable to type 'MouseEventHandler<SVGSymbolElement> | MouseEventHandler<HTMLObjectElement> | MouseEventHandler<HTMLSourceElement> | ... 115 more ... | undefined'.
					onClick={onClick}
					iconBefore={
						<IconContainer>
							<LikeIcon size="small" label="vote" />
						</IconContainer>
					}
				>
					<PlaceholderLabel>{formatMessage(messages.placeholder)}</PlaceholderLabel>
				</StyledButton>
			</ShowOnHoverTableCellWrapper>
		</>
	);
};

// TODO POL-2296 deprecated - should be used only internally
export const CommonPlayField = ({
	value,
	onClick,
	appearance,
	isCurrentUserContributed,
	isEditable,
	showPlaceholderLabel,
	totalContributors,
	localIssueId,
	playId,
	fieldKey,
}: InternalPlayFieldProps) => {
	const handleShow = useCallback(() => {
		onClick(localIssueId, playId, fieldKey);
	}, [onClick, localIssueId, playId, fieldKey]);

	if (totalContributors === 0 && value === 0) {
		return (
			<Placeholder
				isDisabled={!isEditable}
				onClick={handleShow}
				appearance={appearance}
				showPlaceholderLabel={showPlaceholderLabel}
			/>
		);
	}

	return (
		<StyledButton
			spacing={getSpacing(appearance)}
			onClick={handleShow}
			appearance="subtle"
			isDisabled={!isEditable}
			isCurrentUserContributed={isCurrentUserContributed}
			iconBefore={<LikeIcon size="small" label="vote" />}
		>
			<ValueContainer>{value}</ValueContainer>
		</StyledButton>
	);
};

type PlayFieldProps = {
	appearance: 'list' | 'board';
	localIssueId: LocalIssueId;
	playId: Ari;
	fieldKey: FieldKey;
};

export const PlayField = (props: PlayFieldProps) => {
	const { appearance, localIssueId, playId, fieldKey } = props;

	const openRightSidebarOnPlay = useOpenRightSidebarOnPlay();

	const totalBudget = useGetIdeaPlayTotalBudget(playId, localIssueId);
	const totalContributors = useGetIdeaPlayTotalContributors(playId, localIssueId);

	const currentUserAccountId = useCurrentUserAccountId();
	const currentUserContribution = useGetIdeaPlayUserContribution(
		playId,
		localIssueId,
		currentUserAccountId,
	);

	return (
		<CommonPlayField
			isEditable
			isCurrentUserContributed={!!currentUserContribution}
			totalContributors={totalContributors}
			showPlaceholderLabel="always"
			appearance={appearance}
			value={totalBudget}
			localIssueId={localIssueId}
			playId={playId}
			fieldKey={fieldKey}
			onClick={openRightSidebarOnPlay}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueContainer = styled.div({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
});

type StyledButton = {
	isCurrentUserContributed?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)<StyledButton>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		paddingTop: 0,
		paddingRight: token('space.075', '6px'),
		paddingBottom: 0,
		paddingLeft: token('space.050', '4px'),
		fontWeight: token('font.weight.semibold'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ isCurrentUserContributed }) =>
			isCurrentUserContributed
				? `${token('color.icon.brand', B400)} !important`
				: `${token('color.text.subtlest', N200)} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: ({ isCurrentUserContributed }) =>
			isCurrentUserContributed
				? `${token('color.background.accent.blue.subtler', 'rgba(179,212,255,0.6)')}`
				: 'transparent',
	},
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.span({
	color: token('color.text.subtlest', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NonHoveredPlaceholderLabel = styled.div<{ isVisible: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isVisible }) => (isVisible ? 'visible' : 'hidden'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderLabel = styled.span({
	color: token('color.text.subtlest', N200),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const HideOnHoverTableCellWrapper = styled.div({
	display: 'inherit',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ShowOnHoverTableCellWrapper = styled.div({
	display: 'none',
});
