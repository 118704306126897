import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	hideToggleLabel: {
		id: 'polaris-component-issue-types-connections-configuration.connections-config-list.hide-toggle-label',
		defaultMessage: 'Hide connection',
		description: 'Label for toggle button',
	},
	showToggleLabel: {
		id: 'polaris-component-issue-types-connections-configuration.connections-config-list.show-toggle-label',
		defaultMessage: 'Show connection',
		description: 'Label for toggle button',
	},
});
