import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.title',
		defaultMessage: 'Manage projects',
		description: 'Title for the roadmap settings sidebar on step of managing projects',
	},
	backButtonLabel: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.back-button-label',
		defaultMessage: 'Back to roadmap settings',
		description:
			'Back button label for the roadmap settings sidebar on a step of managing projects',
	},
	addProjectButtonLabel: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.add-project-button-label',
		defaultMessage: 'Add a project',
		description: 'Label for the button to add a project to the roadmap',
	},
	projectsCountText: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.projects-count-text',
		defaultMessage: 'This roadmap has {projectsCount, plural, one {1 project} other {# projects}}.',
		description:
			'Label for the projects count in the roadmap settings sidebar on step of managing projects',
	},
	searchPlaceholder: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.search-placeholder',
		defaultMessage: 'Search projects',
		description: 'Search placeholder for the roadmap settings sidebar on step of managing projects',
	},
	removeButtonTooltip: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.remove-button-tooltip',
		defaultMessage: 'Remove this project from a roadmap',
		description: 'Tooltip content for the button to remove a project from the roadmap',
	},
	linkToProjectTooltip: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.link-to-project-tooltip',
		defaultMessage: 'Go to the project',
		description: 'Tooltip content for the link to the project',
	},
	removeSuccessFlagMessage: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.remove-success-flag-message',
		defaultMessage: 'We removed "{projectName}" ideas from this roadmap',
		description: 'Flag message for successful project removal',
	},
	errorTitle: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.error-title',
		defaultMessage: 'We couldn’t remove the project',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.error-description',
		defaultMessage: 'Please try again or refresh the page.',
		description: 'Flag description for an error occurring while removing a project from a roadmap.',
	},
	noSearchMatchMessage: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.no-search-match-message',
		defaultMessage: '0 projects match your search.',
		description:
			'Message for the roadmap configuration sidebar on step of adding a project when no projects match the search',
	},
	noProjectsFoundMessage: {
		id: 'polaris-component-collection-configure-sidebar.manage-projects-content.no-projects-found-message',
		defaultMessage: 'We couldn’t find the project you’re looking for.',
		description:
			'Message for the roadmap configuration sidebar on step of adding a project when no projects are found',
	},
});
