import React, { useState } from 'react';
import Select, { type StylesConfig } from '@atlaskit/select';
import { Box, Inline } from '@atlaskit/primitives';
import {
	useEnvironmentContainer,
	PolarisEnvironmentContainerTypes,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';

import { useIssueTypeValuesForProject } from '../../controllers/index.tsx';
import { IssueTypeIcon } from '../issue-type-icon/issue-type-icon.tsx';

type OptionType = {
	label: string;
	value: IssueTypeFieldValue;
};

const IssueTypeLabel = ({ id, name }: { id: string; name: string }) => (
	<Inline alignBlock="center" space="space.100">
		<IssueTypeIcon issueTypeId={id} />
		<Box>{name}</Box>
	</Inline>
);

type Props = {
	onChange: (issueType: IssueTypeFieldValue) => void;
	readonly?: boolean;
	predefinedValue?: IssueTypeFieldValue | null;
};

export const IssueTypeFormSelect = ({ readonly, onChange, predefinedValue }: Props) => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;
	const issueTypeValues = useIssueTypeValuesForProject({ projectId });
	const options = issueTypeValues.map((issueType) => ({
		label: issueType.name,
		value: issueType,
	}));
	const [value, setValue] = useState<OptionType | null>(
		predefinedValue ? { label: predefinedValue.name, value: predefinedValue } : null,
	);

	return (
		<Select
			options={options}
			value={value}
			onChange={(option) => {
				if (!option) {
					return;
				}

				setValue(option);
				onChange(option.value);
			}}
			isClearable={false}
			isDisabled={readonly}
			styles={styles}
			formatOptionLabel={({ value: { id, name } }) => <IssueTypeLabel id={id} name={name} />}
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			menuPortalTarget={document.body}
		/>
	);
};

const styles: StylesConfig<OptionType> = {
	menuPortal: (base) => ({
		...base,
		zIndex: layers.modal,
	}),
	control: (base) => ({
		...base,
		width: '300px',
	}),
};
