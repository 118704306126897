import React from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import { GoalsField } from '@atlassian/jira-polaris-common/src/ui/fields/goals/main.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { NoValueContainer } from '../../../common/board-header-column/styled.tsx';
import messages from './messages.tsx';

type ColumnHeaderComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const GoalsColumnHeader = ({ fieldKey, groupIdentity }: ColumnHeaderComponentProps) => (
	<GoalsField isEditable={false} fieldKey={fieldKey} value={[groupIdentity]} onUpdate={noop} />
);

const EmptyGoalsColumnHeader = () => {
	const { formatMessage } = useIntl();

	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};

export const goalsColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: GoalsColumnHeader,
	EmptyComponent: EmptyGoalsColumnHeader,
} as const;
