import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { sanitizeUrl } from '@braintree/sanitize-url';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { EmojiProvider } from '@atlaskit/emoji';
import LinkIcon from '@atlaskit/icon/glyph/link';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useCanManageInsights } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type {
	SnippetPropertiesObject,
	SnippetRefreshStatus,
	SnippetData,
} from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import { validSnippetActions } from '../../common/utils/snippet/index.tsx';
import { isAuthRefreshError } from '../common/refresh/index.tsx';
import { Card } from './card/index.tsx';
import { isContentLessCard } from './card/utils.tsx';
import { DropdownActions } from './dropdown-actions/index.tsx';
import { Messages } from './messages/index.tsx';
import { Properties, isPropertiesDefined } from './properties/index.tsx';
import { Quotes } from './quotes/index.tsx';
import { RefreshDropdownItem, PolarisSnippetRefreshIndicator } from './refresh/index.tsx';

type PolarisSnippetContextProps = {
	contentLessCard: boolean;
	snippetData: SnippetData;
	refresh?: SnippetRefreshStatus | null;
	updated: string | null;
	showContextActions: boolean;
	showRefreshIndicator: boolean;
	onSnippetRefreshRequested?: () => void;
	onConfigureProperties?: () => void;
};

const canConfigureSnippet = ({
	snippetData,
	refresh,
}: {
	snippetData: SnippetData;
	refresh?: SnippetRefreshStatus | null;
}) => {
	const { actions } = snippetData;
	const isRefreshable = refresh !== undefined && refresh !== null;
	if ((Array.isArray(actions) && actions.length > 0) || isRefreshable) {
		const validActions = validSnippetActions(actions);
		if (validActions.length > 0) {
			return true;
		}
	}
	return false;
};

export const PolarisSnippetContext = ({
	contentLessCard,
	snippetData,
	updated,
	onConfigureProperties,
	refresh,
	onSnippetRefreshRequested,
	showContextActions,
	showRefreshIndicator,
}: PolarisSnippetContextProps) => {
	const { context, actions: snippetActions } = snippetData;
	const [isHovering, setIsHovering] = useState(false);
	const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
	const isRefreshable = refresh !== undefined && refresh !== null;

	const contextActions = useMemo(() => {
		if ((Array.isArray(snippetActions) && snippetActions.length > 0) || isRefreshable) {
			const validActions = validSnippetActions(snippetActions);
			return [
				...(isHovering || isActionsMenuOpen
					? [
							<DropdownActions
								onConfigureProperties={onConfigureProperties}
								additionalDropdownItems={
									<RefreshDropdownItem
										refresh={refresh}
										updated={updated}
										onSnippetRefreshRequested={onSnippetRefreshRequested}
									/>
								}
								onOpenChange={setIsActionsMenuOpen}
								actions={validActions}
								key="dropdown-actions"
							/>,
						]
					: []),
			];
		}
		return [];
	}, [
		isActionsMenuOpen,
		isHovering,
		isRefreshable,
		onConfigureProperties,
		onSnippetRefreshRequested,
		refresh,
		snippetActions,
		updated,
	]);

	const handleMouseEnter = useCallback(() => {
		setIsHovering(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setIsHovering(false);
	}, []);

	return (
		<Context
			contentLessCard={contentLessCard}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<ContextContentWrapper>
				<Link
					data-testid="polaris-common.ui.snippet.link"
					href={sanitizeUrl(context.url)}
					target="_blank"
					title={context.title}
				>
					<IconWrapper data-testid="polaris-common.ui.snippet.icon-wrapper">
						{context.icon !== undefined ? (
							<Icon src={context.icon} alt={context.title} />
						) : (
							<LinkIcon size="small" label={context.title} />
						)}
					</IconWrapper>
					<Title data-testid="polaris-common.ui.snippet.title">{context.title}</Title>
				</Link>
			</ContextContentWrapper>
			<ContextActionsWrapper>
				<PolarisSnippetRefreshIndicator
					refresh={refresh || undefined}
					updated={updated}
					showRefreshIndicator={showRefreshIndicator}
				/>
				{!isAuthRefreshError(refresh) && showContextActions ? contextActions : null}
			</ContextActionsWrapper>
		</Context>
	);
};

type PolarisSnippetContentProps = {
	data: SnippetData;
	mediaClientConfig: MediaClientConfig;
	dataProviders: ProviderFactory;
};

export const PolarisSnippetContent = ({
	data,
	mediaClientConfig,
	dataProviders,
}: PolarisSnippetContentProps) => {
	switch (data.type) {
		case 'quotes':
			return (
				<Quotes
					content={data.content}
					mediaClientConfig={mediaClientConfig}
					dataProviders={dataProviders}
				/>
			);
		case 'messages':
			return (
				<Messages
					content={data.content}
					mediaClientConfig={mediaClientConfig}
					dataProviders={dataProviders}
				/>
			);
		default:
			return null;
	}
};

type PolarisSnippetPropertiesProps = {
	isReadOnly: boolean;
	icon?: string | undefined;
	properties: SnippetPropertiesObject | null;
	emojiProvider?: Promise<EmojiProvider>;
	refresh?: SnippetRefreshStatus | null;
	updated: string | null;
	onConfigureProperties?: () => void;
	onSnippetRefreshRequested?: () => void;
};

export const PolarisSnippetProperties = ({
	isReadOnly,
	icon,
	properties,
	emojiProvider,
	refresh,
	updated,
	onSnippetRefreshRequested,
	onConfigureProperties,
}: PolarisSnippetPropertiesProps) => (
	<Properties
		isReadOnly={isReadOnly}
		icon={icon}
		properties={properties}
		emojiProvider={emojiProvider}
		refresh={refresh}
		updated={updated}
		onSnippetRefreshRequested={onSnippetRefreshRequested}
		onConfigureProperties={onConfigureProperties}
	/>
);

type PolarisSnippetProps = {
	isReadOnly: boolean;
	data: SnippetData;
	properties: SnippetPropertiesObject | null;
	updated: string | null;
	mediaClientConfig: MediaClientConfig;
	dataProviders: ProviderFactory;
	emojiProvider?: Promise<EmojiProvider>;
	spacingTop?: boolean;
	refresh?: SnippetRefreshStatus | null;
	onSnippetRefreshRequested?: () => void;
	onConfigureProperties?: () => void;
};

export const PolarisSnippet = ({
	isReadOnly,
	data,
	refresh,
	updated,
	properties,
	mediaClientConfig,
	dataProviders,
	emojiProvider,
	spacingTop,
	onSnippetRefreshRequested,
	onConfigureProperties,
}: PolarisSnippetProps) => {
	const canManageInsightProperties = useCanManageInsights();
	const displayContent = useMemo(
		() =>
			(data.content &&
				(!Array.isArray(data.content) ||
					(Array.isArray(data.content) && data.content.length > 0))) ||
			false,
		[data.content],
	);
	const contentLessCard = useMemo(
		() => data.type === 'card' && isContentLessCard(data.content),
		[data.content, data.type],
	);
	return (
		<SnippetContainer data-component-selector="snippet-container-9Ks5">
			<SnippetMarker />
			<Wrapper>
				{spacingTop === true ? <Box xcss={spacingTopStyles} /> : null}
				<PolarisSnippetContext
					contentLessCard={contentLessCard}
					snippetData={data}
					updated={updated}
					showContextActions={false}
					showRefreshIndicator={false}
					onConfigureProperties={onConfigureProperties}
					refresh={refresh}
					onSnippetRefreshRequested={onSnippetRefreshRequested}
				/>
				{data.type === 'card' ? (
					<Card
						content={data.content}
						url={data.context.url}
						mediaClientConfig={mediaClientConfig}
					/>
				) : null}
				{displayContent || (properties && isPropertiesDefined(properties)) ? (
					<ContentContainer
						testId="polaris-common.ui.snippet.content-container"
						contentLessCard={contentLessCard}
					>
						<PolarisSnippetContent
							data={data}
							mediaClientConfig={mediaClientConfig}
							dataProviders={dataProviders}
						/>
					</ContentContainer>
				) : null}
				<PolarisSnippetProperties
					isReadOnly={isReadOnly}
					icon={data.context.icon}
					properties={properties}
					emojiProvider={emojiProvider}
					refresh={refresh}
					updated={updated}
					onSnippetRefreshRequested={onSnippetRefreshRequested}
					onConfigureProperties={
						canManageInsightProperties && canConfigureSnippet({ snippetData: data, refresh })
							? onConfigureProperties
							: undefined
					}
				/>
			</Wrapper>
		</SnippetContainer>
	);
};

PolarisSnippet.defaultProps = {
	properties: null,
	isReadOnly: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
});

const spacingTopStyles = xcss({
	marginTop: 'space.150',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Context = styled.div<{ contentLessCard?: any }>({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderBottom: ({ contentLessCard }) => contentLessCard && 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContextContentWrapper = styled.div({
	flex: '1 1 auto',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContextActionsWrapper = styled.div({
	flex: '0 0 auto',
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginTop: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Icon = styled.img({
	width: '16px',
	height: '16px',
	textIndent: '-9999px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Link = styled.a({
	display: 'flex',
	flexDirection: 'row',
	height: '100%',
	alignItems: 'flex-start',
	wordBreak: 'break-all',
	'&:hover, &:active, &:visited, &:link': {
		outline: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		color: colors.N90,
		textDecoration: 'none',
		boxShadow: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	marginTop: 0,
	marginRight: token('space.075', '6px'),
	marginBottom: 0,
	marginLeft: token('space.075', '6px'),

	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.link', colors.B400),
	whiteSpace: 'normal',
	flexGrow: 1,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div<{ contentLessCard?: any; testId: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingTop: ({ contentLessCard }) => (contentLessCard ? 0 : undefined),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SnippetContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SnippetMarker = styled.div({
	width: '2px',
	backgroundColor: token('color.border', '#dfe1e6'),
	borderRadius: '4px',
	marginRight: token('space.100', '8px'),
	flexShrink: 0,
});
