import React, { useState } from 'react';
import Button from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsConnectionFieldFilterConfigured } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useIsIssueMatchingConnectionFieldFilter } from '../../../controllers/issue/selectors/connection-hooks.tsx';
import { useOpenRightSidebarOnField } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import { ConnectionFieldEditView } from './edit/index.tsx';
import messages from './messages.tsx';
import type { ConnectionFieldProps } from './types.tsx';
import { ConnectionFieldView } from './view/index.tsx';

export const ConnectionField = (props: ConnectionFieldProps) => {
	const { fieldKey, localIssueId, isEditable } = props;
	const { formatMessage } = useIntl();
	const [isEditing, setIsEditing] = useState(false);
	const isMatchingConnectionFieldFilter = useIsIssueMatchingConnectionFieldFilter(
		localIssueId,
		fieldKey,
	);
	const isConnectionFieldFilterConfigured = useIsConnectionFieldFilterConfigured(fieldKey);
	const canEditFields = useCanEditFields();
	const openRightSidebarOnField = useOpenRightSidebarOnField();

	// don't allow connecting issues of the same type
	if (isMatchingConnectionFieldFilter) {
		return null;
	}

	if (!isConnectionFieldFilterConfigured) {
		return (
			canEditFields && (
				<Button onClick={() => openRightSidebarOnField(fieldKey)} appearance="subtle">
					{formatMessage(messages.configureFieldButtonLabelNonFinal)}
				</Button>
			)
		);
	}

	return isEditing && isEditable ? (
		<ConnectionFieldEditView {...props} onClose={() => setIsEditing(false)} />
	) : (
		<ConnectionFieldView {...props} onEdit={() => setIsEditing(true)} />
	);
};
