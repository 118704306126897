import React, {
	useCallback,
	useRef,
	forwardRef,
	useImperativeHandle,
	useEffect,
	useMemo,
} from 'react';
import { styled } from '@compiled/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	Draggable,
	useDroppableEventsCollectionUpdate,
	type DroppableCollectionProps,
} from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { updateCollection } from '@atlassian/jira-polaris-lib-dnd/src/ui/utils.tsx';
import { assertUnreachable } from '@atlassian/jira-polaris-lib-ts-utils/src/index.tsx';
import messages from './messages.tsx';
import type { ItemPosition, ReorderAction } from './option-list-item/drag-handle/index.tsx';
import { OptionListItem } from './option-list-item/index.tsx';

export type DecoratedOption = {
	id: string;
	jiraOptionId: string;
	value: string;
	weight: number;
	emojiId: string | undefined;
	color: string | undefined;
};

export type OptionListApi = {
	scrollToOption: (optionId: string) => void;
};

type OptionListProps = {
	decoratedOptions: DecoratedOption[];
	isReadonly: boolean;
	isDragDisabled: boolean;
	outerSpacing: string;
	isPreview: boolean;
	weightType: 'RATING' | 'NUMBER' | undefined;
	defaultSelectedOptionId?: string;
	selectedOptionId?: string;
	onOptionClick: (option: DecoratedOption, optionElement: HTMLElement) => void;
	onRankOption: (updatedOptionIds: string[]) => void;
	onFieldOptionWeightUpdated?: (optionId: string, updatedWeight: number) => Promise<void>;
};

const OPTION_HEIGHT = 32;

export const OptionList = forwardRef<OptionListApi, OptionListProps>(
	(
		{
			decoratedOptions,
			isReadonly,
			isDragDisabled,
			outerSpacing,
			isPreview,
			weightType,
			defaultSelectedOptionId,
			selectedOptionId,
			onOptionClick,
			onRankOption,
			onFieldOptionWeightUpdated,
		},
		ref,
	) => {
		const { formatMessage } = useIntl();
		const containerRef = useRef<HTMLDivElement>(null);
		const optionsToDisplay = isPreview ? decoratedOptions.slice(0, 10) : decoratedOptions;
		const numberOfHiddenOptions = decoratedOptions.length - optionsToDisplay.length;

		const handleOptionUpdate = useCallback(
			(updatedOptionIds: string[]) => {
				onRankOption(updatedOptionIds);

				sendPendoTrackEvent({
					actionSubjectAndAction: 'option ranked',
					actionSubjectId: 'rankOption',
					source: 'fieldOptionList',
				});
			},
			[onRankOption],
		);

		const handleSort: DroppableCollectionProps<string, string>['onSort'] = useCallback(
			({ updatedCollection }) => {
				handleOptionUpdate(updatedCollection);
			},
			[handleOptionUpdate],
		);

		const rowVirtualizer = useVirtualizer({
			count: optionsToDisplay.length,
			getScrollElement: () => containerRef.current,
			getItemKey: (idx) => optionsToDisplay[idx].id,
			estimateSize: useCallback(() => OPTION_HEIGHT, []),
			overscan: 10,
		});

		useImperativeHandle(
			ref,
			() => ({
				scrollToOption: (optionId) => {
					const index = optionsToDisplay.findIndex((option) => option.id === optionId);

					if (index !== -1) {
						rowVirtualizer.scrollToIndex(index, {
							align: 'end',
						});
					}
				},
			}),
			[optionsToDisplay, rowVirtualizer],
		);

		const defaultSelectedScrollInitializedRef = useRef(false);
		const defaultSelectedInitializedRef = useRef(false);
		useEffect(() => {
			if (defaultSelectedOptionId && !defaultSelectedScrollInitializedRef.current) {
				const scrollIndex = optionsToDisplay.findIndex(({ id }) => id === defaultSelectedOptionId);
				if (scrollIndex !== -1) {
					rowVirtualizer.scrollToIndex(scrollIndex);
				}
				defaultSelectedScrollInitializedRef.current = true;
			}
		}, [defaultSelectedOptionId, optionsToDisplay, rowVirtualizer]);

		const optionIds = useMemo(
			() => decoratedOptions.map((option) => option.id),
			[decoratedOptions],
		);

		useDroppableEventsCollectionUpdate({
			onSort: handleSort,
			collection: optionIds,
			originLabel: 'field-option-configuration',
		});

		const displayHiddenOptionCount = isPreview && numberOfHiddenOptions > 0;

		const handleWeightChange = useCallback(
			(optionId: string, weight: number) => {
				onFieldOptionWeightUpdated?.(optionId, weight);
			},
			[onFieldOptionWeightUpdated],
		);

		const handleOptionClick = useCallback(
			(id: string, optionElement: HTMLElement) => {
				sendPendoTrackEvent({
					actionSubjectAndAction: 'option clicked',
					actionSubjectId: 'openOptionDecorationConfiguration',
					source: 'fieldOptionList',
				});
				return onOptionClick(
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					optionsToDisplay.find((option) => option.id === id)!,
					optionElement,
				);
			},
			[optionsToDisplay, onOptionClick],
		);

		const getPosition = useCallback(
			(index: number): ItemPosition => {
				if (index === 0) {
					return 'FIRST';
				}

				if (index === optionsToDisplay.length) {
					return 'LAST';
				}

				return 'MIDDLE';
			},
			[optionsToDisplay],
		);

		const handleReorder = useCallback(
			(reorderAction: ReorderAction, id: string) => {
				const collection = optionsToDisplay.map((option) => option.id);
				const index = collection.findIndex((optionId) => optionId === id);

				if (index === -1) {
					return;
				}

				switch (reorderAction) {
					case 'MOVE_TO_TOP':
						handleOptionUpdate(
							updateCollection({
								srcIdx: index,
								dstIdx: 0,
								sourceItem: id,
								collection,
							}),
						);
						break;
					case 'MOVE_UP':
						handleOptionUpdate(
							updateCollection({
								srcIdx: index,
								dstIdx: index - 1,
								sourceItem: id,
								collection,
							}),
						);
						break;
					case 'MOVE_DOWN':
						handleOptionUpdate(
							updateCollection({
								srcIdx: index,
								dstIdx: index + 1,
								sourceItem: id,
								collection,
							}),
						);
						break;
					case 'MOVE_TO_BOTTOM':
						handleOptionUpdate(
							updateCollection({
								srcIdx: index,
								dstIdx: collection.length - 1,
								sourceItem: id,
								collection,
							}),
						);
						break;
					default:
						assertUnreachable(reorderAction);
				}
			},
			[handleOptionUpdate, optionsToDisplay],
		);

		return (
			<OptionListContainer isListEmpty={optionsToDisplay.length === 0}>
				<OptionListScrollContainer
					ref={containerRef}
					outerSpacing={outerSpacing}
					data-testid="polaris-lib-field-option-configuration.ui.option-list.option-list-scroll-container"
				>
					<List height={rowVirtualizer.getTotalSize()}>
						{rowVirtualizer.getVirtualItems().map(({ key: id, start, index }) => (
							<OptionWrapper key={id} start={start}>
								<Draggable id={id} isDragDisabled={isDragDisabled}>
									<OptionListItem
										key={optionsToDisplay[index].id}
										id={optionsToDisplay[index].id}
										isReadonly={isReadonly}
										isDragDisabled={isDragDisabled}
										isSelected={selectedOptionId === optionsToDisplay[index].id}
										label={optionsToDisplay[index].value}
										weight={optionsToDisplay[index].weight}
										weightType={weightType}
										emojiId={optionsToDisplay[index].emojiId}
										color={optionsToDisplay[index].color}
										outerSpacing={outerSpacing}
										position={getPosition(index)}
										onWeightChange={handleWeightChange}
										onClick={handleOptionClick}
										onReorder={handleReorder}
										defaultSelected={defaultSelectedOptionId === optionsToDisplay[index].id}
										defaultSelectedInitializedRef={defaultSelectedInitializedRef}
									/>
								</Draggable>
							</OptionWrapper>
						))}
					</List>
				</OptionListScrollContainer>
				{displayHiddenOptionCount && (
					<Text size="small">
						{formatMessage(messages.moreOptionsInPreview, {
							number: numberOfHiddenOptions,
						})}
					</Text>
				)}
			</OptionListContainer>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionListContainer = styled.div<{ isListEmpty: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gap: ({ isListEmpty }) => (isListEmpty ? undefined : token('space.100', '8px')),
	flexDirection: 'column',
	width: '100%',
	minHeight: 0,
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionListScrollContainer = styled.div<{ outerSpacing: string }>({
	overflowY: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ outerSpacing }) => `0 -${outerSpacing}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const List = styled.div<{ height: number }>({
	boxSizing: 'border-box',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	width: '100%',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div<{ start: number }>({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ start }) => `${start}px`,
	left: 0,
	width: '100%',
});
