import React, { useState } from 'react';
import uniq from 'lodash/uniq';
import Button from '@atlaskit/button/new';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { Grid, Stack, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useCollectionsActions } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import {
	useActions as useProjectMetadataActions,
	useProjectsKeysForContainer,
} from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { ignoreEscapePress } from '@atlassian/jira-polaris-lib-escape-keypress-utils/src/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { RightSidebarBody } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/body/index.tsx';
import { RightSidebarFooter } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/footer/index.tsx';
import { RightSidebarHeader } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/header/index.tsx';
import { SIDEBAR_MANAGE_PROJECTS_CONTENT_TYPE } from '../../common/constants.tsx';
import type { SidebarContentType } from '../../common/types.tsx';
import { useActions, useCollectionUUID } from '../../controllers/index.tsx';
import { AddProjectsSidebarBody } from './body/index.tsx';
import messages from './messages.tsx';

type Props = {
	onContentChange: (content: SidebarContentType | undefined) => void;
};

export const AddProjectSidebarContent = ({ onContentChange }: Props) => {
	const { formatMessage } = useIntl();
	const [projectsToAdd, setProjectsToAdd] = useState<ProjectMetadata[]>([]);
	const [isProjectsAdding, setIsProjectsAdding] = useState<boolean>(false);
	const { errorWithRefresh } = useNotifications();
	const collectionUUID = useCollectionUUID();
	const usedProjectsKeys = useProjectsKeysForContainer({ containerId: collectionUUID });
	const { onClose } = useActions();
	const { updateCollection } = useCollectionsActions();
	const { addProjectsMetadata } = useProjectMetadataActions();

	const handleGoBack = () => {
		onContentChange(SIDEBAR_MANAGE_PROJECTS_CONTENT_TYPE);
	};

	useCloseOnEscapePress({
		onClose: (event) => {
			if (ignoreEscapePress(event)) {
				return;
			}
			handleGoBack();
		},
	});

	const handleAddProjects = async () => {
		if (!collectionUUID) {
			return;
		}

		setIsProjectsAdding(true);

		try {
			const projectKeys = uniq([...usedProjectsKeys, ...projectsToAdd.map(({ key }) => key)]);

			await updateCollection(collectionUUID, { projectKeys });
			addProjectsMetadata(collectionUUID, projectsToAdd);

			setIsProjectsAdding(false);
			handleGoBack();
		} catch (error) {
			setIsProjectsAdding(false);
			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
			});
		}
	};

	return (
		<>
			<RightSidebarHeader
				title={formatMessage(messages.title)}
				onClose={onClose}
				onBack={handleGoBack}
				backLabel={formatMessage(messages.backButtonLabel)}
			/>
			<RightSidebarBody>
				<Stack xcss={sidebarBodyStyles}>
					<Text>{formatMessage(messages.description)}</Text>
					<AddProjectsSidebarBody
						ignoredProjectsKeys={usedProjectsKeys}
						selectedProjects={projectsToAdd}
						onSelectProjects={setProjectsToAdd}
					/>
				</Stack>
			</RightSidebarBody>
			<RightSidebarFooter>
				<Grid templateColumns="1fr 1fr" columnGap="space.150">
					<Button shouldFitContainer onClick={handleGoBack}>
						{formatMessage(messages.cancelButtonLabel)}
					</Button>
					<Button
						appearance="primary"
						shouldFitContainer
						isLoading={isProjectsAdding}
						isDisabled={projectsToAdd.length === 0}
						onClick={handleAddProjects}
						testId="polaris-component-collection-configure-sidebar.ui.add-project-content.add-button"
					>
						{formatMessage(messages.addButtonLabel)}
					</Button>
				</Grid>
			</RightSidebarFooter>
		</>
	);
};

const sidebarBodyStyles = xcss({ height: '100%' });
