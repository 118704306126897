import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import LockLockedIcon from '@atlaskit/icon/core/migration/lock-locked--lock-filled';
import LockUnlockedIcon from '@atlaskit/icon/core/migration/lock-unlocked--unlock-filled';
import type { NewIconProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCollectionOwnerId } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { useLoadViewAccessAction } from '@atlassian/jira-polaris-component-view-access/src/controllers/hooks/index.tsx';
import { useActions as usePermissionActions } from '@atlassian/jira-polaris-component-view-access/src/controllers/main.tsx';
import {
	useCurrentViewAccess,
	useCurrentViewAccessConfig,
} from '@atlassian/jira-polaris-component-view-access/src/controllers/selectors/index.tsx';
import {
	ViewAccessModal,
	type ViewAccessModalProps,
} from '@atlassian/jira-polaris-component-view-access/src/ui/index.tsx';
import {
	PRINCIPAL_TYPES,
	VIEW_ACCESS_LEVELS,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useActions, useCurrentUser } from '../../../../controllers/index.tsx';
import messages from './messages.tsx';

type PermissionsSettingsButtonProps = {
	collectionUUID: string;
};

export const PermissionsSettingsButton = ({ collectionUUID }: PermissionsSettingsButtonProps) => {
	const { formatMessage } = useIntl();

	const { isAddingPrincipals, error, failures, skippedAccountFailures } =
		useCurrentViewAccessConfig();

	const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
	const [isPermissionsDataLoading, setIsPermissionsDataLoading] = useState(false);

	const loadCurrentUserViewAccess = useLoadViewAccessAction();

	const {
		loadViewPermissionsConfig,
		updateViewAccessLevel,
		addViewAccessPrincipals,
		removeViewAccessPrincipals,
		changeViewAccessPrincipalRole,
		clearFailures,
		clearSkippedAccountFailures,
	} = usePermissionActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const currentUser = useCurrentUser();

	const collectionOwnerId = useCollectionOwnerId({ collectionUUID });

	const { accessLevel, additionalAccess } = useCurrentViewAccess();
	const { generalDataUpdateFailedError } = useErrorHandlers();

	const { onAfterSelfAccessPermissionDelete } = useActions();

	useEffect(() => {
		if (!collectionUUID) {
			return;
		}

		loadViewPermissionsConfig({ viewUUID: collectionUUID, createAnalyticsEvent });
	}, [collectionUUID, createAnalyticsEvent, loadViewPermissionsConfig]);

	const handleCloseModal = useCallback(() => {
		setIsPermissionsModalOpen(false);
		clearFailures();
		clearSkippedAccountFailures();
	}, [clearSkippedAccountFailures, clearFailures]);

	const handleOpenModal = useCallback(
		async (
			_e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			if (!collectionUUID) {
				return;
			}

			fireUIAnalytics(analyticsEvent, 'roadmapAccess', {
				collectionUUID,
			});

			setIsPermissionsDataLoading(true);
			setIsPermissionsModalOpen(true);

			const [_, currentUserResult] = await Promise.all([
				loadViewPermissionsConfig({ viewUUID: collectionUUID, createAnalyticsEvent }),
				loadCurrentUserViewAccess?.(collectionUUID, true),
			]);

			if (currentUserResult?.state === 'no-access') {
				handleCloseModal();
			}

			setIsPermissionsDataLoading(false);
		},
		[
			collectionUUID,
			loadViewPermissionsConfig,
			createAnalyticsEvent,
			loadCurrentUserViewAccess,
			handleCloseModal,
		],
	);

	const handleUpdateAccessLevel = useCallback(
		async (newAccessLevel: ViewAccessLevel) => {
			if (!collectionUUID) {
				return;
			}

			await updateViewAccessLevel({
				viewUUID: collectionUUID,
				accessLevel: newAccessLevel,
				createAnalyticsEvent,
				onActionFailed: generalDataUpdateFailedError,
			});

			loadCurrentUserViewAccess?.(collectionUUID, true);
		},
		[
			collectionUUID,
			updateViewAccessLevel,
			createAnalyticsEvent,
			generalDataUpdateFailedError,
			loadCurrentUserViewAccess,
		],
	);

	const handleAddPrincipals: ViewAccessModalProps['onAddPrincipals'] = useCallback(
		async ({ principals, role }) => {
			if (!collectionUUID) {
				return;
			}

			await addViewAccessPrincipals({
				viewUUID: collectionUUID,
				principals,
				role,
				createAnalyticsEvent,
				onActionFailed: generalDataUpdateFailedError,
			});
		},
		[collectionUUID, addViewAccessPrincipals, createAnalyticsEvent, generalDataUpdateFailedError],
	);

	const handleRoleChange: ViewAccessModalProps['onPrincipalRoleChange'] = useCallback(
		async ({ name, id, role, type, avatarUrl }) => {
			if (!collectionUUID) {
				return;
			}

			await changeViewAccessPrincipalRole({
				viewUUID: collectionUUID,
				name,
				id,
				role,
				type,
				avatarUrl,
				createAnalyticsEvent,
				onActionFailed: generalDataUpdateFailedError,
			});

			if (type === PRINCIPAL_TYPES.GROUP || currentUser === id) {
				const result = await loadCurrentUserViewAccess?.(collectionUUID, true);
				if (result?.state === 'no-access') {
					handleCloseModal();
				}
			}
		},
		[
			collectionUUID,
			changeViewAccessPrincipalRole,
			createAnalyticsEvent,
			generalDataUpdateFailedError,
			currentUser,
			loadCurrentUserViewAccess,
			handleCloseModal,
		],
	);

	const handleRemoveAccess: ViewAccessModalProps['onRemovePrincipals'] = useCallback(
		async ({ accounts, groups }) => {
			if (!collectionUUID) {
				return;
			}

			await removeViewAccessPrincipals({
				viewUUID: collectionUUID,
				accounts,
				groups,
				createAnalyticsEvent,
				onActionFailed: generalDataUpdateFailedError,
			});

			if (groups.length || (currentUser && accounts.includes(currentUser))) {
				const result = await loadCurrentUserViewAccess?.(collectionUUID, true);
				if (result?.state === 'no-access') {
					handleCloseModal();
					onAfterSelfAccessPermissionDelete?.();
				}
			}
		},
		[
			collectionUUID,
			removeViewAccessPrincipals,
			createAnalyticsEvent,
			generalDataUpdateFailedError,
			currentUser,
			loadCurrentUserViewAccess,
			handleCloseModal,
			onAfterSelfAccessPermissionDelete,
		],
	);

	const roadmapAccessIcon = useMemo(() => {
		switch (accessLevel) {
			case VIEW_ACCESS_LEVELS.OPEN:
				return LockUnlockedIcon;
			case VIEW_ACCESS_LEVELS.LIMITED:
				return LockLockedIcon;
			case VIEW_ACCESS_LEVELS.RESTRICTED:
				return (iconProps: Omit<NewIconProps, 'spacing'>) => (
					<LockLockedIcon {...iconProps} color={token('color.icon.accent.red')} />
				);
			default:
				return LockLockedIcon;
		}
	}, [accessLevel]);

	const nonEditablePrincipalIds = useMemo(
		() => (collectionOwnerId ? [collectionOwnerId] : []),
		[collectionOwnerId],
	);

	return (
		<>
			<IconButton
				appearance="subtle"
				label={formatMessage(messages.roadmapAccess)}
				isTooltipDisabled={false}
				onClick={handleOpenModal}
				icon={roadmapAccessIcon}
			/>
			<ViewAccessModal
				editingMode="roadmap"
				nonEditablePrincipalIds={nonEditablePrincipalIds}
				isOpen={isPermissionsModalOpen}
				isLoading={isPermissionsDataLoading}
				hasError={!!error}
				accessLevel={accessLevel}
				additionalAccess={additionalAccess}
				isUserPickerLoading={isAddingPrincipals}
				canEdit
				hideCopyLinkButton
				currentUserAccountId={currentUser}
				failures={failures}
				skippedAccountFailures={skippedAccountFailures}
				onUpdateAccessLevel={handleUpdateAccessLevel}
				onAddPrincipals={handleAddPrincipals}
				onClose={handleCloseModal}
				onPrincipalRoleChange={handleRoleChange}
				onRemovePrincipals={handleRemoveAccess}
				onClearFailures={clearFailures}
			/>
		</>
	);
};
