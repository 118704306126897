import React, { useCallback, type PropsWithChildren, type ReactElement } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ProfileClient } from '@atlaskit/profilecard';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	useTenantContext,
	type TenantContextSubscriber,
} from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useFabricProfileDirectoryUrl } from '@atlassian/jira-tenant-context-controller/src/components/fabric-profile-directory-url/index.tsx';
import { loadErrorEventAction, profileCardLegacyActionSubject } from '../../common/constants.tsx';
import {
	ProfileClientContainer as ProfileClientContainerOld,
	ProfileClientSubscriber as ProfileClientSubscriberOld,
} from '../../controllers/profileclient-old/index.tsx';
import { ProfileClientContainer } from '../../controllers/profileclient/index.tsx';

export type ProfileCardWrapperPropsOld = {
	TenantContextSubscriber?: typeof TenantContextSubscriber;
	externalProfileClient?: ProfileClient;
	children: (
		atlassianAccountId: string | null,
		profileClient: ProfileClient | null,
		cloudId: string,
		baseUrl: string,
	) => ReactElement;
	analyticsData?: {
		trigger: string;
	};
	onLoadError?: (error: Error, analyticsEvent?: UIAnalyticsEvent) => void;
};

/**
 * @deprecated Cleanup with empanada_nin_concurrent_mode_fixes
 */
const ProfileCardWrapperOldInner = (props: ProfileCardWrapperPropsOld) => {
	const {
		TenantContextSubscriber: TenantContextSubscriberComponent,
		externalProfileClient,
		children,
		onLoadError,
	} = props;

	const cloudId = useCloudId();

	const renderProfileClientContainerWrapper = useCallback(
		(tenantContext?: TenantContext) => {
			if (tenantContext && tenantContext.atlassianAccountId) {
				return (
					<ProfileClientContainerOld
						fabricPfDirUrl={tenantContext.fabricPfDirUrl}
						baseUrl={tenantContext.baseUrl}
						profileClient={externalProfileClient || null}
						onLoadError={onLoadError}
						isGlobal
						cloudId={cloudId}
					>
						<ProfileClientSubscriberOld>
							{({ profileClient }) =>
								children(
									tenantContext.atlassianAccountId,
									profileClient,
									tenantContext.cloudId,
									tenantContext.baseUrl,
								)
							}
						</ProfileClientSubscriberOld>
					</ProfileClientContainerOld>
				);
			}

			// When there is no tenantContext, this will cause the child
			// component of <ProfileCard> to render without being wrapped by
			// a profile card and it will do nothing on hover
			return children('', null, '', '');
		},
		[children, externalProfileClient, onLoadError, cloudId],
	);

	if (TenantContextSubscriberComponent) {
		return (
			<TenantContextSubscriberComponent>
				{({ tenantContext }) => renderProfileClientContainerWrapper(tenantContext)}
			</TenantContextSubscriberComponent>
		);
	}

	// Here we conditionally call hooks which is frowned upon by React.
	// However this is safe as the value of TenantContextSubscriberComponent doesn't change dynamically
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const tenantContext = useTenantContext();

	return renderProfileClientContainerWrapper(tenantContext);
};

ProfileCardWrapperOldInner.defaultProps = {
	externalProfileClient: null,
	sourceName: null,
	createAnalyticsEvent: noop,
};

/**
 * @deprecated Cleanup with empanada_nin_concurrent_mode_fixes
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ProfileCardWrapperOld = AnalyticsEventToProps(profileCardLegacyActionSubject, {
	onLoadError: loadErrorEventAction,
})(ProfileCardWrapperOldInner) as Flow.AbstractComponent<ProfileCardWrapperPropsOld>;

export type ProfileCardWrapperProps = {
	externalProfileClient?: ProfileClient;
	onLoadError?: (error: Error, analyticsEvent?: UIAnalyticsEvent) => void;
};

export const ProfileCardWrapper = ({
	externalProfileClient,
	children,
	onLoadError,
}: PropsWithChildren<ProfileCardWrapperProps>) => {
	const cloudId = useCloudId();
	const accountId = useAccountId();
	const fabricPfDirUrl = useFabricProfileDirectoryUrl();

	if (accountId) {
		return (
			<ProfileClientContainer
				fabricPfDirUrl={fabricPfDirUrl}
				profileClient={externalProfileClient || null}
				onLoadError={onLoadError}
				cloudId={cloudId}
				isGlobal
			>
				{children}
			</ProfileClientContainer>
		);
	}

	// When there is no tenantContext, this will cause the child
	// component of <ProfileCard> to render without being wrapped by
	// a profile card and it will do nothing on hover
	return children;
};
