import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import Select from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { GenericErrorWithRefresh } from '@atlassian/jira-polaris-common/src/common/utils/errors/main.tsx';
import { useCloudId } from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { useAvailableProductsActions } from '@atlassian/jira-polaris-common/src/controllers/available-products/main.tsx';
import {
	useAvailableProductsError,
	useIsAvailableProductsLoading,
	useSitesWithAtlas,
	useSitesWithAvailableProducts,
} from '@atlassian/jira-polaris-common/src/controllers/available-products/selectors/available-products-hooks.tsx';
import {
	useProjectPropertiesActions,
	useProjectProperty,
} from '@atlassian/jira-polaris-common/src/controllers/project-properties/index.tsx';
import { ProjectProperties } from '@atlassian/jira-polaris-common/src/controllers/project-properties/types.tsx';
import type { Site } from '@atlassian/jira-polaris-common/src/services/jira/available-products/types.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import messages from './messages.tsx';

const MAX_ERROR_IMAGE_WIDTH = 370;

type Option = {
	label: string;
	value: Site;
};

const getSiteUrl = (name: string, environment: string) => {
	if (environment === 'staging' || environment === 'dev' || environment === 'local') {
		return `https://${name}.jira-dev.com`;
	}

	return `https://${name}.atlassian.net`;
};

const AtlasSitesSelect = () => {
	const [, { saveProperty }] = useProjectPropertiesActions();
	const cloudId = useCloudId();
	const [, { loadAvailableProducts }] = useAvailableProductsActions();
	const [{ isLoading: isAtlasCloudIdLoading, value: atlasSiteCloudId }] = useProjectProperty(
		ProjectProperties.ATLAS_SITE_CLOUD_ID,
	);

	const changeAtlasSiteCloudId = useCallback(
		(siteCloudId: string) => {
			saveProperty(ProjectProperties.ATLAS_SITE_CLOUD_ID, siteCloudId);
		},
		[saveProperty],
	);

	useEffect(() => {
		loadAvailableProducts();
	}, [loadAvailableProducts]);

	const environment = useEnvironment();
	const [isLoading] = useIsAvailableProductsLoading();
	const [error] = useAvailableProductsError();
	const [sites] = useSitesWithAvailableProducts();
	const [atlasSites] = useSitesWithAtlas();

	const currentSiteOrgId = useMemo(
		() => sites.find((site) => site.cloudId === cloudId)?.org?.id,
		[cloudId, sites],
	);

	const allAtlasSites = useMemo(
		() =>
			atlasSites.map((site) => ({
				label: getSiteUrl(site.displayName, environment),
				value: site,
			})),
		[atlasSites, environment],
	);

	const sitesWithinSameOrg = useMemo(
		() =>
			atlasSites
				.filter((site) => site.org?.id === currentSiteOrgId)
				.map((site) => ({
					label: getSiteUrl(site.displayName, environment),
					value: site,
				})),
		[atlasSites, currentSiteOrgId, environment],
	);

	const defaultValue = useMemo(() => {
		if (atlasSiteCloudId) {
			return allAtlasSites.find((option) => atlasSiteCloudId === option.value.cloudId);
		}
		return allAtlasSites.find((option) => cloudId === option.value.cloudId);
	}, [atlasSiteCloudId, allAtlasSites, cloudId]);

	const [selectedSiteOption, setSelectedSiteOption] = useState(defaultValue);

	useEffect(() => {
		setSelectedSiteOption(defaultValue);
	}, [defaultValue]);

	const onChange = useCallback(
		(item: Option | null) => {
			if (!item) {
				return;
			}
			changeAtlasSiteCloudId(item.value.cloudId);
			setSelectedSiteOption(item);
		},
		[changeAtlasSiteCloudId, setSelectedSiteOption],
	);

	if (isLoading || isAtlasCloudIdLoading) {
		return (
			<SpinnerContainer>
				<Spinner />
			</SpinnerContainer>
		);
	}

	if (error) {
		return (
			<GenericErrorWithRefresh
				error={error}
				onRefresh={loadAvailableProducts}
				maxImageWidth={MAX_ERROR_IMAGE_WIDTH}
			/>
		);
	}

	return (
		<Select
			inputId="atlas-site-select"
			spacing="compact"
			isClearable={false}
			defaultValue={defaultValue}
			value={selectedSiteOption}
			options={sitesWithinSameOrg}
			onChange={onChange}
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			menuPortalTarget={document.body}
			styles={{
				control: (base) => ({
					...base,
					width: 320,
				}),
			}}
		/>
	);
};

export const AtlasConfiguration = () => {
	const { formatMessage } = useIntl();

	return (
		<AtlasConfigWrapper>
			<CaptionContainer>
				<Title>{formatMessage(messages.atlasConnectSite)}</Title>
				<Description>
					<FormattedMessage
						{...messages.atlasHelpMessage}
						values={{
							a: (chunks) => (
								<a
									href="https://support.atlassian.com/jira-product-discovery/docs/integrate-with-atlas/"
									target="_blank"
								>
									{chunks}
								</a>
							),
							link: (chunks) => (
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								<a href={window.location.origin} target="_blank">
									{chunks}
								</a>
							),

							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							currentSite: window.location.origin,
						}}
					/>
				</Description>
			</CaptionContainer>
			<AtlasSitesSelect />
		</AtlasConfigWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AtlasConfigWrapper = styled.div({
	display: 'flex',
	paddingTop: 0,
	paddingRight: token('space.250', '20px'),
	paddingBottom: 0,
	paddingLeft: token('space.250', '20px'),
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.xsmall', fontFallback.heading.xsmall),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	display: 'flex',
	maxHeight: '32px',
	minWidth: '320px',
	justifyContent: 'center',
	paddingTop: token('space.150', '12px'),
	paddingRight: 0,
	paddingBottom: token('space.150', '12px'),
	paddingLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	fontWeight: token('font.weight.regular'),
	lineHeight: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CaptionContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginRight: token('space.400', '32px'),
	alignItems: 'flex-start',
	gap: token('space.100', '8px'),
});
