import React, { useCallback, useState } from 'react';
import Button, { LoadingButton } from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	type KeyboardOrMouseEvent,
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Stack, Text } from '@atlaskit/primitives';
import ProgressBar from '@atlaskit/progress-bar';
import TextField from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import {
	useCollectionName,
	useCollectionsActions,
} from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { CopyToClipboard } from '@atlassian/jira-polaris-lib-copy-to-clipboard/src/ui/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import {
	type UIAnalyticsEvent,
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { ViewsList } from './views-list/index.tsx';

export const CollectionDeleteModal = ({
	collectionUUID,
	onClose,
	onDelete,
	views,
	isLoading = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
	const [confirmedCollectionName, setConfirmedCollectionName] = useState('');
	const { deleteCollection } = useCollectionsActions();
	const collectionName = useCollectionName({ collectionUUID });
	const viewsCount = views.length;

	const handleCloseClick = useCallback(
		(_event: KeyboardOrMouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'close');
			onClose();
		},
		[onClose],
	);

	const handelCancelClick = useCallback(
		(_event: KeyboardOrMouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'cancel');
			onClose();
		},
		[onClose],
	);

	const handleDeleteClick = useCallback(
		async (_event: KeyboardOrMouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'confirm');
			setIsDeleteInProgress(true);

			try {
				await deleteCollection(collectionUUID);
			} catch (error) {
				if (error instanceof Error) {
					errorWithRefresh({
						title: formatMessage(messages.errorTitle),
						description: formatMessage(messages.errorDescription),
					});
				}

				setIsDeleteInProgress(false);
				return;
			}

			onClose();
			onDelete?.();
		},
		[onClose, deleteCollection, collectionUUID, errorWithRefresh, formatMessage, onDelete],
	);

	if (!collectionName) {
		return null;
	}

	return (
		<ModalTransition>
			<ContextualAnalyticsData sourceName="confirmDeleteCollection" sourceType={MODAL}>
				<ModalDialog
					testId="polaris-component-collection-delete-modal.ui.delete-confirmation-modal"
					onClose={handleCloseClick}
				>
					<FireScreenAnalytics />
					<ModalHeader>
						<ModalTitle appearance="danger">
							{formatMessage(messages.header, { roadmapName: collectionName })}
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Stack space="space.200">
							{isLoading ? (
								<ProgressBar isIndeterminate />
							) : (
								<>
									{viewsCount > 0 && (
										<>
											<Text>
												{formatMessage(messages.description, {
													viewsCount,
													b: (text: React.ReactNode) => <b>{text}</b>,
												})}
											</Text>
											<ViewsList collectionUUID={collectionUUID} views={views} />
										</>
									)}
								</>
							)}
							<Stack space="space.100">
								<Text>
									{formatMessage(messages.confirmationTitle, {
										roadmapName: <CopyToClipboard text={collectionName} />,
									})}
								</Text>
								<TextField
									maxLength={255}
									value={confirmedCollectionName}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										setConfirmedCollectionName(event.target.value)
									}
									placeholder={collectionName}
									autoFocus
									isCompact
									testId="polaris-component-collection-delete-modal.ui.view-name-confirmation"
								/>
							</Stack>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button onClick={handelCancelClick}>{formatMessage(messages.cancel)}</Button>
						<LoadingButton
							isDisabled={isLoading || collectionName !== confirmedCollectionName}
							isLoading={isDeleteInProgress}
							onClick={handleDeleteClick}
							appearance="danger"
							testId="polaris-component-collection-delete-modal.ui.delete-button"
						>
							{formatMessage(messages.delete)}
						</LoadingButton>
					</ModalFooter>
				</ModalDialog>
			</ContextualAnalyticsData>
		</ModalTransition>
	);
};
