import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { PRESENTATIONAL_FIELDKEYS } from '@atlassian/jira-polaris-lib-list/src/constants.tsx';

export const COLUMN_DEFAULT_WIDTH = 150;

export const FIELD_KEY_WIDTHS: Record<string, number> = Object.freeze({
	key: 100,
	summary: 400,
	[PRESENTATIONAL_FIELDKEYS.DND]: 28,
	[PRESENTATIONAL_FIELDKEYS.SELECT]: 22,
	[PRESENTATIONAL_FIELDKEYS.ADD]: 250,
});

export const FIELD_TYPE_WIDTHS: { [Type in FieldType]?: number } = Object.freeze({
	[FIELD_TYPES.DATE]: 130,
	[FIELD_TYPES.UPDATED]: 195,
	[FIELD_TYPES.CREATED]: 195,
	[FIELD_TYPES.NUMBER]: 130,
	[FIELD_TYPES.FORMULA]: 130,
	[FIELD_TYPES.SLIDER]: 130,
	[FIELD_TYPES.CHECKBOX]: 130,
	[FIELD_TYPES.RATING]: 130,
	[FIELD_TYPES.INSIGHTS]: 70,
	[FIELD_TYPES.LINKED_ISSUES]: 130,
	[FIELD_TYPES.SHORT_TEXT]: 200,
	[FIELD_TYPES.HYPERLINK]: 200,
	[FIELD_TYPES.LABELS]: 250,
	[FIELD_TYPES.CUSTOM_LABELS]: 250,
	[FIELD_TYPES.ASSIGNEE]: 200,
	[FIELD_TYPES.CREATOR]: 200,
	[FIELD_TYPES.REPORTER]: 200,
	[FIELD_TYPES.PEOPLE]: 205,
	[FIELD_TYPES.JSW_PEOPLE]: 205,
	[FIELD_TYPES.SINGLE_SELECT]: 250,
	[FIELD_TYPES.MULTI_SELECT]: 250,
	[FIELD_TYPES.JSW_MULTI_SELECT]: 250,
	[FIELD_TYPES.DESCRIPTION]: 200,
	[FIELD_TYPES.ISSUE_TYPE]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.ISSUE_KEY]: 100,
	[FIELD_TYPES.STATUS]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.ISSUE_ID]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.DELIVERY_STATUS]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.DELIVERY_PROGRESS]: 200,
	[FIELD_TYPES.ARCHIVED]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.ARCHIVED_BY]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.ARCHIVED_ON]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.ISSUE_COMMENTS]: 70,
	[FIELD_TYPES.VOTES]: 100,
	[FIELD_TYPES.ATLAS_PROJECT]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.ATLAS_GOAL]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.ATLAS_PROJECT_STATUS]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.INTERVAL]: COLUMN_DEFAULT_WIDTH,
	[FIELD_TYPES.REACTIONS]: 200,
	[FIELD_TYPES.PLATFORM_GOALS]: COLUMN_DEFAULT_WIDTH,
});

export const FIELD_TYPE_MIN_WIDTHS: { [Type in FieldType]?: number } = Object.freeze({
	[FIELD_TYPES.SUMMARY]: 100,
	[FIELD_TYPES.NUMBER]: 80,
	[FIELD_TYPES.FORMULA]: 80,
	[FIELD_TYPES.SLIDER]: 75,
	[FIELD_TYPES.CHECKBOX]: 60,
	[FIELD_TYPES.RATING]: 95,
	[FIELD_TYPES.LINKED_ISSUES]: 80,
	[FIELD_TYPES.INSIGHTS]: 80,
});
