import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	deleteIssueTypeLabel: {
		id: 'polaris-component-issue-types-manager.delete-issue-type-label-non-final',
		defaultMessage: 'Delete issue type',
		description: 'Label on an icon button deleting the issue type.',
	},

	addType: {
		id: 'polaris-component-issue-types-manager.add-type-non-final',
		defaultMessage: 'Add type',
		description: 'Label for the button that adds a new issue type.',
	},

	tooltipContent: {
		id: 'polaris-component-issue-types-manager.tooltip-content-non-final',
		defaultMessage: 'Required system type.',
		description:
			'Content of the message for the tooltip component, that wrapps the disabled button responsible for deleting the issue type.',
	},
	deleteTitle: {
		id: 'polaris-component-issue-types-manager.delete-title-non-final',
		defaultMessage: 'Delete issue type',
		description: 'Title for deletion issue type modal dialog',
	},
	deleteInfoMessage: {
		id: 'polaris-component-issue-types-manager.delete-info-message-non-final',
		defaultMessage: 'If you delete the issue type, you won’t be able to retrieve it later.',
		description: 'Info message for deletion issue type modal dialog',
	},
	deleteButton: {
		id: 'polaris-component-issue-types-manager.delete-button-non-final',
		defaultMessage: 'Delete',
		description: 'Button message to confirm deletion in modal dialog.',
	},
	cancelButton: {
		id: 'polaris-component-issue-types-manager.cancel-button-non-final',
		defaultMessage: 'Cancel',
		description: 'Button message to cancel deletion in modal dialog.',
	},
	editWorkflowButtonLabel: {
		id: 'polaris-component-issue-types-manager.edit-workflow-button-label-non-final',
		defaultMessage: 'Edit workflow',
		description: 'Label for button responsible for editing the workflow.',
	},
	change: {
		id: 'polaris-component-issue-types-manager.change-non-final',
		defaultMessage: 'Change',
		description: 'Label for the button opening issue avatar picker.',
	},
	icons: {
		id: 'polaris-component-issue-types-manager.icons-non-final',
		defaultMessage: 'Icons',
		description: 'Title of the section, where a user can pick icons from.',
	},
	enterTypeName: {
		id: 'polaris-component-issue-types-manager.enter-type-name-non-final',
		defaultMessage: 'Enter type name',
		description: 'Placeholder for the text input, where a user enters issue type name.',
	},
	cancelLabel: {
		id: 'polaris-component-issue-types-manager.cancel-label-non-final',
		defaultMessage: 'Cancel',
		description: 'Label for a button responsible for canceling the creation of the new issue type.',
	},
	saveLabel: {
		id: 'polaris-component-issue-types-manager.save-label-non-final',
		defaultMessage: 'Save',
		description: 'Label for a button responsible for saving the new issue type.',
	},
	errorTitle: {
		id: 'polaris-component-issue-types-manager.error-title-non-final',
		defaultMessage: 'Something went wrong.',
		description: 'Title of the error displayed for an error message when a user action fails.',
	},
	errorDescription: {
		id: 'polaris-component-issue-types-manager.error-description-non-final',
		defaultMessage: 'An error occurred. Our team has been notified.',
		description: 'Default description to communicate a general "sent to backlog" error.',
	},
	cantDeleteErrorTitle: {
		id: 'polaris-component-issue-types-manager.cant-delete-error-title-non-final',
		defaultMessage: 'Could not delete.',
		description:
			'Error title displayed when a user attempts to delete an issue type, but the deletion is unsuccessful.',
	},
	cantDeleteErrorDescription: {
		id: 'polaris-component-issue-types-manager.cant-delete-error-description-non-final',
		defaultMessage:
			'There are existing matching issues, that must be changed to another issue type before deleting this issue type.',
		description:
			'Error message displayed when an issue type cannot be deleted because existing issues are still using it.',
	},
});
