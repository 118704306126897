import React, { useState, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorDateIcon from '@atlaskit/icon/core/migration/calendar--editor-date';
import SelectClearIcon from '@atlaskit/icon/core/migration/cross-circle--select-clear';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { renderDateString } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import messages from './messages.tsx';
import type { FuzzyDateProps } from './types.tsx';

export const FuzzyDate = (props: FuzzyDateProps) => {
	const { onClick, onClearDate, triggerProps = {}, placeholder, value: date } = props;
	const [isIconHovered, setHovered] = useState(false);
	const { formatMessage } = useIntl();

	const iconToShow = date ? (
		<div
			onClick={(event: SyntheticEvent) => {
				event.stopPropagation();
				onClearDate();
			}}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			onKeyDown={(event: React.KeyboardEvent) => {
				if (event.key === 'Enter' || event.key === 'Space') {
					event.stopPropagation();
					onClearDate();
				}
				null;
			}}
			role="button"
			tabIndex={0}
		>
			<SelectClearIcon
				color={isIconHovered ? token('color.icon.subtle') : token('color.icon.disabled')}
				LEGACY_primaryColor={isIconHovered ? colors.N400 : colors.N70}
				label={formatMessage(messages.clearDate)}
				LEGACY_size="small"
			/>
		</div>
	) : (
		<EditorDateIcon label="" color={token('color.icon.subtle')} />
	);

	return (
		<Wrapper {...triggerProps}>
			<Button shouldFitContainer onClick={onClick} iconAfter={iconToShow}>
				{date ? renderDateString(date, placeholder) : formatMessage(messages.noDateSet)}
			</Button>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	height: token('space.400', '32px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		font: token('font.body'),
		textAlign: 'left',
		fontWeight: token('font.weight.medium'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'> span:nth-child(2)': {
			margin: 0,
		},
	},
});
