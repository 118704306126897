import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { isFocusOutsideJFE } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { POLARIS_SIDEBAR_TEST_ID } from '../sidebar/index.tsx';

export const shouldPreventClosing = (target?: EventTarget | null) => {
	if (target instanceof HTMLElement) {
		// checking if resizing/opening/closing area of the left sidebar is focused
		const isFocusOnTheRightSidebarResizableHandler =
			target?.dataset?.testid === `${POLARIS_SIDEBAR_TEST_ID}-grab-area` ||
			(target?.parentElement instanceof HTMLElement &&
				target.parentElement.dataset?.testid === `${POLARIS_SIDEBAR_TEST_ID}-resize-button`);

		const isFocusInRightSidebar = target.closest('[data-ds--page-layout--slot="right-sidebar"]');

		const isFocusInTopNavigation = target.closest('[data-ds--page-layout--slot="top-navigation"]');

		// Check if user clicked on any of the nav items in the Jira Navigation sidebar
		const navItem = getWillShowNav4() && target.closest('[role="listitem"]');
		const isFocusInNavV4 = !navItem && target.closest('nav[data-layout-slot="true"]');
		const isFocusInHeaderNavV4 = !navItem && target.closest('header[data-layout-slot="true"]');

		return (
			isFocusOutsideJFE(target) ||
			isFocusOnTheRightSidebarResizableHandler ||
			isFocusInRightSidebar ||
			isFocusInTopNavigation ||
			isFocusInNavV4 ||
			isFocusInHeaderNavV4
		);
	}
	return false;
};

export const isNavigationViewItemAnchorClicked = (target?: EventTarget | null): boolean => {
	if (!isHTMLElement(target)) {
		return false;
	}

	if (isFocusOutsideJFE(target)) {
		return false;
	}

	const navItem = target.closest('[data-component-selector="navigation-view-item"]');
	if (!navItem) {
		return false;
	}

	if (isAnchor(target) && isAnchorValid(target)) {
		return true;
	}

	const closestParent = target.closest('a');
	if (closestParent && isAnchorValid(closestParent)) {
		return true;
	}

	return false;
};

function isHTMLElement(el: unknown): el is HTMLElement {
	return el instanceof HTMLElement;
}

function isAnchor(el: HTMLElement): el is HTMLAnchorElement {
	return el.tagName.toUpperCase() === 'A';
}

function isAnchorValid(el: HTMLAnchorElement): boolean {
	return el.getAttribute('href') !== '';
}
