import React, { useMemo, useRef, useState, Fragment } from 'react';
import { styled } from '@compiled/react';
import compact from 'lodash/compact';
import flattenDeep from 'lodash/flattenDeep';
import union from 'lodash/union';
import { Box, xcss } from '@atlaskit/primitives';
import { CreatableSelect } from '@atlaskit/select';
import { N30, N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { MAX_FIELD_LENGTH } from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectLabels } from '../../../../../../controllers/insights/main.tsx';
import messages from './messages.tsx';
import { Tag } from './tag/index.tsx';

type LabelsFieldProps = {
	isEditable: boolean;
	value: string[] | undefined;
	onUpdate: (arg1: string[]) => void;
};

type ReadViewProps = {
	isEditable: boolean;
	value: string[] | undefined;
	onClick: () => void;
};

type EditViewProps = {
	value: string[] | undefined;
	onUpdate: (arg1: string[]) => void;
};

const ReadView = ({ value, isEditable, onClick }: ReadViewProps) => {
	const { formatMessage } = useIntl();

	if (isEditable && (value === undefined || value.length === 0)) {
		return (
			<ReadViewContainer onClick={onClick}>
				<MultiValueContainer isEditable={isEditable}>
					<AddLabelSpan data-testid="polaris-ideas.ui.insights.insights.insight.properties.labels.add-label-span">
						{formatMessage(messages.none)}
					</AddLabelSpan>
				</MultiValueContainer>
			</ReadViewContainer>
		);
	}

	return (
		<ReadViewContainer
			onClick={onClick}
			data-testid="polaris-ideas.ui.insights.insights.insight.properties.labels.read-view-container"
		>
			<MultiValueContainer isEditable={isEditable}>
				{value &&
					value.map((label, index) => (
						// eslint-disable-next-line react/jsx-key
						<Fragment {...(fg('concurrent-rendering-fix-unique-list-key') ? { key: label } : {})}>
							{index > 0 && <Box xcss={spacerStyles} />}
							<Tag key={label} value={label} />
						</Fragment>
					))}
			</MultiValueContainer>
		</ReadViewContainer>
	);
};

const customSelectStyles = {
	// @ts-expect-error - TS7006 - Parameter 'base' implicitly has an 'any' type.
	menu: (base) => ({ ...base, zIndex: layers.modal }),
} as const;

const EditView = ({ value, onUpdate }: EditViewProps) => {
	const { formatMessage } = useIntl();

	const projectLabels = useProjectLabels().map((labelUsage) => ({
		label: labelUsage.label,
		value: labelUsage.label,
	}));

	const labelsExternal = useMemo(() => {
		if (value !== undefined) {
			return value.map((label) => ({
				label,
				value: label,
			}));
		}
		return undefined;
	}, [value]);

	const [currentValue, setCurrentValue] = useState(labelsExternal);
	const [options, setOptions] = useState(union(projectLabels, labelsExternal || []));

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChange = (newValue: any) => {
		setCurrentValue(newValue);
		if (newValue !== null) {
			// @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
			onUpdate(newValue.map((v) => v.value));
		} else {
			onUpdate([]);
		}
	};

	const handleCreate = (inputValue: string) => {
		const newOption = {
			label: inputValue,
			value: inputValue,
		};
		setOptions(flattenDeep(compact([options, newOption])));
		const newValue = flattenDeep(compact([currentValue, newOption]));
		setCurrentValue(newValue);
		onUpdate(newValue.map((v) => v.value));
	};

	const containerRef = useRef<HTMLDivElement>(null);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<EditViewContainer
			ref={containerRef}
			data-testid="polaris-ideas.ui.insights.insights.insight.properties.labels.edit-view-container"
		>
			<CreatableSelect
				testId="polaris-ideas.ui.insights.insights.insight.properties.labels.creatable-select"
				isMulti
				isSearchable
				isClearable
				isValidNewOption={(newValue: string | undefined) =>
					newValue !== undefined && newValue.length > 0 && newValue.length <= MAX_FIELD_LENGTH
				}
				value={currentValue}
				onChange={handleChange}
				onCreateOption={(x: string) => handleCreate(x)}
				onFocus={() => {
					fireUIAnalytics(
						createAnalyticsEvent({ action: 'focussed', actionSubject: 'input' }),
						'insightLabels',
					);
				}}
				onBlur={() => {
					fireUIAnalytics(
						createAnalyticsEvent({ action: 'blurred', actionSubject: 'input' }),
						'insightLabels',
					);
				}}
				autoFocus
				defaultMenuIsOpen
				options={options}
				placeholder={formatMessage(messages.placeholder)}
				styles={customSelectStyles}
				menuPlacement="auto"
				spacing="compact"
			/>
		</EditViewContainer>
	);
};

export const Labels = (props: LabelsFieldProps) => {
	const { value, isEditable, onUpdate } = props;
	const [isActive, setActive] = useState(false);
	const sortedValue = value !== undefined ? value.sort() : undefined;
	const onBlur = () => {
		setActive(false);
	};
	const isInEditView = isActive && isEditable;

	return (
		<Container onBlur={onBlur}>
			{!isInEditView && (
				<ReadView value={sortedValue} isEditable={isEditable} onClick={() => setActive(true)} />
			)}
			{isInEditView && <EditView value={sortedValue} onUpdate={onUpdate} />}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	minWidth: '200px',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	paddingLeft: token('space.050', '4px'),
	borderRadius: '4px',
	'&:hover': {
		backgroundColor: token('color.background.input.hovered', N30),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiValueContainer = styled.div<{ isEditable: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => isEditable && { cursor: 'pointer' },
	{
		paddingTop: token('space.050', '4px'),
		paddingRight: 0,
		paddingBottom: token('space.050', '4px'),
		paddingLeft: 0,
		display: 'flex',
		flexWrap: 'wrap',
		font: token('font.body'),
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddLabelSpan = styled.span({
	marginRight: token('space.050', '4px'),
	color: token('color.text.subtlest', N200),
});

const spacerStyles = xcss({
	marginRight: 'space.050',
});
