import React, { useCallback, useState, useEffect } from 'react';
import { useWorkflowActions } from '@atlassian/jira-polaris-common/src/controllers/workflow/main.tsx';
import { getIssueTypeExternalUuid } from '@atlassian/jira-polaris-common/src/services/jira/issue-type/index.tsx';
import { useProjectIdUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypeIdForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { AsyncWorkflowEditor } from '@atlassian/jira-workflow-editor/src/async.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';

export const PolarisWorkflowEditor = () => {
	const [issueTypeUuid, setIssueTypeUuid] = useState<string | undefined>(undefined);
	const { loadStatuses } = useWorkflowActions();
	const { goBack } = useRouterActions();

	const projectId = useProjectIdUnsafe();
	const issueTypeId = useIssueTypeIdForProject({ projectId });

	const onClose = useCallback(() => {
		goBack();
	}, [goBack]);

	const onSuccess = useCallback(() => {
		loadStatuses();
	}, [loadStatuses]);

	useEffect(() => {
		if (issueTypeUuid === undefined && issueTypeId !== undefined) {
			getIssueTypeExternalUuid(issueTypeId, projectId).then(setIssueTypeUuid);
		}
	}, [issueTypeUuid, issueTypeId, projectId]);

	if (issueTypeUuid === undefined || issueTypeId === undefined) {
		return null;
	}

	return <AsyncWorkflowEditor issueTypeId={issueTypeId} onClose={onClose} onSuccess={onSuccess} />;
};
