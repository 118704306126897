import React, { memo, useCallback } from 'react';
import Button from '@atlaskit/button/new';
import { Flex } from '@atlaskit/primitives';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useHasViewTemporaryData } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { ConfigExtra as ConfigExtraLegacy } from './legacy.tsx';
import { messages } from './messages.tsx';
import { SaveViewButton } from './save-view-button/index.tsx';

const _ConfigExtraNext = () => {
	const { formatMessage } = useIntl();
	const { clearViewTemporaryData } = useViewActions();
	const isResetButtonVisible = useHasViewTemporaryData();

	const handleReset = useCallback(() => {
		clearViewTemporaryData();
	}, [clearViewTemporaryData]);

	return (
		<Flex
			testId="polaris-ideas.ui.view-controls.config-extra"
			justifyContent="start"
			alignItems="center"
			gap="space.075"
		>
			<SaveViewButton />
			{isResetButtonVisible && (
				<Button
					id="polaris-ideas.ui.view-controls.unsaved-changes.reset"
					appearance="subtle"
					onClick={handleReset}
				>
					{formatMessage(messages.resetButton)}
				</Button>
			)}
		</Flex>
	);
};

const ConfigExtraNext = componentWithFG(
	'polaris_idea_list_performance_improvements',
	memo(_ConfigExtraNext),
	_ConfigExtraNext,
);

export const ConfigExtra = componentWithFG(
	'polaris_just-for-you',
	ConfigExtraNext,
	ConfigExtraLegacy,
);
