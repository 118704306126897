import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	titleLegacy: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.header.title',
		defaultMessage: 'Add ideas',
		description: 'Header title for ideas bucket',
	},
	title: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.header.title-generic-non-final',
		defaultMessage: 'Add work items',
		description: 'Header title for issues bucket',
	},
	searchPlaceholder: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.header.search-placeholder',
		defaultMessage: 'Search',
		description: 'Search field placeholder',
	},
	descriptionLegacy: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.header.description',
		defaultMessage: 'Drag ideas to add them to the view',
		description: 'Description of ideas bucket functionality',
	},
	description: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.header.description-generic-non-final',
		defaultMessage: 'Drag work items to add them to the view',
		description: 'Description of ideas bucket functionality',
	},
});
