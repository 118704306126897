import { B50 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { useMessageContext } from '@post-office/message-context';
import { type ComponentProps } from 'react';

import { Actions } from './components/actions';

export type Props = {
	bodyContent: React.ReactNode;
	onDismissed?: (messageInstanceId: string) => void;
	whyAmISeeingThisModalContent?: ComponentProps<typeof Actions>['whyAmISeeingThisModalContent'];
	extraAnalyticsAttributes?: Record<string, string>;
};

const jiraSideNavMessageStyle = css({
	position: 'relative',
	display: 'flex',
	borderRadius: token('border.radius.100', '8px'),
	backgroundColor: token('color.background.accent.blue.subtlest', B50),
	paddingInline: token('space.200', '16px'),
	paddingBlock: token('space.150', '12px'),
	zIndex: 0,
});

export const JiraSideNavMessage = ({
	onDismissed,
	whyAmISeeingThisModalContent,
	bodyContent,
}: Props) => {
	const { messageInstanceId } = useMessageContext();
	if (!messageInstanceId) {
		throw new Error('messageInstanceId is required');
	}
	return (
		<div data-testid="jira-side-nav-message" css={jiraSideNavMessageStyle}>
			{bodyContent}
			<Actions
				whyAmISeeingThisModalContent={whyAmISeeingThisModalContent}
				onDismissed={() => onDismissed?.(messageInstanceId)}
			/>
		</div>
	);
};
