import { ConfluenceIcon } from '@atlaskit/logo';
import { JiraSideNavFirstPartyRecommendation } from '@post-office/components--jira-side-nav-first-party-recommendation';
// @ts-expect-error: 'React' is declared but its value is never read
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { createRender } from './types';

const CCP_PRODUCT_ID = '6aab3f4e-cc71-474d-8d28-abef8b057808';
const HAMS_PRODUCT_ID = 'confluence.ondemand';
const PRODUCT_NAME = 'Confluence';

const whyAmISeeingThisModalContent = {
	message: (
		<p>
			<FormattedMessage
				id="growth.recommendation-confluence.reasons-modal.message"
				defaultMessage="We think {confluence} will improve your team's productivity. The recommendation is based on:"
				description="Explanation of why we think the Confluence recommendation will be relevant to the customer."
				values={{ confluence: <strong>{PRODUCT_NAME}</strong> }}
			/>
		</p>
	),
};

export type Props = {
	onDismissed: (messageInstanceId: string) => void;
};

export const render = createRender(({ onDismissed }: Props) => (
	<JiraSideNavFirstPartyRecommendation
		app={{
			hamsIdentifier: HAMS_PRODUCT_ID,
			ccpProductId: CCP_PRODUCT_ID,
			logo: <ConfluenceIcon appearance="brand" />,
			header: (
				<FormattedMessage
					id="growth.recommendation-confluence.product-name.header"
					description="Header for Confluence recommendation message in Jira Side Nav"
					defaultMessage="Collaborate on pages in real-time"
				/>
			),
			ctaMessage: (
				<FormattedMessage
					id="growth.recommendation-confluence.cta.label"
					description="CTA text for Confluence recommendation message in Jira Side Nav"
					defaultMessage="Try Confluence"
				/>
			),
		}}
		onDismissed={onDismissed}
		whyAmISeeingThisModalContent={whyAmISeeingThisModalContent}
	/>
));

export default render;
