import React, { memo, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useLocalIssueIdsByGroupIdentity } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import { useIdeasInCreation } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	IssueCreateGroupFromLocationTop,
	IssueCreateGroupTypeEmpty,
	IssueCreateGroupTypeSpecified,
} from '@atlassian/jira-polaris-common/src/controllers/issue/types.tsx';
import { useCurrentViewVerticalGroupBy } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { RowGroupRendererComponentProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
// TODO make ColumnHeader a common component (not bound to board)
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIsRankingAllowedDuringCreation } from '../../../utils.tsx';
import { useExtendedVerticalGroupOptions } from '../../common/utils/vertical-group-options.tsx';
import { ColumnHeader, type ColumnHeaderProps } from '../../idea-board/column/header/index.tsx';
import { useCanCreateIdeasWhenGrouping } from '../utils/can-create-ideas.tsx';
import { messages } from './messages.tsx';

const useIdeaCountOfGroup = (groupId: string | undefined, fieldKey: string | undefined): number => {
	const groupedIds = useLocalIssueIdsByGroupIdentity(fieldKey);
	const ideasInCreation = useIdeasInCreation();

	return useMemo(() => {
		const ids = groupId !== undefined ? groupedIds.groups[groupId] : groupedIds.empty;
		return ids?.filter((id) => !ideasInCreation.includes(id)).length || 0;
	}, [groupId, groupedIds.empty, groupedIds.groups, ideasInCreation]);
};

const RowGroupLegacy = ({
	groupId,
	isExporting,
	onExpandGroup,
}: RowGroupRendererComponentProps) => {
	const { formatMessage } = useIntl();
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const extendedOptions = useExtendedVerticalGroupOptions(verticalGroupByField);
	const canCreateIdeasWhenGrouping = useCanCreateIdeasWhenGrouping({
		isExporting,
		fieldKey: verticalGroupByField?.key,
	});
	const isRankingAllowedDuringCreation = useIsRankingAllowedDuringCreation();
	const ideaCountOfGroup = useIdeaCountOfGroup(groupId, verticalGroupByField?.key);
	const { createIssue } = useIssueActions();

	const handleAddClick: NonNullable<ColumnHeaderProps['onAddClick']> = useCallback(
		({ groupIdentity, fieldKey }) => {
			experience.listView.createIdeaInline.start();
			experience.listView.createIdeaInlineClickResponse.start();

			onExpandGroup?.();

			const extendedOption = extendedOptions.find(
				(option) => option.groupIdentity === groupIdentity,
			);

			const isEmptyGroup = groupIdentity === '' && fieldKey.length > 0;
			createIssue(
				0,
				undefined,
				isEmptyGroup
					? {
							groupType: IssueCreateGroupTypeEmpty,
							fromLocation: IssueCreateGroupFromLocationTop,
							rankingAllowed: isRankingAllowedDuringCreation,
						}
					: {
							groupType: IssueCreateGroupTypeSpecified,
							groupIdentity,
							fieldKey,
							fieldValue: extendedOption?.value,
							fromLocation: IssueCreateGroupFromLocationTop,
							rankingAllowed: isRankingAllowedDuringCreation,
						},
			);
		},
		[createIssue, extendedOptions, isRankingAllowedDuringCreation, onExpandGroup],
	);

	if (!verticalGroupByField) {
		return null;
	}

	return (
		<ColumnHeader
			isAddVisible={canCreateIdeasWhenGrouping}
			onAddClick={handleAddClick}
			isRow
			isReadOnly
			elemAfter={
				ideaCountOfGroup > 0 ? (
					<IssueCountContainer>
						{formatMessage(messages.issuesCountLabel, {
							issuesCount: ideaCountOfGroup,
						})}
					</IssueCountContainer>
				) : null
			}
			fieldKey={verticalGroupByField.key}
			groupIdentity={groupId}
		/>
	);
};

const RowGroupNext = memo(RowGroupLegacy);

export const RowGroup = componentWithFG(
	'polaris_idea_list_performance_improvements',
	RowGroupNext,
	RowGroupLegacy,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCountContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N400),
});
