import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

export const ProjectsSkeleton = () =>
	Array.from({ length: 6 }).map((_, index) => (
		<Box
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({
				paddingTop: 'space.100',
				paddingBottom: 'space.100',
			})}
			key={index}
		>
			<SkeletonCompiled height="20px" />
		</Box>
	));
