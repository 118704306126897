import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewCollapsedSwimlanes,
	useCurrentViewVerticalGroupBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import {
	GRID_BACKGROUND_COLOR,
	HEADER_HEIGHT,
} from '@atlassian/jira-polaris-lib-timeline/src/common/constants.tsx';
import type { RowGroupProps } from '@atlassian/jira-polaris-lib-timeline/src/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
// TODO make ColumnHeader a common component (not bound to board)
import { ColumnHeader } from '../../idea-board/column/header/index.tsx';
import { messages } from './messages.tsx';

export const RowGroup = (props: RowGroupProps) => {
	const {
		children,
		groupIdentity,
		itemsCount,
		isOpenGroup,
		headerContainerRef,
		isPreview = false,
	} = props;

	const { formatMessage } = useIntl();

	const canManageCurrentView = useCanManageCurrentView();
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const collapsedSwimlanes = useCurrentViewCollapsedSwimlanes();
	const { toogleSwimlane } = useViewActions();
	if (verticalGroupByField === undefined) {
		return null;
	}

	const isReadOnly = isPreview || !canManageCurrentView;

	const isVisible =
		isOpenGroup ||
		(collapsedSwimlanes[String(groupIdentity)] === undefined
			? itemsCount > 0
			: collapsedSwimlanes[String(groupIdentity)] === false);

	return (
		<>
			<RowHeaderContainer ref={headerContainerRef}>
				<RowHeaderStickyContainer isExpanded={isVisible}>
					<CollapseButtonContainer>
						<Button
							id="pendo.timeline.row-group-clicked"
							onClick={() => {
								fireCompoundAnalyticsEvent.TimelineGroupToggled(createAnalyticsEvent({}));
								toogleSwimlane(String(groupIdentity), itemsCount === 0);
							}}
							appearance="subtle"
							iconBefore={isVisible ? <ChevronDownIcon label="" /> : <ChevronRightIcon label="" />}
						/>
					</CollapseButtonContainer>
					<ColumnHeader
						isRow
						isReadOnly={isReadOnly}
						elemAfter={
							itemsCount ? (
								<IssueCountContainer>
									{formatMessage(messages.issuesCountLabel, {
										issuesCount: itemsCount,
									})}
								</IssueCountContainer>
							) : null
						}
						fieldKey={verticalGroupByField.key}
						groupIdentity={groupIdentity}
					/>
				</RowHeaderStickyContainer>
			</RowHeaderContainer>
			{!isPreview && isVisible ? <RowContentContainer>{children}</RowContentContainer> : null}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCountContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N400),
	paddingTop: token('space.025', '2px'),
	paddingRight: token('space.025', '2px'),
	paddingBottom: token('space.025', '2px'),
	paddingLeft: token('space.025', '2px'),
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollapseButtonContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyItems: 'center',
	minWidth: '32px',
	minHeight: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowHeaderStickyContainer = styled.div<{ isExpanded: boolean }>({
	position: 'sticky',
	display: 'inline-flex',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > div:last-child': {
		paddingLeft: token('space.050', '4px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isExpanded }) =>
		isExpanded
			? `${token('space.100', '8px')} ${token('space.075', '6px')}`
			: `${token('space.100', '8px')} ${token('space.075', '6px')} ${token('space.050', '4px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	backgroundColor: GRID_BACKGROUND_COLOR,
	left: 0,
	borderRadius: token('border.radius.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowHeaderContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: 'auto',
	position: 'sticky',
	zIndex: 5,
	cursor: 'grab',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${HEADER_HEIGHT + 9}px`, // 8px is the height of the gap between header and group + 1px header border
	minHeight: '36px',
	'&::before': {
		content: '',
		width: '100%',
		height: token('space.100', '8px'),
		backgroundColor: token('elevation.surface', '#fff'),
		position: 'absolute',
		top: token('space.negative.100', '-8px'),
		left: 0,
		zIndex: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContentContainer = styled.div({
	width: 'max-content',
	display: 'flex',
	flexDirection: 'column',
	marginTop: token('space.negative.050', '-4px'),
});
