// styles extracted to separate constants in order to reuse them in card height calculations
export const CONNECTION_ITEM_LINE_HEIGHT = 20;

export const CONNECTION_ITEM_STYLES = {
	lineHeight: `${CONNECTION_ITEM_LINE_HEIGHT}px`,
	fontsize: '13px',
	display: '-webkit-box',
	overflow: 'hidden',
	paddingLeft: '8px',
	webkitLineClamp: '2',
	webkitBoxOrient: 'vertical',
} as const;

export const CONNECTION_ITEM_ELEM_BEFORE_WIDTH = 8;
