import type { ApplicationPermissions } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { AtlBrowserStorageLocal } from '@atlassian/browser-storage-controls';
import { fg } from '@atlassian/jira-feature-gating';
import { type JpdEdition, Role, Edition } from '../types.tsx';

// saved in the browser
const PENDO_STRICTLY_NECESSARY_COOKIE = 'polaris-pendo-strictly-necessary';
const PENDO_FUNCTIONAL_COOKIE = 'polaris-pendo-functional';

// sent to pendo
const PENDO_STRICTLY_NECESSARY_PARAM = 'STRICTLY_NECESSARY';
const PENDO_FUNCTIONAL_PARAM = 'FUNCTIONAL';

type PendoCookieConsent = (typeof PENDO_STRICTLY_NECESSARY_PARAM | typeof PENDO_FUNCTIONAL_PARAM)[];

/**
 * we don't have a way to directly fetch the user's cookie preferences (i.e. sth like {strictlyNecessary: true, functional: false})
 * instead, we try to re-set local storage items for the two categories that we care about in the context of Pendo
 * then, atlassian-cookies-panel in governator determines whether these items can be set based on the user's preferences
 */
const hasUserConsent = async (key: string, isStrictlyNecessary: boolean): Promise<boolean> => {
	AtlBrowserStorageLocal.removeItem(key); // removing consent does not clean up already-set items
	if (isStrictlyNecessary) {
		AtlBrowserStorageLocal.setStrictlyNecessaryItem(key, 'true');
	} else {
		await AtlBrowserStorageLocal.setItem(key, 'true');
	}
	return Boolean(AtlBrowserStorageLocal.getItem(key));
};

export const getPendoCookieConsent = async (): Promise<PendoCookieConsent | undefined> => {
	if (!fg('polaris_browser_storage_controls_pendo_cookies')) {
		return undefined;
	}

	const [consentedToStrictlyNecessary, consentedToFunctional] = await Promise.all([
		hasUserConsent(PENDO_STRICTLY_NECESSARY_COOKIE, true),
		hasUserConsent(PENDO_FUNCTIONAL_COOKIE, false),
	]);

	const consentedCookies: PendoCookieConsent = [];
	if (consentedToStrictlyNecessary) {
		consentedCookies.push(PENDO_STRICTLY_NECESSARY_PARAM);
	}

	if (consentedToFunctional) {
		consentedCookies.push(PENDO_FUNCTIONAL_PARAM);
	}

	return consentedCookies;
};

export const getProductDiscoveryRole = (
	appPermissions: ApplicationPermissions | undefined,
	hasProjectPermissions: boolean,
): Role => {
	if (appPermissions?.hasProductDiscoveryAccess) {
		return Role.CREATOR;
	}

	if (
		appPermissions?.hasCoreAccess ||
		appPermissions?.hasServiceDeskAccess ||
		appPermissions?.hasSoftwareAccess ||
		hasProjectPermissions
	) {
		return Role.CONTRIBUTOR;
	}

	return Role.STAKEHOLDER;
};

export const jpdEditionToProductDiscoveryEdition = (
	jpdEdition: JpdEdition,
	appEditions: { productDiscovery: string } | undefined,
): string | undefined => {
	if (jpdEdition === Edition.UNKNOWN) {
		return undefined;
	}

	if (jpdEdition === Edition.BETA_EDITION) {
		return Edition.BETA_EDITION;
	}

	return appEditions?.productDiscovery;
};
