import React, { useMemo, useEffect } from 'react';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import {
	updatePageLoadInteractionName,
	getActiveInteraction,
} from '@atlaskit/react-ufo/interaction-metrics';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useCurrentViewSlug,
	useCurrentViewKind,
	usePreviousViewKind,
	useCurrentViewFieldCount,
	useCurrentSortByFieldCount,
	useCurrentViewVerticalGroupBy,
	useCurrentViewGroupBy,
	useCurrentViewContainsArchived,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useActiveFiltersCount } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import {
	useIsIssueOpenInSidebar,
	useSelectedIssueQueryParameter,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { CommonUfoData } from '../../../common/utils/common-ufo-data/index.tsx';

type ViewUfoDataProps = {
	useInteractionRenaming: boolean;
	trigger: string | undefined;
};

export const ViewUfoData = ({ useInteractionRenaming, trigger }: ViewUfoDataProps) => {
	const previousViewKind = usePreviousViewKind();
	const viewKind = useCurrentViewKind();
	const viewId = useCurrentViewSlug();
	const viewFieldCount = useCurrentViewFieldCount();
	const viewSortCount = useCurrentSortByFieldCount();
	const viewFilterCount = useActiveFiltersCount();
	const viewGroupBy = useCurrentViewGroupBy();
	const viewVerticalGroupBy = useCurrentViewVerticalGroupBy();
	const viewIsArchive = useCurrentViewContainsArchived();
	const isIssueViewSidebarOpen = useIsIssueOpenInSidebar();
	const selectedIssue = useSelectedIssueQueryParameter();

	useEffect(() => {
		if (!viewKind || !useInteractionRenaming || !fg('jpd-rename-ufo-project-transition')) {
			return;
		}
		const activeInteraction = getActiveInteraction();
		if (!activeInteraction || activeInteraction.ufoName !== 'jpd.project') {
			return;
		}
		updatePageLoadInteractionName(`jpd.${viewKind.toLowerCase()}-view`);
	}, [viewId, viewKind, useInteractionRenaming]);

	const data = useMemo(
		() => ({
			trigger,
			viewId,
			viewKind,
			previousViewKind: previousViewKind ?? 'unknown',
			viewFieldCount,
			viewSortCount,
			viewFilterCount,
			viewHasGroupBy: !!viewGroupBy,
			viewHasVerticalGroupBy: !!viewVerticalGroupBy,
			viewIsArchive,
			viewHasSelectedIssue: !!selectedIssue,
			viewHasRightSidebarOpen: isIssueViewSidebarOpen,
		}),
		[
			viewKind,
			previousViewKind,
			viewId,
			viewFieldCount,
			viewSortCount,
			viewFilterCount,
			viewGroupBy,
			viewVerticalGroupBy,
			viewIsArchive,
			isIssueViewSidebarOpen,
			selectedIssue,
			trigger,
		],
	);

	return (
		<>
			<CommonUfoData trigger={trigger} />
			<UFOCustomData data={data} />
		</>
	);
};
