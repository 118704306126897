import React from 'react';
import { Stack } from '@atlaskit/primitives';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { Draggable } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isGlobalNonSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { ConnectionItem } from './connections-item.tsx';

type ListProps = {
	options: Field[];
	isSortable?: boolean;
	onToggle: (fieldKey: FieldKey, isSelected: boolean) => void;
};

export const List = ({ options, isSortable = false, onToggle }: ListProps) => {
	return (
		<Stack space="space.075">
			{options.map((field) => (
				<Draggable
					id={field.key}
					key={field.key}
					isDragDisabled={!isSortable}
					isDropDisabled={!isSortable}
				>
					<ConnectionItem
						fieldKey={field.key}
						label={field.label}
						isGlobalNonSystemField={
							fg('polaris_display-gsf-as-a-regular-project-field')
								? isGlobalNonSystemField(field)
								: field.global
						}
						isSelected={isSortable}
						isDragEnabled={isSortable}
						onToggle={onToggle}
					/>
				</Draggable>
			))}
		</Stack>
	);
};
