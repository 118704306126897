import React from 'react';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIssueTypeName } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import type { TriggerType } from './trigger.tsx';

type Props = {
	issueTypeId: IssueTypeId;
	triggerType?: TriggerType;
};

const READ_ONLY_VIEW_TEST_ID = 'polaris-components.issue-types.issue-type-select.readonly-view';

export const IssueTypeSelectReadonlyView = ({ issueTypeId, triggerType = 'icon-only' }: Props) => {
	const issueTypeName = useIssueTypeName({ issueTypeId });

	switch (triggerType) {
		case 'icon-and-name':
			return (
				<Inline testId={READ_ONLY_VIEW_TEST_ID} alignBlock="center" space="space.050">
					<IssueTypeIcon issueTypeId={issueTypeId} />
					<Text weight="medium">{issueTypeName}</Text>
				</Inline>
			);
		case 'icon-and-chevron':
		default:
			return (
				<Tooltip content={issueTypeName}>
					{(tooltipProps) => (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Box {...tooltipProps} testId={READ_ONLY_VIEW_TEST_ID} xcss={tooltipWrapperStyles}>
							<IssueTypeIcon issueTypeId={issueTypeId} />
						</Box>
					)}
				</Tooltip>
			);
	}
};

const tooltipWrapperStyles = xcss({
	display: 'inline-flex',
});
