import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'polaris-component-collection-delete-modal.header',
		defaultMessage: 'Do you want to delete "{roadmapName}" roadmap?',
		description: 'Header of the Delete Roadmap confirmation modal window',
	},
	cancel: {
		id: 'polaris-component-collection-delete-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Label of Cancel button',
	},
	delete: {
		id: 'polaris-component-collection-delete-modal.delete',
		defaultMessage: 'Delete',
		description: 'Label of Delete button',
	},
	confirmationTitle: {
		id: 'polaris-component-collection-delete-modal.confirmation-title',
		defaultMessage: 'Type "{roadmapName}" to confirm the deletion:',
		description: 'Title for Confirm roadmap deletion input',
	},
	description: {
		id: 'polaris-component-collection-delete-modal.description',
		defaultMessage:
			'This roadmap has <b>{viewsCount, plural, =1 {# view} other {# views}}</b>. If you delete it, <b>the views will be deleted.</b> It will not delete the ideas that were used in the roadmap.',
		description: 'Description for Delete Roadmap confirmation modal window',
	},
	errorTitle: {
		id: 'polaris-component-collection-delete-modal.error-title',
		defaultMessage: 'We couldn’t delete the roadmap',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-component-collection-delete-modal.error-description',
		defaultMessage: 'Please try again or refresh the page.',
		description: 'Flag description for an error occurring while deleting roadmap.',
	},
});
