import React, { type SyntheticEvent, useMemo, useCallback, useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { LoadingButton } from '@atlaskit/button';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import RemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { Box, Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import {
	useIsCollectionView,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useFieldValueDecorations } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import {
	useFieldType,
	useFieldLabel,
	useIsDeletable,
	useIsGlobalCustomField,
	useIsHidden,
	useIsRestricted,
	useGlobalFieldsInUseArray,
	useIsGlobalSystemField,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useGlobalFieldCopyValuesActions } from '@atlassian/jira-polaris-common/src/controllers/global-fields/main.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useOpenRightSidebarOnFieldList,
	useOpenRightSidebarOption,
	useOpenRightSidebarGroupByOptions,
	useOpenRightSidebarVerticalGroupByOptions,
	useOpenRightSidebarMatrixXAxisOptions,
	useOpenRightSidebarMatrixYAxisOptions,
	useOpenRightSidebarMatrixZAxisOptions,
	useOpenRightSidebarTimelineFieldConfig,
	usePreventRightSidebarClosing,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import {
	useRightSidebarOption,
	useRightSidebarShowing,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	RightSidebarShowingField,
	RightSidebarShowingGroupByOptions,
	RightSidebarShowingTimelineConfig,
	RightSidebarShowingVerticalGroupByOptions,
	RightSidebarShowingXAxisOptions,
	RightSidebarShowingYAxisOptions,
	RightSidebarShowingZAxisOptions,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useCanManageCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { PermissionsIcon } from '@atlassian/jira-polaris-common/src/ui/common/edit-global-field/permissions-icon/index.tsx';
import { FieldItem } from '@atlassian/jira-polaris-common/src/ui/config/fields/field-item/main.tsx';
import { CopyValuesSidebar } from '@atlassian/jira-polaris-component-copy-values/src/ui/copy-values-sidebar/index.tsx';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import {
	useCanEditFields,
	useCanManageGlobalFields,
	useIsAdmin,
	useIsProjectAdmin,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { isSystemFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	getFieldId,
	fieldCanBeHidden,
	canHaveValuesCopiedToGlobalField,
} from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { ClickableBanner } from '@atlassian/jira-polaris-lib-clickable-banner/src/ui/index.tsx';
import { ignoreEscapePress } from '@atlassian/jira-polaris-lib-escape-keypress-utils/src/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { RestrictedFieldBanner } from '@atlassian/jira-polaris-lib-restricted-field/src/ui/restricted-field-banner/index.tsx';
import { RightSidebarBody } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/body/index.tsx';
import { RightSidebarFooter } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/footer/index.tsx';
import { RightSidebarHeader } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/header/index.tsx';
import {
	fireUIAnalytics,
	ContextualAnalyticsData,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { Header } from '../header/index.tsx';
import { HideConfirmationDialog } from './hide-confirmation-dialog/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
	onDelete: (fieldKey: FieldKey) => void;
};

const getDeleteButtonTooltipMessage = (
	canEditFields: boolean,
	isDeletable: boolean,
	isSystemField: boolean,
	formatMessage: IntlShape['formatMessage'],
) => {
	if (isSystemField) {
		return formatMessage(messages.systemField);
	}
	if (!canEditFields) {
		return formatMessage(messages.noPermissions);
	}
	if (!isDeletable) {
		return formatMessage(messages.fieldNotDeletable);
	}
	return undefined;
};

export const FieldComponent = ({ onClose, onDelete }: Props) => {
	const { formatMessage } = useIntl();
	const openFieldList = useOpenRightSidebarOnFieldList();
	const canEditFields = useCanEditFields();
	const onOpenRightSidebarOption = useOpenRightSidebarOption();
	const preventRightSidebarClosing = usePreventRightSidebarClosing();
	const [sidebarOption] = useRightSidebarOption();
	const openRightSidebarGroupByOptions = useOpenRightSidebarGroupByOptions();
	const openRightSidebarVerticalGroupByOptions = useOpenRightSidebarVerticalGroupByOptions();
	const openRightSidebarMatrixXAxisOptions = useOpenRightSidebarMatrixXAxisOptions();
	const openRightSidebarMatrixYAxisOptions = useOpenRightSidebarMatrixYAxisOptions();
	const openRightSidebarMatrixZAxisOptions = useOpenRightSidebarMatrixZAxisOptions();
	const openRightSidebarTimelineFieldConfig = useOpenRightSidebarTimelineFieldConfig();
	const [sidebarShowing] = useRightSidebarShowing();
	// @ts-expect-error - TS2339 - Property 'fieldKey' does not exist on type 'Showing'. | TS2339 - Property 'fieldKey' does not exist on type 'Showing'.
	const fieldKey = sidebarShowing.fieldKey !== undefined ? sidebarShowing.fieldKey : undefined;
	const field = useField(fieldKey);
	const [fieldValueDecorations] = useFieldValueDecorations();
	const isDeletable = useIsDeletable(fieldKey);
	const isHidden = useIsHidden(fieldKey);
	const fieldType = useFieldType(fieldKey);
	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);
	const isGlobalSystemField = useIsGlobalSystemField(fieldKey);
	const [globalFieldsInUse] = useGlobalFieldsInUseArray();
	const isDeleteButtonDisabled = !canEditFields || !isDeletable || isGlobalCustomField;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isAdmin = useIsAdmin();
	const label = useFieldLabel(fieldKey);
	const isSharedView = useIsSharedView();
	const isRestricted = useIsRestricted(fieldKey);
	const canManageGlobalFields = useCanManageGlobalFields();
	const [isProjectAdmin] = useIsProjectAdmin();
	const { updateSystemFieldHidden } = useFieldActions();
	const [updatingHidden, setUpdatingHidden] = useState(false);
	const [displayHideConfirmation, setDisplayHideConfirmation] = useState(false);
	const [displayCopyPanel, setDisplayCopyPanel] = useState(false);
	const { success, error } = useNotifications();
	const isCollectionView = useIsCollectionView();
	const canManageCurrentView = useCanManageCurrentView();
	const { copyValues } = useGlobalFieldCopyValuesActions();
	const { refreshIssues } = useIssueActions();

	const displayHideButton = fieldCanBeHidden(fieldType);
	const displayCopyButton = isProjectAdmin && canHaveValuesCopiedToGlobalField(fieldType);

	useCloseOnEscapePress({
		onClose: (event) => {
			if (ignoreEscapePress(event)) {
				return;
			}
			if (displayCopyPanel) {
				setDisplayCopyPanel(false);
			} else if (canEditFields) {
				openFieldList();
			} else {
				onClose();
			}
		},
	});

	const issueFieldAnalyticsAttributes = useMemo(
		() => ({
			issueFieldKey: fieldKey,
			isGlobalField: isGlobalCustomField,
		}),
		[fieldKey, isGlobalCustomField],
	);

	const onBackToFieldListClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'link' }),
			'backToFieldsList',
		);
		openFieldList();
	}, [openFieldList, createAnalyticsEvent]);

	const onUpdateSystemFieldHidden = useCallback(
		async (analyticEvent: UIAnalyticsEvent) => {
			if (!isHidden) {
				setDisplayHideConfirmation(true);
				return;
			}

			fireUIAnalytics(analyticEvent, 'showSystemField');

			setUpdatingHidden(true);
			try {
				await updateSystemFieldHidden(fieldKey, false);
				setUpdatingHidden(false);
				success({
					title: formatMessage(messages.showSystemFieldSuccessTitle, { field: label }),
					description: formatMessage(messages.showSystemFieldSuccessDescription),
				});
			} catch (e) {
				setUpdatingHidden(false);
			}
		},
		[fieldKey, formatMessage, isHidden, label, success, updateSystemFieldHidden],
	);

	const onCopyValues = useCallback(
		async (
			fromFieldKey: FieldKey,
			toFieldKey: FieldKey,
			fieldOptionsValueMapping: Record<string, string | undefined>,
		) => {
			await copyValues(
				fromFieldKey,
				toFieldKey,
				fieldOptionsValueMapping,
				() => {
					refreshIssues({
						refreshAllLoaded: true,
						loadIssueDeliveryProgress: false,
					});
				},
				() => {
					error({
						title: formatMessage(messages.copyValuesProgressErrorTitle),
						description: formatMessage(messages.copyValuesProgressErrorDescription),
					});
				},
			);
		},
		[copyValues, refreshIssues, error, formatMessage],
	);

	useEffect(() => {
		preventRightSidebarClosing(displayCopyPanel && 'onEsc');
	}, [displayCopyPanel, preventRightSidebarClosing]);

	if (fieldKey === undefined) {
		return null;
	}

	const canNavigateBackToFieldsList = isCollectionView ? canManageCurrentView : canEditFields;

	const headerIcon = canNavigateBackToFieldsList ? (
		<ArrowLeftIcon label={formatMessage(messages.backToFieldsList)} />
	) : undefined;
	const defaultHeaderMessage = canNavigateBackToFieldsList
		? messages.backToFieldsList
		: messages.fieldSettings;
	const defaultHeaderOnClick = canNavigateBackToFieldsList ? onBackToFieldListClick : undefined;

	const header = (message = defaultHeaderMessage, onHeaderClick = defaultHeaderOnClick) => (
		<Header
			icon={headerIcon}
			title={formatMessage(message)}
			onClick={onHeaderClick}
			onClose={onClose}
		/>
	);

	const renderSystemFieldVisibilityButton = () => {
		const icon = isHidden ? (
			<WatchIcon label="" />
		) : (
			<PolarisIcon name={PolarisIconType.Hidden} label="" />
		);
		const buttonText = formatMessage(isHidden ? messages.show : messages.hide);

		return (
			<LoadingButton
				testId="polaris-ideas.ui.right-sidebar.field.system-field-visibility"
				iconBefore={icon}
				isLoading={updatingHidden}
				onClick={(_event: SyntheticEvent, analyticEvent: UIAnalyticsEvent) => {
					onUpdateSystemFieldHidden(analyticEvent);
				}}
			>
				{buttonText}
			</LoadingButton>
		);
	};

	const renderDeleteButton = () => (
		<ButtonWithTooltip
			testId="polaris-ideas.ui.right-sidebar.field.delete"
			appearance="default"
			isDisabled={isDeleteButtonDisabled}
			onClick={(_event: SyntheticEvent, analyticEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticEvent, 'deleteField');
				experience.fieldsSidebar.fieldDelete.start();
				onDelete(fieldKey);
			}}
			iconBefore={<RemoveIcon label={formatMessage(messages.deleteField)} />}
			tooltipProps={{
				content: isDeleteButtonDisabled
					? getDeleteButtonTooltipMessage(
							canEditFields,
							isDeletable,
							isSystemFieldType(fieldType),
							formatMessage,
						)
					: null,
				truncate: true,
			}}
		>
			{formatMessage(messages.deleteField)}
		</ButtonWithTooltip>
	);

	const renderRemoveButton = () => (
		<Button
			testId="polaris-ideas.ui.right-sidebar.field.remove"
			appearance="default"
			onClick={(_event: SyntheticEvent, analyticEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticEvent, 'removeFromProject');
				experience.fieldsSidebar.fieldDelete.start();
				onDelete(fieldKey);
			}}
		>
			{formatMessage(messages.removeFromProject)}
		</Button>
	);

	const renderCopyValuesButton = () => (
		<Button
			testId="polaris-ideas.ui.right-sidebar.field.copy-values"
			appearance="default"
			onClick={(_event: SyntheticEvent, analyticEvent: UIAnalyticsEvent) => {
				setDisplayCopyPanel(true);
				fireUIAnalytics(analyticEvent, 'openCopyValuesPanel');
			}}
		>
			{formatMessage(messages.copyValues)}
		</Button>
	);

	const renderEditGlobalFieldButton = () => (
		<Button
			isDisabled={!canManageGlobalFields}
			appearance="default"
			onClick={(_event: SyntheticEvent, analyticEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticEvent, 'globalFieldEdit');
			}}
			iconAfter={<ShortcutIcon label={formatMessage(messages.editGlobalField)} />}
			href={`/jira/settings/products/jira-product-discovery/global-fields?openFieldConfigurationPanel=${fieldKey}`}
			target="_blank"
		>
			{formatMessage(messages.editGlobalField)}
		</Button>
	);

	const renderHeader = () => {
		if (sidebarShowing.mode !== RightSidebarShowingField) {
			return header();
		}
		const backToMode = sidebarShowing.backTo?.mode;
		switch (backToMode) {
			case RightSidebarShowingGroupByOptions:
				return header(messages.backToColumnsOptions, openRightSidebarGroupByOptions);
			case RightSidebarShowingVerticalGroupByOptions:
				return header(messages.backToGroupByOptions, openRightSidebarVerticalGroupByOptions);
			case RightSidebarShowingXAxisOptions:
				return header(messages.backToXAxisConfiguration, openRightSidebarMatrixXAxisOptions);
			case RightSidebarShowingYAxisOptions:
				return header(messages.backToYAxisConfiguration, openRightSidebarMatrixYAxisOptions);
			case RightSidebarShowingZAxisOptions:
				return header(messages.backToZAxisConfiguration, openRightSidebarMatrixZAxisOptions);
			case RightSidebarShowingTimelineConfig:
				return header(
					messages.backToTimelineFieldsConfiguration,
					openRightSidebarTimelineFieldConfig,
				);
			default:
				return header();
		}
	};

	if (isSharedView) {
		return (
			<UFOSegment name="jpd.right-sidebar.field">
				<FieldContainer>
					<ContextualAnalyticsData attributes={issueFieldAnalyticsAttributes}>
						<Header onClose={onClose} isSharedView={isSharedView} title={label} />
					</ContextualAnalyticsData>
					<FieldConfigContainer>
						<FieldItem
							fieldKey={fieldKey}
							isSharedView={isSharedView}
							onOpenRightSidebarOption={onOpenRightSidebarOption}
							openSidebarOption={sidebarOption}
						/>
					</FieldConfigContainer>
				</FieldContainer>
			</UFOSegment>
		);
	}

	const isOpenedFromProjectFieldsPage =
		sidebarShowing.mode === RightSidebarShowingField &&
		sidebarShowing.origin === 'projectFieldsPage';

	const renderBanner = () => {
		if (!isGlobalCustomField) {
			return null;
		}

		if (isRestricted && isOpenedFromProjectFieldsPage) {
			return (
				<RestrictedFieldBanner
					fieldId={getFieldId(fieldKey)}
					variant={isAdmin ? 'fieldSettingsAdmin' : 'fieldSettingsNonAdmin'}
				/>
			);
		}

		if (isGlobalSystemField) {
			return null;
		}

		return (
			<Box xcss={!isOpenedFromProjectFieldsPage && bannerContainerStyles}>
				<ClickableBanner
					icon={<GlobalFieldMarker color={token('color.icon')} hideTooltip noMargins />}
					message={
						<Text size="small">
							{formatMessage(messages.globalFieldWarning, {
								b: (text: React.ReactNode) => (
									<Text size="small" weight="bold">
										{text}
									</Text>
								),
							})}
						</Text>
					}
					type="information"
					spacing={isOpenedFromProjectFieldsPage ? 'default' : 'medium'}
				/>
			</Box>
		);
	};

	if (displayCopyPanel && isOpenedFromProjectFieldsPage && field) {
		return (
			<UFOSegment name="jpd.right-sidebar.copy-values">
				<ContextualAnalyticsData attributes={issueFieldAnalyticsAttributes}>
					<CopyValuesSidebar
						onCopyValues={onCopyValues}
						onClose={() => {
							fireUIAnalytics(
								createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
								'closeCopyValuesPanel',
							);
							setDisplayCopyPanel(false);
						}}
						field={field}
						fieldValueDecorations={fieldValueDecorations}
						globalFields={globalFieldsInUse}
						canManageGlobalFields={canManageGlobalFields}
					/>
				</ContextualAnalyticsData>
			</UFOSegment>
		);
	}

	const showGlobalFieldControls = fg('polaris_display-gsf-as-a-regular-project-field')
		? isGlobalCustomField && !isGlobalSystemField
		: isGlobalCustomField;

	if (isOpenedFromProjectFieldsPage) {
		return (
			<ContextualAnalyticsData attributes={issueFieldAnalyticsAttributes}>
				<HideConfirmationDialog
					fieldKey={fieldKey}
					isOpen={displayHideConfirmation}
					onClose={() => setDisplayHideConfirmation(false)}
				/>
				<Stack xcss={sidebarContainerStyles}>
					<RightSidebarHeader
						title={formatMessage(messages.fieldSettings)}
						onClose={onClose}
						banner={renderBanner()}
					/>
					<RightSidebarBody>
						<FieldConfigContainer visibleOverflowX>
							<FieldItem
								fieldKey={fieldKey}
								onOpenRightSidebarOption={onOpenRightSidebarOption}
								openSidebarOption={sidebarOption}
							/>
						</FieldConfigContainer>
					</RightSidebarBody>
					<RightSidebarFooter>
						{showGlobalFieldControls ? (
							<Flex alignItems="center" gap="space.100">
								{(isProjectAdmin || canManageGlobalFields) && renderRemoveButton()}
								{renderEditGlobalFieldButton()}
								{!canManageGlobalFields && <PermissionsIcon />}
							</Flex>
						) : (
							<Flex alignItems="center" gap="space.100">
								{renderDeleteButton()}
								{displayHideButton && renderSystemFieldVisibilityButton()}
								{displayCopyButton && renderCopyValuesButton()}
							</Flex>
						)}
					</RightSidebarFooter>
				</Stack>
			</ContextualAnalyticsData>
		);
	}

	return (
		<UFOSegment name="jpd.right-sidebar.field">
			<FieldContainer>
				<ContextualAnalyticsData attributes={issueFieldAnalyticsAttributes}>
					{renderHeader()}
					{renderBanner()}
					<FieldConfigContainer>
						<FieldItem
							fieldKey={fieldKey}
							onOpenRightSidebarOption={onOpenRightSidebarOption}
							openSidebarOption={sidebarOption}
						/>
					</FieldConfigContainer>
					{(!isCollectionView || showGlobalFieldControls) && (
						<FieldMainButtonContainer id="polaris-sidebar.field.delete">
							{showGlobalFieldControls ? (
								<Flex alignItems="center" gap="space.100">
									{(isProjectAdmin || canManageGlobalFields) &&
										!isCollectionView &&
										renderRemoveButton()}
									{renderEditGlobalFieldButton()}
									{!canManageGlobalFields && <PermissionsIcon />}
								</Flex>
							) : (
								renderDeleteButton()
							)}
						</FieldMainButtonContainer>
					)}
				</ContextualAnalyticsData>
			</FieldContainer>
		</UFOSegment>
	);
};

const bannerContainerStyles = xcss({
	marginBlockStart: 'space.025',
	marginBlockEnd: 'space.150',
});

const sidebarContainerStyles = xcss({
	height: '100%',
	paddingBlockStart: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldMainButtonContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	borderTop: `1px solid ${token('color.border', '#ebecf0')}`,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '15px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainer = styled.div({
	minHeight: '100%',
	display: 'flex',
	flexDirection: 'column',
	height: 'calc(100vh - (var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)))',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldConfigContainer = styled.div<{ visibleOverflowX?: boolean }>({
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflowX: ({ visibleOverflowX }) => (visibleOverflowX ? 'visible' : 'auto'),
	overflowY: 'visible',
	height: '100%',
});
