import React from 'react';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { useIntl } from '@atlassian/jira-intl';
import {
	useCollectionFiltersJql,
	useCollectionsActions,
	useCollectionProjectKeys,
} from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { RightSidebarBody } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/body/index.tsx';
import { RightSidebarHeader } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/header/index.tsx';
import { CollectionAdvancedFilters } from '@atlassian/jira-polaris-component-collection-advanced-filters/src/ui/index.tsx';
import { ignoreEscapePress } from '@atlassian/jira-polaris-lib-escape-keypress-utils/src/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import type { SidebarContentType } from '../../common/types.tsx';
import { useActions, useCollectionUUID } from '../../controllers/index.tsx';
import messages from './messages.tsx';

type Props = {
	onContentChange: (content: SidebarContentType | undefined) => void;
};

export const ManageFiltersSidebarContent = ({ onContentChange }: Props) => {
	const { formatMessage } = useIntl();
	const collectionUUID = useCollectionUUID();
	const filtersJql = useCollectionFiltersJql({ collectionUUID });
	const projectKeys = useCollectionProjectKeys({ collectionUUID }) ?? [];
	const { onClose } = useActions();
	const { updateCollection } = useCollectionsActions();
	const { errorWithRefresh } = useNotifications();

	const onChange = async (newFiltersJql: string) => {
		if (!collectionUUID) {
			return;
		}

		try {
			await updateCollection(collectionUUID, {
				filtersJql: newFiltersJql,
			});
		} catch (error) {
			if (error instanceof Error) {
				errorWithRefresh({
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.errorDescription),
				});
			}
		}
	};

	useCloseOnEscapePress({
		onClose: (event) => {
			if (ignoreEscapePress(event)) {
				return;
			}
			onContentChange(undefined);
		},
	});

	return (
		<>
			<RightSidebarHeader
				title={formatMessage(messages.title)}
				onClose={onClose}
				onBack={() => onContentChange(undefined)}
				backLabel={formatMessage(messages.backButtonLabel)}
			/>
			<RightSidebarBody>
				<CollectionAdvancedFilters
					projectKeys={projectKeys}
					value={filtersJql}
					onChange={onChange}
				/>
			</RightSidebarBody>
		</>
	);
};
