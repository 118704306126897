import React, { useMemo } from 'react';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import type { ChainableContextProvider } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { wrapPromiseWithFetchInteraction } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/interaction/index.tsx';
import { getOnboardingInformation } from './services/jira/onboarding-information/index.tsx';
import type { ProjectRemote } from './types.tsx';

export const createProjectRemote = (projectId: ProjectId): ProjectRemote => ({
	getOnboardingInformation: () =>
		wrapPromiseWithFetchInteraction(
			getOnboardingInformation(projectId),
			'getOnboardingInformation',
		),
});

const { RemoteContextProvider, useRemoteFromContext } = createRemoteContext<ProjectRemote>('site');

export const useProjectRemote = useRemoteFromContext;

export const createProjectRemoteProvider =
	(projectId: ProjectId): ChainableContextProvider =>
	({ children }) => {
		const projectRemote = useMemo(
			() => (projectId !== undefined ? createProjectRemote(projectId) : null),
			[],
		);

		if (!projectRemote) {
			return <>{children}</>;
		}

		return <RemoteContextProvider remote={projectRemote}>{children}</RemoteContextProvider>;
	};
