import React, { type RefObject } from 'react';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { TriggerType } from './trigger.tsx';
import { IssueTypeSelectReadonlyView } from './readonly-view.tsx';
import { IssueTypeSelectEditableView } from './editable-view.tsx';

type Props = {
	issueTypeId: IssueTypeId;
	onChange?: (issueType: IssueTypeFieldValue) => void;
	readonly?: boolean;
	triggerType?: TriggerType;
	availableIssueTypeIds?: IssueTypeId[];
	returnFocusRef?: RefObject<HTMLElement>;
};

export const IssueTypeSelect = ({
	issueTypeId,
	onChange,
	readonly,
	triggerType = 'icon-only',
	availableIssueTypeIds,
	returnFocusRef,
}: Props) => {
	const container = useEnvironmentContainer();

	if (container?.type !== PolarisEnvironmentContainerTypes.PROJECT || readonly) {
		return <IssueTypeSelectReadonlyView issueTypeId={issueTypeId} triggerType={triggerType} />;
	}

	return (
		<IssueTypeSelectEditableView
			issueTypeId={issueTypeId}
			triggerType={triggerType}
			projectKey={container.projectKey}
			onChange={onChange}
			availableIssueTypeIds={availableIssueTypeIds}
			returnFocusRef={returnFocusRef}
		/>
	);
};
