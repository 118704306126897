import React from 'react';
import { Box } from '@atlaskit/primitives';
import { EmojiPicker } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/index.tsx';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import { useConnectionFieldIssueTypeId } from '../../../controllers/selectors/field-hooks.tsx';

export const ConnectionFieldEmojiIcon = () => {
	const issueTypeId = useConnectionFieldIssueTypeId();

	if (!issueTypeId) {
		return <EmojiPicker readonly />;
	}

	return (
		<Box paddingBlockStart="space.050" paddingInlineStart="space.075">
			<IssueTypeIcon issueTypeId={issueTypeId} />
		</Box>
	);
};
