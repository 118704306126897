import React, { useMemo, forwardRef } from 'react';
import { styled } from '@compiled/react';
import { Emoji } from '@atlaskit/emoji';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import { B400, N200, N20A, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useFieldEmoji,
	useFieldLabel,
	useFieldTypeIcon,
	useIsGlobalCustomField,
	useIsGlobalNonSystemField,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { EmojiWrapper } from '@atlassian/jira-polaris-common/src/ui/field-config/item/emoji-wrapper/index.tsx';
import { FieldInfoMarker } from '@atlassian/jira-polaris-common/src/ui/field-info-marker/index.tsx';
import { FieldTooltip } from '@atlassian/jira-polaris-common/src/ui/field-tooltip/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';

export const HeaderTrigger = forwardRef<HTMLButtonElement, Props>(
	(
		{
			fieldKey,
			disabled,
			disableTooltip,
			isLabelHidden,
			onClick,
			isFullWidth,
			sortField,
			hasDescriptionTrigger = true,
		},
		ref,
	) => {
		const label = useFieldLabel(fieldKey);

		const iconProps = useMemo(
			() => ({
				label: label || '',
				primaryColor: sortField
					? token('color.text.brand', B400)
					: token('color.text.subtlest', N200),
			}),
			[label, sortField],
		);

		const icon = useFieldTypeIcon(fieldKey, iconProps);
		const emojiId = useFieldEmoji(fieldKey);
		const emoji = useEmoji(emojiId);

		const isSharedView = useIsSharedView();

		const isGlobalCustomField = useIsGlobalCustomField(fieldKey);
		const isGlobalNonSystemField = useIsGlobalNonSystemField(fieldKey);

		return (
			<Container isFullWidth={isFullWidth}>
				{!isSharedView && (
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="hide-from-export"
					>
						<FieldInfoMarker fieldKey={fieldKey} />
					</div>
				)}
				<FieldTooltip
					fieldKey={fieldKey}
					strategy="hover"
					disabled={disableTooltip === true}
					hasDescriptionTrigger={hasDescriptionTrigger}
				>
					<HeaderButton
						data-component-selector="header-button-eE39"
						disabled={disabled}
						sorted={sortField}
						data-testid="polaris-ideas.ui.view-content.idea-list.header.cell.trigger"
						onClick={onClick}
						ref={ref}
					>
						<HeaderButtonLabelContainer>
							{emoji ? (
								<EmojiWrapper>
									<Emoji emoji={emoji} fitToHeight={16} />
								</EmojiWrapper>
							) : (
								<IconContainer
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									className="hide-from-export"
								>
									{icon}
								</IconContainer>
							)}
							{!isLabelHidden && (
								<HeaderLabelContainerWrapper>
									<HeaderLabelContainer>{label}</HeaderLabelContainer>
								</HeaderLabelContainerWrapper>
							)}
							{fg('polaris_display-gsf-as-a-regular-project-field') ? (
								<>{isGlobalNonSystemField && <GlobalFieldMarker />}</>
							) : (
								<>{isGlobalCustomField && <GlobalFieldMarker />}</>
							)}
							{sortField &&
								(sortField.asc ? (
									<ArrowUpIcon size="small" label="header.asc" />
								) : (
									<ArrowDownIcon size="small" label="header.desc" />
								))}
						</HeaderButtonLabelContainer>
						{!disabled && (
							<MoreButtonContainer>
								<MoreVerticalIcon size="small" label="header.more" />
							</MoreButtonContainer>
						)}
					</HeaderButton>
				</FieldTooltip>
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isFullWidth?: boolean }>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	flex: ({ isFullWidth }) => (isFullWidth ? '1 1 auto' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'div[role="presentation"]:last-child': {
		flex: 1,
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderButton = styled.button<{ sorted?: any; disabled: any }>({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props: any) =>
		props.sorted ? token('color.text.brand', B400) : token('color.text.subtlest', N200),
	flex: 1,
	width: '100%',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	border: 'none',
	backgroundColor: 'transparent',
	paddingTop: token('space.050', '4px'),
	paddingBottom: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ disabled }) => (disabled ? 'inherit' : 'pointer'),
	textAlign: 'left',
	fontWeight: token('font.weight.semibold'),
	minHeight: '32px',
	borderRadius: '3px',
	'&:hover, &:focus, &:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ disabled }) =>
			disabled ? 'inherit' : token('color.background.neutral.subtle.hovered', N20A),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props: any) =>
			props.sorted ? token('color.text.brand', B400) : token('color.text.subtle', N500),
		boxShadow: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& svg': {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			color: (props: any) =>
				props.sorted ? token('color.text.brand', B400) : token('color.text.subtle', N500),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderLabelContainerWrapper = styled.div({
	display: 'grid',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderLabelContainer = styled.div({
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	fontWeight: `${token('font.weight.semibold')} !important`,
	textAlign: 'left',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderButtonLabelContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreButtonContainer = styled.div({
	display: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="header-button-eE39"]:hover &': {
		display: 'inline-block',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	marginRight: token('space.075', '6px'),
});
