import { styled } from '@compiled/react';
import { N200, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldLabel = styled.div({
	font: token('font.body.small'),
	color: token('color.text.subtlest', N200),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldValue = styled.div<{ isFullwidth?: boolean }>({
	font: token('font.body.small'),
	color: token('color.text', N800),
	minWidth: 0,
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridColumn: ({ isFullwidth }) => isFullwidth && 'span 2',
});
