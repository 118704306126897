import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchPlaceholder: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.body.search-placeholder',
		defaultMessage: 'Search projects',
		description:
			'Search placeholder for the roadmap configuration sidebar on step of adding a project',
	},
	selectAllProjectsCheckboxLabel: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.body.select-all-projects-checkbox-label',
		defaultMessage: 'Select {projectsCount, plural, one {1 project} other {all # projects}}',
		description:
			'Label for the checkbox that selects all projects in the roadmap configuration sidebar on step of adding a project',
	},
	noSearchMatchMessage: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.body.no-search-match-message',
		defaultMessage: '0 projects match your search.',
		description:
			'Message for the roadmap configuration sidebar on step of adding a project when no projects match the search',
	},
	noProjectsFoundMessage: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.body.no-projects-found-message',
		defaultMessage: 'We couldn’t find the project you’re looking for.',
		description:
			'Message for the roadmap configuration sidebar on step of adding a project when no projects are found',
	},
	errorTitle: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.body.error-title',
		defaultMessage: 'We couldn’t load the projects',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-component-collection-configure-sidebar.add-project-content.body.error-description',
		defaultMessage: 'Please try again or refresh the page.',
		description: 'Flag description for an error occurring while loading projects.',
	},
});
