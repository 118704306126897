import React from 'react';
import AddIcon from '@atlaskit/icon/core/add';
import { Inline, Text, xcss } from '@atlaskit/primitives';
import { components, type ControlProps } from '@atlaskit/react-select';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useConnectionFieldSelectContext } from '../../edit/context/index.tsx';
import type { ConnectionFieldOption } from '../../types.tsx';
import messages from './messages.tsx';

export const ControlComponent = ({ children, ...rest }: ControlProps<ConnectionFieldOption>) => {
	const { formatMessage } = useIntl();
	const { isInCreation } = useConnectionFieldSelectContext();

	if (isInCreation) {
		return (
			<Inline alignBlock="center" alignInline="center" xcss={spinnerContainerStyles}>
				<Spinner size="medium" />
			</Inline>
		);
	}

	return (
		<components.Control {...rest}>
			<Inline space="space.100" alignBlock="center" xcss={containerStyles}>
				<AddIcon
					color="var(--ds-icon-subtlest)"
					LEGACY_primaryColor={token('color.icon.subtlest')}
					label=""
				/>
				{!rest.menuIsOpen && !rest.isFocused && (
					<Text color="color.text.subtlest" maxLines={1}>
						{formatMessage(messages.addButtonLabel)}
					</Text>
				)}
				{children}
			</Inline>
		</components.Control>
	);
};

const containerStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.025',
});

const spinnerContainerStyles = xcss({
	paddingTop: 'space.100',
});
