import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	months: {
		id: 'polaris-ideas.view-controls.config-timeline.mode-toggle.months',
		defaultMessage: 'Months',
		description: 'Text button representing time scale to use for the columns in a timeline',
	},
	quarters: {
		id: 'polaris-ideas.view-controls.config-timeline.mode-toggle.quarters',
		defaultMessage: 'Quarters',
		description: 'Text button representing time scale to use for the columns in a timeline',
	},
});
