import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ProjectId, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	createIssueAttachmentsRemote,
	createNotImplementedIssueAttachmentsRemote,
	createSharingIssueAttachmentsRemote,
} from './attachments/index.tsx';
import {
	createIssueCommentRemote,
	createNotImplementedIssueCommentRemote,
	createSharingIssueCommentRemote,
	createCollectionIssueCommentRemote,
} from './comment/index.tsx';
import {
	createIssueCrudRemote,
	createNotImplementedIssueCrudRemote,
	createSharingIssueCrudRemote,
	createJqlIssueCrudRemote,
} from './crud/index.tsx';
import {
	createIssueDeliveryRemote,
	createNotImplementedIssueDeliveryRemote,
	createSharingIssueDeliveryRemote,
	createJqlIssueDeliveryRemote,
} from './delivery/index.tsx';
import {
	createIssueInsightsRemote,
	createNotImplementedIssueInsightsRemote,
} from './insights/index.tsx';
import {
	createNotImplementedIssueLinkRemote,
	createSharingIssueLinkRemote,
	createIssueLinkRemote,
} from './issue-link/index.tsx';
import { createIssueRankRemote, createNotImplementedIssueRankRemote } from './rank/index.tsx';
import type { IssuesRemote, SharingIssuesRemote } from './types.tsx';
import {
	createIssueWatchersRemote,
	createNotImplementedIssueWatchersRemote,
	createSharingIssueWatchersRemote,
} from './watchers/index.tsx';
import {
	createIssueWorkflowRemote,
	createNotImplementedIssueWorkflowRemote,
	createSharingIssueWorkflowRemote,
	createJqlIssueWorkflowRemote,
} from './workflow/index.tsx';
import {
	createIssueGoalsRemote,
	createNotImplementedIssueGoalsRemote,
	createSharingIssueGoalsRemote,
} from './goals/index.tsx';

export const createNotImplementedIssueRemote = (): IssuesRemote => ({
	...createNotImplementedIssueAttachmentsRemote(),
	...createNotImplementedIssueCommentRemote(),
	...createNotImplementedIssueCrudRemote(),
	...createNotImplementedIssueDeliveryRemote(),
	...createNotImplementedIssueInsightsRemote(),
	...createNotImplementedIssueLinkRemote(),
	...createNotImplementedIssueRankRemote(),
	...createNotImplementedIssueWatchersRemote(),
	...createNotImplementedIssueWorkflowRemote(),
	...createNotImplementedIssueGoalsRemote(),
});

export const createIssueRemote = (
	apolloClient: PolarisApolloClient,
	cloudId: CloudId,
	projectId: ProjectId,
): IssuesRemote => ({
	...createIssueAttachmentsRemote(),
	...createIssueCommentRemote(apolloClient, cloudId, projectId),
	...createIssueCrudRemote(apolloClient, cloudId, projectId),
	...createIssueDeliveryRemote(),
	...createIssueInsightsRemote(apolloClient, cloudId),
	...createIssueLinkRemote(cloudId),
	...createIssueRankRemote(projectId),
	...createIssueWatchersRemote(),
	...createIssueWorkflowRemote(projectId),
	...createIssueGoalsRemote(apolloClient),
});

export const createSharingIssueRemote = (
	viewId: string,
	cloudId: CloudId,
): SharingIssuesRemote => ({
	...createSharingIssueAttachmentsRemote(viewId),
	...createSharingIssueCommentRemote(cloudId),
	...createSharingIssueCrudRemote(viewId),
	...createSharingIssueDeliveryRemote(viewId),
	...createNotImplementedIssueInsightsRemote(),
	...createSharingIssueLinkRemote(),
	...createNotImplementedIssueRankRemote(),
	...createSharingIssueWatchersRemote(),
	...createSharingIssueWorkflowRemote(viewId),
	...createSharingIssueGoalsRemote(),
});

export const createJqlIssueRemote = (
	apolloClient: PolarisApolloClient,
	cloudId: CloudId,
	jql: string,
): IssuesRemote => ({
	...createNotImplementedIssueRankRemote(),
	...createNotImplementedIssueGoalsRemote(),
	...createIssueInsightsRemote(apolloClient, cloudId),
	...createJqlIssueCrudRemote(jql),
	...createJqlIssueWorkflowRemote(),
	...createJqlIssueDeliveryRemote(),
	...createIssueWatchersRemote(),
	...createIssueAttachmentsRemote(),
	...createIssueLinkRemote(cloudId),
	...createCollectionIssueCommentRemote(),
});
