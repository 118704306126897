import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'polaris-component-collection-configure-sidebar.default-content.collection-name-input.error-title',
		defaultMessage: 'We couldn’t update the roadmap',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-component-collection-configure-sidebar.default-content.collection-name-input.error-description',
		defaultMessage: 'Please try again or refresh the page.',
		description: 'Flag description for an error occurring while updating a roadmap.',
	},
	inputLabel: {
		id: 'polaris-component-collection-configure-sidebar.default-content.collection-name-input.input-label',
		defaultMessage: 'Name',
		description: 'Label for the roadmap name fields in the roadmap configuration sidebar',
	},
	collectionNameTooLongError: {
		id: 'polaris-component-collection-configure-sidebar.default-content.collection-name-input.collection-name-too-long-error',
		defaultMessage: 'Roadmap names are limited to 100 characters',
		description: 'Error message for too long roadmap name',
	},
	collectionNameRequiredError: {
		id: 'polaris-component-collection-configure-sidebar.default-content.collection-name-input.collection-name-required-error',
		defaultMessage: 'Roadmap name is required',
		description: 'Message to describe error that roadmap name is required',
	},
});
