import Heading from '@atlaskit/heading';
import { Stack } from '@atlaskit/primitives';
import { JiraSideNavMessage } from '@post-office/components--jira-side-nav-message';
import { useMessageContext } from '@post-office/message-context';
import { type ComponentProps, type FunctionComponent } from 'react';

import { CTAButton } from './components/cta';
import { type App } from './types';

export type Props = {
	app: App;
	/** The default background image of the panel, defaults to 'hills' */
	/** Should be used for hiding the message when dismissed. The messageDismissed event should not need to be fired using this. It will be fired internally within the message */
	onDismissed?: (messageInstanceId: string) => void;
	whyAmISeeingThisModalContent?: ComponentProps<
		typeof JiraSideNavMessage
	>['whyAmISeeingThisModalContent'];
	extraAnalyticsAttributes?: Record<string, string>;
};

type PropsWithApp = {
	app: App;
};

const BodyContent: FunctionComponent<PropsWithApp> = ({ app }) => {
	return (
		<Stack space="space.100">
			<div>{app.logo}</div>
			<Heading size="xsmall">{app.header}</Heading>
			<CTAButton app={app} />
		</Stack>
	);
};

export const JiraSideNavFirstPartyRecommendation = ({
	app,
	onDismissed,
	whyAmISeeingThisModalContent,
}: Props) => {
	const { messageInstanceId } = useMessageContext();
	if (!messageInstanceId) {
		throw new Error('messageInstanceId is required');
	}
	return (
		<JiraSideNavMessage
			onDismissed={onDismissed}
			whyAmISeeingThisModalContent={whyAmISeeingThisModalContent}
			bodyContent={<BodyContent app={app} />}
		/>
	);
};
