/** @jsx jsx */
import React, { useMemo, useRef, useState, useCallback, type ComponentType } from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import Button, { IconButton, LinkButton } from '@atlaskit/button/new';
import { Emoji } from '@atlaskit/emoji';
import LinkExternal from '@atlaskit/icon/core/migration/link-external--shortcut';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { Flex, Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type {
	GroupOptionsType,
	OptionType,
	SelectProps,
} from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/types.tsx';
import {
	useCanManageGlobalFields,
	useIsCollectionView,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import { useOpenRightSidebarOnCreate } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import type { RightSidebarOpeningSource } from '../../../controllers/right-sidebar/types.tsx';
import { EmojiWrapper } from '../../field-config/item/emoji-wrapper/index.tsx';
import { CreateFieldButton } from '../create-field/index.tsx';
import { messages } from './messages.tsx';

type FieldTriggerProps = {
	field: Field;
};

export const FieldItem = ({ field }: FieldTriggerProps) => {
	const icon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	return (
		<Flex alignItems="center">
			{emoji && emojiId !== undefined ? (
				<EmojiWrapper>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</EmojiWrapper>
			) : (
				<Flex alignItems="center" xcss={fieldIconStyles}>
					{icon}
				</Flex>
			)}
			<Box xcss={fieldNameStyles}>{field.label}</Box>
		</Flex>
	);
};

type FieldSelectProps = {
	inputId?: string;
	isClearable?: boolean;
	selectedField?: Field;
	fieldOptions: (OptionType | GroupOptionsType)[];
	SearchableComponent: ComponentType<SelectProps>;
	onChange: (fieldKey?: FieldKey) => void;
	canCreateField?: boolean;
	onFieldCreate?: (fieldKey: FieldKey) => void;
	isSearchable?: boolean;
	sidebarOpeningSource?: RightSidebarOpeningSource;
	isDisabled?: boolean;
	createGlobalFieldLabel?: string;
	noOptionsMessage?: SelectProps['noOptionsMessage'];
};

export const FieldSelect = ({
	inputId,
	isClearable = false,
	selectedField,
	fieldOptions,
	onChange,
	SearchableComponent,
	canCreateField,
	onFieldCreate = noop,
	isSearchable,
	sidebarOpeningSource,
	isDisabled = false,
	createGlobalFieldLabel,
	noOptionsMessage,
}: FieldSelectProps) => {
	const { formatMessage } = useIntl();
	const selectFieldMsg = useMemo(() => formatMessage(messages.notSelectedField), [formatMessage]);

	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const toggleDialog = useCallback(() => setDialogOpen(!dialogOpen), [dialogOpen]);
	const onClear = useCallback(() => onChange(undefined), [onChange]);
	const openRightSidebarOnCreate = useOpenRightSidebarOnCreate(sidebarOpeningSource);
	const [fieldSearchString, setFieldSearchString] = useState('');
	const [isCollectionView] = useIsCollectionView();
	const canManageGlobalFields = useCanManageGlobalFields();

	const handleOpenRightSidebarOnCreate = useCallback(() => {
		openRightSidebarOnCreate(fieldSearchString, onFieldCreate);
	}, [openRightSidebarOnCreate, fieldSearchString, onFieldCreate]);

	return (
		<PolarisInlineDialog
			noPadding
			onClose={(target) => {
				if (buttonRef.current && !buttonRef.current.contains(target)) {
					setDialogOpen(false);
				}
			}}
			isOpen={dialogOpen}
			placement="bottom-start"
			content={
				<>
					<SearchableComponent
						inputId={inputId}
						options={fieldOptions}
						onSelect={(fieldKey) => {
							onChange(fieldKey);
							setDialogOpen(false);
						}}
						onClose={() => setDialogOpen(false)}
						onSearch={setFieldSearchString}
						isSearchable={isSearchable}
						noOptionsMessage={noOptionsMessage}
					/>
					{!isCollectionView && canCreateField && (
						<div css={createFieldButtonContainerStyles}>
							<CreateFieldButton onCreate={handleOpenRightSidebarOnCreate} />
						</div>
					)}
					{isCollectionView && canManageGlobalFields && createGlobalFieldLabel && (
						<Box padding="space.100">
							<LinkButton
								href="/jira/settings/products/jira-product-discovery/global-fields"
								target="_blank"
								shouldFitContainer
								iconAfter={LinkExternal}
							>
								{createGlobalFieldLabel}
							</LinkButton>
						</Box>
					)}
				</>
			}
		>
			<Flex justifyContent="space-between" alignItems="center">
				<Box xcss={buttonWrapperStyles}>
					<Button
						id="pendo.field-select.button"
						testId="polaris-common.ui.common.field-select.button"
						ref={buttonRef}
						isSelected={dialogOpen}
						onClick={toggleDialog}
						iconAfter={(iconProps) => <ChevronDownIcon {...iconProps} />}
						shouldFitContainer
						isDisabled={isDisabled}
					>
						{selectedField ? (
							<FieldItem field={selectedField} />
						) : (
							<Inline alignInline="start" grow="fill">
								{selectFieldMsg}
							</Inline>
						)}
					</Button>
				</Box>
				{isClearable && selectedField !== undefined && (
					<Box paddingInlineStart="space.050">
						<IconButton
							appearance="subtle"
							onClick={onClear}
							label={formatMessage(messages.clearSelectionButton)}
							icon={(iconProps) => <TrashIcon size="small" {...iconProps} />}
							isDisabled={isDisabled}
						/>
					</Box>
				)}
			</Flex>
		</PolarisInlineDialog>
	);
};

const fieldIconStyles = xcss({
	marginInlineEnd: 'space.100',
});

const fieldNameStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const buttonWrapperStyles = xcss({
	flexGrow: 1,
	minWidth: '0',
});

const createFieldButtonContainerStyles = css({
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	boxShadow: token('elevation.shadow.raised'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& button, && button:hover': {
		textAlign: 'center',
	},
});
