/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useSelectedIssuesConnectionFieldIssuesCount } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import { useCanConnectIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { CollapsibleGroup } from './collapsible-group/index.tsx';
import { MAX_VISIBLE_ROWS_COUNT, ROW_HEIGHT } from './constants.tsx';
import { BORDER_COLOR, ROW_BORDER_WIDTH } from './content/constants.tsx';
import { Content } from './content/index.tsx';
import { Header } from './header/index.tsx';

type Props = {
	fieldKey: FieldKey;
	issueTypeId: IssueTypeId;
	isCollapsed: boolean;
	onConfigure: VoidFunction;
	onToggle: (fieldKey: FieldKey) => void;
};

export const ConnectionSection = ({
	fieldKey,
	issueTypeId,
	onConfigure,
	isCollapsed,
	onToggle,
}: Props) => {
	const issuesCount = useSelectedIssuesConnectionFieldIssuesCount(fieldKey);
	const canConnectIssues = useCanConnectIssues();
	const isIssueArchived = useIsSelectedIssueArchived();
	const hasFooter = canConnectIssues && !isIssueArchived;

	let minRowsCount = 1;
	if (issuesCount === 0) {
		minRowsCount += 1;
	}
	if (hasFooter) {
		minRowsCount += 1;
	}
	const bodyHeight = (Math.min(issuesCount, MAX_VISIBLE_ROWS_COUNT) + minRowsCount) * ROW_HEIGHT;

	const handleToggle = useCallback(() => {
		onToggle(fieldKey);
	}, [fieldKey, onToggle]);

	return (
		<CollapsibleGroup
			isCollapsed={isCollapsed}
			onToggle={handleToggle}
			title={
				<Header
					fieldKey={fieldKey}
					issueTypeId={issueTypeId}
					issuesCount={issuesCount}
					onConfigure={onConfigure}
				/>
			}
		>
			<div
				css={containerStyles}
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
				style={{ height: bodyHeight }}
			>
				<Content fieldKey={fieldKey} hasFooter={hasFooter} />
			</div>
		</CollapsibleGroup>
	);
};

const containerStyles = css({
	overflow: 'hidden',
	borderRadius: token('border.radius.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	border: `${ROW_BORDER_WIDTH}px solid ${BORDER_COLOR}`,
});
