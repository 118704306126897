import { useEffect, useState } from 'react';
import intersection from 'lodash/intersection';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	createStore,
	createActionsHook,
	createSelector,
	createStateHook,
} from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	configurations: {},
};

const Store = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'PolarisIssueTypesConnectionsConfigurationStore',
});

export const useIssueTypesConnectionsActions = createActionsHook(Store);

const getConfigurations = (state: State) => state.configurations;
const getConnectionsForIssueType = createSelector(
	getConfigurations,
	(_, { issueTypeId }: { issueTypeId: IssueTypeId }) => issueTypeId,
	(configurations, issueTypeId) => configurations[issueTypeId],
);

export const useConnectionsFieldKeysForIssueType = createStateHook(Store, {
	selector: getConnectionsForIssueType,
});

type UseGetOrLoadConnectionsConfigurationForIssueType = {
	isLoading: boolean;
	configuration: FieldKey[];
	error?: Error;
};

export const useConnectionsConfigurationForIssueType = (
	issueTypeId: IssueTypeId,
	defaultConfiguration: FieldKey[],
): UseGetOrLoadConnectionsConfigurationForIssueType => {
	const { loadConfiguration } = useIssueTypesConnectionsActions();
	const configuration = useConnectionsFieldKeysForIssueType({ issueTypeId });
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error>();

	useEffect(() => {
		if (configuration || isLoading || error) {
			return;
		}
		setIsLoading(true);
		loadConfiguration(issueTypeId, defaultConfiguration)
			.catch(setError)
			.finally(() => setIsLoading(false));
	}, [configuration, issueTypeId, loadConfiguration, defaultConfiguration, isLoading, error]);

	useEffect(
		() => () => {
			setError(undefined);
		},
		[issueTypeId],
	);

	return {
		isLoading: isLoading || (!configuration && !error),
		// we use intersection here to ensure we don't show deleted connection fields that were saved previously
		configuration: intersection(configuration, defaultConfiguration) ?? [],
		error,
	};
};
