import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	editRule: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.item.edit-rule',
		defaultMessage: 'Edit rule',
		description: 'Button to edit the current rule.',
	},
	deleteRule: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.item.delete-rule',
		defaultMessage: 'Delete rule',
		description: 'Button to delete the current rule.',
	},
	greaterThan: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.item.greater-than',
		defaultMessage: 'Values greater than',
		description: 'Input label for formatting options for values greater than a certain number',
	},
	lessThan: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.item.less-than',
		defaultMessage: 'Values less than',
		description: 'Input label for formatting options for values less than a certain number',
	},
	between: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.item.between',
		defaultMessage: 'Values between',
		description: 'Input label for formatting options for values between two numbers',
	},
});
