import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { notImplementedError } from '../../common/utils.tsx';
import { linkAtlasGoals, unLinkAtlasGoals } from '../../services/atlas/goals/index.tsx';
import type { IssueGoalsRemote } from './types.tsx';

export const createNotImplementedIssueGoalsRemote = (): IssueGoalsRemote => ({
	linkGoals: notImplementedError('linkGoals'),
	unlinkGoals: notImplementedError('unlinkGoals'),
});

export const createSharingIssueGoalsRemote = (): IssueGoalsRemote => ({
	...createNotImplementedIssueGoalsRemote(),
});

export const createIssueGoalsRemote = (apolloClient: PolarisApolloClient): IssueGoalsRemote => ({
	linkGoals: (request) => linkAtlasGoals(apolloClient, request),
	unlinkGoals: (request) => unLinkAtlasGoals(apolloClient, request),
});
