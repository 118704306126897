import React, { memo, useCallback, useMemo, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import lowerCase from 'lodash/lowerCase';
import toString from 'lodash/toString';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useOpenRightSidebarGroupByOptions,
	useOpenRightSidebarVerticalGroupByOptions,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	RightSidebarShowingGroupByOptions,
	RightSidebarShowingVerticalGroupByOptions,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import {
	useHasGroupByUnsavedChanges,
	useHasVerticalGroupByUnsavedChanges,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { FieldItem } from '@atlassian/jira-polaris-common/src/ui/common/field-select/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { ActionButton } from '../action-button/index.tsx';
import { messages } from './messages.tsx';

type ConfigColumnsProps = {
	groupBy?: {
		selectedField?: Field;
	};
	verticalGroupBy?: {
		selectedField?: Field;
	};
};

const ConfigColumnsLegacy = ({ groupBy, verticalGroupBy }: ConfigColumnsProps) => {
	const [sidebarShowing] = useRightSidebarShowing();
	const openGroupByOptions = useOpenRightSidebarGroupByOptions();
	const openVerticalGroupByOptions = useOpenRightSidebarVerticalGroupByOptions();
	const viewKind = useCurrentViewKind();
	const { formatMessage } = useIntl();

	const handleOpenColumnsOptions = useCallback(
		(event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
			fireCompoundAnalyticsEvent.ViewControls.openColumnsSidebarButtonClicked(analyticsEvent);
			openGroupByOptions();
		},
		[openGroupByOptions],
	);

	const handleVerticalOpenGroupByOptions = useCallback(
		(event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
			fireCompoundAnalyticsEvent.ViewControls.openGroupBySidebarButtonClicked(analyticsEvent);
			experience.headerView.viewGroupBy.start();
			openVerticalGroupByOptions();
		},
		[openVerticalGroupByOptions],
	);

	const testId = useMemo(
		() =>
			`polaris-ideas.ui.view-control.config-columns.group-by-dropdown.${lowerCase(
				toString(viewKind),
			)}`,
		[viewKind],
	);

	const hasGroupByUnsavedChanges = useHasGroupByUnsavedChanges();
	const hasVerticalGroupByUnsavedChanges = useHasVerticalGroupByUnsavedChanges();

	return (
		<ConfigContainer
			data-testid={testId}
			id="polaris-ideas.ui.view-control.config-columns.group-by-dropdown"
		>
			{groupBy && (
				<>
					{isVisualRefreshEnabled() ? (
						<ActionButton
							id="polaris.ideas.ui.view-controls.open-columns-sidebar"
							testId="polaris-ideas.ui.view-controls.config-columns.open-columns-sidebar"
							isSelected={sidebarShowing.mode === RightSidebarShowingGroupByOptions}
							isEmpty={groupBy.selectedField === undefined}
							onClick={handleOpenColumnsOptions}
							hasUnsavedChanges={hasGroupByUnsavedChanges}
							elemAfter={groupBy.selectedField ? <FieldItem field={groupBy.selectedField} /> : null}
						>
							<FieldGroup>{formatMessage(messages.columnsLabel)}</FieldGroup>
						</ActionButton>
					) : (
						<ActionButton
							id="polaris.ideas.ui.view-controls.open-columns-sidebar"
							testId="polaris-ideas.ui.view-controls.config-columns.open-columns-sidebar"
							isSelected={sidebarShowing.mode === RightSidebarShowingGroupByOptions}
							isEmpty={groupBy.selectedField === undefined}
							onClick={handleOpenColumnsOptions}
							hasUnsavedChanges={hasGroupByUnsavedChanges}
						>
							<FieldGroup>
								{formatMessage(messages.columnsLabel)}
								{groupBy.selectedField && (
									<FieldWrapper>
										<FieldItem field={groupBy.selectedField} />
									</FieldWrapper>
								)}
							</FieldGroup>
						</ActionButton>
					)}
				</>
			)}
			{(verticalGroupBy && groupBy && groupBy.selectedField) || (verticalGroupBy && !groupBy) ? (
				<>
					{isVisualRefreshEnabled() ? (
						<ActionButton
							id="polaris.ideas.ui.view-controls.open-group-by-sidebar"
							testId="polaris-ideas.ui.view-controls.config-columns.open-group-by-sidebar"
							isSelected={sidebarShowing.mode === RightSidebarShowingVerticalGroupByOptions}
							isEmpty={verticalGroupBy.selectedField === undefined}
							onClick={handleVerticalOpenGroupByOptions}
							hasUnsavedChanges={hasVerticalGroupByUnsavedChanges}
							elemAfter={
								verticalGroupBy.selectedField ? (
									<FieldItem field={verticalGroupBy.selectedField} />
								) : null
							}
						>
							<FieldGroup>{formatMessage(messages.groupByLabel)}</FieldGroup>
						</ActionButton>
					) : (
						<ActionButton
							id="polaris.ideas.ui.view-controls.open-group-by-sidebar"
							testId="polaris-ideas.ui.view-controls.config-columns.open-group-by-sidebar"
							isSelected={sidebarShowing.mode === RightSidebarShowingVerticalGroupByOptions}
							isEmpty={verticalGroupBy.selectedField === undefined}
							onClick={handleVerticalOpenGroupByOptions}
							hasUnsavedChanges={hasVerticalGroupByUnsavedChanges}
						>
							<FieldGroup>
								{formatMessage(messages.groupByLabel)}
								{verticalGroupBy.selectedField ? (
									<FieldWrapper>
										<FieldItem field={verticalGroupBy.selectedField} />
									</FieldWrapper>
								) : null}
							</FieldGroup>
						</ActionButton>
					)}
				</>
			) : null}
		</ConfigContainer>
	);
};

const ConfigColumnsNext = memo(ConfigColumnsLegacy);

const ConfigColumns = componentWithFG(
	'polaris_idea_list_performance_improvements',
	ConfigColumnsNext,
	ConfigColumnsLegacy,
);

export default ConfigColumns;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfigContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldGroup = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldWrapper = styled.div({
	marginLeft: token('space.075', '6px'),
	maxWidth: 'clamp(50px, 10vw, 150px)',
});
