import React, { useMemo, useCallback, useEffect, useState } from 'react';
import Button, { LoadingButton } from '@atlaskit/button';
import Form from '@atlaskit/form';
import UploadIcon from '@atlaskit/icon/core/upload';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import EditorSettingsIcon from '@atlaskit/icon/glyph/editor/settings';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import ToolTip from '@atlaskit/tooltip';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { CopyLink } from '@atlassian/jira-polaris-common/src/ui/copy-link/index.tsx';
import {
	useProjectId,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypeIdForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useIsPreviewContributorMode } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useIsProjectAdmin,
	useIsAdmin,
	useIsSiteAdmin,
	useCanImportIssues,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { prepareForSave } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	MODAL,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { ValidValidationError } from '@atlassian/jira-polaris-remote-issue/src/services/jira/create-idea-rest/index.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { isIssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/index.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useIssueActions } from '../../../controllers/issue/index.tsx';
import { useSubmitIdeaFormView } from '../../../controllers/project-properties/index.tsx';
import { usePolarisRouter } from '../../../controllers/route/index.tsx';
import {
	useUserPropertiesActions,
	useUserProperty,
} from '../../../controllers/user-properties/index.tsx';
import {
	useAllIdeasViewId,
	useViewSlug,
} from '../../../controllers/views/selectors/view-hooks.tsx';
import ConfirmDismissModal from '../confirmation-dialog/index.tsx';
import { IssueCreationForm } from '../creation-form/index.tsx';
import { LeavePageWarning } from '../leave-page-warning/index.tsx';
import { useOnSubmittedIssue } from '../notification/index.tsx';
import { useTryApplyFiltersToIssuePayload } from '../utils.tsx';
import { logPolarisError } from '../../../common/utils/errors/index.tsx';
import messages from './messages.tsx';
import { ProjectInfo } from './project-info/index.tsx';

const CREATE_IDEA_FORM_MODAL_HINT_CLOSED_KEY = 'polaris.create-idea-form-modal-hint-closed';
const IMPORT_ISSUES_URL =
	'/secure/BulkCreateSetupPage!default.jspa?externalSystem=com.atlassian.jira.plugins.jim-plugin%3AbulkCreateCsv';

export const handleError = (error: Error) => {
	if (fg('polaris-slo-user-can-create-idea-global-nav')) {
		if (
			error &&
			(error instanceof ValidValidationError ||
				error instanceof ValidationError ||
				isClientFetchError(error))
		) {
			experience.issues.globalJpdIssueCreate.abort();
		} else {
			// failure() for errors other than validation and client fetch errors
			experience.issues.globalJpdIssueCreate.failure(error);
		}
	} else {
		// to keep the old behavior with polaris-slo-user-can-create-idea-global-nav
		if (!(error && error instanceof ValidValidationError)) {
			if (!isClientFetchError(error)) {
				experience.issues.globalJpdIssueCreate.failure(error);
			} else {
				experience.issues.globalJpdIssueCreate.abort();
			}
		}
		experience.issues.globalJpdIssueCreate.abort();
	}
};

export type IssueFormData = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

export const IssueCreationModalDialog = ({
	setIsOpen,
	isOpenModal,
}: {
	isOpenModal: boolean;
	setIsOpen: (isOpen: boolean) => void;
}) => {
	const [isCreating, setCreating] = useState(false);
	const [isModalFinishOpening, setModalFinishOpening] = useState(false);
	const [shouldShowConfirmationDialog, setShouldShowConfirmationDialog] = useState(false);
	const { formatMessage } = useIntl();
	const { submitIdea } = useIssueActions();

	const projectId = useProjectId();
	const issueTypeId = useIssueTypeIdForProject({ projectId });

	const projectKey = useProjectKeyUnsafe();
	const onSubmittedIssueSuccessfully = useOnSubmittedIssue();
	const [isPreviewContributorMode] = useIsPreviewContributorMode();
	const { routeTo, setQuery } = usePolarisRouter();

	const [isProjectAdmin] = useIsProjectAdmin();
	const isSiteAdmin = useIsSiteAdmin();
	const isAdmin = useIsAdmin();
	const canImportIssues = useCanImportIssues();
	const allIdeasViewId = useAllIdeasViewId();
	const allIdeasViewSlug = useViewSlug(allIdeasViewId);

	const viewId = useSubmitIdeaFormView();

	const applyFiltersToIssuePayload = useTryApplyFiltersToIssuePayload(viewId.value);

	const [, { loadProperty, saveProperty }] = useUserPropertiesActions();
	const [{ value: isHintClosed, isLoading: isLoadingHint, error: hintClosedError }] =
		useUserProperty(CREATE_IDEA_FORM_MODAL_HINT_CLOSED_KEY);

	const isSpotlightOpen = isHintClosed !== true && hintClosedError === null && !isLoadingHint;

	const shouldShowSpotlight = useMemo(
		() => isPreviewContributorMode && isModalFinishOpening && isSpotlightOpen,
		[isSpotlightOpen, isModalFinishOpening, isPreviewContributorMode],
	);

	useEffect(() => {
		loadProperty(CREATE_IDEA_FORM_MODAL_HINT_CLOSED_KEY);
	}, [loadProperty]);

	const clearSearchParam = useCallback(() => {
		setQuery({ openIdeaCreationModal: undefined }, 'replace');
	}, [setQuery]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);
		const isCreationModalOpen = params.get('openIdeaCreationModal');
		if (isCreationModalOpen) {
			setIsOpen(true);
		}
	}, [setIsOpen]);

	const handleCloseHint = useCallback(() => {
		saveProperty(CREATE_IDEA_FORM_MODAL_HINT_CLOSED_KEY, true);
	}, [saveProperty]);

	const onSubmitIdea = useCallback(
		(fieldsToUpdate: IssueFormData) => {
			if (!issueTypeId) {
				return;
			}
			const issueTypeValue = fieldsToUpdate[ISSUETYPE_FIELDKEY];

			submitIdea(
				issueTypeValue && isIssueTypeFieldValue(issueTypeValue) && fg('jpd_issues_relationships')
					? issueTypeValue.id
					: issueTypeId,
				fieldsToUpdate.summary,
				fieldsToUpdate,
				(issueKey?: IssueKey, isFiltered?: boolean) => {
					setIsOpen(false);
					clearSearchParam();
					onSubmittedIssueSuccessfully(issueKey, isFiltered);
					experience.issues.globalJpdIssueCreate.success();
				},
				() => setCreating(false),
				(error) => {
					// Full key for splunk search 'polaris-error.create-idea-dialog.form-modal-dialog.submit-idea'
					logPolarisError(
						'create-idea-dialog.form-modal-dialog.submit-idea',
						error || new Error('Unknown error'),
					);
					handleError(error);
				},
				'createIdeaModal',
			);
		},
		[submitIdea, issueTypeId, setIsOpen, clearSearchParam, onSubmittedIssueSuccessfully],
	);

	const onSubmit = useCallback(
		(data: IssueFormData) => {
			const descriptionForSave = data.description
				? prepareForSave(data.description)
				: data.description;
			const transformedData = {
				...data,
				description: descriptionForSave,
			};
			setCreating(true);
			const payloadWithAppliedFilters = applyFiltersToIssuePayload(transformedData);
			onSubmitIdea(payloadWithAppliedFilters);
		},
		[applyFiltersToIssuePayload, onSubmitIdea],
	);

	const onModalCloseWithConfirm = useCallback(
		(isDirty: boolean) => {
			if (isDirty) {
				setShouldShowConfirmationDialog(true);
			} else {
				setIsOpen(false);
				clearSearchParam();
				experience.issues.globalJpdIssueCreate.abort();
			}
		},
		[setIsOpen, clearSearchParam],
	);

	const onConfirmDiscardIssue = useCallback(() => {
		setShouldShowConfirmationDialog(false);
		setIsOpen(false);
		clearSearchParam();
		experience.issues.globalJpdIssueCreate.abort();
	}, [setIsOpen, clearSearchParam]);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onFormSettingsClicked = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({ action: 'clicked', actionSubject: 'formbutton' }));
		setIsOpen(false);
		clearSearchParam(); // clear search param needs to be called before routeTo
		routeTo({
			section: 'settings',
			resource: 'create-ideas',
		});
		experience.issues.globalJpdIssueCreate.abort('User navigated to settings');
	}, [routeTo, setIsOpen, clearSearchParam, createAnalyticsEvent]);

	const onProjectSwitch = useCallback(() => {
		setIsOpen(false);
		clearSearchParam();
		experience.issues.globalJpdIssueCreate.abort('User switched project');
	}, [setIsOpen, clearSearchParam]);

	const viewIdValue = useMemo(() => viewId.value, [viewId.value]);

	const getCopyUrl = useCallback(() => {
		const viewSlug = viewIdValue ? viewIdValue.split('/')[1] : allIdeasViewSlug;
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return `${window.location.origin}/jira/polaris/projects/${projectKey}/ideas/view/${viewSlug}?openIdeaCreationModal=true`;
	}, [viewIdValue, projectKey, allIdeasViewSlug]);

	const renderConfirmationModalDialog = () => {
		if (shouldShowConfirmationDialog) {
			return (
				<ConfirmDismissModal
					onConfirm={onConfirmDiscardIssue}
					onCancel={() => setShouldShowConfirmationDialog(false)}
				/>
			);
		}
		return null;
	};

	return (
		<>
			<ModalTransition>
				{isOpenModal && (
					<ContextualAnalyticsData sourceName="createIdea" sourceType={MODAL}>
						<ShortcutScope>
							<Modal
								testId="polaris-common.ui.create-idea-dialog.form-modal-dialog.issue-creation-form-modal"
								width="large"
								autoFocus={false}
								shouldCloseOnOverlayClick={false}
								onOpenComplete={() => setModalFinishOpening(true)}
							>
								<Form onSubmit={onSubmit}>
									{({ formProps, setFieldValue, dirty }) => (
										<>
											<ModalHeader>
												<Box xcss={titleStyles}>
													<ModalTitle>
														{fg('jpd_issues_relationships')
															? formatMessage(messages.createIdeaMessage)
															: formatMessage(messages.createIdeaMessageLegacy)}
													</ModalTitle>
													<Box xcss={copyLinkStyles}>
														<CopyLink size="small" appearance="subtle-link" url={getCopyUrl()} />
													</Box>
												</Box>
												<Box xcss={titleStyles}>
													{expVal(
														'jpd_create_idea_template_experiment',
														'ideaCreateFormExperimentEnabled',
														false,
													) ? (
														<>
															{(isProjectAdmin || isAdmin || isSiteAdmin) && (
																<Button onClick={onFormSettingsClicked}>
																	{formatMessage(messages.configureFormBtn)}
																</Button>
															)}
															{canImportIssues && (
																<ToolTip
																	position="top"
																	content={formatMessage(messages.importIssues)}
																>
																	<Box xcss={importBtnStyles}>
																		<Button
																			shouldFitContainer
																			appearance="subtle"
																			href={IMPORT_ISSUES_URL}
																			iconBefore={
																				<UploadIcon label={formatMessage(messages.importIssues)} />
																			}
																			testId="polaris-common.ui.create-idea-dialog.form-modal-dialog.import-issues-button"
																			onClick={() => {
																				fireUIAnalytics(
																					createAnalyticsEvent({
																						action: 'clicked',
																						actionSubject: 'importButton',
																					}),
																				);
																			}}
																		/>
																	</Box>
																</ToolTip>
															)}
														</>
													) : (
														<ToolTip
															position="top"
															content={formatMessage(messages.customizeFormBtn)}
														>
															<Button
																appearance="subtle"
																onClick={onFormSettingsClicked}
																iconBefore={
																	<EditorSettingsIcon
																		label={formatMessage(messages.customizeFormBtn)}
																		size="medium"
																	/>
																}
															/>
														</ToolTip>
													)}
													<ToolTip position="top" content={formatMessage(messages.close)}>
														<Button
															appearance="subtle"
															onClick={() => onModalCloseWithConfirm(dirty)}
															iconBefore={<CrossIcon label={formatMessage(messages.close)} />}
														/>
													</ToolTip>
												</Box>
											</ModalHeader>
											<ModalBody>
												<FireScreenAnalytics />
												<ProjectInfo onProjectSwitch={onProjectSwitch} />
												<form {...formProps} id="idea-create.ui.modal.create-form">
													<IssueCreationForm
														setFieldValue={setFieldValue}
														isPreview={isPreviewContributorMode && isSpotlightOpen}
													/>
												</form>
											</ModalBody>
											<ModalFooter>
												<Button
													isDisabled={isCreating}
													appearance="subtle"
													onClick={() => onModalCloseWithConfirm(dirty)}
												>
													{formatMessage(messages.cancelButton)}
												</Button>
												<SpotlightTarget name="contributor-create-idea-form-modal">
													<LoadingButton
														id="polaris.common.src.ui.create-idea-dialog.submit-idea-loading-button"
														appearance="primary"
														form="idea-create.ui.modal.create-form"
														type="submit"
														isLoading={isCreating}
													>
														{fg('jpd_issues_relationships')
															? formatMessage(messages.createButton)
															: formatMessage(messages.createButtonLegacy)}
													</LoadingButton>
												</SpotlightTarget>
											</ModalFooter>
											<LeavePageWarning isDirty={dirty} />
										</>
									)}
								</Form>
							</Modal>
						</ShortcutScope>
					</ContextualAnalyticsData>
				)}
			</ModalTransition>
			<SpotlightTransition>
				{shouldShowSpotlight && (
					<JiraSpotlight
						actions={[
							{
								onClick: handleCloseHint,
								text: formatMessage(messages.spotlightCloseBtn),
							},
						]}
						target="contributor-create-idea-form-modal"
						key="contributor-create-idea-form-modal"
						dialogPlacement="top right"
						targetRadius={3}
						messageId="polaris-common.ui.create-idea-dialog.form-modal-dialog.jira-spotlight.contributor-create-idea-form-modal"
						messageType="transactional"
					>
						{formatMessage(messages.spotlightCreateIdeaBtn)}
					</JiraSpotlight>
				)}
			</SpotlightTransition>
			{renderConfirmationModalDialog()}
		</>
	);
};

const titleStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
});

const copyLinkStyles = xcss({
	marginLeft: 'space.050',
	marginTop: 'space.100',
});

const importBtnStyles = xcss({
	width: '32px',
});
