import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import {
	useTeamField,
	type UseTeamField,
} from '@atlassian/jira-issue-field-team/src/services/index.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const createUseTeamFieldOverride =
	({
		issueKey,
		fieldKey,
		canEditIssues,
		initialValue,
		updateFieldValue,
	}: {
		issueKey: string;
		fieldKey: string;
		canEditIssues: boolean;
		initialValue?: TeamValue;
		updateFieldValue: (value: TeamValue | undefined, jpdExperience?: JPDExperience) => void;
	}): UseTeamField =>
	(_args) => {
		const [{ value, error, fieldConfig }, { resetError }] = useTeamField({
			issueKey: toIssueKey(issueKey),
			fieldKey,
			initialValue,
		});

		const fieldConfigOverride: FieldConfiguration<TeamValue> | null = !fieldConfig
			? null
			: {
					...fieldConfig,
					isEditable: canEditIssues,
				};

		return [
			{ value, error, fieldConfig: fieldConfigOverride },
			{
				saveValue: async (team) => {
					updateFieldValue(team);
				},
				resetError,
			},
		];
	};
