/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These exports are for client side use only
# Importing into post office backend without a bundler will break the build
#
# Generate source: local/cli/src/commands/generate-all/team-export.ts
*/
	import MessageTemplateTemplate from '@post-office/message-template--message-template-template/placements/in-app/jira-side-nav/render';

export default {
	'message-template-template': MessageTemplateTemplate,
};
