import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-component-collection-configure-sidebar.default-content.header.title',
		defaultMessage: 'Roadmap settings',
		description: 'Title for the roadmap configuration sidebar',
	},
	moreButtonLabel: {
		id: 'polaris-component-collection-configure-sidebar.default-content.header.more-button-label',
		defaultMessage: 'More actions',
		description: 'Label for button to show more options',
	},
	deleteRoadmapOptionLabel: {
		id: 'polaris-component-collection-configure-sidebar.default-content.header.delete-roadmap-option-label',
		defaultMessage: 'Delete roadmap',
		description: 'Label for option to delete a roadmap',
	},
});
