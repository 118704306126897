import React, { useCallback, useMemo, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import LockLockedIcon from '@atlaskit/icon/core/migration/lock-locked--lock-filled';
import LockUnlockedIcon from '@atlaskit/icon/core/migration/lock-unlocked--unlock-filled';
import type { NewIconProps } from '@atlaskit/icon/types';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentUser } from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewAccessLevel,
	useCurrentViewId,
	useCurrentViewKind,
	useCurrentViewUUID,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useLoadViewAccessAction } from '@atlassian/jira-polaris-component-view-access/src/controllers/hooks/index.tsx';
import { useActions } from '@atlassian/jira-polaris-component-view-access/src/controllers/main.tsx';
import {
	useCurrentViewAccess,
	useCurrentViewAccessConfig,
} from '@atlassian/jira-polaris-component-view-access/src/controllers/selectors/index.tsx';
import {
	type ViewAccessModalProps,
	ViewAccessModal,
} from '@atlassian/jira-polaris-component-view-access/src/ui/index.tsx';

import {
	PRINCIPAL_TYPES,
	VIEW_ACCESS_LEVELS,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useIsViewPermissionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { VIEW_PERMISSIONS_LOCK_SPOTLIGHT_DISMISSED } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/constants.tsx';
import { ViewPermissionsSpotlight } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/view-permissions/lock-button/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

const ViewAccess = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { generalDataUpdateFailedError } = useErrorHandlers();
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const viewUUID = useCurrentViewUUID();
	const { formatMessage } = useIntl();
	const {
		loadViewPermissionsConfig,
		updateViewAccessLevel,
		addViewAccessPrincipals,
		removeViewAccessPrincipals,
		changeViewAccessPrincipalRole,
		clearFailures,
		clearSkippedAccountFailures,
	} = useActions();
	const { isAddingPrincipals, error, failures, skippedAccountFailures } =
		useCurrentViewAccessConfig();
	const { accessLevel, additionalAccess } = useCurrentViewAccess();
	const currentUser = useCurrentUser();
	const canManageCurrentView = useCanManageCurrentView();
	const { cloneView, setCurrentViewAccessLevel } = useViewActions();
	const viewId = useCurrentViewId();
	const viewKind = useCurrentViewKind();
	const loadCurrentUserViewAccess = useLoadViewAccessAction();
	const viewAccessLevel = useCurrentViewAccessLevel();

	const viewAccessIcon = useMemo(() => {
		switch (viewAccessLevel) {
			case VIEW_ACCESS_LEVELS.OPEN:
				return LockUnlockedIcon;
			case VIEW_ACCESS_LEVELS.LIMITED:
				return LockLockedIcon;
			case VIEW_ACCESS_LEVELS.RESTRICTED:
				return (iconProps: Omit<NewIconProps, 'spacing'>) => (
					<LockLockedIcon {...iconProps} color={token('color.icon.accent.red')} />
				);
			default:
				return LockLockedIcon;
		}
	}, [viewAccessLevel]);

	const handleCloseModal = useCallback(() => {
		setIsModalOpen(false);
		clearFailures();
		clearSkippedAccountFailures();
	}, [clearSkippedAccountFailures, clearFailures]);

	const handleUpdateAccessLevel = useCallback(
		async (newAccessLevel: ViewAccessLevel) => {
			if (!viewUUID) {
				return;
			}

			setCurrentViewAccessLevel(newAccessLevel);

			await updateViewAccessLevel({
				viewUUID,
				viewId,
				accessLevel: newAccessLevel,
				createAnalyticsEvent,
				onActionFailed: generalDataUpdateFailedError,
			});

			loadCurrentUserViewAccess?.(viewUUID, true);
		},
		[
			viewUUID,
			setCurrentViewAccessLevel,
			updateViewAccessLevel,
			viewId,
			createAnalyticsEvent,
			generalDataUpdateFailedError,
			loadCurrentUserViewAccess,
		],
	);

	const handleOpenModal = useCallback(async () => {
		if (!viewUUID) {
			return;
		}

		experience.viewPermissions.openDialog.start();

		setIsLoading(true);
		setIsModalOpen(true);

		const [_, currentUserResult] = await Promise.all([
			loadViewPermissionsConfig({ viewUUID, viewId, createAnalyticsEvent }),
			loadCurrentUserViewAccess?.(viewUUID, true),
		]);

		if (currentUserResult?.state === 'no-access') {
			experience.viewPermissions.openDialog.abort();
			handleCloseModal();
		}

		setIsLoading(false);

		// https://data-portal.internal.atlassian.com/analytics/registry/69636
		fireUIAnalytics(createAnalyticsEvent({}), 'viewAccessButton clicked', {
			viewUUID,
			viewId,
			accessLevel: currentUserResult?.accessLevel,
		});
	}, [
		viewUUID,
		loadViewPermissionsConfig,
		viewId,
		createAnalyticsEvent,
		loadCurrentUserViewAccess,
		handleCloseModal,
	]);

	const handleAddPrincipals: ViewAccessModalProps['onAddPrincipals'] = useCallback(
		async ({ principals, role }) => {
			if (!viewUUID) {
				return;
			}

			await addViewAccessPrincipals({
				viewUUID,
				principals,
				role,
				viewId,
				createAnalyticsEvent,
				onActionFailed: generalDataUpdateFailedError,
			});
		},
		[viewUUID, addViewAccessPrincipals, viewId, createAnalyticsEvent, generalDataUpdateFailedError],
	);

	const handleRoleChange: ViewAccessModalProps['onPrincipalRoleChange'] = useCallback(
		async ({ name, id, role, type, avatarUrl }) => {
			if (!viewUUID) {
				return;
			}

			await changeViewAccessPrincipalRole({
				viewUUID,
				viewId,
				name,
				id,
				role,
				type,
				avatarUrl,
				createAnalyticsEvent,
				onActionFailed: generalDataUpdateFailedError,
			});

			if (type === PRINCIPAL_TYPES.GROUP || currentUser === id) {
				const result = await loadCurrentUserViewAccess?.(viewUUID, true);
				if (result?.state === 'no-access') {
					handleCloseModal();
				}
			}
		},
		[
			viewUUID,
			changeViewAccessPrincipalRole,
			viewId,
			createAnalyticsEvent,
			generalDataUpdateFailedError,
			currentUser,
			loadCurrentUserViewAccess,
			handleCloseModal,
		],
	);

	const handleRemoveAccess: ViewAccessModalProps['onRemovePrincipals'] = useCallback(
		async ({ accounts, groups }) => {
			if (!viewUUID) {
				return;
			}

			await removeViewAccessPrincipals({
				viewUUID,
				accounts,
				groups,
				viewId,
				createAnalyticsEvent,
				onActionFailed: generalDataUpdateFailedError,
			});

			if (groups.length || (currentUser && accounts.includes(currentUser))) {
				const result = await loadCurrentUserViewAccess?.(viewUUID, true);
				if (result?.state === 'no-access') {
					handleCloseModal();
				}
			}
		},
		[
			viewUUID,
			removeViewAccessPrincipals,
			viewId,
			createAnalyticsEvent,
			generalDataUpdateFailedError,
			currentUser,
			loadCurrentUserViewAccess,
			handleCloseModal,
		],
	);

	const handleDuplicateView = useCallback(async () => {
		if (!viewId || !viewKind) {
			return;
		}

		handleCloseModal();
		await cloneView(viewId, viewKind);
	}, [cloneView, handleCloseModal, viewId, viewKind]);

	const viewPermissionLockIconButton = (
		<IconButton
			id="pendo.view-header.access.view-access-modal-button"
			testId="polaris-ideas.ui.view-header.access.view-access-modal-button"
			appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
			label={formatMessage(messages.viewAccessButtonLabel)}
			onClick={handleOpenModal}
			icon={viewAccessIcon}
			isTooltipDisabled={false}
			{...(fg('jpd-trace-ufo-transition') ? { interactionName: 'jpd.view-access-modal' } : {})}
		/>
	);

	return (
		<>
			<ViewAccessModal
				isOpen={isModalOpen}
				isLoading={isLoading}
				hasError={!!error}
				accessLevel={accessLevel}
				additionalAccess={additionalAccess}
				isUserPickerLoading={isAddingPrincipals}
				canEdit={canManageCurrentView}
				currentUserAccountId={currentUser}
				failures={failures}
				skippedAccountFailures={skippedAccountFailures}
				onUpdateAccessLevel={handleUpdateAccessLevel}
				onAddPrincipals={handleAddPrincipals}
				onClose={handleCloseModal}
				onPrincipalRoleChange={handleRoleChange}
				onRemovePrincipals={handleRemoveAccess}
				onClearFailures={clearFailures}
				onDuplicateView={handleDuplicateView}
			/>
			{fg('polaris_pol-12273-spotlight-for-lock-icon') ? (
				<>
					<SpotlightTarget name={VIEW_PERMISSIONS_LOCK_SPOTLIGHT_DISMISSED}>
						{viewPermissionLockIconButton}
					</SpotlightTarget>
					<ViewPermissionsSpotlight />
				</>
			) : (
				viewPermissionLockIconButton
			)}
		</>
	);
};

export const AccessButton = () => {
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();
	const isCollectionView = useIsCollectionView();
	const canManageViews = useCanManageViews();

	if (!isViewPermissionsEnabled || isCollectionView || !canManageViews) {
		return null;
	}

	return <ViewAccess />;
};
