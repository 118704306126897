import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Skeleton, Spacer } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { ButtonSkeleton } from '@atlassian/jira-polaris-common/src/common/ui/skeleton/index.tsx';

export const DeliverSkeleton = () => (
	<DeliverSkeletonContainer>
		<DeliverActionsSkeletonContainer>
			<DeliverProgressSkeleton height="20px" width="370px" />
			<DeliverButtonSkeleton width="120px" />
			<DeliverButtonSkeleton width="106px" />
		</DeliverActionsSkeletonContainer>
		<Skeleton height="16px" width="100px" />
		<Spacer height="10px" />
		<Skeleton height="20px" width="100%" />
		<Spacer height="10px" />
		<Skeleton height="20px" width="100%" />
		<Spacer height="10px" />
		<Skeleton height="20px" width="100%" />
		<Spacer height="10px" />
		<Skeleton height="20px" width="100%" />
	</DeliverSkeletonContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliverActionsSkeletonContainer = styled.div({
	paddingTop: token('space.200', '16px'),
	paddingRight: 0,
	paddingBottom: token('space.300', '24px'),
	paddingLeft: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexWrap: 'wrap-reverse',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliverSkeletonContainer = styled.div({
	paddingTop: 0,
	paddingRight: token('space.100', '8px'),
	paddingBottom: 0,
	paddingLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliverProgressSkeleton = styled(Skeleton)({
	marginRight: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliverButtonSkeleton = styled(ButtonSkeleton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& + &': {
		marginLeft: token('space.150', '12px'),
	},
});
