import { useCallback, useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { SEVEN_DAYS } from './constants.tsx';

const applicationFirstLoad = Date.now();

export const useAppRefresher = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleTabActive = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.hidden) {
			return;
		}

		if (typeof applicationFirstLoad !== 'number') {
			return;
		}

		const timeElapsedSinceApplicationFirstLoad = Date.now() - applicationFirstLoad;
		if (timeElapsedSinceApplicationFirstLoad < SEVEN_DAYS) {
			return;
		}

		fireTrackAnalytics(createAnalyticsEvent({}), 'client refresh', {
			featureFlagValue: fg('polaris_outdated-client-refresh_dvnyi'),
			timeElapsedSinceApplicationFirstLoad,
		});
		if (fg('polaris_outdated-client-refresh_dvnyi')) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.reload();
		}
	}, [createAnalyticsEvent]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('visibilitychange', handleTabActive);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('visibilitychange', handleTabActive);
		};
	}, [handleTabActive]);
};
