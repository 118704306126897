/** @jsx jsx */
import React, { forwardRef } from 'react';
import { css, jsx } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';

type Props = {
	errorMessage: string;
};

export const ValidationIcon = ({ errorMessage }: Props) => {
	// takes the scrollbar on windows into account because it overlays the error icon
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const isWindows = navigator?.userAgent?.includes('Windows');
	return (
		<Tooltip component={WarningTooltip} content={errorMessage} position="bottom-end">
			<div
				css={[
					containerStyles,
					isWindows ? containerWindowsSpacingStyles : containerDefaultSpacingStyles,
				]}
			>
				<ErrorIcon
					testId="polaris-component-issue-create-input.ui.validation-error-icon"
					color="var(--ds-icon-danger)"
					label={errorMessage}
				/>
			</div>
		</Tooltip>
	);
};

const WarningTooltip = forwardRef<HTMLDivElement, TooltipPrimitiveProps>((props, ref) => (
	<TooltipPrimitive {...props} ref={ref} css={tooltipStyles} />
));

const tooltipStyles = css({
	display: 'block',
	boxSizing: 'border-box',
	margin: '0 -3px',
	paddingTop: token('space.050', '4px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.050', '4px'),
	paddingLeft: token('space.100', '8px'),
	borderRadius: token('border.radius.100', '3px'),
	backgroundColor: token('color.icon.danger'),
	color: token('color.text.inverse'),
	boxShadow: token('elevation.shadow.overlay'),
});

const containerStyles = css({
	lineHeight: '100%',
	cursor: 'pointer',
});

const containerDefaultSpacingStyles = css({
	paddingRight: token('space.150'),
});

const containerWindowsSpacingStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingRight: '18px',
});
