import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { ValueRule } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { messages } from './messages.tsx';
import { getRangeTypeFromRules } from './utils.tsx';

type Props = {
	rules: ValueRule[];
	color: string | undefined;
	emojiId: string | undefined;
};

export const NumberDecorationRangeTag = (props: Props) => {
	const { formatMessage } = useIntl();
	const { rules, color, emojiId } = props;
	const emoji = useEmoji(emojiId);

	const { ltRule, gtRule } = getRangeTypeFromRules(rules);
	if (gtRule === undefined && ltRule !== undefined) {
		return (
			<RangeItem>
				<Box
					testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.item.tag.box"
					xcss={captionStyles}
				>
					{formatMessage(messages.lessThan)}
				</Box>
				<CommonDecoratedTag mainColor={color} emoji={emoji} value={ltRule.value} />
			</RangeItem>
		);
	}
	if (gtRule !== undefined && ltRule === undefined) {
		return (
			<RangeItem>
				<Box
					testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.item.tag.box"
					xcss={captionStyles}
				>
					{formatMessage(messages.greaterThan)}
				</Box>
				<CommonDecoratedTag mainColor={color} emoji={emoji} value={gtRule.value} />
			</RangeItem>
		);
	}
	// should be the default case, but flow doesn't get it
	if (gtRule !== undefined && ltRule !== undefined) {
		return (
			<RangeItem>
				<Box
					testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.item.tag.box"
					xcss={captionStyles}
				>
					{formatMessage(messages.between)}
				</Box>
				<CommonDecoratedTag mainColor={color} emoji={emoji} value={gtRule.value} />
				<Box
					testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.item.tag.box"
					xcss={captionStyles}
				>
					{formatMessage(messages.and)}
				</Box>
				<CommonDecoratedTag mainColor={color} emoji={emoji} value={ltRule.value} />
			</RangeItem>
		);
	}
	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RangeItem = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: '32px',
});

const captionStyles = xcss({
	paddingRight: 'space.050',
});
