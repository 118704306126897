/** @jsx jsx */
import { jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import EditIcon from '@atlaskit/icon/core/edit';
import { useIntl } from '@atlassian/jira-intl';
import { useConnectionFieldIssueIds } from '../../../../controllers/issue/selectors/connection-hooks.tsx';
import type { ConnectionFieldProps } from '../types.tsx';
import { InlineConnectionsList } from '../../../connection/connection-list/inline/index.tsx';
import messages from './messages.tsx';

type Props = ConnectionFieldProps & {
	onEdit: () => void;
};

export const ConnectionFieldView = ({
	onEdit,
	fieldKey,
	localIssueId,
	isActive,
	isEditable,
}: Props) => {
	const { formatMessage } = useIntl();
	const connectedIssueIds = useConnectionFieldIssueIds(localIssueId, fieldKey);

	return (
		<Box
			xcss={[
				containerStyles,
				connectedIssueIds.length === 0 && containerStylesEmpty,
				isEditable && isActive && containerHoveredStyles,
			]}
			onClick={isEditable ? onEdit : undefined}
		>
			<InlineConnectionsList fieldKey={fieldKey} localIssueId={localIssueId} isActive={isActive} />
			{isActive && (
				<IconButton
					icon={EditIcon}
					onClick={onEdit}
					spacing="compact"
					label={formatMessage(messages.editButtonLabel)}
				/>
			)}
		</Box>
	);
};

const containerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: 'space.050',
	paddingInlineEnd: 'space.050',
	width: '100%',
	borderRadius: '4px',
});

const containerHoveredStyles = xcss({
	cursor: 'pointer',
	backgroundColor: 'color.background.neutral.subtle.hovered',
});

const containerStylesEmpty = xcss({
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
});
