import React from 'react';
import { styled } from '@compiled/react';
import { Flex } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { EmojiPicker } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/index.tsx';
import type { EmojiPickerProps } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/types.tsx';
import messages from './messages.tsx';

type TemplateEmojiPickerProps = EmojiPickerProps & {
	thumbnailColor?: string;
};

export const TemplateEmojiPicker = ({
	onEmojiSelected,
	selectedEmojiId,
	thumbnailColor,
}: TemplateEmojiPickerProps) => {
	const { formatMessage } = useIntl();

	return (
		<Flex direction="column">
			<Label>{formatMessage(messages.emojiPickerHeader)}</Label>
			<EmojiPicker
				onEmojiSelected={onEmojiSelected}
				selectedEmojiId={selectedEmojiId}
				color={thumbnailColor}
				zIndex={layers.modal + 10}
			/>
		</Flex>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.label({
	paddingTop: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	font: token('font.body.small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N100,
});
