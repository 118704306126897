import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { HiddenCountComponentProps } from '../../../fields/observable-values-list/context/index.tsx';

export const HiddenCount = ({ count }: HiddenCountComponentProps) => (
	<Container>+ {count}</Container>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Container = styled.div({
	font: token('font.body.small'),
	backgroundColor: token('elevation.surface'),
	borderRadius: '3px',
	paddingTop: token('space.050', '4px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.050', '4px'),
	paddingLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: `-2px 0px 8px 4px ${token('elevation.surface', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	clipPath: 'inset(0px 0px 0px -12px)',
	whiteSpace: 'nowrap',
});
