import { useMemo } from 'react';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { formulaUsesFields } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/main.tsx';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { useFieldsByKey } from '../field/selectors/field-hooks.tsx';
import * as actions from './actions/index.tsx';
import type { State, Props } from './types.tsx';

export type Actions = typeof actions;

const initialState: State = {
	meta: {
		loading: true,
		error: undefined,
	},
	globalFields: {},
	copyValuesTasks: {},
	containerProps: undefined,
};

const GlobalFieldsStore = createPolarisStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisGlobalFieldsStore',
});

const onUpdateContainer = (
	{ getState, dispatch }: StoreActionApi<State>,
	{ visibleFieldsByKey }: Props,
) => {
	const hasLoadedGlobalFields = Boolean(Object.keys(getState().globalFields).length);
	if (hasLoadedGlobalFields || !visibleFieldsByKey) {
		return;
	}

	const uniqueDependencies = new Set<FieldKey>();
	Object.values(visibleFieldsByKey).forEach(({ global, type, formula }) => {
		if (global && type === FIELD_TYPES.FORMULA && formula) {
			const dependencies = formulaUsesFields(formula);
			dependencies.forEach((dependency) => uniqueDependencies.add(dependency));
		}
	});

	// global fields can only have other global fields as dependencies
	// an unresolved field here indicates a dependency that has been removed from the project or deleted altogether
	// we load global fields to figure out which scenario applies
	for (const dependency of uniqueDependencies) {
		if (!visibleFieldsByKey[dependency]) {
			dispatch(actions.loadGlobalFields());
			return;
		}
	}
};

const { Container, useActions, createHook, createHigherLevelHook } = createJpdContainer<
	Props,
	State,
	Actions
>(GlobalFieldsStore, {
	onUpdate: () => onUpdateContainer,
});

export const GlobalFieldsContainer = Container;
export const useGlobalFieldsActions = useActions;

export const useGlobalFieldCopyValuesActions = () => {
	const { copyValues } = useGlobalFieldsActions();
	const [fieldsByKeys] = useFieldsByKey();

	return useMemo(
		() => ({
			copyValues: (
				fromProjectFieldKey: FieldKey,
				toGlobalFieldKey: FieldKey,
				fieldOptionsValueMapping?: Record<string, string | undefined>,
				onComplete?: () => void,
				onProgressPollingError?: () => void,
			) => {
				const fromField = fieldsByKeys[fromProjectFieldKey];
				const toField = fieldsByKeys[toGlobalFieldKey];
				return copyValues(
					fromField,
					toField,
					fieldOptionsValueMapping,
					onComplete,
					onProgressPollingError,
				);
			},
		}),
		[fieldsByKeys, copyValues],
	);
};

export { createHook, createHigherLevelHook };
