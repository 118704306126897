import gqlTagPolaris from 'graphql-tag';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CustomErrorHandlerRespose } from '@atlassian/jira-apollo-polaris/src/index.tsx';
import type { LinkGoalsInput, UnlinkGoalsInput } from './types.tsx';

const LINK_ATLAS_GOALS_MUTATION = gqlTagPolaris`
mutation jira_atlas_LinkGoals($input: TownsquareCreateRelationshipsInput!) {
  townsquare {
    createRelationships(input: $input) {
      errors {
		message 
	  }
	  success
    }
  }
}
`;

const UNLINK_ATLAS_GOALS_MUTATION = gqlTagPolaris`
mutation jira_atlas_UnlinkGoals($input: TownsquareDeleteRelationshipsInput!) {
  townsquare {
    deleteRelationships(input: $input) {
      errors {
    message 
    }
    success
    }
  }
}
`;

export const linkAtlasGoals = async (
	client: PolarisApolloClient,
	input: LinkGoalsInput,
): Promise<void> => {
	const result = await client.mutate({
		mutation: LINK_ATLAS_GOALS_MUTATION,
		variables: {
			input,
		},
		context: {
			errorHandler: ({ graphQLErrors }: CustomErrorHandlerRespose) =>
				graphQLErrors?.length === 1 && [401, 404].includes(graphQLErrors[0].extensions?.statusCode),
		},
		fetchPolicy: 'no-cache',
	});

	if (result.errors !== undefined) {
		throw new Error(
			`polaris-ideas.link-atlas-goals-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}
};

export const unLinkAtlasGoals = async (
	client: PolarisApolloClient,
	input: UnlinkGoalsInput,
): Promise<void> => {
	const result = await client.mutate({
		mutation: UNLINK_ATLAS_GOALS_MUTATION,
		variables: {
			input,
		},
		context: {
			errorHandler: ({ graphQLErrors }: CustomErrorHandlerRespose) =>
				graphQLErrors?.length === 1 && [401, 404].includes(graphQLErrors[0].extensions?.statusCode),
		},
		fetchPolicy: 'no-cache',
	});

	if (result.errors !== undefined) {
		throw new Error(
			`polaris-ideas.unlink-atlas-goals-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}
};
