/** @jsx jsx */
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	type ChangeEvent,
	type KeyboardEvent,
} from 'react';
import { css, jsx } from '@compiled/react';
import type { EmojiDescription } from '@atlaskit/emoji';
import EmojiAddIcon from '@atlaskit/icon/core/migration/emoji-add';
import { Box } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useCollectionEmoji,
	useCollectionName,
	useCollectionsActions,
} from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { EmojiPicker } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/index.tsx';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useCollectionUUID } from '../../../controllers/index.tsx';
import messages from './messages.tsx';

const NAME_MAX_LENGHT = 100;

export const CollectionNameInput = () => {
	const collectionUUID = useCollectionUUID();
	const collectionName = useCollectionName({ collectionUUID });
	const emojiId = useCollectionEmoji({ collectionUUID });
	const [inputValue, setInputValue] = useState(collectionName);
	const [inputError, setInputError] = useState<string | undefined>();
	const { updateCollection } = useCollectionsActions();
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	const inputRef = useRef<HTMLInputElement>(null);

	const collectionNameValidation = useCallback(
		(val?: string) => {
			if (val === undefined || val.trim().length === 0) {
				return formatMessage(messages.collectionNameRequiredError);
			}
			if (val.length > NAME_MAX_LENGHT) {
				return formatMessage(messages.collectionNameTooLongError);
			}
			return undefined;
		},
		[formatMessage],
	);

	useEffect(() => {
		// Validate input on value change
		setInputError(collectionNameValidation(inputValue));
	}, [collectionNameValidation, inputValue]);

	const handleUpdateError = () => {
		errorWithRefresh({
			title: formatMessage(messages.errorTitle),
			description: formatMessage(messages.errorDescription),
		});
	};

	useEffect(() => {
		setInputValue(collectionName);
	}, [collectionName]);

	const handleUpdateName = async () => {
		if (inputValue === collectionName || inputError || !collectionUUID) {
			return;
		}

		try {
			await updateCollection(collectionUUID, { name: inputValue });
		} catch (error) {
			handleUpdateError();
			setInputValue(collectionName);
		}
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			inputRef.current?.blur();
		}
	};

	const handleUpdateEmoji = async (emoji: EmojiDescription | undefined) => {
		if (emojiId === emoji?.id || !collectionUUID) {
			return;
		}

		try {
			await updateCollection(collectionUUID, { emoji: emoji?.id });
		} catch (error) {
			handleUpdateError();
		}
	};

	return (
		<>
			<label css={labelStyles} htmlFor="collection-name-input">
				{formatMessage(messages.inputLabel)}
			</label>
			<Textfield
				ref={inputRef}
				testId="polaris-component-collection-configure-sidebar.ui.default-content.collection-name-input.collection-name"
				placeholder={collectionName}
				value={inputValue}
				onKeyDown={handleKeyDown}
				onBlur={handleUpdateName}
				onChange={(event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value)}
				id="collection-name-input"
				elemBeforeInput={
					<Box paddingInlineStart="space.025">
						<EmojiPicker
							onEmojiSelected={handleUpdateEmoji}
							selectedEmojiId={emojiId}
							emojiAddIcon={(iconProps) => (
								<EmojiAddIcon
									{...iconProps}
									color={token('color.icon')}
									LEGACY_primaryColor={token('color.icon')}
								/>
							)}
						/>
					</Box>
				}
			/>
			{inputError !== undefined && <ErrorContainer>{inputError}</ErrorContainer>}
		</>
	);
};

const labelStyles = css({
	font: token('font.heading.xsmall'),
	color: token('color.text.subtle'),
	marginBlockEnd: token('space.200'),
});
