import React, { type ChangeEvent, memo, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type EditableTitleProps = {
	isEditing: boolean;
	isEditingAllowed: boolean;
	summary: string;
	onEdit: () => void;
	onCancel: () => void;
	onConfirm: (value: string) => void;
};

type ReadViewProps = {
	isEditingAllowed: boolean;
	summary: string;
	onEdit: () => void;
};

type EditViewProps = {
	summary: string;
	onConfirm: (value: string) => void;
	onCancel: () => void;
};

const ReadView = memo<ReadViewProps>(({ summary, onEdit, isEditingAllowed }: ReadViewProps) => (
	<ReadViewContainer isEditingAllowed={isEditingAllowed} onClick={() => onEdit()}>
		{summary}
	</ReadViewContainer>
));

const EditView = memo<EditViewProps>(({ summary, onConfirm, onCancel }: EditViewProps) => {
	const { formatMessage } = useIntl();

	const [valueInternal, setValueInternal] = useState(summary);

	useEffect(() => {
		setValueInternal(summary);
	}, [summary]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const changeHandler = (newValue: any) => {
		setValueInternal(newValue);
	};

	const confirm = () => {
		if (valueInternal !== summary) {
			onConfirm(valueInternal || formatMessage(messages.defaultIdeaTitle));
		}
		onCancel();
	};

	return (
		<>
			<TextField
				onBlur={confirm}
				maxLength={255}
				onKeyDown={(event) => {
					// handle ENTER key
					if (event.keyCode === 13) {
						confirm();
					}
					// handle ESC key
					if (event.keyCode === 27) {
						onCancel();
					}
				}}
				value={valueInternal}
				onChange={(event: ChangeEvent<HTMLInputElement>) => changeHandler(event.target.value)}
				autoFocus
				isCompact
			/>
		</>
	);
});

export const EditableSummary = memo<EditableTitleProps>(
	({ summary, isEditing, isEditingAllowed, onEdit, onCancel, onConfirm }: EditableTitleProps) => (
		<Container>
			{!isEditing && (
				<ReadView isEditingAllowed={isEditingAllowed} summary={summary} onEdit={onEdit} />
			)}
			{isEditing && <EditView summary={summary} onConfirm={onConfirm} onCancel={onCancel} />}
		</Container>
	),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	font: token('font.heading.medium'),
	fontWeight: 'inherit',
	maxWidth: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	paddingRight: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		font: token('font.heading.medium'),
		fontWeight: 'inherit',
		marginLeft: '0px',
		marginRight: '0px',
		marginTop: '0px',
		marginBottom: '0px',
		padding: '0px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div<{ isEditingAllowed: boolean }>(
	{
		overflow: 'hidden',
		wordBreak: 'break-word',
		textOverflow: 'ellipsis',
		borderTop: '3px solid transparent',
		borderBottom: '3px solid transparent',
		paddingLeft: token('space.050', '4px'),
		paddingTop: token('space.100', '8px'),
		paddingBottom: token('space.100', '8px'),
		transition: '0.3s',
		borderRadius: '3px',
		font: token('font.heading.medium'),
		fontWeight: 'inherit',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditingAllowed }) =>
		isEditingAllowed && {
			'&:hover': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: token('color.background.neutral.hovered', colors.N30),
			},
		},
);
