import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	hideButtonLabel: {
		id: 'polaris-issue-types-connections-configuration.hide-button-label',
		defaultMessage: 'Hide',
		description: 'Label on hide button',
	},
	configureButtonLabel: {
		id: 'polaris-issue-types-connections-configuration.configure-button-label',
		defaultMessage: 'Configure tab',
		description: 'Label on configure button',
	},
	moreButtonLabel: {
		id: 'polaris-issue-types-connections-configuration.more-button-label',
		defaultMessage: 'More',
		description: 'Label on more dropdown menu trigger button',
	},
	emptyScreenTitle: {
		id: 'polaris-issue-types-connections-configuration.empty-screen-title',
		defaultMessage: 'All connections are hidden',
		description: 'Title on empty connections tab',
	},
	emptyScreenMessage: {
		id: 'polaris-issue-types-connections-configuration.empty-screen-message',
		defaultMessage: '{count} available',
		description: 'Message with number of available connections to configure',
	},
	emptyScreenConfigureButtonLabel: {
		id: 'polaris-issue-types-connections-configuration.empty-screen-configure-button-label',
		defaultMessage: 'Configure',
		description: 'Label for configure button on empty connections tab',
	},
	enabledConnections: {
		id: 'polaris-issue-types-connections-configuration.enabled-connections',
		defaultMessage: 'Enabled connections for {issueType}',
		description: 'Label on section showing enabled connections',
	},
	availableConnections: {
		id: 'polaris-issue-types-connections-configuration.available-connections',
		defaultMessage: 'Available connections',
		description: 'Label on section showing available connections',
	},
	saveButtonLabel: {
		id: 'polaris-issue-types-connections-configuration.save-button-label',
		defaultMessage: 'Save',
		description: 'Label on save button',
	},
	cancelButtonLabel: {
		id: 'polaris-issue-types-connections-configuration.cancel-button-label',
		defaultMessage: 'Cancel',
		description: 'Label on cancel button',
	},
	errorHeader: {
		id: 'polaris-apps-collections.collections.error-header',
		defaultMessage: 'We couldn’t load connections',
		description: 'Error header when connections cannot be loaded',
	},
	errorBody: {
		id: 'polaris-apps-collections.collections.error-body',
		defaultMessage: 'Try to refresh again in a few minutes',
		description: 'Description of the error message when connections cannot be loaded',
	},
});
