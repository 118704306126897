import { useCallback } from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import {
	useDroppableEventsCollectionUpdate,
	type DroppableCollectionProps,
} from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';

type DroppableHandlerProps = {
	children: React.ReactNode;
	sortFields: SortField[];
	onSortFieldsUpdate: (nextSortFields: SortField[]) => void;
};

export const DroppableHandler = ({
	children,
	sortFields,
	onSortFieldsUpdate,
}: DroppableHandlerProps) => {
	const handleSort: DroppableCollectionProps<SortField, FieldKey>['onSort'] = useCallback(
		({ updatedCollection }) => {
			onSortFieldsUpdate(updatedCollection);
		},
		[onSortFieldsUpdate],
	);

	useDroppableEventsCollectionUpdate({
		onSort: handleSort,
		collection: sortFields,
		getSrcIdForCollectionItem: (item) => item.fieldKey,
		originLabel: 'view-sort-configuration',
	});

	return children;
};
