import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createIdeaMessageLegacy: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.create-idea-message',
		defaultMessage: 'Create an idea',
		description: 'Button message for creating an idea',
	},
	createIdeaMessage: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.create-idea-message-non-final',
		defaultMessage: 'Create',
		description: 'Button message for creating an idea',
	},
	createButtonLegacy: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.create-button',
		defaultMessage: 'Create idea',
		description: 'Button message for submitting form to create an issue',
	},
	createButton: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.create-button-non-final',
		defaultMessage: 'Create',
		description: 'Button message for submitting form to create an issue',
	},
	cancelButton: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Button message for cancelling create issue form',
	},
	close: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.close',
		defaultMessage: 'Discard & close',
		description: 'Tooltip for closing a modal',
	},
	spotlightCreateIdeaBtn: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.spotlight-create-idea-btn',
		defaultMessage:
			'This will create an idea in the project. You can use custom fields to review these ideas in separate views from the ones you use for prioritization and creating roadmaps.',
		description: 'Spotlight message for create idea form modal for contributors',
	},
	spotlightCloseBtn: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.spotlight-close-btn',
		defaultMessage: 'Got it',
		description: 'Button message for closing spotlight',
	},
	projectLabel: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.project-label',
		defaultMessage: 'Project',
		description: 'Label for current project',
	},
	customizeFormBtn: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.customize-form-btn',
		defaultMessage: 'Customize form',
		description: 'Button caption for opening the create idea form settings page',
	},
	configureFormBtn: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.configure-form-btn',
		defaultMessage: 'Configure form',
		description: 'Button caption for opening the create idea form settings page',
	},
	copyLink: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.copy-link',
		defaultMessage: 'Copy link',
		description: 'Tooltip for copy link button inside the form',
	},
	importIssues: {
		id: 'polaris-common.create-idea-dialog.form-modal-dialog.import-issues',
		defaultMessage: 'Import issues',
		description: 'Tooltip for import issues button',
	},
});
