/** @jsx jsx */
import React, { useState, useMemo, useCallback, useRef } from 'react';
import { css, jsx } from '@compiled/react';
import InlineEdit from '@atlaskit/inline-edit';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import GoalsPicker from '@atlassian/jira-business-goal-picker/src/ui/index.tsx';
import { createIssueAri } from '@atlassian/jira-polaris-common/src/common/utils/ari/index.tsx';
import { useCloudId } from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Goal } from '@atlassian/jira-business-goal-picker/src/common/types.tsx';
import { EXTERNAL_REFERENCE_PROVIDERS } from '@atlassian/jira-polaris-domain-field/src/field/external-reference/types.tsx';
import { useAtlasGoals } from '@atlassian/jira-polaris-common/src/controllers/atlas/index.tsx';
import { ExternalReferenceView } from '../external-reference/view/index.tsx';

export type Props = {
	isActive?: boolean;
	isEditable: boolean;
	fieldKey: FieldKey;
	isMultiline?: boolean;
	placeholder?: string | undefined;
	menuPortalTarget?: HTMLElement;
	compactForBoard?: boolean;
	isCompact?: boolean;
	onUpdate: (arg1: string | string[] | undefined) => void;
	value: string[];
	issueId?: string;
};

export const GoalsField = ({
	isActive,
	fieldKey,
	isEditable,
	value,
	onUpdate,
	placeholder,
	isMultiline,
	isCompact = false,
	issueId,
}: Props) => {
	const [isEditing, setIsEditing] = useState(false);
	const cloudId = useCloudId();
	const issueAri = issueId ? createIssueAri(cloudId, issueId.toString()) : '';

	const containerRef = useRef<HTMLDivElement | null>(null);

	const [{ data }] = useAtlasGoals({ goalAris: value });

	const goalsData: Goal[] = useMemo(() => {
		return data.map((goal) => ({
			id: goal.id,
			name: goal.name,
			status: goal.state?.label || 'PENDING',
		}));
	}, [data]);

	const onGoalPicked = useCallback(
		(goal: Goal) => {
			const updatedGoals = value.length ? [...value, goal.id] : [goal.id];
			onUpdate(updatedGoals);
		},
		[onUpdate, value],
	);

	const onGoalUnlinked = useCallback(
		(_: string, remainingGoals: Goal[]) => {
			const updatedAris = remainingGoals.map((goal) => goal.id);
			onUpdate(updatedAris);
		},
		[onUpdate],
	);

	const onClose = useCallback(() => {
		setIsEditing(false);
	}, [setIsEditing]);

	const renderReadView = () => (
		<Box
			testId="polaris-common.ui.fields.goals.read-view"
			onClick={() => setIsEditing(true)}
			xcss={readViewContainerStyles}
			ref={containerRef}
		>
			<ExternalReferenceView
				placeholder={placeholder}
				isActive={isActive}
				provider={EXTERNAL_REFERENCE_PROVIDERS.ATLAS_GOAL}
				fieldKey={fieldKey}
				isEditable={isEditable}
				options={value || []}
				isMultiline={isMultiline}
				containerRef={containerRef}
				isCompact={isCompact}
			/>
		</Box>
	);

	const renderEditView = () => (
		<Box testId="polaris-common.ui.fields.goals.edit-view" xcss={editViewContainerStyles}>
			<Flex alignItems="center" xcss={editModeStyles}>
				<GoalsPicker
					goals={goalsData}
					goalCount={goalsData.length}
					isEditable={isEditable}
					editMode
					showPlaceholder
					shouldRenderToParent
					onGoalPicked={onGoalPicked}
					issueAri={issueAri}
					onClose={onClose}
					onGoalUnlinked={onGoalUnlinked}
				/>
			</Flex>
		</Box>
	);

	if (isEditable) {
		return (
			<div css={inlineEditContainerStyles}>
				<InlineEdit
					defaultValue={value}
					readViewFitContainerWidth
					isEditing={isEditing}
					readView={renderReadView}
					editView={renderEditView}
					onCancel={onClose}
					onConfirm={onClose}
					onEdit={() => setIsEditing(true)}
					hideActionButtons
				/>
			</div>
		);
	}

	return renderReadView();
};

const editViewContainerStyles = xcss({
	zIndex: 'dialog',
	position: 'relative',
	whiteSpace: 'nowrap',
});

const editModeStyles = xcss({
	width: '100%',
	minHeight: '32px',
	paddingTop: 'space.0',
	paddingBottom: 'space.0',
	paddingLeft: 'space.100',
	paddingRight: 'space.075',
});

const inlineEditContainerStyles = css({
	width: '100%',
	marginTop: token('space.negative.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > form > div > div > div': {
		border: 0,
	},
});

const readViewContainerStyles = xcss({
	position: 'relative',
	borderRadius: '4px',
});
