import React, { memo, useCallback } from 'react';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useCallbacks } from '../../../controllers/selectors/callback-hooks.tsx';
import { useCanEditField, useReadonly } from '../../../controllers/selectors/config-hooks.tsx';
import { useDecorationsComponents } from '../../../controllers/selectors/decoration-hooks.tsx';
import { useFieldType } from '../../../controllers/selectors/field-hooks.tsx';
import { DecoratorDateTimeItems } from './datetime-decoration/index.tsx';
import { DecoratorNumberItems } from './number-decoration/index.tsx';
import { type DecoratorItemsProps, DecoratorOptionItems } from './option-decoration/index.tsx';
import { ProjectDecorationItem } from './project-decoration/index.tsx';
import { DecoratorRatingItems } from './rating-decoration/index.tsx';
import { StatusDecorationItem } from './status-decoration/index.tsx';

type Props = {
	decorationConfigHeader?: React.JSX.Element;
};

export const DecorationConfigContent = memo(({ decorationConfigHeader }: Props) => {
	const fieldType = useFieldType();
	const canEditField = useCanEditField();
	const { onOptionOpened } = useCallbacks();
	const decorationsComponents = useDecorationsComponents();
	const readonly = useReadonly();

	const handleOnOpenOption = useCallback<DecoratorItemsProps['onOpenOption']>(
		(id) => {
			onOptionOpened?.(id);
		},
		[onOptionOpened],
	);

	for (const [type, component] of Object.entries(decorationsComponents)) {
		if (type === fieldType && component) {
			return (
				<>
					{decorationConfigHeader}
					{component}
				</>
			);
		}
	}

	switch (fieldType) {
		case FIELD_TYPES.DATE:
		case FIELD_TYPES.INTERVAL:
			return (
				<DecoratorDateTimeItems
					readonly={!canEditField || readonly}
					decorationConfigHeader={decorationConfigHeader}
				/>
			);
		case FIELD_TYPES.SINGLE_SELECT:
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return (
				<DecoratorOptionItems
					showOptionCreateButton={canEditField && !readonly}
					onOpenOption={handleOnOpenOption}
					readonly={!canEditField || readonly}
					decorationConfigHeader={decorationConfigHeader}
				/>
			);
		case FIELD_TYPES.CUSTOM_LABELS:
		case FIELD_TYPES.LABELS:
			return (
				<DecoratorOptionItems
					showOptionCreateButton={false}
					onOpenOption={handleOnOpenOption}
					readonly={!canEditField || readonly}
					decorationConfigHeader={decorationConfigHeader}
				/>
			);
		case FIELD_TYPES.RATING:
			return (
				<DecoratorRatingItems
					readonly={!canEditField || readonly}
					decorationConfigHeader={decorationConfigHeader}
				/>
			);
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.LINKED_ISSUES:
		case FIELD_TYPES.FORMULA:
			return (
				<DecoratorNumberItems
					readonly={!canEditField || readonly}
					decorationConfigHeader={decorationConfigHeader}
				/>
			);
		case FIELD_TYPES.STATUS:
			return (
				<>
					{decorationConfigHeader}
					<StatusDecorationItem />
				</>
			);
		case FIELD_TYPES.PROJECT:
			return <ProjectDecorationItem />;
		default:
			return null;
	}
});
