import React, { type ComponentType, type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Inline, Stack } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type CreateViewTooltipProps = {
	message: React.ReactNode;
};

export const CreateEntityLimitTooltipContent = ({ message }: CreateViewTooltipProps) => {
	const { formatMessage } = useIntl();

	return (
		<TextContainer>
			<Stack space="space.100">
				<Inline space="space.050" alignBlock="center">
					<WarningIcon label={formatMessage(messages.title)} />
					<Heading size="xsmall">{formatMessage(messages.title)}</Heading>
				</Inline>
				<Inline space="space.100">{message}</Inline>
			</Stack>
		</TextContainer>
	);
};

export const CreateEntityLimitTooltip = ({
	children,
	message,
}: PropsWithChildren<CreateViewTooltipProps>) => {
	if (!message) {
		return <>{children}</>;
	}

	return (
		<Tooltip
			position="right"
			content={<CreateEntityLimitTooltipContent message={message} />}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			component={TooltipComponent as ComponentType<TooltipPrimitiveProps>}
		>
			{children}
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	paddingTop: token('space.200', '16px'),
	paddingRight: token('space.300', '24px'),
	paddingBottom: token('space.200', '16px'),
	paddingLeft: token('space.300', '24px'),
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipComponent = styled(TooltipPrimitive)({
	padding: 0,
	boxShadow: token(
		'elevation.shadow.overlay',
		'0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 12px rgba(9, 30, 66, 0.15)',
	),
	borderRadius: '6px',
	overflow: 'hidden',
	width: '320px',
});
