import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { messages } from '../../messages.tsx';

export const fieldTypeNameForPolarisFieldType = (fieldType?: FieldType) => {
	switch (fieldType) {
		case FIELD_TYPES.ATLAS_GOAL:
			return messages.atlasGoalFieldType;
		case FIELD_TYPES.ATLAS_PROJECT:
			return messages.atlasProjectFieldType;
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
			return messages.atlasProjectStatusFieldType;
		case FIELD_TYPES.VOTES:
			return messages.playFieldType;
		case FIELD_TYPES.DELIVERY_PROGRESS:
			return messages.deliveryProgressFieldType;
		case FIELD_TYPES.DELIVERY_STATUS:
			return messages.deliveryStatusFieldType;
		case FIELD_TYPES.CHECKBOX:
			return messages.checkboxPresentationFieldType;
		case FIELD_TYPES.SLIDER:
			return messages.sliderFieldType;
		case FIELD_TYPES.RATING:
			return messages.ratingFieldType;
		case FIELD_TYPES.HYPERLINK:
			return messages.hyperlinkFieldType;
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.INSIGHTS:
		case FIELD_TYPES.LINKED_ISSUES:
			return messages.customFormulaTypeLabel;
		case FIELD_TYPES.SHORT_TEXT:
			return messages.shortTextFieldType;
		case FIELD_TYPES.DATE:
		case FIELD_TYPES.INTERVAL:
			return messages.dateFieldType;
		case FIELD_TYPES.NUMBER:
			return messages.numberFieldType;
		case FIELD_TYPES.CREATED:
		case FIELD_TYPES.UPDATED:
			return messages.datetimeFieldType;
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return messages.labelsFieldType;
		case FIELD_TYPES.SINGLE_SELECT:
			return messages.dropdownFieldType;
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return messages.checkboxFieldType;
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.CREATOR:
			return messages.userPickerFieldType;
		case FIELD_TYPES.ISSUE_COMMENTS:
			return messages.commentsFieldType;
		case FIELD_TYPES.STATUS:
			return messages.statusFieldType;
		case FIELD_TYPES.REACTIONS:
			return messages.reactionsFieldType;
		case FIELD_TYPES.SUMMARY:
			return messages.summaryFieldType;
		case FIELD_TYPES.ISSUE_KEY:
			return messages.issueKeyFieldType;
		case FIELD_TYPES.PROJECT:
			return messages.projectFieldType;
		case FIELD_TYPES.CONNECTION:
			return messages.connectionFieldType;
		case FIELD_TYPES.ISSUE_TYPE:
			return messages.issueTypeFieldType;
		case FIELD_TYPES.TEAM:
			return messages.teamFieldType;
		case FIELD_TYPES.PLATFORM_GOALS:
			return messages.goalsFieldType;
		default:
			return null;
	}
};
