import { createSelector } from 'reselect';
import {
	iconForPolarisFieldType,
	iconForPolarisFieldTypeNew,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '../types.tsx';

const getFieldsByKey = createSelector(
	(state: State) => state.formulaFieldData.fieldsByKey,
	(fields?: FieldsByKey) => fields || {},
);

const createGetFieldByKey = (fieldKey?: FieldKey) =>
	createSelector(getFieldsByKey, (fields) => (fieldKey ? fields[fieldKey] : undefined));

const createGetFieldConfiguration = (fieldKey?: FieldKey) => {
	const getFieldByKey = createGetFieldByKey(fieldKey);
	return createSelector(getFieldByKey, (field) => field?.configuration);
};

const createGetFieldOptionWeightTypeIsSet = (fieldKey?: FieldKey) => {
	const getFieldConfiguration = createGetFieldConfiguration(fieldKey);
	return createSelector(
		getFieldConfiguration,
		(configuration) => configuration?.optionWeightType !== undefined,
	);
};

export const createGetFieldType = (fieldKey?: FieldKey) => {
	const getFieldByKey = createGetFieldByKey(fieldKey);
	return createSelector(getFieldByKey, (field) => field?.type);
};

export const createGetOptions = (fieldKey: FieldKey) => {
	const getFieldByKey = createGetFieldByKey(fieldKey);
	return createSelector(getFieldByKey, (field) => field?.options);
};

export const getFieldTypeIcon = (fieldKey?: FieldKey) => {
	const getFieldType = createGetFieldType(fieldKey);
	const getFieldOptionWeightTypeIsSet = createGetFieldOptionWeightTypeIsSet(fieldKey);
	const getFieldConfiguration = createGetFieldConfiguration(fieldKey);

	return createSelector(
		getFieldType,
		getFieldOptionWeightTypeIsSet,
		getFieldConfiguration,
		(fieldType, fieldOptionWeightTypeIsSet, fieldConfiguration) =>
			fg('jpd_icon_for_polaris_field_type_new')
				? iconForPolarisFieldTypeNew(fieldType, undefined, fieldConfiguration)
				: iconForPolarisFieldType(fieldType, undefined, fieldOptionWeightTypeIsSet),
	);
};

export const getFieldEmoji = (fieldKey?: FieldKey) => {
	const getFieldByKey = createGetFieldByKey(fieldKey);
	return createSelector(getFieldByKey, (field) => field?.emoji);
};
