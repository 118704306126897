import React from 'react';
import { Inline } from '@atlaskit/primitives';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

export const ProjectsSkeleton = () => (
	<Inline
		testId="polaris-component-collection-configure-sidebar.ui.default-content.skeleton"
		alignBlock="center"
		space="space.100"
		shouldWrap
	>
		<SkeletonCompiled height="24px" width="150px" />
		<SkeletonCompiled height="24px" width="80px" />
		<SkeletonCompiled height="24px" width="60px" />
		<SkeletonCompiled height="24px" width="60px" />
		<SkeletonCompiled height="24px" width="200px" />
		<SkeletonCompiled height="24px" width="170px" />
		<SkeletonCompiled height="24px" width="120px" />
		<SkeletonCompiled height="24px" width="80px" />
		<SkeletonCompiled height="24px" width="60px" />
		<SkeletonCompiled height="24px" width="60px" />
		<SkeletonCompiled height="24px" width="200px" />
	</Inline>
);
