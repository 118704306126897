import React, { useState, useEffect } from 'react';
import { useIsIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { PlatformGoalsField } from '@atlassian/jira-polaris-common/src/ui/fields/goals/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils/index.tsx';

type GoalsFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
	isHovered: boolean;
};

export const GoalsField = ({ localIssueId, fieldKey, isHovered }: GoalsFieldProps) => {
	const [isActive, setIsActive] = useState(isHovered);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);

	useEffect(() => {
		if (isHovered) {
			setIsActive(true);
		}
	}, [isHovered]);

	return (
		<PlatformGoalsField
			isActive={isActive}
			localIssueId={localIssueId}
			fieldKey={fieldKey}
			isEditable={isEditable && !isIdeaArchived}
			onUpdate={onUpdate}
			isCompact={false}
		/>
	);
};
