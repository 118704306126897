import { generateLocalDecorationId } from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { GlobalField } from '@atlassian/jira-polaris-domain-field/src/global-field/types.tsx';
import {
	getPresentationType,
	getFieldConfiguration,
	transformDecorations,
	getOptions,
	getArchivedOption,
	isEditable,
} from '@atlassian/jira-polaris-remote-field/src/controllers/crud/jira/transform/utils.tsx';
import { getFieldType } from '@atlassian/jira-polaris-remote-field/src/controllers/crud/utils.tsx';
import type { JiraApiGlobalField } from '../../services/jira/types.tsx';

const isFieldWithOptions = (field: JiraApiGlobalField) =>
	field.type === FIELD_TYPES.SINGLE_SELECT ||
	field.type === FIELD_TYPES.MULTI_SELECT ||
	field.type === FIELD_TYPES.JSW_MULTI_SELECT;

export const transformGlobalField = (field: JiraApiGlobalField): GlobalField => {
	const transformableField = {
		...field,
		clauseNames: [],
		options: field.options?.map((option) => ({
			...option,
			externalUuid: null,
		})),
	};

	if (transformableField.configuration?.valueDecorations) {
		transformableField.configuration.valueDecorations =
			transformableField.configuration.valueDecorations.map((decoration) => ({
				...decoration,
				localDecorationId: generateLocalDecorationId(),
			}));
	}

	const type = getFieldType(field);
	const presentation = getPresentationType(transformableField);
	const options = isFieldWithOptions(field) ? getOptions(transformableField) || [] : undefined;
	const configuration = getFieldConfiguration(transformableField);
	const archivedOption = getArchivedOption(transformableField);
	const editable = isEditable(transformableField);
	const decorations = transformDecorations(transformableField);

	const fieldConfigration = field.configuration
		? {
				...field.configuration,
				...configuration,
			}
		: undefined;

	return {
		key: field.key,
		label: field.name,
		description: field.description,
		type,
		isLocked: field.isLocked,
		presentation,
		emoji: field.configuration?.emoji,
		configuration: fieldConfigration,
		editable,
		options,
		archivedOption,
		global: true,
		custom: true,
		formula: field.configuration?.polarisFormula,
		projectsCount: field.projectsCount,
		origin: field.origin,
		valueDecorations: decorations,
		hasRestrictedContext: field.hasRestrictedContext,
		issueTypes: [],
		stableId: field.stableId,
	};
};
