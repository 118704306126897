import React, { useCallback, useMemo, useState, type ReactNode } from 'react';
import orderBy from 'lodash/orderBy';
import Button from '@atlaskit/button/new';
import Lozenge from '@atlaskit/lozenge';
import { Inline, Box, Stack, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import {
	useGlobalFieldKeysOfType,
	useAreGlobalFieldsLoading,
	useAvailableGlobalFields,
	useIsGlobalFieldsLoadingFailed,
} from '@atlassian/jira-polaris-common/src/controllers/global-fields/selectors/global-fields-hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { FormulaDependencyFooterNotice } from '@atlassian/jira-polaris-component-field-configuration/src/ui/configuration/formula/dependency-info/footer/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { getFormulaDependencies } from '@atlassian/jira-polaris-domain-field/src/global-field/utils.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { RightSidebarBody } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/body/index.tsx';
import { RightSidebarFooter } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/footer/index.tsx';
import { RightSidebarHeader } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/header/index.tsx';
import { AssociationHardLimitError } from '@atlassian/jira-polaris-remote-global-field/src/services/jira/associate-global-field/errors.tsx';
import {
	type UIAnalyticsEvent,
	ContextualAnalyticsData,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { FieldType } from '../types.tsx';
import { EmptyScreen } from './empty-screen/index.tsx';
import { ErrorState } from './error-state/index.tsx';
import { GlobalFieldsSelector } from './global-fields-selector/index.tsx';
import messages from './messages.tsx';
import type { GlobalFieldsListProps } from './types.tsx';

export const GlobalFieldsList = ({
	selectedFields,
	setSelectedFields,
	fieldTypeFilter,
	setFieldTypeFilter,
	onClose,
	onOpenFieldDetails,
}: GlobalFieldsListProps) => {
	const { formatMessage } = useIntl();
	const { associateGlobalFields, setHighlightedFields } = useFieldActions();
	const { setForceReloadViewOnNextUpdate } = useViewActions();
	const { error } = useNotifications();

	const [addingInProgress, setAddingInProgress] = useState<boolean>(false);

	const globalFields = useAvailableGlobalFields();
	const globalFormulaFieldKeys = useGlobalFieldKeysOfType(FIELD_TYPES.FORMULA);
	const areGlobalFieldsLoading = useAreGlobalFieldsLoading();
	const isGlobalFieldsLoadingFailed = useIsGlobalFieldsLoadingFailed();

	const noGlobalFieldsAvailable = globalFields.length === 0;

	const sortedGlobalFields = useMemo(() => orderBy(globalFields, (f) => f.label), [globalFields]);

	const displayDependencyNotice = useMemo(
		() => selectedFields.some((fieldKey) => globalFormulaFieldKeys.has(fieldKey)),
		[globalFormulaFieldKeys, selectedFields],
	);

	const onFieldSelectionChange = useCallback(
		(fieldKey: string) => {
			setSelectedFields((selected: string[]) => {
				if (selected.includes(fieldKey)) {
					return selected.filter((p) => p !== fieldKey);
				}
				return [...selected, fieldKey];
			});
		},
		[setSelectedFields],
	);

	const onSelectAllFields = useCallback(
		(fieldType: FieldType) => {
			setSelectedFields(
				globalFields
					.filter(({ origin }) => (fieldType === 'jpd' ? origin === 'jpd' : origin === undefined))
					.map((f) => f.key),
			);
		},
		[globalFields, setSelectedFields],
	);

	const onUnselectAllFields = useCallback(() => {
		setSelectedFields([]);
	}, [setSelectedFields]);

	const addToProject = useCallback(
		async (_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'addToProject');
			setAddingInProgress(true);

			try {
				setForceReloadViewOnNextUpdate(true);
				const fields = [...selectedFields];

				const allDependencies = fields
					.map((field) => getFormulaDependencies(field, globalFields))
					.flat();
				const dependencies = [...new Set(allDependencies)];

				await associateGlobalFields(fields, dependencies);
				setHighlightedFields([...fields, ...dependencies]);

				onClose();
			} catch (err) {
				setAddingInProgress(false);

				if (err instanceof AssociationHardLimitError) {
					error({
						title: formatMessage(messages.associationHardLimitErrorTitle, {
							item: (chunks: ReactNode[]) => <Lozenge>{chunks}</Lozenge>,
						}),
						description: formatMessage(messages.associationHardLimitErrorDescription),
					});
				} else {
					error({
						title: formatMessage(messages.associationErrorTitle),
						description: formatMessage(messages.associationErrorDescription),
					});
				}
			}
		},
		[
			associateGlobalFields,
			setForceReloadViewOnNextUpdate,
			error,
			formatMessage,
			onClose,
			selectedFields,
			setHighlightedFields,
			globalFields,
		],
	);

	return (
		<ContextualAnalyticsData>
			<Stack xcss={containerStyles}>
				<RightSidebarHeader onClose={onClose} title={formatMessage(messages.heading)} />
				<RightSidebarBody>
					{isGlobalFieldsLoadingFailed ? (
						<ErrorState header={formatMessage(messages.errorScreenHeader)} />
					) : (
						<>
							{!areGlobalFieldsLoading && noGlobalFieldsAvailable ? (
								<EmptyScreen />
							) : (
								<GlobalFieldsSelector
									loading={areGlobalFieldsLoading}
									fields={sortedGlobalFields}
									selectedFields={selectedFields}
									onFieldSelectionChange={onFieldSelectionChange}
									fieldTypeFilter={fieldTypeFilter}
									setFieldTypeFilter={setFieldTypeFilter}
									onSelectAllFields={onSelectAllFields}
									onUnselectAllFields={onUnselectAllFields}
									onOpenFieldDetails={onOpenFieldDetails}
								/>
							)}
						</>
					)}
				</RightSidebarBody>

				<RightSidebarFooter>
					<Stack space="space.100">
						{displayDependencyNotice && <FormulaDependencyFooterNotice />}
						<Inline space="space.100">
							<Box xcss={buttonWrapperStyles}>
								<Button
									shouldFitContainer
									onClick={(_event, analyticsEvent) => {
										fireUIAnalytics(analyticsEvent, 'cancel');
										onClose();
									}}
								>
									{formatMessage(messages.cancel)}
								</Button>
							</Box>
							<Box xcss={buttonWrapperStyles}>
								{!noGlobalFieldsAvailable && (
									<Tooltip
										content={
											selectedFields.length === 0 ? formatMessage(messages.selectFieldTooltip) : ''
										}
									>
										{(tooltipProps) => (
											<Button
												testId="polaris-ideas.ui.right-sidebar.global-fields.global-field-list.add-to-project"
												{...tooltipProps}
												isLoading={addingInProgress}
												appearance="primary"
												shouldFitContainer
												onClick={(event, analyticEvent) => addToProject(event, analyticEvent)}
												isDisabled={selectedFields.length === 0}
											>
												{formatMessage(messages.addToProject)}
											</Button>
										)}
									</Tooltip>
								)}
							</Box>
						</Inline>
					</Stack>
				</RightSidebarFooter>
			</Stack>
		</ContextualAnalyticsData>
	);
};

const containerStyles = xcss({
	height: '100%',
	paddingBlockStart: 'space.300',
});

const buttonWrapperStyles = xcss({
	width: '50%',
});
