import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import {
	isNotFoundError,
	isPermissionError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { State, Props } from '../types.tsx';

export const loadStatuses =
	(): Action<State, Props> =>
	async (
		{ getState, setState },
		{
			projectId,
			issueTypeIds,
			createAnalyticsEvent,
			onWorkflowLoadingFailed,
			issuesRemote,
			isProjectLoading,
		},
	) => {
		if (
			projectId !== undefined &&
			issueTypeIds !== undefined &&
			issueTypeIds.length > 0 &&
			issuesRemote.fetchTransitionsForIssueType !== undefined &&
			!isProjectLoading
		) {
			const statusesPromise = issuesRemote
				.fetchStatuses()
				.then((statusesResponse) => {
					const statusesResponseById = keyBy(statusesResponse, ({ id }) => id);

					setState({
						statuses: pick(statusesResponseById, issueTypeIds),
						meta: {
							...getState().meta,
							initialized: true,
						},
					});
				})
				.catch((error) => {
					fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadStatuses failure');
					onWorkflowLoadingFailed(error);
				});

			// Only load transitions that are not already in the state
			const availableTransitions = getState().transitions || {};
			const issueTypeIdsTransitionsToLoad = fg('fix_workflow_transitions_refetch')
				? issueTypeIds.filter((issueTypeId) => !(issueTypeId in availableTransitions))
				: issueTypeIds;

			const transitionPromises = issueTypeIdsTransitionsToLoad.map((issueTypeId) =>
				issuesRemote.fetchTransitionsForIssueType?.({ issueTypeId }).then(({ transitions }) => ({
					[`${issueTypeId}`]: transitions,
				})),
			);

			await Promise.all([statusesPromise, ...transitionPromises])
				.then(([_, ...transitionsByIdeaType]) => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const newTransitions = transitionsByIdeaType.reduce<Record<string, any>>(
						(agg, transition) => ({
							// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
							...agg,
							...transition,
						}),
						{},
					);

					setState({
						transitions: { ...availableTransitions, ...newTransitions },
					});
				})
				.catch((error) => {
					fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadWorkflow failure');
					onWorkflowLoadingFailed(error);
				});
		}
	};

export const loadStatusesForSharedView =
	(): Action<State, Props> =>
	async (
		{ getState, setState },
		{ createAnalyticsEvent, onWorkflowLoadingFailed, issuesRemote },
	) => {
		try {
			const statusesResponse = await issuesRemote.fetchStatuses();

			setState({
				statuses: keyBy(statusesResponse, ({ id }) => id),
				meta: {
					...getState().meta,
					initialized: true,
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadStatuses failure');
			if (isNotFoundError(error) || isPermissionError(error)) {
				return;
			}
			onWorkflowLoadingFailed(error);
		}
	};
