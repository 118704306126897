import React, { useState, useCallback } from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import { useActions } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { messages } from './messages.tsx';
import { useProjectId } from './project-id-provider.tsx';
import { IconField } from './icon-field.tsx';
import { NameInput } from './name-input.tsx';
import { useFormState } from './utils/use-form-state.tsx';

type Props = {
	onCreate: () => void;
};

export const TypeCardCreate = ({ onCreate }: Props) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { error } = useNotifications();
	const projectId = useProjectId();
	const { avatarId, name, onChangeAndSubmitAvatarId, onChangeName, isNameInvalid } = useFormState({
		projectId,
	});
	const { formatMessage } = useIntl();
	const { createIssueType } = useActions();

	const onInputSubmit = useCallback(async () => {
		if (isSubmitting) return;
		setIsSubmitting(true);
		try {
			await createIssueType(projectId, { avatarId, name: name.trim(), description: '' });
			onCreate();
			setIsSubmitting(false);
		} catch (err) {
			error({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
			});
			setIsSubmitting(false);
		}
	}, [avatarId, createIssueType, error, formatMessage, isSubmitting, name, onCreate, projectId]);

	return (
		<Flex xcss={wrapperStyles} columnGap="space.100" alignItems="center">
			<IconField
				avatarId={avatarId}
				isDisabled={isSubmitting}
				onChange={onChangeAndSubmitAvatarId}
			/>
			<NameInput
				value={name}
				isDisabled={isSubmitting}
				shouldAutofocus
				onSubmit={onInputSubmit}
				onChange={onChangeName}
				isInvalid={isNameInvalid}
			/>
			<Button onClick={onCreate} appearance="subtle" isDisabled={isSubmitting}>
				{formatMessage(messages.cancelLabel)}
			</Button>
			<Button onClick={onInputSubmit} appearance="primary" isLoading={isSubmitting}>
				{formatMessage(messages.saveLabel)}
			</Button>
		</Flex>
	);
};

const wrapperStyles = xcss({
	borderWidth: '1px',
	borderRadius: '4px',
	borderColor: 'color.border',
	borderStyle: 'solid',
	width: 'initial',
	paddingBlock: 'space.025',
	paddingInline: 'space.050',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});
