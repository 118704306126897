import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { CreateIdeaSpotlight } from '@atlassian/jira-polaris-lib-onboarding/src/ui/create-idea/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useVisibleSelectedIssueIds } from '../../utils.tsx';
import { messages } from './messages.tsx';
import type { AddIdeaButtonProps } from './types.tsx';

const AddIdeaButtonLegacy = ({ onClick }: AddIdeaButtonProps) => {
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const { formatMessage } = useIntl();

	if (!isEmpty(visibleSelectedIssueIds)) {
		return null;
	}

	return (
		<CreateIdeaSpotlight>
			<Button
				appearance="primary"
				id="polaris-ideas.ui.view-controls.add-idea-button"
				testId="polaris-ideas.ui.view-controls.add-idea-button.add-idea-header-button"
				onClick={onClick}
			>
				{formatMessage(messages.addIdeaButton)}
			</Button>
		</CreateIdeaSpotlight>
	);
};

const AddIdeaButtonNext = memo(AddIdeaButtonLegacy);

export const AddIdeaButton = componentWithFG(
	'polaris_idea_list_performance_improvements',
	AddIdeaButtonNext,
	AddIdeaButtonLegacy,
);
