import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'polaris-ideas.idea-view.connections.header.error-title',
		defaultMessage: 'We couldn’t hide this connection.',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-ideas.idea-view.connections.header.error-description',
		defaultMessage: 'Our team has been notified. Please try again.',
		description: 'Flag description to communicate an error.',
	},
});
