/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	useFieldTypeIcon,
	useFieldLabel,
} from '../../../../../controllers/field/selectors/field-hooks.tsx';

type LabelItemProps = {
	amount: number;
	fieldKey: FieldKey;
	showFieldLabel?: boolean;
};

export const LabelItem = ({ amount, fieldKey, showFieldLabel }: LabelItemProps) => {
	const fieldLabel = useFieldLabel(fieldKey);
	const icon = useFieldTypeIcon(fieldKey, undefined);

	return (
		<div data-component-selector="content-wrapper-aw1" css={contentWrapperStyles}>
			<Box xcss={iconContainerStyles}>{icon}</Box>
			{showFieldLabel && fieldLabel ? (
				<>
					<Box xcss={labelWrapperStyles}>{fieldLabel}</Box>
					<Box data-component-selector="amount-wrapper-aw1" xcss={amountWrapperStyles}>
						{amount}
					</Box>
				</>
			) : (
				<Box paddingInlineEnd="space.025">{amount}</Box>
			)}
		</div>
	);
};

const iconContainerStyles = xcss({
	marginRight: 'space.050',
	display: 'flex',
	alignItems: 'center',
});

const labelWrapperStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	font: 'font.body.UNSAFE_small',
	color: 'color.text',
	overflow: 'hidden',
	marginRight: 'space.050',
});

// Hover state styles for this component are created in: src/packages/polaris/apps/common/src/ui/idea-card-v2/main.tsx

const amountWrapperStyles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest.hovered',
	paddingInline: 'space.050',
	borderRadius: 'border.radius.050',
	whiteSpace: 'nowrap',
	font: 'font.body.UNSAFE_small',
	color: 'color.text',
});

const contentWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
	backgroundColor: token('color.background.accent.gray.subtlest'),
	paddingInline: token('space.050'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBlock: '3px',
	borderRadius: token('border.radius.100'),
	justifySelf: 'flex-start',
	gridColumn: '-1/1',
});
