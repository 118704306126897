/** @jsx jsx */
import React, { useRef, type PropsWithChildren } from 'react';
import { jsx, css } from '@compiled/react';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import { ObservableValuesList } from '../../../fields/observable-values-list/index.tsx';
import { ConnectionsList } from '../index.tsx';
import { TooltipComponent } from '../../tooltip-component/index.tsx';
import { useConnectionFieldIssueIds } from '../../../../controllers/issue/selectors/connection-hooks.tsx';
import { ConnectedIssueCard } from '../../../issue-card/index.tsx';

type Props = {
	fieldKey: string;
	localIssueId: string;
	isActive?: boolean;
};

export const InlineConnectionsList = ({ fieldKey, localIssueId, isActive }: Props) => {
	const listRef = useRef(null);
	const containerRef = useRef(null);
	const connectedIssueIds = useConnectionFieldIssueIds(localIssueId, fieldKey);

	const cards = connectedIssueIds.map(({ id }) => (
		<Box key={id} padding="space.050">
			<ConnectedIssueCard id={id} type="link" />
		</Box>
	));

	return (
		<Box ref={containerRef} xcss={innerContainerStyles}>
			{connectedIssueIds.length > 1 ? (
				<Flex ref={listRef} alignItems="center" wrap="wrap" xcss={listStyles}>
					<ObservableValuesList
						listRef={listRef}
						isActive={isActive}
						containerRef={containerRef}
						MoreTagWrapper={MoreTagWrapper}
						TooltipComponent={({ children }) => (
							<Tooltip
								hideTooltipOnClick
								component={TooltipComponent}
								content={
									<ConnectionsList
										localIssueId={localIssueId}
										fieldKey={fieldKey}
										connectionItems={connectedIssueIds}
									/>
								}
							>
								{children}
							</Tooltip>
						)}
					>
						{cards}
					</ObservableValuesList>
				</Flex>
			) : (
				<Inline>{cards}</Inline>
			)}
		</Box>
	);
};

const MoreTagWrapper = ({ children }: PropsWithChildren) => (
	<div css={moreTagWrapperStyles}>{children}</div>
);

const innerContainerStyles = xcss({
	overflow: 'hidden',
	position: 'relative',
});

const listStyles = xcss({
	width: 'max-content',
});

const moreTagWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="more-tag-container-327a"]': {
		lineHeight: token('space.200'),
	},
});
