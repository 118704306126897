/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These exports are for client side use only
# Importing into post office backend without a bundler will break the build
#
# Generate source: local/cli/src/commands/generate-all/team-export.ts
*/
	import RecommendationConfluence from '@post-office/message-template--recommendation-confluence/placements/in-app/jira-side-nav/render';
	import RecommendationJpd from '@post-office/message-template--recommendation-jpd/placements/in-app/jira-side-nav/render';
	import RecommendationJsm from '@post-office/message-template--recommendation-jsm/placements/in-app/jira-side-nav/render';
	import RecommendationJswVisualizeWork from '@post-office/message-template--recommendation-jsw-visualize-work/placements/in-app/jira-side-nav/render';
	import RecommendationLoom from '@post-office/message-template--recommendation-loom/placements/in-app/jira-side-nav/render';

export default {
	'recommendation-confluence': RecommendationConfluence,
	'recommendation-jpd': RecommendationJpd,
	'recommendation-jsm': RecommendationJsm,
	'recommendation-jsw-visualize-work': RecommendationJswVisualizeWork,
	'recommendation-loom': RecommendationLoom,
};
