import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	type PolarisEnvironmentContainer,
	PolarisEnvironmentContainerTypes,
} from '@atlassian/jira-polaris-component-environment-container/src/controllers/types.tsx';
import {
	type StatusCategory,
	toKnownStatusCategoryKey,
} from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StatusCategory as JiraStatusCategory } from '@atlassian/jira-shared-types/src/rest/jira/status-category.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { wrapPromiseWithFetchInteraction } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/interaction/index.tsx';
import {
	getPolarisArjConfiguration,
	getPolarisArjHierarchyConfiguration,
} from '../../../services/jira/arj-configuration/index.tsx';
import { getJpdEditionsMeta } from '../../../services/jira/edition-meta/index.tsx';
import { fetchIsEditorAiEnabled } from '../../../services/jira/editor-ai/index.tsx';
import { getClassicProjectEpicNameFieldKey } from '../../../services/jira/epic-name/index.tsx';
import {
	getIssueLinkTypes,
	getSpecialJpdIssueLinkTypes,
} from '../../../services/jira/issue-link-types/index.tsx';
import { getStatusCategories } from '../../../services/jira/status-categories/index.tsx';
import type { State } from '../../types.tsx';

export type LoadProps = {
	onLoadingFailed: (arg1: Error) => void;
	apolloClient: PolarisApolloClient;
	cloudId: CloudId;
	container: PolarisEnvironmentContainer | undefined;
};

export const load =
	({ onLoadingFailed, apolloClient, cloudId, container }: LoadProps) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		getClassicProjectEpicNameFieldKey()
			.then((epicNameFieldKey?: string) => {
				setState({
					jira: {
						...getState().jira,
						epicNameFieldKey,
					},
				});
			})
			.catch((error: Error) => {
				fireErrorAnalytics(createErrorAnalytics('epicNameField failure', error));
				onLoadingFailed(error);
			});

		wrapPromiseWithFetchInteraction(getIssueLinkTypes(), 'getIssueLinkTypes')
			.then((response) => {
				setState({
					jira: {
						...getState().jira,
						issueLinkTypes: response.issueLinkTypes,
					},
				});
			})
			.catch((error: FetchError) => {
				fireErrorAnalytics(createErrorAnalytics('loadIssueLinkTypes failure', error));

				if (error.statusCode !== 404) {
					onLoadingFailed(error);
				}

				setState({
					meta: {
						...getState().meta,
						errors: {
							issueLinkTypes: error,
						},
					},
				});
			});

		wrapPromiseWithFetchInteraction(getSpecialJpdIssueLinkTypes(), 'getSpecialJpdIssueLinkTypes')
			.then((jpdLinks) => {
				setState({
					jira: {
						...getState().jira,
						jpdIssueLinkTypes: {
							delivery:
								jpdLinks.deliveryLinkTypeId !== undefined
									? String(jpdLinks.deliveryLinkTypeId)
									: undefined,
							merge:
								jpdLinks.mergeLinkTypeId !== undefined
									? String(jpdLinks.mergeLinkTypeId)
									: undefined,
							dataPoint:
								jpdLinks.datapointLinkTypeId !== undefined
									? String(jpdLinks.datapointLinkTypeId)
									: undefined,
						},
					},
				});
			})
			.catch((error: Error) => {
				fireErrorAnalytics(createErrorAnalytics('loadJpdIssueLinkTypes failure', error));
				onLoadingFailed(error);
			});

		if (fg('polaris-refactor-arjhierarchyconfiguration')) {
			wrapPromiseWithFetchInteraction(
				getPolarisArjConfiguration(),
				'getPolarisArjConfiguration',
			).then((config) => {
				const state = getState().jira;
				setState({
					jira: {
						...state,
						arj: {
							...state.arj,
							epicLinkCustomFieldId: config.epicLinkCustomFieldId,
							parentCustomFieldId: config.parentCustomFieldId,
							storyPointsCustomFieldId: config.storyPointsCustomFieldId,
						},
					},
				});
			});

			wrapPromiseWithFetchInteraction(
				getPolarisArjHierarchyConfiguration(),
				'getPolarisArjHierarchyConfiguration',
			).then((config) => {
				const state = getState().jira;
				setState({
					jira: {
						...state,
						arj: {
							...state.arj,
							arjHierarchyConfiguration: config.levels,
						},
					},
				});
			});
		} else {
			getPolarisArjConfiguration().then((config) => {
				setState({
					jira: {
						...getState().jira,
						arj: {
							epicLinkCustomFieldId: config.epicLinkCustomFieldId,
							parentCustomFieldId: config.parentCustomFieldId,
							storyPointsCustomFieldId: config.storyPointsCustomFieldId,
							arjHierarchyConfiguration: undefined,
						},
					},
				});
			});
		}

		if (container && container.type !== PolarisEnvironmentContainerTypes.VIEW) {
			wrapPromiseWithFetchInteraction(getStatusCategories(), 'getStatusCategories').then(
				(jiraStatusCategories) => {
					const statusCategories: StatusCategory[] = [];

					jiraStatusCategories.forEach((category: JiraStatusCategory) => {
						const key = toKnownStatusCategoryKey(category.key);
						if (category.colorName !== undefined && category.name !== undefined) {
							statusCategories.push({
								key,
								id: category.id,
								colorName: category.colorName,
								name: category.name,
							});
						}
					});

					setState({
						jira: {
							...getState().jira,
							statusCategories,
						},
					});
				},
			);
		}

		if (container && container.type !== PolarisEnvironmentContainerTypes.VIEW) {
			wrapPromiseWithFetchInteraction(getJpdEditionsMeta(), 'getJpdEditionsMeta')
				.then((response) => {
					setState({
						features: {
							...getState().features,
							isBeta: response.beta,
							isSelfServeEnabledOnSite: response.isSelfServeEnabledOnSite,
							isEnforcePermissionChecksEnabledOnSite:
								response.isEnforcePermissionChecksEnabledOnSite,
							canSelfServeBetaSiteCreator: response.canSelfServe,
						},
					});
				})
				.catch((error) => {
					fireErrorAnalytics(createErrorAnalytics('getEditionsMeta failure', error));
					onLoadingFailed(error);
				});
		}

		fetchIsEditorAiEnabled(apolloClient, cloudId)
			.then((isEditorAiEnabled) => {
				setState({
					features: {
						...getState().features,
						isEditorAiEnabled,
					},
				});
			})
			.catch((error) => {
				fireErrorAnalytics(createErrorAnalytics('fetchIsEditorAiEnabled failure', error));
				onLoadingFailed(error);
			});
	};
