import React from 'react';
import Button from '@atlaskit/button/new';
import PreferencesIcon from '@atlaskit/icon/core/migration/customize--preferences';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useProjectsMetadataHasError,
	useProjectsMetadataLoading,
} from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { RightSidebarBody } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/body/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SIDEBAR_MANAGE_PROJECTS_CONTENT_TYPE,
	SIDEBAR_MANAGE_FILTERS_CONTENT_TYPE,
} from '../../common/constants.tsx';
import type { SidebarContentType } from '../../common/types.tsx';
import { useCollectionUUID } from '../../controllers/index.tsx';
import { Filters } from './filters/index.tsx';
import { CollectionNameInput } from './collection-name-input/index.tsx';
import { DefaultSidebarContentHeader } from './header/index.tsx';
import messages from './messages.tsx';
import { ProjectsList } from './projects-list/index.tsx';
import { ProjectsSkeleton } from './skeleton/index.tsx';

type Props = {
	onContentChange: (content: SidebarContentType) => void;
};

export const DefaultSidebarContent = ({ onContentChange }: Props) => {
	const { formatMessage } = useIntl();
	const collectionUUID = useCollectionUUID();
	const projectsHasError = useProjectsMetadataHasError({ containerId: collectionUUID });
	const isProjectsLoading = useProjectsMetadataLoading({ containerId: collectionUUID });

	return (
		<>
			<DefaultSidebarContentHeader />
			<RightSidebarBody>
				<Stack space="space.250" xcss={bodyStyles}>
					<Stack xcss={nameSectionStyles}>
						<CollectionNameInput />
					</Stack>
					<Box backgroundColor="color.background.neutral" xcss={dividerStyles} />
					<Stack space="space.200" xcss={sectionStyles}>
						<Text color="color.text.subtle" weight="semibold">
							{formatMessage(messages.projectsSectionTitle)}
						</Text>
						{isProjectsLoading ? <ProjectsSkeleton /> : <ProjectsList />}
						<Inline>
							<Button
								testId="polaris-component-collection-configure-sidebar.ui.default-content.manage-projects-button"
								iconBefore={() => (
									<PreferencesIcon label="" color={iconColor} LEGACY_primaryColor={iconColor} />
								)}
								isDisabled={isProjectsLoading || projectsHasError}
								onClick={() => onContentChange(SIDEBAR_MANAGE_PROJECTS_CONTENT_TYPE)}
							>
								{formatMessage(messages.manageProjectsButtonLabel)}
							</Button>
						</Inline>
					</Stack>
					{fg('jpd-aurora-roadmap-advanced-filtering') && (
						<>
							<Box backgroundColor="color.background.neutral" xcss={dividerStyles} />
							<Stack space="space.200" xcss={sectionStyles}>
								<Text color="color.text.subtle" weight="semibold">
									{formatMessage(messages.filtersSectionTitle)}
								</Text>
								<Filters collectionUUID={collectionUUID} />
								<Inline>
									<Button
										testId="polaris-component-collection-configure-sidebar.ui.default-content.manage-filters-button"
										iconBefore={() => (
											<PreferencesIcon label="" color={iconColor} LEGACY_primaryColor={iconColor} />
										)}
										onClick={() => onContentChange(SIDEBAR_MANAGE_FILTERS_CONTENT_TYPE)}
									>
										{formatMessage(messages.manageFiltersButtonLabel)}
									</Button>
								</Inline>
							</Stack>
						</>
					)}
				</Stack>
			</RightSidebarBody>
		</>
	);
};

const iconColor = token('color.icon', '#44546F');

const bodyStyles = xcss({
	height: '100%',
	paddingBottom: 'space.150',
});

const dividerStyles = xcss({
	height: '2px',
	borderRadius: '1px',
	marginBlock: 'space.075',
	flexShrink: 0,
});

const sectionStyles = xcss({ minHeight: '0px' });

const nameSectionStyles = xcss({ flexShrink: 0 });
