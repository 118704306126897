import React, { type PropsWithChildren, type ReactNode } from 'react';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Inline, Pressable, Stack, xcss } from '@atlaskit/primitives';

type Props = {
	title: ReactNode;
	isCollapsed: boolean;
	onToggle: VoidFunction;
};

export const CollapsibleGroup = ({
	title,
	children,
	isCollapsed,
	onToggle,
}: PropsWithChildren<Props>) => {
	return (
		<Stack space="space.100">
			<Pressable xcss={triggerStyles} onClick={onToggle}>
				<Inline space="space.100" alignBlock="center">
					{isCollapsed ? <ChevronRightIcon label="" /> : <ChevronDownIcon label="" />}
					{title}
				</Inline>
			</Pressable>
			{!isCollapsed && children}
		</Stack>
	);
};

const triggerStyles = xcss({
	background: 'none',
	paddingBlock: 'space.050',
	paddingInline: 'space.0',
});
