import { notImplementedError } from '../../common/utils.tsx';
import {
	associateGlobalField,
	associateGlobalFieldOld,
	associateGlobalFields,
	associateGlobalFieldsOld,
} from '../../services/jira/associate-global-field/index.tsx';
import { getGlobalFieldProjectsUsageInfo } from '../../services/jira/get-global-field-projects-usage/index.tsx';
import {
	unassociateGlobalField,
	unassociateGlobalFieldOld,
} from '../../services/jira/unassociate-global-field/index.tsx';
import { getGlobalFieldProjectViews } from '../../services/polaris-api/get-global-field-project-views/index.tsx';
import type { GlobalFieldUsageRemote } from './types.tsx';

export const createNotImplementedJiraGlobalFieldUsageRemote = (): GlobalFieldUsageRemote => ({
	addGlobalFieldToProjectsOld: notImplementedError('addGlobalFieldToProjects'),
	addGlobalFieldToProjects: notImplementedError('addGlobalFieldToProjects'),
	addGlobalFieldsToProjectOld: notImplementedError('addGlobalFieldsToProject'),
	addGlobalFieldsToProject: notImplementedError('addGlobalFieldsToProject'),
	removeGlobalFieldFromProjectOld: notImplementedError('removeGlobalFieldFromProject'),
	removeGlobalFieldFromProject: notImplementedError('removeGlobalFieldFromProject'),
	getGlobalFieldProjectUsage: notImplementedError('getGlobalFieldProjectUsage'),
	getGlobalFieldProjectViews: notImplementedError('getGlobalFieldProjectViews'),
});

export const createJiraGlobalFieldUsageRemote = (): GlobalFieldUsageRemote => ({
	addGlobalFieldToProjectsOld: async ({ fieldKey, issueTypes }) => {
		await associateGlobalFieldOld(fieldKey, issueTypes);
	},
	addGlobalFieldToProjects: async ({ fieldKey, projects }) => {
		await associateGlobalField(fieldKey, projects);
	},
	addGlobalFieldsToProjectOld: async ({ fieldKeys, issueType }) => {
		await associateGlobalFieldsOld(fieldKeys, issueType);
	},
	addGlobalFieldsToProject: async ({ fieldKeys, projectId }) => {
		await associateGlobalFields(fieldKeys, projectId);
	},
	removeGlobalFieldFromProjectOld: async ({ fieldKey, issueType }) => {
		await unassociateGlobalFieldOld(fieldKey, issueType);
	},
	removeGlobalFieldFromProject: async ({ fieldKey, projectId }) => {
		await unassociateGlobalField(fieldKey, projectId);
	},
	getGlobalFieldProjectUsage: ({ fieldKey }) => getGlobalFieldProjectsUsageInfo(fieldKey),
	getGlobalFieldProjectViews: ({ fieldKey, projectId }) =>
		getGlobalFieldProjectViews(fieldKey, projectId),
});
