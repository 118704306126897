import React, { useCallback } from 'react';
import { HelperMessage } from '@atlaskit/form';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import { Flex, Text, Box, xcss } from '@atlaskit/primitives';
import Select, {
	CreatableSelect,
	components,
	type ControlProps,
	type NoticeProps,
	type OptionProps,
} from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsCollectionView } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { N300A } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { FieldSelectOptionComponent } from './field-select-option/index.tsx';
import messages from './messages.tsx';
import type { SelectProps, OptionType } from './types.tsx';

const ESCAPE = 27;

const OptionComponent = ({ data, ...rest }: OptionProps<OptionType>) => (
	<components.Option data={data} {...rest}>
		{data.key ? (
			<FieldSelectOptionComponent fieldKey={data.key} emojiId={data.emoji} {...data} />
		) : (
			rest.children
		)}
	</components.Option>
);

const CreateOptionLabel = ({ inputValue }: { inputValue: string }) => {
	const { formatMessage } = useIntl();
	return (
		<Flex
			testId="polaris-component-field-searchable-dropdown.ui.field-searchable-dropdown.create-option"
			gap="space.050"
			alignItems="center"
			xcss={createOptionContainerStyles}
		>
			<AddIcon label="" />
			{formatMessage(messages.createOption, { value: <Text weight="bold">{inputValue}</Text> })}
		</Flex>
	);
};

const NoOptionsMessage = (props: NoticeProps<OptionType>) => {
	const { formatMessage } = useIntl();
	return (
		<components.NoOptionsMessage {...props}>
			{formatMessage(messages.noMatchesIndicator)}
		</components.NoOptionsMessage>
	);
};

const CreatableControlComponent = (props: ControlProps<OptionType>) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<components.Control {...props} />
			<Box xcss={helperMessageContainerStyles}>
				<HelperMessage>{formatMessage(messages.creatableSelectHelperText)}</HelperMessage>
			</Box>
		</>
	);
};

const DropdownIndicator = () => (
	<EditorSearchIcon primaryColor={token('color.icon', colors.N100)} label="" />
);

const useNoOptionsMessage = ({
	noOptionsMessage,
	options,
}: Pick<SelectProps, 'noOptionsMessage' | 'options'>) => {
	const [isCollectionView] = useIsCollectionView();
	const { formatMessage } = useIntl();

	return useCallback<Exclude<SelectProps['noOptionsMessage'], undefined | string>>(
		(obj) => {
			if (isCollectionView && !options.length) {
				if (noOptionsMessage) {
					return typeof noOptionsMessage === 'string' ? noOptionsMessage : noOptionsMessage(obj);
				}
				return formatMessage(messages.roadmapNoOptions);
			}

			return formatMessage(messages.noMatchesIndicator);
		},
		[formatMessage, isCollectionView, noOptionsMessage, options.length],
	);
};

export const FieldSearchableDropdown = ({
	inputId,
	autoFocus = true,
	options,
	onSelect,
	onClose,
	onSearch,
	isSearchable = true,
	isCreatable,
	noOptionsMessage,
}: SelectProps) => {
	const { formatMessage } = useIntl();
	const SelectComponent = isCreatable ? CreatableSelect : Select;
	const noOptionsMessageProp = useNoOptionsMessage({ options, noOptionsMessage });

	return (
		<SelectComponent
			inputId={inputId}
			autoFocus={autoFocus && isSearchable}
			backspaceRemovesValue={false}
			formatCreateLabel={(inputValue) => <CreateOptionLabel inputValue={inputValue} />}
			isValidNewOption={(inputValue, _, allOptions) =>
				!!inputValue &&
				!allOptions.some((o) => o.label?.trim().toLowerCase() === inputValue.trim().toLowerCase())
			}
			components={{
				DropdownIndicator,
				Option: OptionComponent,
				Control: isCreatable ? CreatableControlComponent : components.Control,
				NoOptionsMessage,
			}}
			controlShouldRenderValue={false}
			hideSelectedOptions={false}
			isClearable={false}
			escapeClearsValue
			menuIsOpen
			onKeyDown={(e) => {
				if (e.keyCode === ESCAPE) {
					onClose && onClose();
				}
			}}
			onChange={(option, action) => onSelect(option?.key ?? '', action)}
			options={options}
			isOptionDisabled={(option: OptionType) => !!option.disabled}
			isOptionSelected={() => false} // selected item is not shown, so it's never true
			placeholder={formatMessage(messages.searchHint)}
			styles={{
				control: (styles) => ({
					...styles,
					width: 224,
					margin: token('space.100', '8px'),
					lineHeight: '20px',
					display: isSearchable ? styles.display : 'none',
					':hover': {
						cursor: 'text',
					},
				}),
				menu: () => ({
					width: 240,

					borderTop: isSearchable ? `1px solid ${token('color.border', N300A)}` : undefined,
				}),
				menuList: (styles) => ({
					...styles,
					height: isCreatable ? undefined : 'calc(50vh - var(--topNavigationHeight) - 80px)',
				}),
			}}
			tabSelectsValue={false}
			onInputChange={(inputValue) => {
				onSearch?.(inputValue);
			}}
			isSearchable={isSearchable}
			noOptionsMessage={noOptionsMessageProp}
		/>
	);
};

const createOptionContainerStyles = xcss({
	marginTop: 'space.negative.050',
	marginLeft: 'space.negative.050',
});

const helperMessageContainerStyles = xcss({
	marginBottom: 'space.100',
	marginLeft: 'space.100',
});
