import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	useSelectedIssueIssueType,
	useSelectedIssueKey,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Key = memo<Record<any, any>>(({ embedded = false }) => {
	const key = useSelectedIssueKey();
	const issueType = useSelectedIssueIssueType();
	if (key === undefined || issueType === undefined) {
		return null;
	}
	const singleIssueViewUrl = `/browse/${key}`;
	return (
		<Container>
			<Avatar src={issueType.iconUrl} size="xsmall" />
			<KeyContainer>{embedded ? key : <a href={singleIssueViewUrl}>{key}</a>}</KeyContainer>
		</Container>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.accent.magenta.bolder', colors.N200),
	marginLeft: token('space.025', '2px'),
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:hover, a:active, a:visited, a:focus': {
		color: 'inherit',
		borderRadius: '3px',
		paddingTop: token('space.050', '4px'),
		paddingRight: token('space.050', '4px'),
		paddingBottom: token('space.050', '4px'),
		paddingLeft: token('space.050', '4px'),
		boxShadow: 0,
		border: 0,
	},
});
