import React, { useEffect, memo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { preserveOffsetOnSource } from '@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { useThemeObserver, token } from '@atlaskit/tokens';
import {
	N100,
	B400,
	DN100,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { DEFAULT } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import {
	BOARD_COLUMN_GAP,
	BOARD_COLUMN_WIDTH,
	BOARD_COLUMN_PADDING,
	BOARD_COLUMN_TITLE_HEIGHT,
	BOARD_COLUMN_BORDER_WIDTH,
	MODE_GROUPING_ONLY_COLUMNS_FAKE_GROUP_NAME,
} from '../../constants.tsx';
import type { IssuesByGroupIdentity } from '../../types/common.tsx';
import type { CustomComponents } from '../../types/custom-components.tsx';
import {
	type DragState,
	type DraggableColumnHeaderData,
	isDraggableCardData,
	isDraggableColumnHeaderData,
} from '../../types/draggable.tsx';
import { useColumnDND, type UseColumnDNDProps } from '../../utils/draggable.tsx';
import { CardList } from '../card-list/index.tsx';
import { Footer } from './footer/index.tsx';

type ColumnProps = {
	isCardDropDisabled: boolean;
	isReadOnly: boolean;
	isFooterVisible: boolean;
	fieldValue: unknown;
	groupIdentity: string | undefined;
	droppableId: string;
	index: number;
	isLastColumn?: boolean;
	isPreview?: boolean;
	idsByColumn: IssuesByGroupIdentity;
	unfilteredIdsByColumn: IssuesByGroupIdentity;
	highlightColor: string;
	hideEmptyColumns: boolean;
	components: CustomComponents;
};

export const Column = memo((props: ColumnProps) => {
	const {
		fieldValue,
		droppableId,
		isCardDropDisabled,
		groupIdentity,
		index,
		isReadOnly,
		isFooterVisible,
		isLastColumn = false,
		isPreview = false,
		idsByColumn,
		unfilteredIdsByColumn,
		highlightColor,
		hideEmptyColumns,
		components,
	} = props;
	const columnContainerRef = useRef<HTMLDivElement | null>(null);
	const columnStickyHeaderRef = useRef<HTMLDivElement | null>(null);

	const { colorMode } = useThemeObserver();
	const theme = colorMode === 'dark' ? 'dark' : 'light';

	const ideaIds =
		(groupIdentity === undefined ? idsByColumn.empty : idsByColumn.groups[groupIdentity]) || [];

	const unfilteredIdeaIds =
		(groupIdentity === undefined
			? unfilteredIdsByColumn.empty
			: unfilteredIdsByColumn.groups[groupIdentity]) || [];

	const isDragDisabled = isReadOnly || groupIdentity === undefined;

	const analyticAttributes = {
		columnNoValue: fieldValue === undefined,
		columnIndex: index,
		columnDragDisabled: isDragDisabled,
		columnCardDropDisabled: isCardDropDisabled,
		columnCardCount: ideaIds.length,
	};

	const isEmptyColumn = !ideaIds.length;

	const canDrop: UseColumnDNDProps['canDrop'] = ({ source }) =>
		(isDraggableCardData(source.data) && !isCardDropDisabled) ||
		(isDraggableColumnHeaderData(source.data) && groupIdentity !== undefined);

	const { isCardBeingDraggedOver, columnDropEdge } = useColumnDND({
		draggableId: droppableId,
		droppableId,
		index,
		columnContainerRef,
		ideaIds,
		canDrop,
	});

	const [dragStatus, setDragStatus] = useState<DragState>('idle');
	const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(null);

	useEffect(() => {
		if (!columnStickyHeaderRef.current || isDragDisabled) {
			return undefined;
		}

		const cleanupDragAndDrop = combine(
			draggable({
				canDrag: () => !isDragDisabled && !isPreview,
				element: columnStickyHeaderRef.current,
				onGenerateDragPreview: ({ nativeSetDragImage, source, location }) => {
					setDragStatus('preview');
					setCustomNativeDragPreview({
						getOffset: preserveOffsetOnSource({
							element: source.element,
							input: location.current.input,
						}),
						render: ({ container }) => {
							setPreviewContainer(container);
							return () => {
								setPreviewContainer(null);
							};
						},
						nativeSetDragImage,
					});
				},
				getInitialData() {
					const data: DraggableColumnHeaderData = {
						draggableId: droppableId,
						index,
						type: 'COLUMN_HEADER',
					};
					return data;
				},
				onDragStart() {
					setDragStatus('dragging');
				},
				onDrop() {
					// the setTimeout prevents a flickering before the column is moved to another location
					setTimeout(() => setDragStatus('idle'));
				},
			}),
		);

		return cleanupDragAndDrop;
	}, [index, droppableId, isDragDisabled, isPreview]);

	const { ColumnHeader, ColumnFooter } = components;

	const columnContent = (
		<Container
			isNoValueColumn={groupIdentity === undefined}
			highlightColor={highlightColor}
			isBeingDraggedOver={isCardBeingDraggedOver}
			columnDropEdge={columnDropEdge}
			isFirstColumn={index === 0}
			isLastColumn={isLastColumn}
			dragStatus={dragStatus}
		>
			<TitleWrapper
				ref={columnStickyHeaderRef}
				isPreview={isPreview}
				data-testid="polaris-lib-board.ui.column.title-wrapper"
			>
				<Title
					isNoValueColumn={groupIdentity === undefined}
					highlightColor={highlightColor}
					isBeingDraggedOver={isCardBeingDraggedOver}
					isPreview={isPreview}
					columnDropEdge={columnDropEdge}
					isFirstColumn={index === 0}
					isLastColumn={isLastColumn}
				>
					<ColumnHeader
						isReadOnly={isReadOnly}
						groupIdentity={groupIdentity}
						cardsCount={ideaIds.length}
					/>
				</Title>
			</TitleWrapper>
			<CardList
				groupIdentity={MODE_GROUPING_ONLY_COLUMNS_FAKE_GROUP_NAME}
				columnIdentity={groupIdentity}
				isPreview={isPreview}
				droppableId={droppableId}
				isReadOnly={isReadOnly}
				showHiddenIdeasText={ideaIds.length === 0 && unfilteredIdeaIds.length > 0}
				isFullHeight
				components={components}
			/>
			{!isReadOnly && isFooterVisible && (
				<Footer component={ColumnFooter} groupIdentity={groupIdentity} fieldValue={fieldValue} />
			)}
		</Container>
	);

	if (isPreview) {
		return columnContent;
	}

	return (
		<ContextualAnalyticsData attributes={analyticAttributes}>
			<Spacer
				data-testid="polaris-lib-board.ui.column.column"
				ref={columnContainerRef}
				isHidden={hideEmptyColumns && isEmptyColumn}
			>
				{columnContent}
			</Spacer>
			{previewContainer
				? ReactDOM.createPortal(
						<PreviewWrapper isDarkTheme={theme === 'dark'}>
							<Column {...props} isReadOnly isPreview />
						</PreviewWrapper>,
						previewContainer,
					)
				: null}
		</ContextualAnalyticsData>
	);
});

Column.displayName = 'BoardColumn';

const getBackgroundColor = ({
	isNoValueColumn,
	highlightColor,
}: {
	isNoValueColumn: boolean;
	highlightColor: string;
}) =>
	// eslint-disable-next-line no-nested-ternary
	isNoValueColumn
		? token('color.background.input.hovered', N100)
		: highlightColor === undefined || highlightColor === DEFAULT.highlightColor
			? token('color.background.input.hovered', N100)
			: highlightColor;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	isNoValueColumn: boolean;
	highlightColor: string;
	isBeingDraggedOver: boolean;
	columnDropEdge: Edge | null;
	isFirstColumn: boolean;
	isLastColumn: boolean;
	dragStatus: string;
}>({
	display: 'flex',
	position: 'relative',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${BOARD_COLUMN_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${BOARD_COLUMN_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${BOARD_COLUMN_WIDTH}px`,
	minHeight: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${BOARD_COLUMN_PADDING}px`,
	paddingTop: 0,
	boxSizing: 'border-box',
	borderRadius: '5px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isNoValueColumn, highlightColor }) =>
		getBackgroundColor({ isNoValueColumn, highlightColor }),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ isBeingDraggedOver }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`${BOARD_COLUMN_BORDER_WIDTH}px solid ${
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isBeingDraggedOver ? token('color.border.brand', B400) : 'transparent'
		}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ columnDropEdge }) => !columnDropEdge && 'none',
		content: '',
		width: '2px',
		position: 'absolute',
		top: token('space.negative.025', '-2px'),
		bottom: token('space.negative.025', '-2px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		left: ({ columnDropEdge, isFirstColumn }) =>
			columnDropEdge === 'left' ? `${isFirstColumn ? '-2px' : '-7px'}` : 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		right: ({ columnDropEdge, isLastColumn }) =>
			columnDropEdge === 'right' ? `${isLastColumn ? '-2px' : '-7px'}` : 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.border.brand', B400),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ dragStatus }) => dragStatus === 'dragging' && 0.3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		filter: ({ dragStatus }) => dragStatus === 'dragging' && 'saturate(0)',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Spacer = styled.div<{ isHidden: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isHidden }) => isHidden && 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingRight: `${BOARD_COLUMN_GAP}px`,
	height: 'auto',
	minHeight: '100%',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div<{
	isNoValueColumn: boolean;
	highlightColor: string;
	isBeingDraggedOver: boolean;
	isPreview: boolean;
	columnDropEdge: Edge | null;
	isFirstColumn: boolean;
	isLastColumn: boolean;
}>({
	height: '100%',
	width: '100%',
	boxSizing: 'border-box',
	borderTopLeftRadius: '5px',
	borderTopRightRadius: '5px',
	paddingTop: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingRight: '10px',
	paddingBottom: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '10px',
	alignItems: 'center',
	justifyContent: 'flex-start',
	flex: '0 0 auto',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isNoValueColumn, highlightColor, isPreview }) =>
		isPreview ? undefined : getBackgroundColor({ isNoValueColumn, highlightColor }),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ isNoValueColumn, highlightColor, isBeingDraggedOver }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`${BOARD_COLUMN_BORDER_WIDTH}px solid ${
			isBeingDraggedOver
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.border.brand', B400)
				: getBackgroundColor({ isNoValueColumn, highlightColor })
		}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderLeft: ({ columnDropEdge, isFirstColumn }) =>
		columnDropEdge === 'left' && isFirstColumn
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${BOARD_COLUMN_BORDER_WIDTH}px solid ${token('color.border.brand', B400)}`
			: undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRight: ({ columnDropEdge, isLastColumn }) =>
		columnDropEdge === 'right' && isLastColumn
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${BOARD_COLUMN_BORDER_WIDTH}px solid ${token('color.border.brand', B400)}`
			: undefined,
	borderBottomWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div<{ isPreview: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${BOARD_COLUMN_TITLE_HEIGHT}px`,
	marginTop: token('space.negative.025', '-2px'),
	marginRight: token('space.negative.150', '-12px'),
	marginBottom: 0,
	marginLeft: token('space.negative.150', '-12px'),
	position: 'sticky',
	top: 0,
	zIndex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isPreview }) => (isPreview ? undefined : token('elevation.surface', 'white')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewWrapper = styled.div<{ isDarkTheme: boolean }>({
	height: '80vh',
	boxSizing: 'border-box',
	borderRadius: '5px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: ({ isDarkTheme }) => (isDarkTheme ? DN100 : 'white'),
});
