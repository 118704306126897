import React from 'react';
import { TeamValue } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';

type ColumnHeaderComponentProps = {
	groupIdentity: string;
};

const TeamColumnHeader = ({ groupIdentity }: ColumnHeaderComponentProps) => (
	<TeamValue name={groupIdentity} />
);

const EmptyTeamColumnHeader = () => <TeamValue />;

export const teamColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: TeamColumnHeader,
	EmptyComponent: EmptyTeamColumnHeader,
} as const;
