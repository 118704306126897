import React, {
	type ComponentType,
	type ReactNode,
	useCallback,
	useMemo,
	useState,
	useEffect,
	useRef,
	forwardRef,
	type ComponentPropsWithoutRef,
} from 'react';
import { styled } from '@compiled/react';
import isNumber from 'lodash/isNumber';
import LockIcon from '@atlaskit/icon/glyph/lock-filled';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	monitorForElements,
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { B400, N500 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { sized } from '@atlassian/jira-polaris-lib-resize-observer/src/index.tsx';
import { CONTINUOUS_PADDING_PIXELS } from '../../common/constants/index.tsx';
import { useMatrixActions } from '../../controllers/index.tsx';
import { useIsAxesDragDisabled } from '../../controllers/selectors/axis-hooks.tsx';
import {
	DISCRETE,
	type AxisValue,
	CONTINUOUS,
	type ContinuousAxis,
	type Axis,
	type AxisGroupValue,
} from '../../types.tsx';
import type { AxisSizingCallback } from '../container/types.tsx';
import type { AxisLayoutMode } from './types.tsx';
import { calculateIntervalCount } from './utils.tsx';

type AxisItemProps<TAxisValue> = {
	value: TAxisValue;
	mode: AxisLayoutMode;
	itemCount: number;
	axisHeight?: number;
	components?: {
		Tooltip?: ComponentType<{
			value: TAxisValue;
		}>;
		Label?: ComponentType<{
			value: TAxisValue;
			mode: AxisLayoutMode;
		}>;
	};
};

type DiscreteAxisItemProps = AxisItemProps<AxisValue>;
type ContinuousAxisItemProps = AxisItemProps<number>;

const RawValueLabel = ({ value }: { value: AxisValue }) => {
	const { formatNumber } = useIntl();
	return (
		<Label>
			{typeof value === 'number'
				? formatNumber(Math.round(value), {
						notation: 'compact',
						compactDisplay: 'short',
					})
				: value}
		</Label>
	);
};

const DiscreteAxisItem = forwardRef<HTMLDivElement, DiscreteAxisItemProps>(
	({ value, components, mode, itemCount, axisHeight }, ref) => {
		const size = axisHeight !== undefined ? axisHeight / itemCount : 0;
		return (
			<Item data-component-selector="item-KidZ" mode={mode} itemSize={size} ref={ref}>
				{mode === 'HORIZONTAL' && (
					<Marker data-component-selector="marker-nZjK" isVertical={false} />
				)}
				<LabelWrapper>
					{value !== undefined && components?.Label !== undefined ? (
						<components.Label value={value} mode={mode} />
					) : (
						<RawValueLabel value={value} />
					)}
				</LabelWrapper>
				{mode === 'VERTICAL' && <Marker data-component-selector="marker-nZjK" isVertical />}
			</Item>
		);
	},
);

const ContinuousAxisItem = ({ value, components, mode }: ContinuousAxisItemProps) => (
	<Item data-component-selector="item-KidZ">
		{mode === 'HORIZONTAL' && <ExactMarker isVertical={false} />}
		<LabelWrapper>
			{value !== undefined && components?.Label !== undefined ? (
				<components.Label value={value} mode={mode} />
			) : (
				<RawValueLabel value={value} />
			)}
		</LabelWrapper>
		{mode === 'VERTICAL' && <ExactMarker isVertical />}
	</Item>
);

type ContinuousAxisItemsProps = {
	axis: ContinuousAxis;
	mode: AxisLayoutMode;
	currentWidth: number;
};

const ContinuousAxisItems = ({ axis, mode, currentWidth }: ContinuousAxisItemsProps) => {
	const {
		range: { min, max, isReverseOrder },
	} = axis;
	const count = useMemo(
		() => calculateIntervalCount({ min, max, isReverseOrder }, currentWidth),
		[min, max, isReverseOrder, currentWidth],
	);

	const values = useMemo(() => {
		if (count === 0) {
			return [];
		}

		const stepSize = max / count;

		return [...Array(count + 1)].map((x, i) => {
			const value = isReverseOrder ? max - i * stepSize : min + i * stepSize;
			return value;
		});
	}, [min, max, isReverseOrder, count]);

	return (
		<ContinuousAxisContainer>
			<Spacer />
			<ContinuousWrapper itemCount={values.length}>
				{values.map((value) => (
					<ContinuousAxisItem
						key={`${value}`}
						value={value}
						itemCount={values.length}
						components={axis.components}
						mode={mode}
					/>
				))}
			</ContinuousWrapper>
			<Spacer />
		</ContinuousAxisContainer>
	);
};

type AxisComponentProps = {
	axis: Axis | undefined;
	dimension?: string;
	mode: AxisLayoutMode;
	currentWidth: number;
	locked: boolean;
	lockedMessage: string | undefined;
	onSizing?: AxisSizingCallback<number>;
};

type AxisInfoLabelProps = {
	isVerticalMode: boolean;
	locked: boolean;
	lockedMessage: string | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	LabelComponent?: ComponentType<Record<any, any>>;
	onSizeChanged?: (width: number, height: number) => void;
};

const AxisInfoLabel = ({
	LabelComponent,
	onSizeChanged,
	isVerticalMode,
	locked,
	lockedMessage,
}: AxisInfoLabelProps) =>
	LabelComponent !== undefined ? (
		<SizedAxisInfoWrapper isVerticalMode={isVerticalMode} onSizeChanged={onSizeChanged}>
			<IconWrapper>
				{locked && (
					<Tooltip content={lockedMessage || ''}>
						<LockIcon label="lock" size="small" />
					</Tooltip>
				)}
				<AxisLabel>
					<LabelComponent />
				</AxisLabel>
			</IconWrapper>
		</SizedAxisInfoWrapper>
	) : null;

type DndAxisProps = {
	id: AxisValue;
	isDragDisabled: boolean;
	mode: AxisLayoutMode;
	itemCount: number;
	children: ReactNode;
};

const DndAxisItem = (props: DndAxisProps) => {
	const { children, id, isDragDisabled, mode, itemCount } = props;

	const ref = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [dragStatus, setDragStatus] = useState<'idle' | 'preview' | 'dragging'>('idle');

	const dragType = `axis-${mode}`;

	useEffect(() => {
		if (!ref.current) return undefined;

		const cleanupDragAndDrop = combine(
			draggable({
				element: ref.current,
				onGenerateDragPreview: () => {
					setDragStatus('preview');
				},
				getInitialData() {
					return { id, type: dragType };
				},
				onDragStart() {
					setDragStatus('dragging');
				},
				onDrop() {
					setDragStatus('idle');
				},
				canDrag: () => !isDragDisabled,
			}),
			dropTargetForElements({
				element: ref.current,
				getData({ input, element }) {
					return attachClosestEdge(
						{ id },
						{
							input,
							element,
							allowedEdges: mode === 'HORIZONTAL' ? ['left', 'right'] : ['top', 'bottom'],
						},
					);
				},
				canDrop({ source }) {
					return source.data.type === dragType;
				},
				onDrag(args) {
					if (args.source.data.id !== id) {
						const dropEdge = extractClosestEdge(args.self.data);
						if (closestEdge !== dropEdge) {
							setClosestEdge(dropEdge);
						}
					}
				},
				onDragLeave() {
					setClosestEdge(null);
				},
				onDrop() {
					setClosestEdge(null);
				},
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [closestEdge, dragType, id, isDragDisabled, mode]);

	return (
		<DiscreteAxisItemWrapper
			ref={ref}
			mode={mode}
			itemCount={itemCount}
			closestEdge={closestEdge}
			dragStatus={dragStatus}
		>
			{children}
		</DiscreteAxisItemWrapper>
	);
};

export const AxisComponent = ({
	dimension = '',
	axis,
	mode,
	onSizing,
	currentWidth,
	locked,
	lockedMessage,
}: AxisComponentProps) => {
	const [, { updateAxisOptions }] = useMatrixActions();
	const isAxesDragDisabled = useIsAxesDragDisabled();

	const options = useMemo(
		() => (axis !== undefined && axis.type === DISCRETE ? axis?.range?.values : []),
		[axis],
	);
	const [localOptions, setLocalOptions] = useState(options);
	const [axisHeight, setAxisHeight] = useState(0);
	const [axisWidth, setAxisWidth] = useState(0);

	const axisItemRef = useRef<HTMLDivElement | null>(null);

	const isVerticalMode = mode === 'VERTICAL';
	const isHorizontalMode = mode === 'HORIZONTAL';

	const isDragDisabled = localOptions.length === 1 || isAxesDragDisabled;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const container = document.getElementById('polaris-matrix.ui.axis-container');
	useEffect(() => {
		const height = container?.clientHeight;
		if (height !== undefined) {
			setAxisHeight(height);
		}
	}, [container?.clientHeight]);

	useEffect(() => {
		const axisItemHeight = axisItemRef?.current?.clientHeight;
		if (axisItemHeight !== undefined && isVerticalMode) {
			setAxisWidth(axisItemHeight);
		}
	}, [axisItemRef?.current?.clientHeight, axisWidth, isVerticalMode, options]);

	useEffect(() => {
		setLocalOptions(options);
	}, [options]);

	const onInfoLabelSizeChanged = useCallback(
		(width: number, height: number) => {
			onSizing?.(height);
		},
		[onSizing],
	);

	const dragType = `axis-${mode}`;

	useEffect(() => {
		const cleanupDragAndDrop = combine(
			monitorForElements({
				canMonitor({ source }) {
					return source.data.type === dragType;
				},
				onDrop: ({ source, location }) => {
					const target = location.current.dropTargets?.[0];
					if (!target || source.data.id === target?.data?.id) {
						return;
					}

					const edge = extractClosestEdge(target.data);

					const newOrder = isVerticalMode
						? [...localOptions].reverse().map((el) => el)
						: [...localOptions];

					const srcIdx = newOrder.findIndex((el) => el === source.data.id);
					const dstIdx = newOrder.findIndex((el) => el === target.data.id);

					if (srcIdx === undefined || dstIdx === undefined) {
						return;
					}

					// Destination index adjusted with edge data
					let adj = srcIdx < dstIdx && (edge === 'top' || edge === 'left') ? -1 : 0;
					adj = srcIdx > dstIdx && (edge === 'bottom' || edge === 'right') ? 1 : adj;

					const sourceItem = newOrder[srcIdx];
					newOrder.splice(srcIdx, 1);
					newOrder.splice(dstIdx + adj, 0, sourceItem);

					isVerticalMode && newOrder.reverse();
					const newSortedGroupOptions: AxisGroupValue[] = newOrder.map((option) => ({
						id: String(option),
					}));

					setLocalOptions(newOrder);
					updateAxisOptions(newSortedGroupOptions, dimension);
				},
			}),
		);

		return () => {
			cleanupDragAndDrop();
		};
	}, [dimension, dragType, isVerticalMode, localOptions, updateAxisOptions]);

	if (axis === undefined) {
		return null;
	}

	const isVerticalDiscreteAxis = isVerticalMode && axis.type === DISCRETE;
	const fieldOptions = isVerticalDiscreteAxis ? [...localOptions].reverse() : [...localOptions];

	return (
		<AxisContainer isVerticalMode={isVerticalDiscreteAxis}>
			{isHorizontalMode && (
				<AxisInfoLabel
					isVerticalMode={isVerticalDiscreteAxis}
					LabelComponent={axis.Label}
					onSizeChanged={onInfoLabelSizeChanged}
					locked={locked}
					lockedMessage={lockedMessage}
				/>
			)}
			{axis.type === DISCRETE && (
				<DiscreteWrapper
					axisWidth={axisWidth}
					id={isVerticalMode ? 'polaris-matrix.ui.axis-container' : ''}
					mode={mode}
				>
					{fieldOptions.map((value, index) => (
						<DndAxisItem
							isDragDisabled={isDragDisabled || isNumber(value)}
							key={`${value}-${index}`}
							id={value}
							mode={mode}
							itemCount={localOptions.length}
						>
							<DiscreteAxisItem
								ref={axisItemRef}
								axisHeight={axisHeight}
								itemCount={localOptions.length}
								value={value}
								components={axis.components}
								mode={mode}
							/>
						</DndAxisItem>
					))}
				</DiscreteWrapper>
			)}
			{axis.type === CONTINUOUS && (
				<>
					{isHorizontalMode && <AxisLine />}
					<ContinuousAxisItems axis={axis} mode={mode} currentWidth={currentWidth} />
					{isVerticalMode && <AxisLine />}
				</>
			)}
			{isVerticalMode && (
				<AxisInfoLabel
					isVerticalMode={isVerticalDiscreteAxis}
					LabelComponent={axis.Label}
					onSizeChanged={() => undefined}
					locked={locked}
					lockedMessage={lockedMessage}
				/>
			)}
		</AxisContainer>
	);
};

const ITEM_MARGIN = 4;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const calcMaxItemSize = (itemCount: any) => `calc(${100 / itemCount}% - ${ITEM_MARGIN}px)`;
const calcItemWidth = (itemSize = 0) => `${itemSize - ITEM_MARGIN * 2}px`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Item = styled.div<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mode?: any;
	itemSize?: number;
}>({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: ({ mode }) => mode === 'VERTICAL' && 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transformOrigin: ({ mode }) => mode === 'VERTICAL' && '0 0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ mode }) => mode === 'VERTICAL' && 'rotate(-90deg) translate(-100%, 0%)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ mode, itemSize }) => (mode === 'VERTICAL' ? calcItemWidth(itemSize) : '100%'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelWrapper = styled.div({
	minHeight: '16px',
	display: 'flex',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	marginTop: token('space.025', '2px'),
	marginRight: 0,
	marginBottom: token('space.025', '2px'),
	marginLeft: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExactMarker = styled.div<{ isVertical: boolean }>({
	height: '12px',
	width: '4px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border', colors.N30),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ isVertical }) => (isVertical ? '3px 3px 0 0' : '0 0 3px 3px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Marker = styled.div<{ isVertical: boolean }>({
	height: '4px',
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border', colors.N30),
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ isVertical }) => !isVertical && '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ isVertical }) => isVertical && '10px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DiscreteAxisItemWrapper = styled.div<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mode: any;
	itemCount: number;
	closestEdge: Edge | null;
	dragStatus: string;
}>({
	position: 'relative',
	width: '100%',
	height: '100%',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-component-selector="marker-nZjK"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ dragStatus }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			dragStatus !== 'idle' ? colors.N50 : token('color.border', colors.N30),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ mode }) => mode === 'VERTICAL' && `${ITEM_MARGIN}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ mode }) => mode === 'HORIZONTAL' && `${ITEM_MARGIN}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ mode }) => mode === 'VERTICAL' && `${ITEM_MARGIN}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ mode }) => mode === 'HORIZONTAL' && `${ITEM_MARGIN}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: ({ mode, itemCount }) => mode === 'VERTICAL' && calcMaxItemSize(itemCount),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ mode, itemCount }) => mode === 'HORIZONTAL' && calcMaxItemSize(itemCount),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ dragStatus }) => (dragStatus === 'dragging' ? 0.75 : 1),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ dragStatus, closestEdge }) => (!closestEdge || dragStatus !== 'idle') && 'none',
		content: '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		width: ({ mode }) => (mode === 'VERTICAL' ? '100%' : '3px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ mode }) => (mode === 'HORIZONTAL' ? '100%' : '3px'),
		position: 'absolute',
		zIndex: 200,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: ({ closestEdge }) => (closestEdge === 'top' ? `${-ITEM_MARGIN}px` : 'auto'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		bottom: ({ closestEdge }) => (closestEdge === 'bottom' ? `${-ITEM_MARGIN}px` : 'auto'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		left: ({ closestEdge }) => (closestEdge === 'left' ? `${-ITEM_MARGIN}px` : 'auto'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		right: ({ closestEdge }) => (closestEdge === 'right' ? `${-ITEM_MARGIN}px` : 'auto'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.border.brand', B400),
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DiscreteWrapper = styled.div<{ mode: any; axisWidth: string | number }>({
	borderRadius: '0 0 3px 0',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minWidth: ({ mode, axisWidth }) => mode === 'VERTICAL' && `${axisWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ mode, axisWidth }) => mode === 'HORIZONTAL' && `${axisWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ mode }) => mode === 'VERTICAL' && '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ mode }) => mode === 'HORIZONTAL' && '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ mode }) => (mode === 'VERTICAL' ? 'column' : 'row'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContinuousAxisContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Spacer = styled.div({
	flex: '0 0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${CONTINUOUS_PADDING_PIXELS}px`,
});

const getNegativeHalfItemOffset = ({ itemCount }: { itemCount: number }) =>
	`calc(-${100 / itemCount / 2}%)`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContinuousWrapper = styled.div<{ itemCount: number }>({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: (props) => getNegativeHalfItemOffset(props),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: (props) => getNegativeHalfItemOffset(props),
	display: 'flex',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > [data-component-selector="item-KidZ"]': {
		alignItems: 'center',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AxisContainer = styled.div<{ isVerticalMode: boolean }>({
	width: '100%',
	height: '100%',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ isVerticalMode }) => (isVerticalMode ? 'row' : 'column'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AxisInfoWrapper = styled.div<{ isVerticalMode: boolean }>({
	height: '24px',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignSelf: ({ isVerticalMode }) => isVerticalMode && 'end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transformOrigin: ({ isVerticalMode }) => isVerticalMode && '10px 10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ isVerticalMode }) => isVerticalMode && 'rotate(-90deg)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: N500,
});

const SizedAxisInfoWrapper = sized<ComponentPropsWithoutRef<typeof AxisInfoWrapper>>(
	AxisInfoWrapper,
	{
		shouldUseRequestAnimationFrame: false,
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AxisLabel = styled.div({
	textTransform: 'uppercase',
	marginRight: token('space.050', '4px'),
	flexShrink: 1,
	minWidth: 0,
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AxisLine = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border', colors.N30),
	height: '4px',
	width: '100%',
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});
