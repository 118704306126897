import React from 'react';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { ConnectionFieldEditableSingleSelect } from '@atlassian/jira-polaris-common/src/ui/fields/connection/editable-single-select/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

export type FooterRendererProps = {
	fieldKey: FieldKey;
};

export const FooterRenderer = ({ fieldKey }: FooterRendererProps) => {
	const currentIssueLocalIssueId = useSelectedIssue();

	if (!currentIssueLocalIssueId) {
		return null;
	}

	return (
		<ConnectionFieldEditableSingleSelect
			fieldKey={fieldKey}
			localIssueId={currentIssueLocalIssueId}
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			menuPortalTarget={document.body}
			isEditable
		/>
	);
};
