import React from 'react';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import { Box, Stack, Inline, Text, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { FormattedDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { VIEW_PROFILE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import { ProfileCard } from '@atlassian/jira-profilecard-next/src/main.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useVisitorsWithUserData } from '../utils.tsx';
import { messages } from './messages.tsx';

type Props = {
	viewUUID: ViewUUID;
	testId?: string;
};

export const VisitorsList = ({ viewUUID, testId }: Props) => {
	const visitors = useVisitorsWithUserData(viewUUID);

	const { formatMessage } = useIntl();

	return (
		<Box padding="space.250" xcss={fixedSizeCss}>
			<Stack space="space.250">
				<Heading size="xsmall" as="h4">
					{formatMessage(messages.heading)} ({visitors.length})
				</Heading>

				<Stack space="space.100">
					{visitors.map((visitor, i) => {
						const content = (
							<Inline
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...(fg('concurrent-rendering-fix-unique-list-key')
									? { key: visitor.accountId ?? i }
									: {})}
								alignBlock="center"
								spread="space-between"
								testId={testId && `${testId}.visitor-row`}
							>
								<Inline alignBlock="center" space="space.100">
									<Avatar src={visitor.avatar} size="small" presence={visitor.presence} />
									<Text>{visitor.name ?? formatMessage(messages.anonymous)}</Text>
								</Inline>

								<Text>
									<FormattedDate date={visitor.timestamp?.getTime() ?? Date.now()} />
								</Text>
							</Inline>
						);

						return visitor.accountId == null || visitor.name == null ? (
							content
						) : (
							<ProfileCard
								key={visitor.accountId}
								actions={[VIEW_PROFILE_ACTION]}
								accountId={visitor.accountId}
								TenantContextSubscriber={
									fg('empanada_nin_concurrent_mode_fixes') ? undefined : TenantContextSubscriber
								}
							>
								{content}
							</ProfileCard>
						);
					})}
				</Stack>
			</Stack>
		</Box>
	);
};

const fixedSizeCss = xcss({
	width: '352px',
	maxHeight: '340px',
	overflowY: 'auto',
});
