import React, { useCallback, useEffect, useMemo, useRef, useState, type ElementRef } from 'react';
import { styled } from '@compiled/react';
import defer from 'lodash/defer';
import uniqBy from 'lodash/uniqBy';
import { v4 as uuid } from 'uuid';
import Select from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useGroupOptions } from '../../../../../controllers/issue/selectors/grouping-hooks.tsx';
import { useDecoratableSelectCustomComponentsForLabels } from '../../../../common/decoration/decoration/select/index.tsx';
import { createLabelsGroups } from '../../../../fields/labels/utils.tsx';
import messages from './messages.tsx';
import { stylesConfig } from './styled.tsx';

type SelectFieldValue = {
	id: string;
};

type SelectFieldProps = {
	isEditable: boolean;
	fieldKey: FieldKey;
	value: SelectFieldValue[] | undefined;
	onUpdate: (inputValue: SelectFieldValue[], forceStateUpdate: boolean) => void;
	onCloseRequested: () => void;
	isMenuOpen: boolean;
	onOpenMenu: () => void;
};

export const LabelsField = ({
	fieldKey,
	value,
	onUpdate,
	onCloseRequested,
	isEditable,
	isMenuOpen,
	onOpenMenu,
}: SelectFieldProps) => {
	const { formatMessage } = useIntl();
	const groupOptions = useGroupOptions(fieldKey);
	const [inputValue, setInputValue] = useState('');
	const [selectMenuId] = useState(uuid());
	const containerRef = useRef<HTMLDivElement>(null);
	const selectRefNext = useRef<ElementRef<typeof Select<SelectFieldValue, boolean>> | null>(null);

	const optionsExternal = useMemo(
		() =>
			groupOptions.options.map(({ groupIdentity }) => ({
				id: groupIdentity,
				value: groupIdentity,
			})),
		[groupOptions.options],
	);

	const [options, setOptions] = useState(optionsExternal);
	const optionsGroups = createLabelsGroups(value || [], optionsExternal, true);

	const onChange = useCallback(
		(newValue: SelectFieldValue[] | undefined, forceStateUpdate: boolean) => {
			if (newValue === null || newValue === undefined) {
				onUpdate([], forceStateUpdate);
			} else {
				onUpdate(newValue, forceStateUpdate);
			}
			setInputValue('');
			defer(() => selectRefNext.current?.focus());
		},
		[onUpdate],
	);

	const onAdd = useCallback(
		(newValue: string) => {
			if (newValue !== '') {
				const splitValues = newValue.split(' ');
				const newValues = [...(value || []), ...splitValues.map((part) => ({ id: part.trim() }))];
				const safeNewValues = uniqBy(newValues, ({ id }) => id);

				onChange(safeNewValues, true);

				const newOptions = [
					...options,
					...splitValues.map((part) => ({ id: part.trim(), value: part.trim() })),
				];
				const safeNewOptions = uniqBy(newOptions, ({ id }) => id);

				setOptions(safeNewOptions);
			}
			return Promise.resolve(newValue);
		},
		[onChange, options, value],
	);

	useEffect(() => {
		const handleClickAnywhere = (e: MouseEvent) => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const menuElement = document.getElementById(selectMenuId);
			const containerElement = containerRef.current;

			// @ts-expect-error - TS2345 - Argument of type 'EventTarget | null' is not assignable to parameter of type 'Node | null'. | TS2571 - Object is of type 'unknown'.
			if (!menuElement?.contains(e.target) && !containerElement?.contains(e.target)) {
				onCloseRequested();
			}
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mousedown', handleClickAnywhere);
		return () => {
			// clean up

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('mousedown', handleClickAnywhere);
		};
	});

	const components = useDecoratableSelectCustomComponentsForLabels({
		fieldKey,
		onAddOption: onAdd,
		menuId: selectMenuId,
		isMulti: true,
		showEditButton: false,
	});

	return (
		<EditViewContainer ref={containerRef}>
			<Select<SelectFieldValue, boolean>
				isDisabled={!isEditable}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onKeyDown={(event: any) => {
					// handle ENTER key
					if (event.key === 'Enter') {
						onAdd(inputValue);
					}
				}}
				ref={selectRefNext}
				// @ts-expect-error - TS2345 - Argument of type 'OptionsType<SelectFieldValue>' is not assignable to parameter of type 'SelectFieldValue[]'.
				onChange={(newValue) => onChange(newValue, false)}
				isMulti
				hideSelectedOptions={false}
				isSearchable
				isClearable
				value={value}
				inputValue={inputValue}
				onMenuOpen={onOpenMenu}
				menuIsOpen={isMenuOpen}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onInputChange={(newInputValue: any) => setInputValue(newInputValue)}
				options={optionsGroups}
				components={components}
				getOptionLabel={({ id }) => id}
				getOptionValue={({ id }) => id}
				placeholder={formatMessage(messages.placeholder)}
				enableAnimation={false}
				menuPosition="fixed"
				closeMenuOnScroll={() => true}
				// @ts-expect-error - TS2322 - Type '{ menu: (styles: {    string: number | string;}) => { width: string; zIndex: number; string: number | string; }; groupHeading: (styles: {    string: number | string;}) => { ':empty': { display: string; }; string: number | string; }; group: (styles: {    string: number | string;}) => { padding: string; ':not(:first-o...' is not assignable to type 'StylesConfig<SelectFieldValue, true, GroupBase<SelectFieldValue>>'

				styles={stylesConfig}
			/>
		</EditViewContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	width: 'fit-content',
});
