import React, { useCallback, type ChangeEvent } from 'react';
import { jsx } from '@compiled/react';
import { Box, xcss, Inline, Text } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DragHandleThin } from '@atlassian/jira-polaris-lib-custom-icons/src/ui/icons/drag-handle-thin/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

type Props = {
	fieldKey: FieldKey;
	label: string;
	isSelected: boolean;
	isDragEnabled?: boolean;
	isGlobalNonSystemField?: boolean;
	onToggle: (arg1: FieldKey, arg2: boolean) => void;
};

export const ConnectionItem = ({
	fieldKey,
	label,
	isSelected,
	isDragEnabled = true,
	isGlobalNonSystemField,
	onToggle,
}: Props) => {
	const { formatMessage } = useIntl();

	const handleToggle = useCallback(
		(event: ChangeEvent<HTMLInputElement>, _analyticEvent: UIAnalyticsEvent) => {
			onToggle(fieldKey, event.currentTarget.checked);
		},
		[fieldKey, onToggle],
	);

	return (
		<Inline alignBlock="center" space="space.025" xcss={isDragEnabled && draggableStyles}>
			{isDragEnabled && (
				<Inline xcss={dragHandleStyles} alignInline="center" alignBlock="center">
					<DragHandleThin label="" color={token('color.icon.subtle')} />
				</Inline>
			)}
			<Text size="medium" maxLines={1}>
				{label}
			</Text>
			{isGlobalNonSystemField && <GlobalFieldMarker />}
			<Box xcss={toggleStyles}>
				<Toggle
					testId="polaris-component-issue-types-connections-configuration.ui.connections-config-list.toggle"
					label={
						isSelected
							? formatMessage(messages.hideToggleLabel)
							: formatMessage(messages.showToggleLabel)
					}
					isChecked={isSelected}
					onChange={handleToggle}
				/>
			</Box>
		</Inline>
	);
};

const draggableStyles = xcss({
	cursor: 'grab',
});

const dragHandleStyles = xcss({
	flexShrink: 0,
	width: '16px',
	height: '16px',
});

const toggleStyles = xcss({
	flexShrink: 0,
	marginLeft: 'auto',
});
