import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';

const getIssueTypePropertyUrl = (issueTypeId: IssueTypeId, propertyKey: string): string =>
	`/rest/api/3/issuetype/${issueTypeId}/properties/${propertyKey}`;

export const setIssueTypeProperty = async (
	issueTypeId: IssueTypeId,
	propertyKey: string,
	data: string[],
): Promise<void> => {
	await performPutRequest(getIssueTypePropertyUrl(issueTypeId, propertyKey), {
		body: JSON.stringify(data),
	});
};

export const getIssueTypeProperty = (issueTypeId: IssueTypeId, propertyKey: string) =>
	fetchJson(getIssueTypePropertyUrl(issueTypeId, propertyKey)).then(({ value }) => value);
