/** @jsx jsx */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css, jsx } from '@compiled/react';
import keyBy from 'lodash/keyBy';
import { useVirtualizer } from '@tanstack/react-virtual';
import { IconButton } from '@atlaskit/button/new';
import EyeOpen from '@atlaskit/icon/core/eye-open';
import EyeOpenStrikethrough from '@atlaskit/icon/core/eye-open-strikethrough';
import { DragHandleButtonSmall } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button-small';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useDecorationsForField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import { useSortedGroupOptions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { DecoratedRating } from '@atlassian/jira-polaris-common/src/ui/decoration/rating/index.tsx';
import { CheckboxField } from '@atlassian/jira-polaris-common/src/ui/fields/checkbox/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { DragHandleThin } from '@atlassian/jira-polaris-lib-custom-icons/src/ui/icons/drag-handle-thin/index.tsx';
import { Draggable } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { TeamValue } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';
import { type ExtendedOption, EMPTY_VALUE_ID } from '../../../../common/utils/board.tsx';
import {
	useRestrictedOptions,
	useExtendedOptionsInNaturalOrder,
} from '../../../view-content/common/utils/field-visibility-options.tsx';
import {
	getNewFieldValueFilterOnHide,
	getNewFieldValueFilterOnShow,
} from '../../../view-content/idea-board/column/header/utils.tsx';
import { connectionFieldValueConfig } from './connection/index.tsx';
import { dateFieldValueConfig } from './dates/index.tsx';
import { DroppableHandler } from './droppable-handler/index.tsx';
import { EmptyFieldValue, emptyFieldValueConfig } from './empty/index.tsx';
import { externalReferencePropertyFieldValueConfig } from './external-reference-property/index.tsx';
import { externalReferenceFieldValueConfig } from './external-reference/index.tsx';
import { issueTypeFieldValueConfig } from './issue-type/index.tsx';
import { labelsFieldValueConfig } from './labels/index.tsx';
import { messages } from './messages.tsx';
import { numberFieldValueConfig } from './numbers/index.tsx';
import { optionsFieldValueConfig } from './options/index.tsx';
import { projectFieldValueConfig } from './project/index.tsx';
import { reactionsFieldValueConfig } from './reactions/index.tsx';
import { statusFieldValueConfig } from './status/index.tsx';
import { goalsFieldValueConfig } from './goals/index.tsx';
import type { FieldValueConfig } from './types.tsx';
import { userFieldValueConfig } from './users/index.tsx';

const fieldValues: FieldValueConfig = {
	config: {
		[FIELD_TYPES.SINGLE_SELECT]: optionsFieldValueConfig,
		[FIELD_TYPES.MULTI_SELECT]: optionsFieldValueConfig,
		[FIELD_TYPES.JSW_MULTI_SELECT]: optionsFieldValueConfig,
		[FIELD_TYPES.STATUS]: statusFieldValueConfig,
		[FIELD_TYPES.ASSIGNEE]: userFieldValueConfig,
		[FIELD_TYPES.CREATOR]: userFieldValueConfig,
		[FIELD_TYPES.REPORTER]: userFieldValueConfig,
		[FIELD_TYPES.PEOPLE]: userFieldValueConfig,
		[FIELD_TYPES.JSW_PEOPLE]: userFieldValueConfig,
		[FIELD_TYPES.LABELS]: labelsFieldValueConfig,
		[FIELD_TYPES.CUSTOM_LABELS]: labelsFieldValueConfig,
		[FIELD_TYPES.NUMBER]: numberFieldValueConfig,
		[FIELD_TYPES.FORMULA]: numberFieldValueConfig,
		[FIELD_TYPES.SLIDER]: numberFieldValueConfig,
		[FIELD_TYPES.RATING]: numberFieldValueConfig,
		[FIELD_TYPES.CHECKBOX]: numberFieldValueConfig,
		[FIELD_TYPES.INSIGHTS]: numberFieldValueConfig,
		[FIELD_TYPES.DATE]: dateFieldValueConfig,
		[FIELD_TYPES.ATLAS_GOAL]: externalReferenceFieldValueConfig,
		[FIELD_TYPES.ATLAS_PROJECT]: externalReferenceFieldValueConfig,
		[FIELD_TYPES.ATLAS_PROJECT_STATUS]: externalReferencePropertyFieldValueConfig,
		[FIELD_TYPES.REACTIONS]: reactionsFieldValueConfig,
		[FIELD_TYPES.PROJECT]: projectFieldValueConfig,
		[FIELD_TYPES.CONNECTION]: connectionFieldValueConfig,
		[FIELD_TYPES.ISSUE_TYPE]: issueTypeFieldValueConfig,
		[FIELD_TYPES.PLATFORM_GOALS]: goalsFieldValueConfig,
	},
	default: emptyFieldValueConfig,
};

type FieldValueContentProps = {
	field: Field;
	fieldKey: FieldKey;
	groupIdentity?: string;
};

export const FieldValueContent = memo<FieldValueContentProps>(
	({ field, fieldKey, groupIdentity }: FieldValueContentProps) => {
		const decorations = useDecorationsForField(fieldKey);

		const { Component, EmptyComponent } =
			(field.type && fieldValues.config[field.type]) || fieldValues.default;

		if (groupIdentity === undefined) {
			const SafeEmptyComponent = EmptyComponent || EmptyFieldValue;
			return <SafeEmptyComponent fieldKey={fieldKey} />;
		}
		if (field.type === FIELD_TYPES.CHECKBOX) {
			return <CheckboxField value={Number(groupIdentity)} />;
		}
		if (field.type === FIELD_TYPES.RATING) {
			return <DecoratedRating value={Number(groupIdentity)} decorations={decorations} />;
		}
		if (field.type === FIELD_TYPES.TEAM) {
			return (
				<Box>
					<TeamValue id="groupIdentity.id" name={groupIdentity} />
				</Box>
			);
		}
		return <Component fieldKey={fieldKey} groupIdentity={groupIdentity} />;
	},
);

const NO_VALUE_RESTRICTION = 'no-value';
const OPTION_HEIGHT = 32;

type ValuesProps = {
	field: Field;
	options: ExtendedOption<unknown>[];
	setOptions: (options: GroupValue[]) => void;
	isDisabledDnD?: boolean;
	isHideButtonVisible?: boolean;
};

const ValuesOld = ({
	field,
	options,
	setOptions,
	isDisabledDnD = false,
	isHideButtonVisible = false,
}: ValuesProps) => {
	const { formatMessage } = useIntl();
	const restrictions = useRestrictedOptions(field);
	const restrictionsMap = useMemo(
		() => keyBy(restrictions, ({ groupIdentity }) => groupIdentity ?? NO_VALUE_RESTRICTION),
		[restrictions],
	);

	const sortedGroupOptions = useSortedGroupOptions(field.key);
	const statusOptions = useExtendedOptionsInNaturalOrder(field);
	const { updateFieldFilter } = useViewActions();
	const fieldFilter = useFieldFilter(field.key);
	const [localOptions, setLocalOptions] = useState(options);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		setLocalOptions(options);
	}, [options]);

	const onShowGroupIdentity = (groupIdentity?: string) => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
			'showInView',
			{
				fieldValueId: groupIdentity,
			},
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'icon clicked',
			actionSubjectId: 'showInView',
			attributes: { fieldValueId: groupIdentity || '' },
		});
		updateFieldFilter(
			getNewFieldValueFilterOnShow(
				field,
				sortedGroupOptions,
				statusOptions,
				fieldFilter,
				groupIdentity,
			),
		);
	};

	const onHideGroupIdentity = (groupIdentity?: string) => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
			'hideInView',
			{
				fieldValueId: groupIdentity,
			},
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'icon clicked',
			actionSubjectId: 'hideInView',
			attributes: { fieldValueId: groupIdentity || '' },
		});
		updateFieldFilter(
			getNewFieldValueFilterOnHide(
				field,
				sortedGroupOptions,
				statusOptions,
				fieldFilter,
				groupIdentity,
			),
		);
	};

	const { isHideable: isFieldHideable } =
		(field?.type !== undefined ? fieldValues.config[field.type] : undefined) || fieldValues.default;

	const handleOptionsUpdate = useCallback(
		(nextOptions: ExtendedOption<unknown>[]) => {
			const newSortedGroupOptions = nextOptions.map((option) => ({
				id: option.groupIdentity ?? EMPTY_VALUE_ID,
			}));

			setLocalOptions(nextOptions);
			setOptions(newSortedGroupOptions);
		},
		[setOptions],
	);

	const isDragDisabled = isDisabledDnD || localOptions.length === 1;

	if (!field) {
		return <Box xcss={valuesContainerStyles} />;
	}

	return (
		<DroppableHandler options={localOptions} onOptionsUpdate={handleOptionsUpdate}>
			<Box xcss={valuesContainerStyles}>
				{localOptions.map((option, index) => {
					const isValueHidden = !restrictionsMap[option.groupIdentity ?? NO_VALUE_RESTRICTION];

					return (
						<Draggable isDragDisabled={isDragDisabled} key={`${field.key}-${index}`} id={index}>
							<div css={[valueContainerStyles, !isValueHidden && visibleValueValueContainerStyles]}>
								{isVisualRefreshEnabled() && fg('pol-11373_replace_drag_icons_in_panels') ? (
									<Box xcss={[isDragDisabled && hiddenStyles]}>
										<div css={draggableButtonStyles}>
											<DragHandleThin label="view-dragHandle" color={token('color.icon.subtle')} />
										</div>
									</Box>
								) : (
									<Box paddingInlineStart="space.050" xcss={[isDragDisabled && hiddenStyles]}>
										<DragHandleButtonSmall
											appearance="subtle"
											type="button"
											label="view-dragHandle"
										/>
									</Box>
								)}
								{isVisualRefreshEnabled() && fg('pol-11373_replace_drag_icons_in_panels') ? (
									<div css={fieldValueContentWrapper}>
										<FieldValueContent
											fieldKey={field.key}
											field={field}
											groupIdentity={option.groupIdentity}
										/>
									</div>
								) : (
									<FieldValueContent
										fieldKey={field.key}
										field={field}
										groupIdentity={option.groupIdentity}
									/>
								)}

								<Box xcss={valueContainerSpacerStyles} />
								{isHideButtonVisible && isFieldHideable && (
									<>
										<Tooltip
											content={
												isValueHidden
													? formatMessage(messages.showGroupOption)
													: formatMessage(messages.filterGroupOption)
											}
										>
											<Box padding="space.050">
												<IconButton
													label={
														isValueHidden
															? formatMessage(messages.showGroupOption)
															: formatMessage(messages.filterGroupOption)
													}
													id="pendo.group-identity.button"
													onClick={() =>
														isValueHidden
															? onShowGroupIdentity(option.groupIdentity)
															: onHideGroupIdentity(option.groupIdentity)
													}
													appearance="subtle"
													spacing="compact"
													icon={({ label }) => (
														<>
															{isValueHidden ? (
																<EyeOpenStrikethrough label={label} color="currentColor" />
															) : (
																<Box
																	data-component-selector="visible-image-bS93"
																	xcss={[noDisplayStyles]}
																>
																	<EyeOpen label={label} color="currentColor" />
																</Box>
															)}
														</>
													)}
												/>
											</Box>
										</Tooltip>
									</>
								)}
								{!isHideButtonVisible && isFieldHideable && isValueHidden && (
									<Box xcss={[readonlyHiddenIconStyles]}>
										<EyeOpenStrikethrough label="" color="currentColor" />
									</Box>
								)}
							</div>
						</Draggable>
					);
				})}
			</Box>
		</DroppableHandler>
	);
};

type ValueItemProps = {
	field: Field;
	groupIdentity?: string;
	isDragDisabled: boolean;
	isValueHidden: boolean;
	isHideButtonVisible: boolean;
	isFieldHideable: boolean;
	isDragging: boolean;
	onShowGroupIdentity: (groupIdentity?: string) => void;
	onHideGroupIdentity: (groupIdentity?: string) => void;
};

const ValueItem = ({
	field,
	isDragDisabled,
	isHideButtonVisible,
	isFieldHideable,
	isValueHidden,
	isDragging,
	groupIdentity,
	onShowGroupIdentity,
	onHideGroupIdentity,
}: ValueItemProps) => {
	const { formatMessage } = useIntl();
	const [isHovered, setIsHovered] = useState(false);

	const visibilityIconLabel = isValueHidden
		? formatMessage(messages.showGroupOption)
		: formatMessage(messages.filterGroupOption);

	const visibilityIcon = isValueHidden ? (
		<EyeOpenStrikethrough label={visibilityIconLabel} color="currentColor" />
	) : (
		isHovered && <EyeOpen label={visibilityIconLabel} color="currentColor" />
	);

	const dragIcon =
		isVisualRefreshEnabled() && fg('pol-11373_replace_drag_icons_in_panels') ? (
			<Box xcss={[isDragDisabled && hiddenStyles]}>
				<div css={draggableButtonStyles}>
					<DragHandleThin label="view-dragHandle" color={token('color.icon.subtle')} />
				</div>
			</Box>
		) : (
			<Box paddingInlineStart="space.050" xcss={[isDragDisabled && hiddenStyles]}>
				<DragHandleButtonSmall appearance="subtle" type="button" label="view-dragHandle" />
			</Box>
		);

	return (
		<div
			role="presentation"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			css={[valueContainerStyles, !isValueHidden && visibleValueValueContainerStyles]}
		>
			{isDragging ? (
				<Box
					xcss={
						isVisualRefreshEnabled() && fg('pol-11373_replace_drag_icons_in_panels')
							? dragIconPlaceholderStyles
							: dragIconPlaceholderStylesOld
					}
				/>
			) : (
				dragIcon
			)}

			{isVisualRefreshEnabled() && fg('pol-11373_replace_drag_icons_in_panels') ? (
				<div css={fieldValueContentWrapper}>
					<FieldValueContent fieldKey={field.key} field={field} groupIdentity={groupIdentity} />
				</div>
			) : (
				<FieldValueContent fieldKey={field.key} field={field} groupIdentity={groupIdentity} />
			)}

			<Box xcss={valueContainerSpacerStyles} />

			{isHideButtonVisible &&
				isFieldHideable &&
				(isDragging ? (
					visibilityIcon
				) : (
					<Tooltip
						content={
							isValueHidden
								? formatMessage(messages.showGroupOption)
								: formatMessage(messages.filterGroupOption)
						}
					>
						<Box padding="space.050">
							<IconButton
								label={visibilityIconLabel}
								id="pendo.group-identity.button"
								onClick={() =>
									isValueHidden
										? onShowGroupIdentity(groupIdentity)
										: onHideGroupIdentity(groupIdentity)
								}
								appearance="subtle"
								spacing="compact"
								icon={() => visibilityIcon}
							/>
						</Box>
					</Tooltip>
				))}

			{!isHideButtonVisible && isFieldHideable && isValueHidden && (
				<Box xcss={[readonlyHiddenIconStyles]}>
					<EyeOpenStrikethrough label="" color="currentColor" />
				</Box>
			)}
		</div>
	);
};

const ValuesNext = ({
	field,
	options,
	setOptions,
	isDisabledDnD = false,
	isHideButtonVisible = false,
}: ValuesProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const restrictions = useRestrictedOptions(field);
	const restrictionsMap = useMemo(
		() => keyBy(restrictions, ({ groupIdentity }) => groupIdentity ?? NO_VALUE_RESTRICTION),
		[restrictions],
	);

	const sortedGroupOptions = useSortedGroupOptions(field.key);
	const extendedOptions = useExtendedOptionsInNaturalOrder(field);
	const { updateFieldFilter } = useViewActions();
	const fieldFilter = useFieldFilter(field.key);
	const [localOptions, setLocalOptions] = useState(options);
	const [isDragging, setIsDragging] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		setLocalOptions(options);
	}, [options]);

	const virtualizer = useVirtualizer({
		count: localOptions.length,
		estimateSize: () => OPTION_HEIGHT,
		getScrollElement: () => containerRef.current,
		getItemKey: (idx) => localOptions[idx].groupIdentity ?? idx,
		overscan: 10,
	});

	const onShowGroupIdentity = (groupIdentity?: string) => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
			'showInView',
			{
				fieldValueId: groupIdentity,
			},
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'icon clicked',
			actionSubjectId: 'showInView',
			attributes: { fieldValueId: groupIdentity || '' },
		});
		updateFieldFilter(
			getNewFieldValueFilterOnShow(
				field,
				sortedGroupOptions,
				extendedOptions,
				fieldFilter,
				groupIdentity,
			),
		);
	};

	const onHideGroupIdentity = (groupIdentity?: string) => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
			'hideInView',
			{
				fieldValueId: groupIdentity,
			},
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'icon clicked',
			actionSubjectId: 'hideInView',
			attributes: { fieldValueId: groupIdentity || '' },
		});
		updateFieldFilter(
			getNewFieldValueFilterOnHide(
				field,
				sortedGroupOptions,
				extendedOptions,
				fieldFilter,
				groupIdentity,
			),
		);
	};

	const { isHideable: isFieldHideable } =
		(field?.type !== undefined ? fieldValues.config[field.type] : undefined) || fieldValues.default;

	const handleOptionsUpdate = useCallback(
		(nextOptions: ExtendedOption<unknown>[]) => {
			const newSortedGroupOptions = nextOptions.map((option) => ({
				id: option.groupIdentity ?? EMPTY_VALUE_ID,
			}));

			setLocalOptions(nextOptions);
			setOptions(newSortedGroupOptions);
		},
		[setOptions],
	);

	const isDragDisabled = isDisabledDnD || localOptions.length === 1;

	const handleDragStart = useCallback(() => {
		setIsDragging(true);
	}, []);

	const handleDragEnd = useCallback(() => {
		setIsDragging(false);
	}, []);

	if (!field) {
		return <Box xcss={valuesContainerStyles} />;
	}

	return (
		<DroppableHandler
			options={localOptions}
			onOptionsUpdate={handleOptionsUpdate}
			onDragEnd={handleDragEnd}
			onDragStart={handleDragStart}
		>
			<Box ref={containerRef} xcss={valuesContainerStyles}>
				{/* eslint-disable-next-line jira/react/no-style-attribute */}
				<div style={{ minHeight: virtualizer.getTotalSize() }}>
					{virtualizer.getVirtualItems().map(({ index, size, start }) => {
						const { groupIdentity } = localOptions[index];
						const isValueHidden = !restrictionsMap[groupIdentity ?? NO_VALUE_RESTRICTION];

						return (
							<div
								key={index}
								css={optionWrapperStyles}
								// eslint-disable-next-line jira/react/no-style-attribute
								style={{ height: size, transform: `translateY(${start}px)` }}
							>
								<Draggable isDragDisabled={isDragDisabled} key={`${field.key}-${index}`} id={index}>
									<ValueItem
										field={field}
										isDragDisabled={isDragDisabled}
										isHideButtonVisible={isHideButtonVisible}
										isFieldHideable={isFieldHideable}
										isValueHidden={isValueHidden}
										isDragging={isDragging}
										groupIdentity={groupIdentity}
										onShowGroupIdentity={onShowGroupIdentity}
										onHideGroupIdentity={onHideGroupIdentity}
									/>
								</Draggable>
							</div>
						);
					})}
				</div>
			</Box>
		</DroppableHandler>
	);
};

export const Values = componentWithFG(
	'jpd_virtualize_options_in_group_and_filter',
	ValuesNext,
	ValuesOld,
);

const optionWrapperStyles = css({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
});

const valuesContainerStyles = xcss({
	maxHeight: 'calc(100vh - var(--topNavigationHeight, 0px) - 325px)',
	overflowY: 'auto',
	marginBlockEnd: 'space.300',
	position: 'relative',
});

const valueContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
	height: `${OPTION_HEIGHT}px`,
	paddingInlineEnd: token('space.200'),
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
});

const hiddenStyles = xcss({
	visibility: 'hidden',
});

const dragIconPlaceholderStyles = xcss({
	width: '16px',
});

const dragIconPlaceholderStylesOld = xcss({
	width: '12px',
});

const noDisplayStyles = xcss({
	display: 'none',
});

const visibleValueValueContainerStyles = css({
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-component-selector="visible-image-bS93"]': {
			display: 'block',
		},
	},
});

const readonlyHiddenIconStyles = xcss({
	height: token('space.200'),
	paddingInline: 'space.100',
});

const valueContainerSpacerStyles = xcss({
	flex: 1,
});

const draggableButtonStyles = css({
	display: 'flex',
	alignItems: 'center',
	cursor: 'grab',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginInline: '5px', // left padding of the side panel is 16px, the icon is 6px wide, so we need a static 5px here to align the icon with the sidebar content
});

const fieldValueContentWrapper = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> div': {
		marginInlineStart: 0,
		paddingInlineStart: 0,
		paddingLeft: 0,
		marginLeft: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="decorated-tag-content-c3R"]': {
		marginInlineStart: 0,
		paddingInlineStart: 0,
		paddingLeft: 0,
		marginLeft: 0,
	},
});
