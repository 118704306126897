import React, { memo, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useFullscreenQueryParameter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCurrentViewContainsArchived } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	useCanEditIssues,
	useCanDeleteIssues,
	useCanMergeIdeas,
	useCanArchiveIdeas,
	useCanManageDeliveryTickets,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useVisibleSelectedIssueIds } from '../../utils.tsx';
import ArchiveIdeasButton from './archive-ideas/index.tsx';
import DeleteIdeasButton from './delete-ideas/index.tsx';
import { MergeIdeasButton } from './merge-ideas/index.tsx';
import { messages } from './messages.tsx';
import { SendToBacklogButton } from './send-to-backlog/index.tsx';
import { SendToTopButton } from './send-to-top/index.tsx';
import UnarchiveIdeasButton from './unarchive-ideas/index.tsx';

const ManageBarLegacy = () => {
	const [canEditIssues] = useCanEditIssues();
	const [canDeleteIssues] = useCanDeleteIssues();
	const canMergeIdes = useCanMergeIdeas();
	const canArchiveIdeas = useCanArchiveIdeas();
	const canManageDeliveryTickets = useCanManageDeliveryTickets();
	const containsArchived = useCurrentViewContainsArchived();
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const { formatMessage } = useIntl();
	const fullscreen = useFullscreenQueryParameter();

	const { clearSelection } = useViewActions();

	const onClose = (_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'clearSelection');
		clearSelection(visibleSelectedIssueIds);
	};

	if (isEmpty(visibleSelectedIssueIds)) {
		return null;
	}

	return (
		<ControlsButtonContainer isFullscreen={fullscreen}>
			<ControlsButtonContent>
				<LabelContainer>
					<Button
						appearance="subtle"
						spacing="none"
						iconBefore={
							<EditorCloseIcon label={formatMessage(messages.closeButtonLabel)} size="medium" />
						}
						onClick={onClose}
					/>
					<Label>{formatMessage(messages.label, { count: visibleSelectedIssueIds.length })}</Label>
				</LabelContainer>
				{!containsArchived && (
					<ButtonsWrapper>
						{canEditIssues && <SendToTopButton />}
						{canMergeIdes && <MergeIdeasButton />}
						{canManageDeliveryTickets && <SendToBacklogButton />}
					</ButtonsWrapper>
				)}
				<ButtonsWrapper>
					{!containsArchived && canArchiveIdeas && <ArchiveIdeasButton />}
					{containsArchived && canArchiveIdeas && <UnarchiveIdeasButton />}
					{canDeleteIssues && <DeleteIdeasButton />}
				</ButtonsWrapper>
			</ControlsButtonContent>
		</ControlsButtonContainer>
	);
};

ManageBarLegacy.displayName = 'ManageBar';

const ManageBarNext = memo(ManageBarLegacy);

export const ManageBar = componentWithFG(
	'polaris_idea_list_performance_improvements',
	ManageBarNext,
	ManageBarLegacy,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlsButtonContainer = styled.div<{
	isFullscreen: boolean;
}>({
	position: 'fixed',
	bottom: token('space.300', '24px'),
	zIndex: 3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ isFullscreen }) => (isFullscreen ? 0 : 'calc(var(--leftSidebarWidth, 0px) + 16px)'),
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ isFullscreen }) =>
		isFullscreen ? '100%' : 'calc(100vw - var(--leftSidebarWidth) - 32px)',
	display: 'flex',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlsButtonContent = styled.div({
	position: 'absolute',
	bottom: '0',
	backgroundColor: token('color.background.neutral.bold.pressed', '#172B4D'),
	boxShadow: '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)',
	borderRadius: '8px',
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
	color: token('color.text.inverse', N0),
	display: 'flex',
	alignItems: 'stretch',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text.inverse', N0)} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		':hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text.inverse', N0)} !important`,
			backgroundColor: token('color.background.neutral.bold.hovered', '#2C3E5D'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		':active': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text.inverse', N0)} !important`,
			backgroundColor: token('color.background.neutral.bold', '#44546F'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&[disabled]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text.subtlest', '#626F86')} !important`,
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '9px',
	paddingRight: token('space.100', '8px'),
	position: 'relative',

	'&::before': {
		content: '',
		top: '3px',
		position: 'absolute',
		bottom: '3px',
		width: '1px',
		left: '0px',
		backgroundColor: token('color.border.accent.gray', '#758195'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.div({
	font: token('font.body'),
	fontWeight: token('font.weight.semibold'),
	paddingRight: token('space.200', '16px'),
});
