import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import type { ProjectsResponse } from './types.tsx';

const createGetJpdProjectsUrl = (startAt: number, maxResults: number) => {
	const queryParams = new URLSearchParams({
		typeKey: PRODUCT_DISCOVERY_PROJECT,
		maxResults: maxResults.toString(),
		startAt: startAt.toString(),
	});

	return `/rest/api/3/project/search?${queryParams.toString()}`;
};

export const getAllJpdProjects = async (): Promise<ProjectMetadata[]> => {
	const maxPageSize = 50;

	const firstProjectsPage = await fetchJson<ProjectsResponse>(
		createGetJpdProjectsUrl(0, maxPageSize),
	);

	const projects = firstProjectsPage.values;

	let isLastPage = firstProjectsPage.isLast;
	let nextPageUrl = firstProjectsPage.nextPage;

	while (!isLastPage) {
		// eslint-disable-next-line no-await-in-loop
		const nextProjectsPage = await fetchJson<ProjectsResponse>(nextPageUrl);
		isLastPage = nextProjectsPage.isLast;
		nextPageUrl = nextProjectsPage.nextPage;

		projects.push(...nextProjectsPage.values);
	}

	return projects.map(({ id, key, name, avatarUrls }) => ({
		id,
		key,
		name,
		avatarUrls,
	}));
};
