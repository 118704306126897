import React from 'react';
import { styled } from '@compiled/react';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { SlideIn } from '@atlaskit/motion';
import Portal from '@atlaskit/portal';
import { Stack, xcss } from '@atlaskit/primitives';
import { N0, N40, N40A, N50A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { RightSidebarContainer } from '../controllers/index.tsx';
import { DEFAULT_SIDEBAR_WIDTH } from './constants.tsx';
import type { RightSidebarProps } from './types.tsx';

export const RightSidebar = ({
	children,
	onClose,
	preventCloseOnEscape = false,
	width = `${DEFAULT_SIDEBAR_WIDTH}px`,
}: RightSidebarProps) => {
	useCloseOnEscapePress({
		onClose,
		isDisabled: preventCloseOnEscape,
	});

	return (
		<OutsideClickAlerter onClickOutside={onClose}>
			{(outsideClickAlerterProps) => (
				<Portal zIndex={layers.rightSidebar}>
					<RightSidebarContainer onClose={onClose}>
						<SlideIn enterFrom="right" fade="none">
							{(slideInProps) => (
								<Panel
									// eslint-disable-next-line jira/react/no-style-attribute
									style={{ width }}
									{...outsideClickAlerterProps}
									{...slideInProps}
									aria-hidden
									data-testid="polaris-lib-right-sidebar.ui.panel"
								>
									<Stack xcss={containerStyles}>{children}</Stack>
								</Panel>
							)}
						</SlideIn>
					</RightSidebarContainer>
				</Portal>
			)}
		</OutsideClickAlerter>
	);
};

const containerStyles = xcss({
	height: '100%',
	paddingBlockStart: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Panel = styled.div({
	position: 'fixed',
	right: 0,
	bottom: 0,
	backgroundColor: token('elevation.surface', N0),
	height: 'calc(100vh - (var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)))',
	boxShadow: token('elevation.shadow.overlay', `0 1px 1px ${N50A}, 0 0 1px 1px ${N40A}`),
	borderLeft: `1px solid ${token('color.border', N40)}`,
	clipPath: 'inset(0px 0px 0px -15px)', // Remove shadow everywhere except on the left side of the sidebar
});
