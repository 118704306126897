import { NOT_FOUND } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { FetchError } from '@atlassian/jira-fetch/src/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getIssueTypeProperty, setIssueTypeProperty } from '../../services/issue-type-property.tsx';
import type { State } from '../types.tsx';
import { ISSUE_TYPE_CONNECTIONS_CONFIGURATION_PROPERTY_KEY } from './constants.tsx';

// Exported for testing purposes only
export const setIssueTypeConfigurationStateInternal =
	(issueTypeId: IssueTypeId, configuration: FieldKey[]): Action<State, void, Promise<void>> =>
	async ({ setState, getState }) => {
		const state = getState();

		setState({
			configurations: {
				...state.configurations,
				[issueTypeId]: configuration,
			},
		});
	};

export const loadConfiguration =
	(
		issueTypeId: IssueTypeId,
		defaultConfiguration: FieldKey[],
	): Action<State, void, Promise<void>> =>
	async ({ dispatch }) => {
		try {
			const response = await getIssueTypeProperty(
				issueTypeId,
				ISSUE_TYPE_CONNECTIONS_CONFIGURATION_PROPERTY_KEY,
			);

			dispatch(
				setIssueTypeConfigurationStateInternal(
					issueTypeId,
					Array.isArray(response) ? response : defaultConfiguration,
				),
			);
		} catch (error) {
			if (error instanceof FetchError && error.statusCode === NOT_FOUND) {
				dispatch(setIssueTypeConfigurationStateInternal(issueTypeId, defaultConfiguration));

				return;
			}

			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.error.loadingIssueTypesConnectionsConfigurationFailed',
						error,
					),
				);
			}
			throw error;
		}
	};

export const saveConfiguration =
	(issueTypeId: IssueTypeId, configuration: FieldKey[]): Action<State, void, Promise<void>> =>
	async ({ setState, getState, dispatch }) => {
		const { configurations } = getState();

		dispatch(setIssueTypeConfigurationStateInternal(issueTypeId, configuration));

		try {
			await setIssueTypeProperty(
				issueTypeId,
				ISSUE_TYPE_CONNECTIONS_CONFIGURATION_PROPERTY_KEY,
				configuration,
			);
		} catch (error) {
			setState({
				configurations,
			});

			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.error.savingIssueTypesConnectionsConfigurationFailed',
						error,
					),
				);
			}
			throw error;
		}
	};

export const hideConnection =
	(issueTypeId: IssueTypeId, fieldKey: FieldKey): Action<State, void, Promise<void>> =>
	async ({ getState, dispatch }) => {
		const { configurations } = getState();
		const existingConfiguration = configurations[issueTypeId];

		if (!existingConfiguration) {
			return;
		}

		const configuration = existingConfiguration.filter((key) => key !== fieldKey);

		await dispatch(saveConfiguration(issueTypeId, configuration));
	};
