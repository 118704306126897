import React, { type PropsWithChildren, useRef, useEffect } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

type ObserverProps = {
	observer?: IntersectionObserver;
};

export const Observer = ({ observer, children }: PropsWithChildren<ObserverProps>) => {
	const elementRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const el = elementRef?.current;
		el && observer?.observe(el);
		() => el && observer?.unobserve(el);
	}, [observer]);

	return (
		<Box ref={elementRef} xcss={observerStyles}>
			{children}
		</Box>
	);
};

const observerStyles = xcss({
	maxWidth: 'fit-content',
	width: '100%',
});
