import { xcss } from '@atlaskit/primitives';
import { type Options } from '@atlassiansox/cross-flow-support';
import { CrossflowCtaPrimaryPressable } from '@post-office/components--crossflow-cta-button';
import { type FunctionComponent } from 'react';

import { type App } from '../../types';

const JOURNEY = 'get-started';
const SOURCE_COMPONENT = 'jira-side-nav';
const SOURCE_CONTEXT = 'tryItNow';

const ctaButtonStyles = xcss({
	color: 'color.link',
	backgroundColor: 'color.background.accent.blue.subtlest',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'start',
	paddingLeft: '0',
});

export const CTAButton: FunctionComponent<{ app: App }> = ({ app }) => {
	const crossflowOptions: Options = {
		targetProduct: app.hamsIdentifier,
		journey: JOURNEY,
		sourceComponent: SOURCE_COMPONENT,
		sourceContext: SOURCE_CONTEXT,
	};

	return (
		<CrossflowCtaPrimaryPressable
			crossflowOptions={crossflowOptions}
			ctaText={app.ctaMessage}
			pressableStyle={ctaButtonStyles}
		/>
	);
};
