import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useTeamValue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { TeamValue } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useCurrentViewLayoutType } from '../../../../controllers/views/selectors/view-hooks.tsx';
import { EmptyField } from '../empty/index.tsx';
import { FieldLabel, FieldValue } from '../field/styled.tsx';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
};

export const TeamField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty = false,
}: Props) => {
	const value = useTeamValue(fieldKey, issueId);
	const label = useFieldLabel(fieldKey);
	const viewLayoutType = useCurrentViewLayoutType();

	if (hideEmpty && value === undefined) {
		return null;
	}

	const renderValue = () => {
		if (value) {
			return (
				<Box xcss={valueStyles}>
					<TeamValue
						id={value?.id}
						name={value?.name}
						displayPreview={viewLayoutType !== ViewLayoutType.SUMMARY}
					/>
				</Box>
			);
		}
		if (isSummary) {
			return <EmptyField />;
		}
	};

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<FieldValue>{renderValue()}</FieldValue>
		</>
	);
};

const valueStyles = xcss({
	// we need 5px margin to match label/select/multi-select, so that they align if used together
	// xcss doesn't allow '5px', hence the transparent border
	borderLeft: '1px solid transparent',
});
