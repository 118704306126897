import { useCallback } from 'react';
import { useFieldKeysOfType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useCurrentViewFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { useCanModifyReporter } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	type FieldValueFilter,
	type Filter,
	type NumericFieldFilter,
	type IntervalFieldFilter,
	GENERIC_FIELD_FILTER,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypeValuesForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { FieldValuesUpdateRequest } from '@atlassian/jira-polaris-common/src/controllers/issue/actions/update-field-value/types.tsx';

type FieldPermissions = {
	canModifyReporter: boolean;
};

const canApplyTheFilter = (
	filter: FieldValueFilter | NumericFieldFilter | IntervalFieldFilter,
	permissions: FieldPermissions,
) => {
	if (filter.type === 'FIELD') {
		if (filter.fieldType === FIELD_TYPES.CREATOR) {
			return false;
		}
		if (filter.fieldType === FIELD_TYPES.REPORTER && !permissions.canModifyReporter) {
			return false;
		}
	}
	if (filter.type === 'INTERVAL') {
		return false;
	}
	if (filter.values.length === 1 && filter.type === 'NUMBER') {
		const numericField: NumericFieldFilter = filter;
		if (numericField.values[0].operator === 'EQ') {
			return true;
		}
	}

	if (filter.type === 'FIELD') {
		const hasEmptyValue =
			filter.values.find(({ stringValue }) => stringValue === undefined) !== undefined;

		return filter.values.length <= 1 || hasEmptyValue;
	}

	return false;
};

const getNumericAndFieldValueFilters = (
	currentViewFilters: Filter[],
): (FieldValueFilter | NumericFieldFilter | IntervalFieldFilter)[] => // used reduce instead of filter because of known limitation of filter function https://github.com/facebook/flow/issues/6516
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentViewFilters.reduce<Array<any>>(
		(
			acc: (FieldValueFilter | NumericFieldFilter | IntervalFieldFilter)[],
			filter,
		): (FieldValueFilter | NumericFieldFilter | IntervalFieldFilter)[] => {
			if (filter.type === 'NUMBER' || filter.type === 'FIELD' || filter.type === 'INTERVAL') {
				acc.push(filter);
			}
			return acc;
		},
		[],
	);

const getFilterValueForIssueTypeField = (
	filter: FieldValueFilter,
	issueTypes: IssueTypeFieldValue[],
): IssueTypeFieldValue | undefined => {
	if (!filter.values.length || filter.values.length > 1) {
		return;
	}

	const [{ stringValue }] = filter.values;

	if (!stringValue) {
		return;
	}

	return issueTypes.find(({ id }) => id === stringValue);
};

const getFilterValueForUpdate = (
	filter: FieldValueFilter | NumericFieldFilter | IntervalFieldFilter,
	isCheckbox: boolean,
): unknown => {
	if (filter.type === 'INTERVAL') {
		return undefined;
	}
	if (filter.type === 'NUMBER') {
		const numericFilter: NumericFieldFilter = filter;
		const { numericValue } = numericFilter.values[0];

		if (isCheckbox && numericFilter.values[0].operator === 'NEQ' && numericValue === 1) {
			return 0;
		}

		return numericValue;
	}

	if (filter.type !== 'FIELD') {
		return undefined;
	}

	const fieldFilter: FieldValueFilter = filter;
	const hasEmptyValue =
		fieldFilter.values.find(({ stringValue }) => stringValue === undefined) !== undefined;
	const strValue = fieldFilter.values[0]?.stringValue;
	if (strValue == null || hasEmptyValue) {
		return undefined;
	}

	switch (fieldFilter.fieldType) {
		case FIELD_TYPES.SINGLE_SELECT:
		case FIELD_TYPES.STATUS:
			return { id: strValue };
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.REPORTER:
			return { accountId: strValue };
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return [{ accountId: strValue }];
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return [strValue];
		case FIELD_TYPES.ATLAS_GOAL:
			return [strValue];
		case FIELD_TYPES.ATLAS_PROJECT:
			return strValue;
		default:
			return [{ id: strValue }];
	}
};

/**
 * @returns callback which returns whether it could apply all the filters to the new idea
 * and whether the idea is no longer filtered or not
 */
export const useTryApplyFiltersToCreatedIssue = (): ((issueId: string) => boolean) => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : '';
	const issueTypesValues = useIssueTypeValuesForProject({ projectId });
	const { updateFieldValues } = useIssueActions();
	const currentViewFilters = useCurrentViewFilter();
	const checkboxFieldKeys = useFieldKeysOfType(FIELD_TYPES.CHECKBOX);
	const [hasModifyReportPermission] = useCanModifyReporter();

	return useCallback(
		(issueId) => {
			const allFilters = getNumericAndFieldValueFilters(currentViewFilters);
			if (allFilters.length === 0) {
				return true;
			}

			const filtersToApply = allFilters.filter(
				(filter) =>
					canApplyTheFilter(filter, { canModifyReporter: hasModifyReportPermission }) ||
					checkboxFieldKeys.includes(filter.field),
			);
			if (filtersToApply.length === 0) {
				return false;
			}

			const { filtered } = updateFieldValues({
				localIssueIds: [issueId],
				fields: filtersToApply.reduce<FieldValuesUpdateRequest['fields']>((acc, filter) => {
					if (filter.type === GENERIC_FIELD_FILTER && filter.fieldType === FIELD_TYPES.ISSUE_TYPE) {
						const newValue = getFilterValueForIssueTypeField(filter, issueTypesValues);

						if (newValue) {
							acc[filter.field] = { newValue };
						}

						return acc;
					}

					const newValue = getFilterValueForUpdate(
						filter,
						checkboxFieldKeys.includes(filter.field),
					);

					acc[filter.field] = {
						newValue,
						appendMultiValues: false,
					};

					return acc;
				}, {}),
			});

			return filtered !== true;
		},
		[
			currentViewFilters,
			updateFieldValues,
			hasModifyReportPermission,
			checkboxFieldKeys,
			issueTypesValues,
		],
	);
};
