import React, { memo, useRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { WeightTag } from '@atlassian/jira-polaris-component-weight-tag/src/ui/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RefObject } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsWeightedField } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { DynamicOptionDecoratedTag } from '../../../common/decoration/decoration/tag/index.tsx';
import { ObservableValuesList } from '../../observable-values-list/index.tsx';

export type SelectViewProps = {
	isActive?: boolean;
	isMultiline?: boolean;
	isEditable: boolean;
	fieldKey: FieldKey;
	options: OptionProperty[];
	placeholder?: string | undefined;
	containerRef?: RefObject<HTMLDivElement | null>;
};

const SelectViewNonObservable = memo(({ isEditable, fieldKey, options }: SelectViewProps) => {
	const isWeightTagVisible = useIsWeightedField(fieldKey);

	return (
		<Box xcss={[multiValueContainerStyles, isEditable && editableCursorStyles]}>
			{isWeightTagVisible && (
				<Box xcss={weightTagContainerStyles}>
					<WeightTag options={options} />
				</Box>
			)}
			<Box xcss={optionsContainerStyles}>
				{options.map(({ id }) => (
					<DynamicOptionDecoratedTag fieldKey={fieldKey} key={id} id={id} />
				))}
			</Box>
		</Box>
	);
});

const SelectViewObservable = memo(
	({ isActive, isEditable, isMultiline, containerRef, fieldKey, options }: SelectViewProps) => {
		const isWeightTagVisible = useIsWeightedField(fieldKey);
		const listRef = useRef(null);

		return (
			<Box xcss={[multiValueContainerStyles, isEditable && editableCursorStyles]}>
				{isWeightTagVisible && (
					<Box xcss={weightTagContainerStyles}>
						<WeightTag options={options} />
					</Box>
				)}
				<Box
					ref={listRef}
					xcss={[optionsContainerStyles, !isMultiline && singlelineOptionsContainerStyles]}
				>
					<ObservableValuesList listRef={listRef} isActive={isActive} containerRef={containerRef}>
						{options.map(({ id }) => (
							<DynamicOptionDecoratedTag key={id} fieldKey={fieldKey} id={id} />
						))}
					</ObservableValuesList>
				</Box>
			</Box>
		);
	},
);

export const SelectView = memo(
	({
		isActive,
		isEditable,
		isMultiline,
		fieldKey,
		placeholder,
		options,
		containerRef,
	}: SelectViewProps) => {
		if (options.length === 0) {
			return (
				<Box xcss={[placeholderContainerStyles, isEditable && editableCursorStyles]}>
					{placeholder !== undefined ? placeholder : null}
				</Box>
			);
		}

		if (options.length === 1) {
			return (
				<SelectViewNonObservable fieldKey={fieldKey} isEditable={isEditable} options={options} />
			);
		}

		return (
			<SelectViewObservable
				fieldKey={fieldKey}
				isActive={isActive}
				isEditable={isEditable}
				isMultiline={isMultiline}
				options={options}
				containerRef={containerRef}
			/>
		);
	},
);

const placeholderContainerStyles = xcss({
	boxSizing: 'content-box',
	color: 'color.text.subtlest',
	cursor: 'auto',
	paddingBottom: 'space.100',
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
	paddingTop: 'space.100',
	minHeight: 'size.100',
});

const editableCursorStyles = xcss({
	cursor: 'pointer',
});

const multiValueContainerStyles = xcss({
	display: 'grid',
	font: 'font.body',
	gap: 'space.0',
	gridTemplateAreas: '"weight options"',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: '1fr',
	overflow: 'hidden',
});

const optionsContainerStyles = xcss({
	display: 'flex',
	flexWrap: 'wrap',
	gridArea: 'options',
});

const singlelineOptionsContainerStyles = xcss({
	alignItems: 'center',
	width: 'max-content',
});

const weightTagContainerStyles = xcss({
	margin: 'space.050',
});
