/* eslint-disable jira/js/no-document-script */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable jira/jira-ssr/no-unchecked-globals-usage */
import { useEffect, useRef, useState } from 'react';
import { getPendoPlugin } from '@atlassian/jira-choreographer-services/src/main.tsx';
import { environmentToEnvType } from '@atlassian/jira-choreographer-services/src/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsBeta } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import {
	useHasNoProjectPermissions,
	useIsLoadedPermissions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { checkThirdParty } from '@atlassian/browser-storage-controls';
import { useIsSharedView } from '../../controllers/environment/index.tsx';
import { useHasFieldsError } from '../../controllers/field/selectors/field-hooks.tsx';
import { useCurrentUser } from '../../controllers/user/index.tsx';
import { useHasViewSetsError } from '../../controllers/views/selectors/view-hooks.tsx';
import { logPolarisError } from '../../common/utils/errors/index.tsx';
import {
	type InlineScriptsProps,
	type JpdEdition,
	type PendoWidgetSettings,
	Edition,
} from './types.tsx';
import {
	getPendoCookieConsent,
	getProductDiscoveryRole,
	jpdEditionToProductDiscoveryEdition,
} from './utils/index.tsx';

const PENDO_APP_KEY = '568765c2-daf3-47fb-7b95-fe1569e5a0d9';
let pendingRequest = Promise.resolve();

const addPendoWidget = async ({
	atlassianAccountId,
	userFullname,
	emailAddress,
	isJiraAdmin,
	isSiteAdmin,
	appPermissions,
	appEditions,
	jpdEdition,
	hasProjectPermissions,
	environment,
}: PendoWidgetSettings) => {
	// POL-7279 Fix Pendo breaking CI/CD

	if (window.location.hostname === 'localhost') {
		return undefined;
	}
	const scriptId = 'jpd-pendo-widget';

	if (document.getElementById(scriptId)) {
		return undefined;
	}

	const currentInstanceName = window.location.hostname.replace(
		/(\.jira(-dev)?)?\.com$|(\.atlassian)?\.net$/,
		'',
	);

	const cookiePreferences = await getPendoCookieConsent();

	const pendoConfig = {
		visitor: {
			id: atlassianAccountId, // atlassian account id or empty string for anonymous users
			full_name: userFullname, // Recommended if using Pendo Feedback
			email: emailAddress,
			isJiraAdmin,
			isSiteAdmin,
			hasCoreAccess: appPermissions?.hasCoreAccess,
			hasSoftwareAccess: appPermissions?.hasSoftwareAccess,
			hasServiceDeskAccess: appPermissions?.hasServiceDeskAccess,
			hasProductDiscoveryAccess: appPermissions?.hasProductDiscoveryAccess,
			coreEdition: appEditions?.core,
			softwareEdition: appEditions?.software,
			serviceDeskEdition: appEditions?.serviceDesk,
			productDiscoveryEdition: jpdEditionToProductDiscoveryEdition(jpdEdition, appEditions),
			productDiscoveryRole: getProductDiscoveryRole(appPermissions, hasProjectPermissions),
			cookiePreferences,
		},
		account: {
			id: currentInstanceName, // Highly recommended
			hasCoreAccess: appPermissions?.hasCoreAccess,
			hasSoftwareAccess: appPermissions?.hasSoftwareAccess,
			hasServiceDeskAccess: appPermissions?.hasServiceDeskAccess,
			hasProductDiscoveryAccess: appPermissions?.hasProductDiscoveryAccess,
			coreEdition: appEditions?.core,
			softwareEdition: appEditions?.software,
			serviceDeskEdition: appEditions?.serviceDesk,
			productDiscoveryEdition: jpdEditionToProductDiscoveryEdition(jpdEdition, appEditions),
			productDiscoveryRole: getProductDiscoveryRole(appPermissions, hasProjectPermissions),
		},
	};
	if (fg('polaris_checkthirdparty_for_pendo')) {
		checkThirdParty('pendo-jpd', async () => {
			pendingRequest = pendingRequest.then(() =>
				initializePendo(PENDO_APP_KEY, pendoConfig, environment),
			);
		});
	} else {
		pendingRequest = pendingRequest.then(() =>
			initializePendo(PENDO_APP_KEY, pendoConfig, environment),
		);
	}
};

// add pendo script
export const addPendoScript = (
	resolve: (value: unknown) => void,
	config: unknown,
	id: string,
	apiKey: string,
) => {
	let script = document.getElementById(id) as HTMLScriptElement | null;
	if (script) {
		// @ts-expect-error as id is not in the global window
		window.pendo = window[id];

		window.pendo.initialize(config);
		resolve(script);
	} else {
		script = document.createElement('script');

		script.id = id;
		script.defer = true;
		script.onload = () => {
			// @ts-expect-error as id is not in the global window
			window[id] = window.pendo;
			window.pendo.initialize(config);
			resolve(script);
		};
		script.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
		document.head.appendChild(script);
	}
};

// remove pendo from the window and remove pendo scripts from the document
export const removePendo = () => {
	try {
		if (window.pendo) {
			window.pendo?.teardown();

			delete window.pendo;

			document.querySelectorAll('script[src*="pendo.io"]').forEach((x) => x.remove());
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		logPolarisError('pendoCleanupError', e);
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initializePendo = (apiKey: string, pendoConfig: any, environment: Environment) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	new Promise<any>((resolve) => {
		const id = `pendo-agent-${apiKey}`;
		const config = fg('post-office_enable_pendo_choreographer')
			? getPendoPlugin(environmentToEnvType(environment)).getPendoConfig(pendoConfig)
			: pendoConfig;

		if (window.pendo && window.pendo.teardown && apiKey !== window.pendo.apiKey) {
			if (fg('jpd-cleanup-pendo-on-unmount')) {
				removePendo();
			} else {
				window.pendo.teardown();
				delete window.pendo;
			}
		}
		addPendoScript(resolve, config, id, apiKey);
	});

const addStatusPageWidget = () => {
	const scriptId = 'jpd-statuspage-widget';

	if (document.getElementById(scriptId)) {
		return undefined;
	}

	const s = document.createElement('script');
	s.id = scriptId;
	s.type = 'text/javascript';
	s.src = 'https://qmzzdxyvmbmk.statuspage.io/embed/script.js';
	s.defer = true;

	document.head?.appendChild(s);
	return s;
};

export const InlineScripts = ({ embedded }: InlineScriptsProps) => {
	const tenantContext = useTenantContext();
	const environment = useEnvironment();
	const isSharedView = useIsSharedView();
	const hasFieldsError = useHasFieldsError();
	const hasConfigError = useHasViewSetsError();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const { data: userData, error: userDataError } = useCurrentUser();
	const areScriptsAdded = useRef<boolean>(false);

	const [jpdEdition, setJpdEdition] = useState<JpdEdition | undefined>(undefined);
	const isBeta = useIsBeta();

	useEffect(() => {
		if (isBeta === undefined) {
			setJpdEdition(Edition.UNKNOWN);
			return;
		}

		setJpdEdition(isBeta ? Edition.BETA_EDITION : Edition.LICENSED);
	}, [isBeta]);

	useEffect(() => {
		if (areScriptsAdded.current) {
			return undefined;
		}

		if (embedded) {
			return undefined;
		}

		const userLoaded = userData || !tenantContext.atlassianAccountId || userDataError;
		if (!userLoaded || jpdEdition === undefined) {
			return undefined;
		}

		// Permissions will load only when projectID is available.
		// If there are no /fields response, /config will not be started and projectID will be absent.
		// Check permissionsLoaded only if there are no /fields and /config errors otherwise skip condition and initialise Pendo.
		if (isSharedView && !(hasFieldsError || hasConfigError || permissionsLoaded)) {
			return undefined;
		}

		let statusPageWidgetScript: HTMLScriptElement | undefined;

		if (tenantContext.atlassianAccountId === null) {
			addPendoWidget({
				atlassianAccountId: '',
				userFullname: '',
				emailAddress: '',
				isJiraAdmin: false,
				isSiteAdmin: false,
				appPermissions: undefined,
				appEditions: undefined,
				jpdEdition,
				hasProjectPermissions: !hasNoProjectPermissions,
				environment,
			});
			statusPageWidgetScript = addStatusPageWidget();
		} else {
			addPendoWidget({
				atlassianAccountId: tenantContext.atlassianAccountId,
				userFullname: tenantContext.userFullname || '',
				emailAddress: userData?.emailAddress || '',
				isJiraAdmin: tenantContext.isAdmin,
				isSiteAdmin: tenantContext.isSiteAdmin,
				appPermissions: tenantContext.appPermissions,
				appEditions: tenantContext.appEditions,
				jpdEdition,
				hasProjectPermissions: !hasNoProjectPermissions,
				environment,
			});
			statusPageWidgetScript = addStatusPageWidget();
		}
		areScriptsAdded.current = true;

		return () => {
			document.getElementById('pendo-base')?.remove();
			statusPageWidgetScript?.parentNode?.removeChild(statusPageWidgetScript);

			if (fg('jpd-cleanup-pendo-on-unmount')) {
				removePendo();
			}
		};
	}, [
		hasConfigError,
		hasFieldsError,
		isSharedView,
		permissionsLoaded,
		hasNoProjectPermissions,
		userData,
		userDataError,
		embedded,
		tenantContext,
		jpdEdition,
		environment,
	]);

	return null;
};
