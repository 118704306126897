import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projects: {
		id: 'polaris-components.view-header.view-breadcrumbs.projects',
		defaultMessage: 'Projects',
		description: 'First breadcrumb in the view header',
	},
	roadmaps: {
		id: 'polaris-components.view-header.view-breadcrumbs.roadmaps',
		defaultMessage: 'Roadmaps',
		description: 'First breadcrumb in the roadmap view header',
	},
});
