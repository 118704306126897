export const CARD_PADDING = 7;
export const CARD_PADDING_REDUCED = 4;
export const CARD_HORIZONTAL_PADDING = 12;
export const SUMMARY_CARD_RIGHT_PADDING = 7;
export const HEADER_GAP = 8;

export const FIELD_HEIGHT = 30;
export const FIELD_HEIGHT_COMPACT = 20;
export const ROW_GAP = 4;
export const COLUMN_GAP = 8;

export const KEY_FIELD_HEIGHT = 20;
export const ASSIGNEE_SUMMARY_MIN_HEIGHT = 30;
export const ASSIGNEE_SUMMARY_CONTAINER_GAP = 8;
export const ASSIGNEE_SUMMARY_AVATAR_WIDTH = 28;
export const ASSIGNEE_SUMMARY_RIGHT_PADDING = 14;
export const ISSUE_TYPE_PLACEHOLDER_WIDTH = 22;

export const CARD_SUMMARY_TOOLTIP_WIDTH = 324;
export const TOOLTIP_DELAY = 300;

export const DISPLAYING_FIELDS_MIN_CARD_WIDTH = 75;

/**
 * WARNING
 * This is used both for card height calculation and the summary component itself.
 * Any changes that affect the summary field styles should take place here.
 * This was moved away from the summary component because of cyclic dependencies.
 *
 * Refactor: This file cannot be easily tokenised (not even with getTokenValue) as it is used in two places and compiled needs to be able to statically evaluate these styles
 */
export const SUMMARY_FIELD_STYLES = {
	lineHeight: '24px',
	margin: 0,
	fontWeight: '600',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	textOverflow: 'clip',
};

/**
 * WARNING
 * Also used for card height calculation.
 * Any changes that affect the string field value styles should take place here.
 * This was moved away from the string field component because of cyclic dependencies.
 *
 * Refactor: This file cannot be easily tokenised (not even with getTokenValue) as it is used in two places and compiled needs to be able to statically evaluate these styles
 */
export const STRING_FIELD_VALUE_STYLES = {
	wordBreak: 'break-word',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	textOverflow: 'clip',
};
