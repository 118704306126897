import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import { isValid, parseISO } from 'date-fns';
import type { EmojiDescription } from '@atlaskit/emoji';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { getPaletteEntryForMainColorWithNoDefault } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import { DATETIME_FORMAT } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import {
	CommonDecoratedTag,
	DecoratedTagContent,
} from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { DecoratedDatetimeProps } from './types.tsx';

type Props = {
	mainColor: string | undefined;
	emoji: EmojiDescription | undefined;
	value: string | undefined;
	skipWrap?: boolean | undefined;
	placeholder?: string | undefined;
	isCompact?: boolean;
};

export const DecoratedDatetimeComponent = memo<Props>(
	({ mainColor, emoji, value, skipWrap, placeholder, isCompact }: Props) => {
		const paletteEntry = useMemo(
			() => getPaletteEntryForMainColorWithNoDefault(mainColor),
			[mainColor],
		);
		if (value === undefined && placeholder !== undefined) {
			return <Placeholder>{placeholder}</Placeholder>;
		}
		if (!paletteEntry && !emoji && skipWrap === true) {
			// @ts-expect-error - TS2739 - Type '{ children: string | undefined; }' is missing the following properties from type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { compact: any; textColor: string; }, any>>': compact, textColor
			return <DecoratedTagContent compact={isCompact}>{value}</DecoratedTagContent>;
		}
		return (
			<CommonDecoratedTag mainColor={mainColor} emoji={emoji} value={value} compact={isCompact} />
		);
	},
);

export const getFormattedValue = (
	value: string,
	locale: Locale,
	format: string,
): string | undefined => {
	if (value === undefined) {
		return undefined;
	}
	const date = parseISO(value);

	if (!isValid(date)) {
		return undefined;
	}

	return formatWithLocale(date, format, locale);
};

const getVisibleValue = (datetime: string, locale: Locale) =>
	getFormattedValue(datetime, locale, DATETIME_FORMAT);

const DecoratedDatetimeInternal = ({
	datetime,
	decoration,
	skipWrap,
	placeholder,
	isCompact,
}: DecoratedDatetimeProps) => {
	const { locale } = useTenantContext();
	const emojiDescription = useEmoji(decoration?.emoji);

	const value = datetime !== undefined ? getVisibleValue(datetime, locale) : undefined;
	return (
		<DecoratedDatetimeComponent
			mainColor={decoration?.backgroundColor}
			emoji={emojiDescription}
			value={value?.toString()}
			skipWrap={skipWrap}
			placeholder={placeholder}
			isCompact={isCompact}
		/>
	);
};

export const DecoratedDateTime = (props: DecoratedDatetimeProps) => (
	<DecoratedDatetimeInternal {...props} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N300,
	paddingTop: token('space.100', '8px'),
	paddingRight: '0px',
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.025', '2px'),
});
