import React from 'react';
import { Inline } from '@atlaskit/primitives';

import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import { useIssueTypeName } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';

import type { FieldValueComponentProps, FieldValueConfiguration } from '../types.tsx';

const Component = ({ groupIdentity }: FieldValueComponentProps) => {
	const issueTypeName = useIssueTypeName({ issueTypeId: groupIdentity });

	return (
		<Inline alignBlock="center" space="space.075">
			<IssueTypeIcon issueTypeId={groupIdentity} />
			{issueTypeName}
		</Inline>
	);
};

export const issueTypeFieldValueConfig: FieldValueConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
};
