import { useCallback } from 'react';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	useDroppableEventsCollectionUpdate,
	type DroppableCollectionProps,
} from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';

type DroppableHandlerProps = {
	fields: Field[];
	onFieldsUpdate: (updatedFields: Field[]) => void;
	children: React.ReactNode;
};

export const DroppableHandler = ({ children, fields, onFieldsUpdate }: DroppableHandlerProps) => {
	const handleSort: DroppableCollectionProps<Field, FieldKey>['onSort'] = useCallback(
		({ updatedCollection }) => {
			onFieldsUpdate(updatedCollection);
		},
		[onFieldsUpdate],
	);

	useDroppableEventsCollectionUpdate({
		onSort: handleSort,
		collection: fields,
		getSrcIdForCollectionItem: (field) => field.key,
		originLabel: 'field-config',
	});

	return children;
};
