import React, { createContext, useRef, type PropsWithChildren } from 'react';

export const PageLoadContext = createContext<{
	isFirstMount: React.MutableRefObject<boolean>;
}>({ isFirstMount: { current: true } });

export const PageLoadProvider = ({ children }: PropsWithChildren) => {
	const isFirstMount = useRef(true);

	return <PageLoadContext.Provider value={{ isFirstMount }}>{children}</PageLoadContext.Provider>;
};
