import React from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

export const EditWorkflowButton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={wrapperStyles} data-component-selector="edit-workflow-ew1">
			<Button>{formatMessage(messages.editWorkflowButtonLabel)}</Button>
		</Box>
	);
};

const wrapperStyles = xcss({
	visibility: 'hidden',
});
