import React, { useCallback, useEffect } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueCreateInput } from '@atlassian/jira-polaris-component-issue-create-input/src/ui/issue-create-input.tsx';
import { useFieldFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCurrentViewLastSelectedIssueTypeId } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { useFilteredIssueTypeValues } from '@atlassian/jira-polaris-component-issue-types/src/utils.tsx';
import { useHasProjectCustomIssueTypes } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { ROW_PINNED_BOTTOM_DATA_COMPONENT_SELECTOR } from '../../constants.tsx';
import messages from './messages.tsx';

type Props = {
	enforcedIssueTypeId?: IssueTypeId;
	onCreate: (issueTypeId: IssueTypeId, summary: string, createdByBlur: boolean) => void;
	onCancel: () => void;
};

const TEST_ID = 'polaris-ideas.ui.view-content.idea-list.rows.add-idea-input.add-idea-list-input';

const shouldPreventCreationOnBlur = (element: Element) =>
	!!element.closest(`[data-component-selector="${ROW_PINNED_BOTTOM_DATA_COMPONENT_SELECTOR}"]`);

export const InlineIssueCreate = ({ enforcedIssueTypeId, onCreate, onCancel }: Props) => {
	const { formatMessage } = useIntl();

	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;
	const hasProjectCustomIssueTypes = useHasProjectCustomIssueTypes({ projectId });
	const currentViewIssueTypeFilter = useFieldFilter(ISSUETYPE_FIELDKEY);
	const currentViewFilterIssueTypeIds = useFilteredIssueTypeValues({
		filter: currentViewIssueTypeFilter,
	}).map(({ id }) => id);

	const previouslySelectedIssueTypeId = useCurrentViewLastSelectedIssueTypeId();
	const suggestedIssueTypeId =
		enforcedIssueTypeId ?? previouslySelectedIssueTypeId ?? currentViewFilterIssueTypeIds[0];

	const { setLastSelectedIssueTypeId } = useViewActions();

	useEffect(() => {
		experience.listView.createIdeaInlineClickResponse.success();
	}, []);

	const handleCreate = useCallback(
		(issueTypeId: IssueTypeId, summary: string, createdByBlur: boolean) => {
			setLastSelectedIssueTypeId(issueTypeId);
			onCreate(issueTypeId, summary, createdByBlur);
		},
		[onCreate, setLastSelectedIssueTypeId],
	);

	return (
		<div data-testid={TEST_ID}>
			<IssueCreateInput
				placeholder={formatMessage(messages.placeholderNonFinal)}
				onCancel={onCancel}
				onCreate={handleCreate}
				preventAutoFocusScroll
				isCreationOnBlurPrevented={shouldPreventCreationOnBlur}
				paddingLeft="56px"
				defaultIssueTypeId={suggestedIssueTypeId}
				availableIssueTypeIds={currentViewFilterIssueTypeIds}
				shouldShowIssueTypeSelect={hasProjectCustomIssueTypes}
			/>
		</div>
	);
};
