import React, { useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { MenuOptions, type MenuOptionsProps } from '../../common/menu/index.tsx';

export const Menu = ({ onApply, onEdit, onDelete, isAppliedTemplate }: MenuOptionsProps) => {
	const [openMenu, setOpenMenu] = useState(false);

	const onToggleOpenMenu = useCallback(() => {
		setOpenMenu(!openMenu);
	}, [openMenu]);

	const closeMoreMenu = useCallback(() => setOpenMenu(false), []);

	return (
		<Popup
			isOpen={openMenu}
			onClose={closeMoreMenu}
			placement="bottom-end"
			content={() => (
				<div
					onKeyPress={closeMoreMenu}
					onClick={(e) => {
						e.stopPropagation();
						closeMoreMenu();
					}}
					tabIndex={0}
					role="menu"
				>
					<MenuOptions
						isAppliedTemplate={isAppliedTemplate}
						onApply={onApply}
						onEdit={onEdit}
						onDelete={onDelete}
					/>
				</div>
			)}
			trigger={(triggerProps) => (
				<div
					{...triggerProps}
					onClick={onToggleOpenMenu}
					onKeyPress={onToggleOpenMenu}
					role="button"
					tabIndex={0}
				>
					<MoreButton
						data-component-selector="more-btn-nN39"
						appearance="subtle"
						isSelected={openMenu}
						isVisible={openMenu}
						iconBefore={<MoreVerticalIcon label="more" />}
					/>
				</div>
			)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreButton = styled(Button)<{ isVisible: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isVisible }) => (isVisible ? 'visible' : 'hidden'),
	borderRadius: '3px',
	minWidth: '32px',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="template-bR3m"]:hover &': {
		visibility: 'visible',
	},
});
