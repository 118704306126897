import React, { useEffect, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import { CustomThemeButton } from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { getFuzzyScale, FuzzyScale } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import type { IntervalValue } from '@atlassian/jira-polaris-lib-date-time/src/types.tsx';
import { pickerButtonStyle } from '../utils.tsx';
import { DayPicker } from './day-picker/index.tsx';
import { messages } from './messages.tsx';
import MonthPicker from './month-picker/index.tsx';
import QuarterPicker from './quarter-picker/index.tsx';
import type { PickerProps } from './types.tsx';
import {
	getLastUsedDateFieldScaleFromStorage,
	setLastUsedDateFieldScaleToStorage,
} from './utils/storage.tsx';

const FuzzyDatePickerContent = (props: PickerProps) => {
	const [date, setDate] = useState(props.value);
	const initialScale = getFuzzyScale(date);
	const lastUsedScale = getLastUsedDateFieldScaleFromStorage();
	const [scale, setScale] = useState((!date && lastUsedScale) || initialScale);

	const { formatMessage } = useIntl();

	// Ensure we update the local state when given new value
	useEffect(() => {
		if (props.value) {
			setDate(props.value);
		}
	}, [props.value]);

	const onChange = useCallback(
		(newDate?: IntervalValue) => {
			if (newDate) {
				props.onChange(newDate);
			}
		},
		[props],
	);

	const onScaleChange = useCallback((newScale: FuzzyScale) => {
		setScale(newScale);
		setLastUsedDateFieldScaleToStorage(newScale);
	}, []);

	const dateValue = date ? date.end : undefined;

	return (
		<DatePickerContentWrapper id="date-picker">
			<HeaderContainer>
				{scale && (
					<ButtonGroup>
						<CustomThemeButton
							isSelected={scale === FuzzyScale.DAY}
							onClick={() => onScaleChange(FuzzyScale.DAY)}
							theme={pickerButtonStyle(scale === FuzzyScale.DAY)}
							shouldFitContainer
						>
							{formatMessage(messages.dayPickerButtonMessage)}
						</CustomThemeButton>
						<CustomThemeButton
							isSelected={scale === FuzzyScale.MONTH}
							onClick={() => onScaleChange(FuzzyScale.MONTH)}
							theme={pickerButtonStyle(scale === FuzzyScale.MONTH)}
							shouldFitContainer
						>
							{formatMessage(messages.monthPickerButtonMessage)}
						</CustomThemeButton>
						<CustomThemeButton
							isSelected={scale === FuzzyScale.QUARTER}
							onClick={() => onScaleChange(FuzzyScale.QUARTER)}
							theme={pickerButtonStyle(scale === FuzzyScale.QUARTER)}
							shouldFitContainer
						>
							{formatMessage(messages.quarterPickerButtonMessage)}
						</CustomThemeButton>
					</ButtonGroup>
				)}
			</HeaderContainer>
			<div>
				{scale === FuzzyScale.DAY && <DayPicker date={dateValue} onSelect={onChange} />}
				{scale === FuzzyScale.MONTH && <MonthPicker date={dateValue} onSelect={onChange} />}
				{scale === FuzzyScale.QUARTER && <QuarterPicker date={dateValue} onSelect={onChange} />}
			</div>
		</DatePickerContentWrapper>
	);
};

export default FuzzyDatePickerContent;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BaseContainer = styled.div({
	paddingTop: token('space.250', '20px'),
	paddingRight: token('space.250', '20px'),
	paddingBottom: token('space.250', '20px'),
	paddingLeft: token('space.250', '20px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled(BaseContainer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> * + *': {
		marginTop: token('space.100', '8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroup = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		flex: '1 1 0px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> * + *': {
		marginLeft: token('space.050', '4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DatePickerContentWrapper = styled.div({
	width: '322px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button[data-selected]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.selected', colors.B50),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.selected', colors.B400),
	},
});
