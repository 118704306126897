import React, {
	type ReactNode,
	type SyntheticEvent,
	useMemo,
	type ReactChild,
	useState,
	memo,
} from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ButtonNew from '@atlaskit/button/new';
import AddIconOld from '@atlaskit/icon/glyph/editor/add';
import AddIcon from '@atlaskit/icon/utility/add';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

export type ActionButtonProps = {
	isSelected?: boolean;
	isEmpty: boolean;
	isAccentuated?: boolean | undefined;
	id: string;
	children?: ReactNode;
	elemAfter?: ReactNode | null;
	hasUnsavedChanges?: boolean;
	iconBefore?: ReactChild;
	onClick: (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	testId?: string;
};

const ActionButtonLegacy = ({
	id,
	children = null,
	elemAfter,
	isEmpty,
	isSelected = false,
	isAccentuated,
	onClick,
	hasUnsavedChanges,
	iconBefore,
	testId,
}: ActionButtonProps) => {
	const [isHovered, setIsHovered] = useState(false);

	const iconAfter = useMemo(() => {
		if (iconBefore) {
			return undefined;
		}

		if (elemAfter !== null && elemAfter !== undefined) {
			if (isVisualRefreshEnabled()) {
				return (
					<Box
						xcss={[
							// Baseline styles - not introducing any colors
							badgeBaseStyle,

							// Colors for different states (the latest wins)
							badgeColorsDefaultStyle,
							isHovered && badgeColorsSelectedStyle,
							isAccentuated && badgeAccentuatedStyle,
							isHovered && isAccentuated && badgeAccentuatedHoveredStyle,
							hasUnsavedChanges && badgeUnsavedChangesStyle,
							isHovered && hasUnsavedChanges && badgeUnsavedChangesHoveredStyle,

							// Selected style overrides all of the above
							isSelected && badgeColorsSelectedStyle,
						]}
					>
						{elemAfter}
					</Box>
				);
			}
			return (
				<ElementAfterContainer
					hasUnsavedChanges={hasUnsavedChanges && !isSelected}
					isSelected={isSelected}
					isAccentuated={isAccentuated && !isSelected}
				>
					{elemAfter}
				</ElementAfterContainer>
			);
		}

		if (isVisualRefreshEnabled()) {
			return (
				<>
					{isEmpty && (
						<AddIcon
							LEGACY_size="small"
							label=""
							color="currentColor"
							testId="polaris-ideas.ui.view-controls.action-button.add-icon"
						/>
					)}
				</>
			);
		}

		return isEmpty ? (
			<AddIconContainer isSelected={isSelected}>
				<AddIconOld
					size="small"
					label=""
					testId="polaris-ideas.ui.view-controls.action-button.add-icon"
				/>
			</AddIconContainer>
		) : undefined;
	}, [elemAfter, isEmpty, iconBefore, isSelected, hasUnsavedChanges, isAccentuated, isHovered]);

	if (isVisualRefreshEnabled()) {
		return (
			<ButtonNew
				id={id}
				testId={testId}
				isSelected={isSelected}
				onClick={onClick}
				iconAfter={() => iconAfter}
				iconBefore={() => iconBefore}
				onPointerEnter={() => setIsHovered(true)}
				onPointerLeave={() => setIsHovered(false)}
			>
				{children}
			</ButtonNew>
		);
	}

	return (
		<ControlButtonWrapper
			isAccentuated={isAccentuated && !isSelected}
			hasUnsavedChanges={hasUnsavedChanges && !isSelected}
			hasChanges={isEmpty === false && !isSelected}
		>
			<Button
				id={id}
				testId={testId}
				isSelected={isSelected}
				onClick={onClick}
				iconAfter={iconAfter}
				iconBefore={iconBefore}
			>
				{children}
			</Button>
		</ControlButtonWrapper>
	);
};

const ActionButtonNext = memo(ActionButtonLegacy);

export const ActionButton = componentWithFG(
	'polaris_idea_list_performance_improvements',
	ActionButtonNext,
	ActionButtonLegacy,
);

const badgeBaseStyle = xcss({
	font: 'font.body',
	paddingInline: 'space.100',
	paddingBlock: 'space.025',
	borderRadius: 'border.radius',
	marginRight: 'space.negative.025',
	marginLeft: 'space.050',
});

const badgeColorsDefaultStyle = xcss({
	color: 'color.text.accent.blue',
	backgroundColor: 'color.background.accent.blue.subtlest',
});

const badgeColorsSelectedStyle = xcss({
	color: 'color.text.accent.blue',
	backgroundColor: 'color.background.accent.blue.subtlest.hovered',
});

const badgeUnsavedChangesStyle = xcss({
	color: 'color.text.warning',
	backgroundColor: 'color.background.warning',
});

const badgeUnsavedChangesHoveredStyle = xcss({
	color: 'color.text.warning',
	backgroundColor: 'color.background.warning.hovered',
});

const badgeAccentuatedStyle = xcss({
	color: 'color.text.discovery',
	backgroundColor: 'color.background.discovery',
});

const badgeAccentuatedHoveredStyle = xcss({
	color: 'color.text.discovery',
	backgroundColor: 'color.background.discovery.hovered',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ElementAfterContainer = styled.span<{
	isAccentuated?: boolean;
	isSelected?: boolean;
	hasUnsavedChanges?: boolean;
}>({
	/* eslint-disable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isSelected, hasUnsavedChanges, isAccentuated }) =>
		isSelected
			? 'inherit'
			: hasUnsavedChanges
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.warning.hovered', colors.Y75)
				: isAccentuated
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.background.discovery.hovered', colors.P75)
					: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.background.selected.pressed', colors.B75),
	/* eslint-enable no-nested-ternary */
	borderRadius: '2px',
	marginLeft: token('space.050', '4px'),
	minWidth: '16px',
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	padding: '0 3px',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddIconContainer = styled.span<{ isSelected: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isSelected }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isSelected ? 'inherit' : token('color.background.neutral.subtle.hovered', colors.N20A),
	borderRadius: '2px',
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlButtonWrapper = styled.div<{
	isSharedView?: boolean;
	isAccentuated?: boolean;
	hasChanges?: boolean;
	hasUnsavedChanges?: boolean;
}>({
	/* eslint-disable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: ({ isAccentuated, hasUnsavedChanges, hasChanges }) =>
			hasUnsavedChanges
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.warning', colors.Y50)
				: isAccentuated
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.background.discovery', colors.P50)
					: hasChanges
						? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							token('color.background.accent.blue.subtler', colors.B50)
						: undefined,
		/* eslint-enable no-nested-ternary */
	},
});
