import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types.tsx';
import { loadDeliveryTicketsEstimate } from '../load-delivery-tickets-estimate/index.tsx';
import { loadIssueLinkArchived } from '../load-issue-link-archived/index.tsx';
import { addDeliveryTickets } from '../load-issue/index.tsx';

export const refreshIssueLinks =
	(onRefreshed: () => void, shouldRefreshAllDeliveryTickets = false, externalIssueKey?: string) =>
	async (
		{ getState, setState, dispatch }: StoreActionApi<State>,
		{ issueKey: internalIssueKey, issuesRemote, onDeliveryIssuesUpdated }: Props,
	) => {
		const issueKey = externalIssueKey ?? internalIssueKey; // external issue key is used in a scenario where you do not want to store issue key in idea state, but still want to refresh issue links for a specific issue key
		if (!issueKey) {
			return;
		}
		issuesRemote.fetchIssue({ issueIdOrKey: issueKey }).then((response) => {
			addDeliveryTickets(getState, setState, response, shouldRefreshAllDeliveryTickets);
			onDeliveryIssuesUpdated(response);
			setState({
				issueLinks: response.fields.issuelinks,
			});

			dispatch(loadIssueLinkArchived());
			dispatch(loadDeliveryTicketsEstimate());
			onRefreshed();
		});
	};
