import { JiraServiceManagementIcon } from '@atlaskit/logo';
import { JiraSideNavFirstPartyRecommendation } from '@post-office/components--jira-side-nav-first-party-recommendation';
// @ts-expect-error: 'React' is declared but its value is never read
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { createRender } from './types';

export const CCP_PRODUCT_ID = 'de63a248-1f93-46ed-b6ea-ab8e38af1c88';
export const HAMS_PRODUCT_ID = 'jira-servicedesk.ondemand';
const PRODUCT_NAME = 'Jira Service Management';

const whyAmISeeingThisModalContent = {
	message: (
		<p>
			<FormattedMessage
				id="growth.recommendation-jsm.reasons-modal.message"
				defaultMessage="We think {jsm} will improve your team's productivity. The recommendation is based on:"
				description="Explanation of why we think the JSM recommendation will be relevant to the customer."
				values={{ jsm: <strong>{PRODUCT_NAME}</strong> }}
			/>
		</p>
	),
};

export type Props = {
	onDismissed: (messageInstanceId: string) => void;
};

export const render = createRender(({ onDismissed }: Props) => (
	<JiraSideNavFirstPartyRecommendation
		app={{
			hamsIdentifier: HAMS_PRODUCT_ID,
			ccpProductId: CCP_PRODUCT_ID,
			logo: <JiraServiceManagementIcon appearance="brand" />,
			header: (
				<FormattedMessage
					id="growth.recommendation-jsm.product-name.header"
					description="Header for Jira Service Management recommendation message in Jira Side Nav"
					defaultMessage="Automate workflows for faster resolutions"
				/>
			),
			ctaMessage: (
				<FormattedMessage
					id="growth.recommendation-jsm.cta.label"
					description="CTA text for Jira Service Management recommendation message in Jira Side Nav"
					defaultMessage="Try Jira Service Management"
				/>
			),
		}}
		onDismissed={onDismissed}
		whyAmISeeingThisModalContent={whyAmISeeingThisModalContent}
	/>
));

export default render;
