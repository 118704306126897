import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import ShareIcon from '@atlaskit/icon/core/migration/share';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import LikeIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import type { NewIconProps } from '@atlaskit/icon/types';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { ELID_ISSUE_HEADER } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import { GRID_PADDING_OVERRIDE_COMPONENT_SELECTOR } from '@atlassian/jira-issue-view-common-views/src/issue-page-grid/styled.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import ModalActionsCloseButton from '../../modal-close-button.tsx';

export const IssueViewModalHeaderSkeleton = ({
	onClose,
	renderFeedback,
	issueKey,
}: {
	onClose: () => void;
	renderFeedback?: () => ReactNode;
	issueKey: IssueKey;
}) => {
	let renderFeedbackWithWrapper;
	if (renderFeedback) {
		const feedback = renderFeedback();
		renderFeedbackWithWrapper = <FeedbackWrapper>{feedback}</FeedbackWrapper>;
	}
	const iconProps: NewIconProps = {
		label: '',
		LEGACY_size: 'medium',
	};
	const issueKeyLink = `/browse/${issueKey}`;
	return (
		<ModalHeaderSkeletonWrapper id={ELID_ISSUE_HEADER}>
			<ModalHeaderSkeletonBreadcrumbsWrapper>
				<IssueTypeLoadingIcon />
				<IssueKeyClickableWrapper target="_blank" href={issueKeyLink}>
					{issueKey}
				</IssueKeyClickableWrapper>
			</ModalHeaderSkeletonBreadcrumbsWrapper>
			<HeaderActionsOverflowWrapper>
				<ModalHeaderSkeletonActionsWrapper>
					{renderFeedbackWithWrapper}
					<SkeletonIconWrapper>
						<WatchIcon
							{...iconProps}
							// @ts-expect-error Type '"var(--ds-background-neutral-hovered)"' is not assignable to type '"currentColor" | "var(--ds-text)" | "var(--ds-text-accent-lime)" | "var(--ds-text-accent-lime-bolder)" | "var(--ds-text-accent-red)" | "var(--ds-text-accent-red-bolder)" | ... 55 more ... | undefined'.ts(2322)
							color={token('color.background.neutral.hovered', colors.N30)}
						/>
					</SkeletonIconWrapper>
					<SkeletonIconWrapper>
						<LikeIcon
							{...iconProps}
							// @ts-expect-error Type '"var(--ds-background-neutral-hovered)"' is not assignable to type '"currentColor" | "var(--ds-text)" | "var(--ds-text-accent-lime)" | "var(--ds-text-accent-lime-bolder)" | "var(--ds-text-accent-red)" | "var(--ds-text-accent-red-bolder)" | ... 55 more ... | undefined'.ts(2322)
							color={token('color.background.neutral.hovered', colors.N30)}
						/>
					</SkeletonIconWrapper>
					<SkeletonIconWrapper>
						<ShareIcon
							{...iconProps}
							// @ts-expect-error Type '"var(--ds-background-neutral-hovered)"' is not assignable to type '"currentColor" | "var(--ds-text)" | "var(--ds-text-accent-lime)" | "var(--ds-text-accent-lime-bolder)" | "var(--ds-text-accent-red)" | "var(--ds-text-accent-red-bolder)" | ... 55 more ... | undefined'.ts(2322)
							color={token('color.background.neutral.hovered', colors.N30)}
						/>
					</SkeletonIconWrapper>
					<SkeletonIconWrapper>
						<MoreIcon
							{...iconProps}
							// @ts-expect-error Type '"var(--ds-background-neutral-hovered)"' is not assignable to type '"currentColor" | "var(--ds-text)" | "var(--ds-text-accent-lime)" | "var(--ds-text-accent-lime-bolder)" | "var(--ds-text-accent-red)" | "var(--ds-text-accent-red-bolder)" | ... 55 more ... | undefined'.ts(2322)
							color={token('color.background.neutral.hovered', colors.N30)}
						/>
					</SkeletonIconWrapper>
				</ModalHeaderSkeletonActionsWrapper>
				{/* This is pulled out of the ModalHeaderSkeletonActionsWrapper so that it is not hidden on very small screens */}
				<ModalActionsCloseButton onClose={onClose} />
			</HeaderActionsOverflowWrapper>
		</ModalHeaderSkeletonWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalHeaderSkeletonWrapper = styled.div({
	paddingTop: token('space.400', '32px'),
	paddingRight: token('space.400', '32px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.400', '32px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[GRID_PADDING_OVERRIDE_COMPONENT_SELECTOR]: {
		paddingLeft: 0,
	},
	display: 'flex',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonIconWrapper = styled.div({
	paddingTop: token('space.050', '4px'),
	marginTop: '0px',
	marginRight: token('space.150', '12px'),
	marginBottom: '0px',
	marginLeft: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueTypeLoadingIcon = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 2,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: gridSize * 2,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton.subtle', colors.N30),
	borderRadius: '3px',
	marginRight: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKeyClickableWrapper = styled.a({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N300),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtle', colors.N300),
		cursor: 'pointer',
	},
	whiteSpace: 'nowrap',
	cursor: 'default',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalHeaderSkeletonActionsWrapper = styled.div({
	display: 'flex',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalHeaderSkeletonBreadcrumbsWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderActionsOverflowWrapper = styled.span({
	display: 'flex',
	overflow: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeedbackWrapper = styled.div({
	flexShrink: 0,
});
