import React, { type SyntheticEvent, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import Select, {
	components,
	type OptionProps,
	type MenuListComponentProps,
} from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useDescriptionField,
	useStringFields,
	useFieldByKey,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useOpenRightSidebarOnCreate } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { SharingSettingsOpeningSource } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { CreateFieldButton } from '@atlassian/jira-polaris-common/src/ui/common/create-field/index.tsx';
import { FieldSelectOptionComponent } from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/field-select-option/index.tsx';
import type { OptionType } from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/types.tsx';
import { useCanCreateFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import type { FieldSelectProps } from '@atlassian/jira-polaris-lib-control-sharing/src/controllers/types.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { AnalyticsFieldTypes, SELECT_MAX_WIDTH, SELECT_MIN_WIDTH } from './constants.tsx';
import messages from './messages.tsx';

const OptionComponent = ({ data, ...rest }: OptionProps<OptionType>) => (
	<components.Option data={data} {...rest}>
		<FieldSelectOptionComponent fieldKey={data.key} emojiId={data.emoji} {...data} />
	</components.Option>
);

const MenuListComponent = ({
	onCreateClick,
	...props
}: MenuListComponentProps<OptionType> & { onCreateClick: () => void }) => {
	const canCreateFields = useCanCreateFields();

	return (
		<>
			<components.MenuList {...props} />
			{canCreateFields && (
				<CreateFieldButtonContainer>
					<CreateFieldButton
						onCreate={onCreateClick}
						id="pendo.publish-modal.create-new-field-button"
					/>
				</CreateFieldButtonContainer>
			)}
		</>
	);
};

export const DescriptionFieldSelect = ({ selectedFieldKey, onChange }: FieldSelectProps) => {
	const { formatMessage } = useIntl();

	const [descriptionField] = useDescriptionField();
	const stringFields = useStringFields();
	const allFields = [...(descriptionField ? [descriptionField] : []), ...stringFields];
	const fieldOptions = allFields.map(
		({ key, label, type, emoji, configuration, global, custom }) => ({
			key,
			label,
			type,
			emoji,
			global,
			custom,
			hasWeightType: configuration?.optionWeightType !== undefined,
		}),
	);
	const openRightSidebarOnCreate = useOpenRightSidebarOnCreate(SharingSettingsOpeningSource);
	const [isOpen, setIsOpen] = useState(false);
	const selectedField = useFieldByKey(selectedFieldKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleToggle = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (!isOpen) {
				fireUIAnalytics(analyticsEvent, 'descriptionField');
			} else {
				fireUIAnalytics(createAnalyticsEvent({}), 'select closed', 'descriptionField');
			}
			setIsOpen(!isOpen);
		},
		[isOpen, createAnalyticsEvent],
	);

	const handleClose = useCallback(() => {
		setIsOpen(false);
		fireUIAnalytics(createAnalyticsEvent({}), 'select closed', 'descriptionField');
		sendPendoTrackEvent({
			source: 'publishModal',
			actionSubjectAndAction: 'select closed',
			actionSubjectId: 'descriptionField',
		});
	}, [createAnalyticsEvent]);

	const handleOpenRightSidebarOnCreate = useCallback(() => {
		sendPendoTrackEvent({
			source: 'publishModal',
			actionSubjectAndAction: 'button clicked',
			actionSubjectId: 'createNewField',
		});
		openRightSidebarOnCreate(undefined, onChange);
	}, [openRightSidebarOnCreate, onChange]);

	const handleChange = useCallback(
		(option: OptionType | null) => {
			if (option) {
				onChange(option.key);

				const analyticsAttributes = {
					fieldType:
						descriptionField?.key === option.key
							? AnalyticsFieldTypes.DEFAULT
							: AnalyticsFieldTypes.SHORT_TEXT,
				};
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'descriptionField changed',
					analyticsAttributes,
				);
				sendPendoTrackEvent({
					source: 'publishModal',
					actionSubjectAndAction: 'descriptionField changed',
					attributes: analyticsAttributes,
				});
				handleClose();
			}
		},
		[createAnalyticsEvent, descriptionField?.key, handleClose, onChange],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={handleClose}
			placement="bottom-end"
			// zIndex of sharing dialog is 501
			zIndex={502}
			content={() => (
				<Select
					components={{
						Control: () => null,
						Option: OptionComponent,
						MenuList: (props) => (
							<MenuListComponent {...props} onCreateClick={handleOpenRightSidebarOnCreate} />
						),
					}}
					controlShouldRenderValue={false}
					hideSelectedOptions={false}
					isClearable={false}
					escapeClearsValue
					menuIsOpen
					onChange={handleChange}
					options={fieldOptions}
					isOptionSelected={({ key }) => key === selectedField?.key}
					styles={{
						menu: () => ({
							minWidth: SELECT_MIN_WIDTH,
							maxWidth: SELECT_MAX_WIDTH,
						}),
					}}
					isSearchable={false}
				/>
			)}
			trigger={(triggerProps) => (
				<ButtonWrapper>
					<Button
						{...triggerProps}
						id="pendo.publish-modal.description-field-button"
						isSelected={isOpen}
						iconAfter={<ChevronDownIcon label={formatMessage(messages.placeholder)} />}
						onClick={handleToggle}
					>
						{selectedField ? (
							<>
								<FieldSelectOptionComponent
									fieldKey={selectedField.key}
									emojiId={selectedField.emoji}
									hasWeightType={selectedField.configuration?.optionWeightType !== undefined}
									{...selectedField}
								/>
							</>
						) : (
							formatMessage(messages.placeholder)
						)}
					</Button>
				</ButtonWrapper>
			)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minWidth: `${SELECT_MIN_WIDTH}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		maxWidth: `${SELECT_MAX_WIDTH}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateFieldButtonContainer = styled.div({
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
	boxShadow: '0 -1px 0 rgb(0 0 0 / 10%)',
});
