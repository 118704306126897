import React, { useRef } from 'react';
import Avatar from '@atlaskit/avatar';
import { SimpleTag as Tag } from '@atlaskit/tag';
import { Box, xcss } from '@atlaskit/primitives';
import { ObservableValuesList } from '../../../observable-values-list/index.tsx';
import type { UserTagsProps } from '../types.tsx';

export const HorizontalLayout = ({
	users,
	hiddenCountRef,
	setHiddenCountRefValue,
}: UserTagsProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);

	return (
		<Box ref={containerRef} xcss={containerStyles}>
			<ObservableValuesList
				isActive
				containerRef={containerRef}
				hiddenCountExternalRef={hiddenCountRef}
				setHiddenCountExternalRefValue={setHiddenCountRefValue}
			>
				{users.map((user) => (
					<Box key={user.id} xcss={tagContainerStyles}>
						<Tag
							text={user.name}
							appearance="rounded"
							elemBefore={<Avatar src={user.avatarUrl} borderColor="transparent" size="xsmall" />}
						/>
					</Box>
				))}
			</ObservableValuesList>
		</Box>
	);
};

const containerStyles = xcss({
	display: 'flex',
	gap: 'space.050',
	position: 'relative',
	overflow: 'hidden',
});

const tagContainerStyles = xcss({
	display: 'flex',
});
