import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	more: {
		id: 'polaris-common.connection.list.more-non-final',
		defaultMessage: '+ {amount} more',
		description:
			'Button label for opening sidebar with connected issues, showing how many more items are available, e.g.: 16 more',
	},
});
