import type { CSSProperties } from 'react';

// styles extracted to separate constants in order to reuse them in card height calculations
export const CONNECTIONS_LIST_STYLES = {
	gap: 6,
	marginTop: 8,
} satisfies CSSProperties;

export const CONNECTION_FIELD_TOP_PADDING = 4;
export const CONNECTION_FIELD_LABEL_HEIGHT = 16;

export const CONNECTION_FIELD_ITEMS_LIMIT = 10;
