import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectsSectionTitle: {
		id: 'polaris-component-collection-configure-sidebar.default-content.projects-section-title',
		defaultMessage: 'Projects',
		description: 'Title for the projects section in the roadmap configuration sidebar',
	},
	manageProjectsButtonLabel: {
		id: 'polaris-component-collection-configure-sidebar.default-content.manage-projects-button-label',
		defaultMessage: 'Manage projects',
		description: 'Label for the button to manage projects in the roadmap configuration sidebar',
	},
	filtersSectionTitle: {
		id: 'polaris-component-collection-configure-sidebar.default-content.filters-section-title',
		defaultMessage: 'Filters',
		description: 'Title for the filters section in the roadmap configuration sidebar',
	},
	manageFiltersButtonLabel: {
		id: 'polaris-component-collection-configure-sidebar.default-content.manage-filters-button-label',
		defaultMessage: 'Manage filters',
		description: 'Label for the button to manage filters in the roadmap configuration sidebar',
	},
});
