import React from 'react';
import { Flex, Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import AsyncTeamProfileCardNext from '@atlassian/jira-profilecard-next/src/ui/team-profilecard/async.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const extractId = (teamId?: string): string | undefined => teamId?.split('/').pop();

const TeamName = ({ name }: { name: string }) => (
	<Flex alignItems="center" xcss={teamNameContainerStyles}>
		<Text maxLines={1} size="small" weight="semibold" color="color.text.accent.blue.bolder">
			{name}
		</Text>
	</Flex>
);

export const TeamValue = ({
	id,
	name,
	displayPlaceholder = false,
	displayPreview = false,
}: Props) => {
	const { formatMessage } = useIntl();

	if (!name && !displayPlaceholder) {
		return <Box xcss={emptyStyles} />;
	}

	if (!name) {
		return (
			<Box xcss={placeholderStyles} paddingInline="space.050">
				<Text color="color.text.subtlest">{formatMessage(messages.noValue)}</Text>
			</Box>
		);
	}

	const extractedId = extractId(id);

	if (!displayPreview || !extractedId) {
		return <TeamName name={name} />;
	}

	return (
		<Box xcss={teamFieldContainerStyles}>
			<AsyncTeamProfileCardNext teamId={extractedId} trigger="hover" triggerLinkType="none">
				<TeamName name={name} />
			</AsyncTeamProfileCardNext>
		</Box>
	);
};

const emptyStyles = xcss({
	height: '24px',
});

const placeholderStyles = xcss({
	// we need 5px margin to match label/select/multi-select, so that they align if used together
	// xcss doesn't allow '5px', hence the transparent border
	borderLeft: '1px solid transparent',
	paddingInline: 'space.050',
});

const teamFieldContainerStyles = xcss({
	cursor: 'pointer',
});

const teamNameContainerStyles = xcss({
	width: 'fit-content',
	paddingInline: 'space.050',
	marginInline: 'space.050',
	marginBlock: 'space.025',
	height: '22px',
	borderRadius: '3px',
	background: token('color.background.accent.blue.subtler'),
});
