/** @jsx jsx */
import React, { useCallback, useState } from 'react';
import { css, jsx } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button/new';
import ErrorIcon from '@atlaskit/icon/core/error';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	ACCESS_ROLE_EDITOR,
	ACCESS_ROLE_ERRORS,
	ACCESS_ROLE_VIEWER,
	PRINCIPAL_TYPES,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRole,
	AccessRoleError,
	PrincipalType,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import messages from './messages.tsx';

export type AccessListItemProps = {
	id: string;
	name: string;
	role: AccessRole;
	type: PrincipalType;
	canDowngradeRole: boolean;
	userType?: string;
	isCurrentUser?: boolean;
	avatarUrl?: string;
	isDisabled?: boolean;
	error?: AccessRoleError;
	onPrincipalRoleChange: ({
		name,
		id,
		role,
		type,
		avatarUrl,
	}: {
		name: string;
		id: string;
		role: AccessRole;
		type: PrincipalType;
		avatarUrl?: string;
	}) => Promise<void>;
	onRemovePrincipals: ({
		accounts,
		groups,
	}: {
		accounts: string[];
		groups: string[];
	}) => Promise<void>;
};

export const AccessListItem = ({
	id,
	name,
	role,
	isCurrentUser = false,
	avatarUrl,
	userType,
	type,
	canDowngradeRole,
	isDisabled = false,
	error,
	onPrincipalRoleChange,
	onRemovePrincipals,
}: AccessListItemProps) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleRoleChange = useCallback(
		async (newRole: AccessRole) => {
			setIsOpen(false);

			if (role !== newRole) {
				setIsLoading(true);
				await onPrincipalRoleChange({
					name,
					id,
					role: newRole,
					type,
					avatarUrl,
				});
				setIsLoading(false);
			}
		},
		[role, name, id, type, avatarUrl, onPrincipalRoleChange],
	);

	const handleRemoveAccess = useCallback(async () => {
		setIsOpen(false);

		await onRemovePrincipals(
			type === PRINCIPAL_TYPES.PROFILE
				? {
						accounts: [id],
						groups: [],
					}
				: {
						accounts: [],
						groups: [id],
					},
		);
	}, [type, onRemovePrincipals, id]);

	return (
		<Flex
			gap="space.100"
			alignItems="center"
			testId="polaris-component-view-access.ui.access-screen.access-user-list.access-list-item.item-container"
		>
			<Avatar src={avatarUrl} />
			<Flex alignItems="center" xcss={principalInfoContainerStyles}>
				<Stack grow="fill">
					<Text weight="medium">
						{name}
						{isCurrentUser && ` (${formatMessage(messages.youLabel)})`}
					</Text>
					{userType && (
						<Text size="small" weight="medium" color="color.text.subtlest">
							{userType}
						</Text>
					)}
				</Stack>
				{error && (
					<Tooltip
						content={
							error === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION
								? formatMessage(messages.errorTooltipMissingProjectAccess)
								: formatMessage(messages.errorTooltipMissingManageViewsPermission)
						}
					>
						<ErrorIcon
							testId="polaris-component-view-access.ui.access-screen.access-user-list.access-list-item.error-icon"
							label=""
							color={token('color.icon.accent.red')}
						/>
					</Tooltip>
				)}
			</Flex>
			{isDisabled || error === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION ? (
				<Box xcss={roleLabelStyles}>
					{role === ACCESS_ROLE_EDITOR
						? formatMessage(messages.canEditLabel)
						: formatMessage(messages.canViewLabel)}
				</Box>
			) : (
				<Popup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					placement="bottom"
					content={() => (
						<MenuGroup spacing="compact" minWidth={120}>
							<Section>
								<ButtonItem
									isSelected={role === ACCESS_ROLE_EDITOR}
									onClick={() => handleRoleChange(ACCESS_ROLE_EDITOR)}
									testId="polaris-component-view-access.ui.access-screen.access-user-list.access-list-item.edit-role-button"
								>
									{formatMessage(messages.canEditLabel)}
								</ButtonItem>
								<Tooltip
									content={
										!canDowngradeRole ? formatMessage(messages.downgradeEditorTooltip) : undefined
									}
								>
									<ButtonItem
										isSelected={role === ACCESS_ROLE_VIEWER}
										isDisabled={!canDowngradeRole}
										onClick={() => handleRoleChange(ACCESS_ROLE_VIEWER)}
										testId="polaris-component-view-access.ui.access-screen.access-user-list.access-list-item.view-role-button"
									>
										{formatMessage(messages.canViewLabel)}
									</ButtonItem>
								</Tooltip>
							</Section>
						</MenuGroup>
					)}
					zIndex={layers.modal + 1}
					trigger={(triggerProps) => (
						<div css={accessListItemButtonWrapperStyles}>
							<Button
								{...triggerProps}
								appearance="subtle"
								isSelected={isOpen}
								onClick={() => setIsOpen(!isOpen)}
								iconAfter={ChevronDownIcon}
								testId="polaris-component-view-access.ui.access-screen.access-user-list.access-list-item.role-button"
								isDisabled={isLoading}
								isLoading={isLoading}
								data-component-selector="access-list-item-button-g39Sv"
							>
								{role === ACCESS_ROLE_EDITOR
									? formatMessage(messages.canEditLabel)
									: formatMessage(messages.canViewLabel)}
							</Button>
						</div>
					)}
				/>
			)}
			<Box xcss={removePrincipalContainerStyles}>
				{canDowngradeRole && (
					<Button
						onClick={handleRemoveAccess}
						isDisabled={!canDowngradeRole}
						testId="polaris-component-view-access.ui.access-screen.access-user-list.access-list-item.remove-access"
						appearance="subtle"
						shouldFitContainer
					>
						{formatMessage(messages.removeAccessLabel)}
					</Button>
				)}
			</Box>
		</Flex>
	);
};

const roleLabelStyles = xcss({
	flexShrink: 0,
	width: '120px',
	paddingInline: 'space.100',
});

const principalInfoContainerStyles = xcss({
	flexGrow: 1,
});

const accessListItemButtonWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
	width: '120px',
	flexShrink: 0,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="access-list-item-button-g39Sv"]': {
		flexGrow: 1,
		textAlign: 'left',
		paddingLeft: token('space.100'),
	},
});

const removePrincipalContainerStyles = xcss({
	flexShrink: '0',
	width: '80px',
});
