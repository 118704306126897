import React, { useEffect, useState } from 'react';
import type { PolarisRoute } from '@atlassian/jira-polaris-common/src/controllers/route/types.tsx';
import { usePermissions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useIsProjectAdmin,
	useCanEditIssues,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { updatePendoVisitorOptions } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { FireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

const usePrimaryRole = (): string => {
	const isAdmin = useIsProjectAdmin();
	const [canEditIssues] = useCanEditIssues();

	if (isAdmin) {
		return 'ADMIN';
	}
	return canEditIssues ? 'CREATOR' : 'CONTRIBUTOR';
};

export const FireRoleAnalytics = () => {
	const tenantContext = useTenantContext();

	const [permissions] = usePermissions();
	const primaryRole = usePrimaryRole();
	const [roleIdentified, setRoleIdentified] = useState(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		if (permissions.meta.loaded) {
			setRoleIdentified(true);
			updatePendoVisitorOptions({ role: primaryRole });
		}
	}, [
		createAnalyticsEvent,
		permissions.meta.loaded,
		primaryRole,
		tenantContext.atlassianAccountId,
	]);

	return roleIdentified ? (
		<FireTrackAnalytics
			eventName="JpdUserRole identified"
			attributes={{
				role: primaryRole,
				appEditions: tenantContext.appEditions,
				appPermissions: tenantContext.appPermissions,
				isJiraAdmin: tenantContext.isAdmin,
				isSiteAdmin: tenantContext.isSiteAdmin,
			}}
		/>
	) : null;
};

export const constructAppUrl = (projectKey: string, route?: PolarisRoute) => {
	const appUrl = `/jira/polaris/projects/${projectKey}/ideas`;
	if (route?.section === undefined) {
		return appUrl;
	}
	const sectionUrl = `${appUrl}/${route?.section}`;
	if (route?.resource === undefined) {
		return sectionUrl;
	}
	return `${sectionUrl}/${route?.resource}`;
};
