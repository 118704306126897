// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See closest README.md

import { createIllustration } from '@atlassian/jira-illustration/src/ui/index.tsx';
import type { SVGViewBox } from '@atlassian/jira-illustration/src/ui/types.tsx';
import srcDark from '../../assets/jpd-timeline-blank-darkmode.svg';
import srcLight from '../../assets/jpd-timeline-blank.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
	autoDark: false,
});

export default Illustration;

// for advanced use only
// view box should be automatically set by svg values
const viewBox: SVGViewBox = [0, 0, 290, 232];
export { viewBox };
