import React, { type RefObject } from 'react';
import { LinkButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	useIssueTypeAvatarId,
	useIssueTypeIdsForProject,
	useIssueTypeName,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import { makeAvatarUrlFromId } from '@atlassian/jira-polaris-component-issue-types/src/common/utils/make-avatar-url-from-id.tsx';
import { messages } from './messages.tsx';
import { IssueTypeSelectTrigger, type TriggerType } from './trigger.tsx';

type IssueTypeOptionProps = {
	issueTypeId: IssueTypeId;
	isSelected: boolean;
	onClick: (issueType: IssueTypeFieldValue) => void;
};

const IssueTypeOption = ({ issueTypeId, onClick, isSelected }: IssueTypeOptionProps) => {
	const avatarId = useIssueTypeAvatarId({ issueTypeId });
	const name = useIssueTypeName({ issueTypeId });
	const iconUrl = makeAvatarUrlFromId(avatarId ?? '', 'xsmall');

	if (!name) {
		return null;
	}

	return (
		<DropdownItem
			onClick={() => onClick({ id: issueTypeId, name, iconUrl })}
			isSelected={isSelected}
			elemBefore={<IssueTypeIcon issueTypeId={issueTypeId} />}
		>
			{name}
		</DropdownItem>
	);
};

type Props = {
	issueTypeId: IssueTypeId;
	projectKey: string;
	triggerType?: TriggerType;
	onChange?: (issueType: IssueTypeFieldValue) => void;
	availableIssueTypeIds?: IssueTypeId[];
	returnFocusRef?: RefObject<HTMLElement>;
};

export const IssueTypeSelectEditableView = ({
	issueTypeId,
	onChange,
	projectKey,
	triggerType = 'icon-only',
	availableIssueTypeIds,
	returnFocusRef,
}: Props) => {
	const { formatMessage } = useIntl();
	const container = useEnvironmentContainer();
	const issueTypeIdsForProject = useIssueTypeIdsForProject({ projectId: container?.id });
	const issueTypeIds = availableIssueTypeIds ?? issueTypeIdsForProject;

	const handleOptionClick = (newIssueType: IssueTypeFieldValue) => {
		if (issueTypeId === newIssueType.id) {
			return;
		}

		onChange && onChange(newIssueType);
	};

	return (
		<DropdownMenu<HTMLButtonElement>
			trigger={(triggerProps) => (
				<IssueTypeSelectTrigger {...triggerProps} issueTypeId={issueTypeId} type={triggerType} />
			)}
			spacing="compact"
			returnFocusRef={returnFocusRef}
		>
			<DropdownItemGroup>
				{issueTypeIds.map((id) => (
					<IssueTypeOption
						key={id}
						issueTypeId={id}
						isSelected={id === issueTypeId}
						onClick={handleOptionClick}
					/>
				))}
			</DropdownItemGroup>
			<DropdownItemGroup hasSeparator>
				<Box paddingInline="space.150">
					<LinkButton
						href={`/jira/polaris/projects/${projectKey}/ideas/settings/types`}
						appearance="default"
					>
						{formatMessage(messages.editIssueTypesLabel)}
					</LinkButton>
				</Box>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};
